import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import ErrorModal from "components/Modals/ErrorModal";
import ResponsibleCard from "../ResponsibleAndSubAccounts/ResponsibleCard";
import NewBillingAddressModal from "./NewBillingAddressModal";
import { deleteBillingInfo, getBillingInfoList } from "services/allServices";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function BillingAddressSetting() {
  const { t, i18n } = useTranslation(["settings", "global", "nav", "projects"]);
  
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [billingInfoList, setBillingInfoList] = useState();
  const { addToast } = useToasts();


  const onListBilingInfo = () => {
    getBillingInfoList().then(res => {
      setBillingInfoList(res);
    });
  };

  const onDeleteBillingInfo = (billing_info_id) => {
    deleteBillingInfo(billing_info_id).then(res => {
      if (res.status === "cannot_delete_last_proforma") {
        addToast(t("settings:isOneBillingAddress"), {
          appearance: "error",
          autoDismiss: true
        });
        setOpenModal(false);

      } else if (res.status === "related_project_exists") {
        addToast(t("settings:invoicesAlert"), {
          appearance: "error",
          autoDismiss: true
        });
        setOpenModal(false);
      } else {
        onListBilingInfo();
        setOpenModal(false);
        addToast(t("settings:deletionSuccessful"), {
          appearance: "success",
          autoDismiss: true
        });
      }

    });
  };


  useEffect(() => {
    onListBilingInfo();
  }, []);

  return (
    <div className="container">
      <div className="items-center justify-between lg:flex">
        <div>
          <h2 className="mt-5 mb-1 font-semibold">{t("settings:myBillingAddresses")}</h2>
          <p>
            {t("projects:billingDesc")}
          </p>
        </div>
        <Button
          onClick={() => setOpenCreateModal(true)}
          primary
          className="flex items-center px-3 mt-3 lg:mt-0"
        >
          <i className="mr-2 ri-add-circle-line"></i>
          {t("settings:createNewAddress")}
        </Button>
      </div>
      <div className="grid grid-cols-4 gap-10 mt-10">
        {billingInfoList?.map((item, index) => {
          return (
            <div className="col-span-4 lg:col-span-1" key={index}>
              <ResponsibleCard
                title={item.billing_info_name}
                description={item.is_personal ? item.name + " " + item.surname : (item.company_name?.length > 18 ? item.company_name.substring(0, 18) + "..." : item.company_name)}
                setOpenModal={(v) => setOpenModal(v)}
                setOpenCreateModal={(v) => setOpenEditModal(v)}
                data={item}
              />
            </div>
          );
        })

        }
      </div>
      {openModal && (
        <ErrorModal
          title={t("settings:deleteAddress")}
          question={t("settings:sureDeleteAddress")}
          buttonText={t("global:yesDelete")}
          setOpenModal={() => setOpenModal(false)}
          onClick={() => onDeleteBillingInfo(openModal.id)}

        />
      )}
      {openCreateModal && (
        <NewBillingAddressModal setOpenModal={(v) => setOpenCreateModal(v)}
                                onListBilingInfo={() => onListBilingInfo()} />
      )}

      {openEditModal && (
        <NewBillingAddressModal datas={openEditModal} setOpenModal={(v) => setOpenEditModal(v)}
                                onListBilingInfo={() => onListBilingInfo()} />
      )}
    </div>
  );
}

export default BillingAddressSetting;
