import React, { useEffect, useState } from "react";
import { TextField } from "components";
import { useTranslation } from "react-i18next";
import RadioButton from "components/FormElements/RadioButton";
import BillingPersonalForm from "./BillingPersonalForm";
import { useAuthStore } from "store/AuthStore";
import { createBillingInfo, updateBillingInfo } from "services/allServices";
import BillingCorporateForm from "./BillingCorporateForm";
import { useToasts } from "react-toast-notifications";


function NewBillingAddressModal({
  title,
  question,
  description,
  setOpenModal,
  onListBilingInfo,
  datas
}) {
  const [userInformations] = useAuthStore((state) => [
    state.userInformations
  ]);
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [isCheck, setIsCheck] = useState("1");
  const [billingTitle, setBillingTitle] = useState();
  const [billingTitleError, setBillingTitleError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);


  const { addToast } = useToasts();

  const handleChange = (e) => {
    setIsCheck(e.target.value);
  };

  const onCreateBillingInfo = (data) => {
    if (billingTitle) {
      let configureData = {
        customer_id: userInformations.customer_info.id,
        billing_info_name: billingTitle,
        name: data.name ? data.name : "",
        surname: data.surname ? data.surname : "",
        email: data.email ? data.email : "",
        company_name: data.company_name ? data.company_name : "",
        country: data.country ? data.country : "",
        state: data.state ? data.state : "",
        cities: data.cities ? data.cities : "",
        address: data.address ? data.address : "",
        tax_no: data.tax_no ? data.tax_no : "",
        tax_office: data.tax_office ? data.tax_office : "",
        post_code: data.post_code ? data.post_code : "",
        tc_no: data.tc_no ? data.tc_no : "",
        is_corporate: isCheck === "2" ? true : false,
        is_personal: isCheck === "1" ? true : false,
        phone: data.phone
      };
      createBillingInfo(configureData).then(res => {
        setOpenModal(false);
        setDisableButton(false);
        onListBilingInfo();
        addToast(t("settings:billingAddressAdded"), {
          appearance: "success",
          autoDismiss: true
        });
      });
    } else {
      setBillingTitleError(true);
      setDisableButton(false);
    }

  };

  const onEditBillingInfo = (data) => {
    let configureData = {
      customer_id: userInformations.customer_info.id,
      billing_info_name: billingTitle,
      name: data.name ? data.name : "",
      surname: data.surname ? data.surname : "",
      email: data.email,
      phone: data.phone,
      company_name: data.company_name ? data.company_name : "",
      country: data.country ? data.country : "",
      state: data.state ? data.state : "",
      cities: data.cities ? data.cities : "",
      address: data.address ? data.address : "",
      tax_no: data.tax_no ? data.tax_no : "",
      tax_office: data.tax_office ? data.tax_office : "",
      post_code: data.post_code ? data.post_code : "",
      tc_no: data.tc_no ? data.tc_no : "",
      is_corporate: isCheck === "2" ? true : false,
      is_personal: isCheck === "1" ? true : false
    };
    updateBillingInfo(datas.id, configureData).then(res => {
      setOpenModal(false);
      setDisableButton(false);
      onListBilingInfo();
      addToast(t("settings:updatedBillAddress"), {
        appearance: "success",
        autoDismiss: true
      });
    });
  };


  useEffect(() => {
    if (datas) {
      if (datas.is_personal) {
        setIsCheck("1");
      } else {
        setIsCheck("2");
      }
      setBillingTitle(datas.billing_info_name);
    }
  }, []);

  useEffect(() => {
    if (billingTitle) {
      setBillingTitleError(false);

    }
  }, [billingTitle]);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{datas ? t("settings:updateBillingAddress") : t("projects:newBillingAddress")} </h3>
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>
            <div className="flex flex-col pt-4 bg-modalBg">
              <div className="px-5 pt-4">
                <p>
                  {t("projects:billingDesc")}
                </p>
                <div className="grid grid-cols-3 gap-5">
                  <div className="col-span-3 mt-5 lg:col-span-2">
                    <TextField
                      id="title"
                      name="title"
                      type="text"
                      value={billingTitle}
                      onChange={setBillingTitle}
                      ref={null}
                      inputClass="capitalize"
                      label={t("projects:billingTitle")}
                      placeholder={t("projects:billingPlaceholder")}
                    />
                  </div>
                  <div className="col-span-3 mt-5 lg:col-span-1">
                    <p>{t("projects:addressType")}</p>
                    <div className="flex mt-3">
                      <div>
                        <RadioButton
                          id="1"
                          label={t("projects:individual")}
                          isCheck={isCheck === "1"}
                          setIsCheck={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="ml-3">
                        <RadioButton
                          id="2"
                          label={t("projects:corporate")}
                          isCheck={isCheck === "2"}
                          setIsCheck={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {billingTitleError &&
                  <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>

                }
              </div>


              {isCheck === "1" && (
                <BillingPersonalForm
                  datas={datas}
                  setOpenModal={(v) => setOpenModal(v)}
                  onCreateBillingInfo={(v) => onCreateBillingInfo(v)}
                  onEditBillingInfo={(v) => onEditBillingInfo(v)}
                  disableButton={disableButton}
                  setDisableButton={setDisableButton}
                />
              )}

              {isCheck === "2" && (
                <BillingCorporateForm
                  datas={datas}
                  setOpenModal={(v) => setOpenModal(v)}
                  onCreateBillingInfo={(v) => onCreateBillingInfo(v)}
                  onEditBillingInfo={(v) => onEditBillingInfo(v)}
                  disableButton={disableButton}
                  setDisableButton={setDisableButton}
                />
              )}


            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default NewBillingAddressModal;
