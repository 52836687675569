import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import { getTicketList } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import SupportRequestTable from "./TicketAndSupportTables/SupportRequestTable";
import NewSupportRequestModal from "./TicketAndSupportModals/NewSupportRequestModal";
import SupportRequestFilterModal from "./TicketAndSupportModals/SupportRequestFilterModal";
import { useGlobalStore } from "store/GlobalStore";
import { useLocation } from "react-router";
import TicketStaffRatingModal from "./TicketAndSupportModals/TicketStaffRatingModal";

function SupportRequest() {
  const { t } = useTranslation(["support", "global"]);
  const [
    supportRequestType,
    opensupportRequest,
    setOpensupportRequest
  ] = useGlobalStore((state) => [
    state.supportRequestType,
    state.opensupportRequest,
    state.setOpensupportRequest
  ]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openStaffRatingModal, setOpenStaffRatingModal] = useState(false);


  const [ticketList, setTicketList] = useState();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageLimit, ticketFilterData, setTicketFilterData] = useGlobalStore((state) => [state.pageLimit, state.ticketFilterData, state.setTicketFilterData]);
  const location = useLocation();

  const onGetTicketList = (page, limit, configureFilter) => {
    getTicketList(page, limit, configureFilter ? configureFilter : ticketFilterData).then((data) => {
      if (data?.status !== "ticket_not_found") {
        setTicketList(data);
        setNumberOfPages(data[0]?.number_of_pages);
      } else if (data?.status === "ticket_not_found") {
        setTicketList("ticket_not_found");
      }
    });
  };

  useEffect(() => {

    const asyncFunction = async () => {
      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }
      let configureFilter = {
        title: null,
        ticket_code: null,
        department_id: null,
        related_product_id: null,
        date: null
      };
      
      if (!ticketFilterData || ticketFilterData == "") {
        await setTicketFilterData(configureFilter);
        await onGetTicketList(page, pageLimit, configureFilter);

      } else {
        await onGetTicketList(page, pageLimit, ticketFilterData);
      }

    };

    asyncFunction();
  }, [opensupportRequest]);
  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("support:allTickets")}</h2>
        <div className="items-center lg:flex ">
          <div className="flex my-5 lg:my-0">
            <div className="relative">
              <Button
                onClick={() => setOpenFilterModal(true)}
                className="flex items-center mr-5 px-7"
                tertiary
              >
                <i className="ri-equalizer-line" />
                <span>{t("global:filter")}</span>
              </Button>
              {(ticketFilterData?.count && ticketFilterData?.count > 0) ?
                <div
                  className="absolute flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 right-3 bg-primary-hover">
                  {ticketFilterData?.count}
                </div>
                :
                <></>
              }
            </div>
            <Button
              onClick={() => setOpensupportRequest(true)}
              className="flex items-center px-7"
              secondary
              icon
              dark
            >
              <i className="ri-folder-add-line" />
              <span>{t("support:newTicket")}</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        {ticketList && ticketList !== "ticket_not_found" ? (
          <SupportRequestTable ticketList={ticketList} onGetTicketList={onGetTicketList} numberOfPages={numberOfPages}
            setOpenStaffRatingModal={setOpenStaffRatingModal} />
        ) : ticketList === "ticket_not_found" ? (
          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/ticketNotFound.svg" width="572" height="288" />
            <h1 className="mt-6"> {t("support:noTicket")}</h1>
            <h5>{t("support:ticketDesc")}</h5>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full mt-20">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
              type="Oval"
              color="#ff7c30"
              height={75}
              width={75}
              timeout={3000} //3 secs
            />
          </div>
        )}
      </div>

      {opensupportRequest && (
        <NewSupportRequestModal
          setOpenModal={() => setOpensupportRequest(false)}
        />
      )}
      {openFilterModal && (
        <SupportRequestFilterModal
          setOpenModal={() => setOpenFilterModal(false)}
          pageLimit={pageLimit}
          onClick={onGetTicketList}
          ticketFilterData={ticketFilterData}
          setTicketFilterData={setTicketFilterData}
        />
      )}

      {openStaffRatingModal &&
        <TicketStaffRatingModal
          ticketID={openStaffRatingModal}
          setOpenModal={() => setOpenStaffRatingModal(false)}

        />

      }
    </div>
  );
}

export default SupportRequest;
