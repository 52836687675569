import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import RadioButton from "components/FormElements/RadioButton";
import ComboBox from "components/FormElements/ComboBox";
import { useMultiselectComboBox } from "hooks";
import { createAuthorizedStaff, getProjectList, updateAuthorizedStaff } from "services/allServices";
import CheckBoxList from "components/FormElements/CheckBoxList";
import { PermissionList } from "./PermissionList";
import { useToasts } from "react-toast-notifications";

function ResponsibleCreateModal({
  openModal,
  setOpenModal,
  onGetResponsibleList
}) {

  const { t, i18n } = useTranslation(["projects", "global", "settings"]);

  const [selectedProject, listProject, changeSelectedProject, setProjectList, setAllSelected] = useMultiselectComboBox("Proje Seçiniz");
  const { addToast } = useToasts();
  const [permissionList, setPermissionList] = useState(PermissionList.permissionList);
  const [isCheck, setIsCheck] = useState("all");
  const [customerCode, setCustomerCode] = useState("");
  const onGetProjectList = () => {
    getProjectList().then(async (res) => {
      await setProjectList(res, "id", "name");
      if (openModal === true) {
        await changeSelectedProject(res[0].id, res[0].name);
      } else {

        setAllSelected(openModal?.project_list)


      }
    });
  };


  const onChangeGroup = (value) => {
    setIsCheck(value);

    if (value === "all") {
      let newList = [];
      PermissionList.permissionList.map(item => {
        newList.push({ ...item, checked: true });
      });
      setPermissionList(newList);
    } else if (value === "accounting") {
      let newList = [];
      PermissionList.permissionList.map(item => {
        if (item.group === "accounting" || item.group === "all") {
          newList.push({ ...item, checked: true });
        } else {
          newList.push({ ...item, checked: false });
        }
      });
      setPermissionList(newList);
    } else if (value === "tech") {
      let newList = [];
      PermissionList.permissionList.map(item => {
        if (item.group === "tech" || item.group === "all") {
          newList.push({ ...item, checked: true });
        } else {
          newList.push({ ...item, checked: false });
        }
      });
      setPermissionList(newList);
    }
  };

  const onCreateAuthorizedStaff = () => {
    let configureParams = {
      customer_code: customerCode,
      permission_list: permissionList,
      project_list: selectedProject,
      group_name: isCheck
    };

    if (openModal === true) {
      createAuthorizedStaff(configureParams).then(res => {
        if (res?.status === "customer_not_found") {
          addToast(t("settings:noFoundCustomer"), {
            appearance: "error",
            autoDismiss: true
          });
          setOpenModal(false);
          return;
        } else {
          setOpenModal(false);
          addToast(t("global:successfully"), {
            appearance: "success",
            autoDismiss: true
          });
          onGetResponsibleList();
        }
      });
    } else {
      updateAuthorizedStaff(openModal.authorized_account_id, configureParams).then(res => {
        if (res?.status === "customer_not_found") {
          addToast(t("settings:noFoundCustomer"), {
            appearance: "error",
            autoDismiss: true
          });
          setOpenModal(false);
          return;
        } else {
          setOpenModal(false);
          addToast(t("global:successfully"), {
            appearance: "success",
            autoDismiss: true
          });
          onGetResponsibleList();
        }
      });
    }
  };

  useEffect(() => {
    onGetProjectList();
      if (openModal === true) {

        if (isCheck === "all") {
          let newList = [];
          PermissionList.permissionList.map(item => {
            newList.push({ ...item, checked: true });
          });
          setPermissionList(newList);
        }
      }

      if (openModal !== true) {
        setCustomerCode(openModal?.customer_code);
        setIsCheck(openModal?.group_name);
        setPermissionList(openModal?.permission_list);
        if (openModal?.permission_list?.length > 0 && openModal?.permission_list?.length !== PermissionList.permissionList?.length) {
          let newItems = [] //permissionList güncellenmişse eski listede olmayanları ekler
          PermissionList.permissionList.map(item => {
            if (!openModal?.permission_list?.find(x => x.id === item.id)) {
              newItems.push(item)
            }
          })
          setPermissionList([...openModal?.permission_list, ...newItems])

        }

      }
    

  }, []);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{t("settings:newResponsible")}</h3>
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>
            <div className="flex flex-col px-5 pt-4 pb-10 bg-modalBg">
              <p>
                {t("settings:editOrAssignPerson")}
              </p>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <TextField
                  id="code"
                  name="code"
                  type="text"
                  ref={null}
                  label={t("nav:authorizedCode")}
                  placeholder="ORN-CP15987352"
                  value={customerCode}
                  onChange={(value) => setCustomerCode(value)}
                />
                <ComboBox
                  id="product_property_rule1"
                  name="product_property_rule1"
                  selected={selectedProject}
                  setSelected={changeSelectedProject}
                  list={listProject}
                  label={t("global:project")}
                  multiselect={true}
                  isSearchable={false}
                />
              </div>

              <div className="flex mt-3">
                <div className="mr-5">
                  <RadioButton
                    id="all"
                    label={t("settings:administrative")}
                    isCheck={isCheck === "all"}
                    setIsCheck={(e) => onChangeGroup(e.target.value)}
                  />
                </div>
                <div className="mr-5">
                  <RadioButton
                    id="accounting"
                    label={t("nav:accounting")}
                    isCheck={isCheck === "accounting"}
                    setIsCheck={(e) => onChangeGroup(e.target.value)}
                  />
                </div>
                <div className="">
                  <RadioButton
                    id="tech"
                    label={t("settings:technical")}
                    isCheck={isCheck === "tech"}
                    setIsCheck={(e) => onChangeGroup(e.target.value)}
                  />
                </div>

              </div>

              <div>
                <CheckBoxList
                  items={permissionList}
                  allItems={permissionList}
                  required="required"
                  label="hide"
                  setCheckbox={setPermissionList}
                  inputStyle=" text-sm cursor-pointer my-4 flex items-center"
                  labelStyle="mt-3 mb-0"
                  checkBoxStyle=""
                  containerStyle="grid grid-cols-4  mt-5"
                //selectIconOnClick={(id) => onSetSelectedGroup(id, "tab")}
                //onClickedItem={(item) => controlListItems("tab", item.id, !item.checked)}
                />
              </div>


            </div>

            <div
              className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button onClick={onCreateAuthorizedStaff} className="w-full px-12 mr-0 lg:w-auto" primary>
                {t("global:apply")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsibleCreateModal;
