import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import AlertWarning from "components/ContentAlerts/AlertWarning";
import AlertInfo from "components/ContentAlerts/AlertInfo";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox, useCountry } from "hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCSR, getCountry } from "services/allServices";
import { useAuthStore } from "store/AuthStore";

const schema = yup.object().shape({

  company_name: yup.string().required(),
  department: yup.string().required(),
  domain: yup.string().required(),
  email: yup.string().required(),
});

function CSRCreateModal({ setOpenModal, setOpenCSRCreatedModal, hostname }) {
  let language = localStorage.getItem("ln");

  const { t, i18n } = useTranslation(["global", "services","signup", "nav"]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [selectedTab, setSelectedTab] = useState("1");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [
    country,
    countryStates,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    onGetCountry,
    onGetStates,
    onGetCities,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState
  ] = useCountry();

  const onSubmit = (data) => {
    let configure = {
      organization: data.company_name,
      organizational_unit: data.department,
      common_name: data.domain,
      email_address: data.email,
      country: selectedCountry.id,
      state: selectedState.text,
      city: selectedCity.text,
    }
    setButtonDisabled(true);

    createCSR(configure).then((res) => {
      setOpenModal(false);
      setOpenCSRCreatedModal(res);
      setSelectedTab("1");

    })

  }



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fixed inset-0 z-10 overflow-y-auto ">
        <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <div
            className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{ overflow: "initial" }}
          >
            <div className="bg-white ">
              <div className="sm:flex sm:items-start">
                <div className="w-full">
                  <div className="flex items-center justify-between px-5 py-3 ">
                    <h3
                      className="text-xl font-semibold leading-6 text-title"
                      id="modal-headline"
                    >
                      {t("services:generateCsrKey")}
                    </h3>
                    <i
                      onClick={() => setOpenModal(false)}
                      className="text-2xl cursor-pointer ri-close-line text-main"
                    ></i>
                  </div>
                  <div className="w-full px-5 pt-5 pb-10 bg-modalBg">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                      <div
                        onClick={() => setSelectedTab("1")}
                        className={`col-span-1 bg-white flex items-center justify-center rounded-md p-2 border cursor-pointer ${selectedTab === "1" ? "border-primary-hover" : "border-passive"}`}>
                        <i className={`ri-window-2-line  text-2xl ${selectedTab === "1" ? "text-primary-hover" : "text-main"}`}></i>
                        <p className={`ml-2 font-semibold text-lg ${selectedTab === "1" && "text-primary-hover"}`}>{t("global:other")}</p>
                      </div>
                      <div
                        onClick={() => setSelectedTab("2")}
                        className={`col-span-1 bg-white flex items-center justify-center rounded-md p-2 border cursor-pointer ${selectedTab === "2" ? "border-primary-hover" : "border-passive"}`}>
                        <i className={`ri-windows-line  text-2xl ${selectedTab === "2" ? "text-primary-hover" : "text-main"}`}></i>
                        <p className={`ml-2 font-semibold text-lg ${selectedTab === "2" && "text-primary-hover"}`}> IIS</p>
                      </div>
                    </div>
                    {selectedTab === "1" && (
                      <>
                        <div className="mt-5">
                          <AlertInfo
                            title={t("global:information")}
                            message={t("services:noTurkishCharacter")}
                            message2={t("services:mailUsedForCertificate")}
                            message3={t("services:generatedRsa")}

                          />
                        </div>
                        <div className="my-5">
                          <AlertWarning
                            title={t("services:beCareful")}
                            message={t("services:rsaDesc")}
                            message2="Örn: *makdos.com"
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                          <div className="col-span-2 lg:col-span-1">
                            <TextField
                              label={t("signup:companytitle")}
                              placeholder={t("signup:companytitle")}
                              type="text"
                              name="company_name"
                              innerRef={register}

                            />
                            {errors.company_name && errors.company_name.type === "required" && (
                              <span className="text-sm text-alert-red">Bu alan zorunludur.</span>
                            )}
                          </div>
                          <div className="col-span-2 lg:col-span-1">
                            <TextField
                              label={t("services:companyDepartment")}
                              placeholder={t("services:technology")}
                              type="text"
                              name="department"
                              innerRef={register}

                            />
                            {errors.department && errors.department.type === "required" && (
                              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
                            )}
                          </div>

                          <div className="col-span-2 lg:col-span-1">
                            <TextField
                              label={t("nav:domain")}
                              placeholder="www.alanadi.com"
                              type="text"
                              name="domain"
                              innerRef={register}
                              defaultText={hostname}


                            />
                          </div>
                          <div className="col-span-2 lg:col-span-1">
                            <ComboBox
                              id="country"
                              name="country"
                              label={t("global:country")}
                              isSearchable
                              list={country}
                              selected={selectedCountry}
                              setSelected={setSelectedCountry}
                            />
                          </div>
                          {selectedCountry &&
                            selectedCountry.text !== "Ülke Seçiniz" && (
                              <div className="col-span-2 lg:col-span-1">
                                <ComboBox
                                  id="state"
                                  name="state"
                                  label={t("global:province")}
                                  isSearchable
                                  list={countryStates}
                                  selected={selectedState}
                                  setSelected={setSelectedState}
                                />
                              </div>
                            )}
                          {selectedState && selectedState.text !== "İl Seçiniz" && (
                            <div className="col-span-2 lg:col-span-1">
                              <ComboBox
                                id="cities"
                                name="cities"
                                label={t("global:district")}
                                isSearchable
                                list={cities}
                                selected={selectedCity}
                                setSelected={setSelectedCity}
                              />
                            </div>
                          )}

                          <div className="col-span-2 lg:col-span-2">
                            <TextField
                              id="email"
                              name="email"
                              label={t("global:email")}
                              placeholder="info@alanadiniz.com"
                              type="email"
                              innerRef={register}

                            />
                            {errors.email && errors.email.type === "required" && (
                              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
                            )}
                            <p className="mt-1 text-sm font-bold">{t("services:mailDesc")}.</p>
                          </div>
                        </div>
                      </>
                    )}

                    {selectedTab === "2" && (
                      <div className="mt-5">
                        <img src="/player.svg" width="100%" height="251" />
                        <h5 className="my-2 font-semibold">
                          {t("services:issSslInstallation")}
                        </h5>
                        {language === 'tr' ? <p>
                          Windows için IIS kurulumu hakkımızdaki videomuzu
                          izleyebilir veya{" "}
                          <a className="text-primary-hover">buradaki</a> yazımızı
                          okuyarak adımları takip edebilirsiniz.
                        </p> :
                        <p>
                          You can watch our video about installing IIS for Windows or follow the steps by reading our article <a className="text-primary-hover">here </a>.
                        </p>
                        }
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                    <Button
                      onClick={() => {
                        setSelectedTab("8");
                        setOpenModal(false);
                      }}
                      className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                      tertiary
                    >
                      {t("global:giveUp")}
                    </Button>
                    <Button
                      disabled={buttonDisabled}
                      className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                      primary
                      type="submit"
                    >
                      {t("global:create")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CSRCreateModal;
