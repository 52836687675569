import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { useCheckList } from "hooks";
import { useSupportTicketStore } from "store/SupportTicketStore";
import { useNavigate } from "react-router";
import MakdosTable from "components/MakdosTable";

function ComplaintNotificationTable({ abuseList }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [data, setData] = useState(abuseList);
  const [check, setCheck] = useState(false);
  const [checkArr, setCheckArr] = useState([]);
  const [checkList, onSetCheckList, setCheckList] = useCheckList();
  const navigate = useNavigate();
  const [setSelectedTicketID] = useSupportTicketStore((state) => [
    state.setSelectedTicketID
  ]);
  const pageLimits = [2, 5, 10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(5);
  const [activePage, setActivePage] = useState(1);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id"
      },
      {
        Header: "Konu",
        accessor: "title"
      },
      {
        Header: "Gönderen",
        accessor: "sender"
      },
      {
        Header: "Tarih",
        accessor: "time",
        Cell: (props) =>
          props.value !== null
            ? moment(props.value).format("DD-MM-YYYY HH:mm")
            : "-"
      },
      {
        Header: "IP Adresi",
        accessor: "ipaddress"
      },
      {
        Header: "",
        accessor: "col6",
        Cell: tableButton
      }
    ],
    [t]
  );

  function tableButton(props) {
    if (props) {
      return (
        <div
          onClick={() => {
            navigate("/complaint-notification-details");
          }}
          className="flex items-center cursor-pointer"
        >
          <i className="ri-eye-line curs"></i>
        </div>
      );
    }
  }

  useEffect(() => {
    setData(abuseList);
  }, [abuseList]);
  return (
    <div>
      {abuseList && abuseList !== "ticket_not_found" &&
        <div className="w-full mb-12 ">
          <MakdosTable
            isCheckboxVisible={false}
            setCheckList={() => {
            }}
            checkList={[]}
            data={data}
            setData={setData}
            showPagination={true}
            columns={columns}
            pageLimits={pageLimits}
            isBackendPagination={false}
            backendPaginationMethod={() => {
            }}
            activePageSize={activePageSize}
            setActivePageSize={setActivePageSize}
            activePage={activePage}
            setActivePage={setActivePage}
            isDndAvailable={false}
          />
        </div>
      }

    </div>
  );
}

export default ComplaintNotificationTable;
