import React from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

function VirtualIPTable({ setOpenNoteModal, setOpenIPDetailsModal, ipList }) {
  const { t, i18n } = useTranslation(["projects", "global", "services", "projects"]);
  const data = React.useMemo(
    () => ipList,
    [ipList]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t("services:ipAddress"),
        accessor: "ip_class",
        Cell: (props) => {
          if (props.value) {
            return props.value;
          } else {
            return props.row.original.ip_address;
          }
        }

      },
      {
        Header: "Subnet",
        accessor: "subnet_type",
        Cell: (props) => { return props.row.original.is_class ? props.value : "/32" }
      },
      {
        Header: t("projects:productName"),
        accessor: "customer_product"
      },
      {
        Header: "Nat",
        accessor: "nat_input",
      },
      {
        Header: t("global:settings"),
        accessor: "col5",
        Cell: settings
      }
    ],
    [t, ipList]
  );

  function settings(props) {
    return (
      <div className="flex items-center ">
        <div data-for={props.row.original.id.toString()}
          data-tip='' data-delay-update='1000'>
          <i
            className={`cursor-pointer ri-information-line ${props.row.original?.note != "" ? " text-primary-hover" : " text-main"}`}
          ></i>
          <ReactTooltip
            id={props.row.original.id.toString()}
            place="top"
            backgroundColor="black"
            delayUpdate={1000}
            getContent={() => { return props.row.original?.note ?<div className="whitespace-pre-wrap">{props.row.original?.note}</div>  : t("services:noteDesc") }}
          />

        </div>
        <i
          onClick={() => setOpenNoteModal(props.row.original)}
          className="ml-3 cursor-pointer ri-sticky-note-line"
        ></i>
        <i
          onClick={() => setOpenIPDetailsModal(props.row.original)}
          className="ml-3 cursor-pointer ri-edit-line"
        ></i>
        {/* E-posta gönderecek basınca */}
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">

      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="p-3 text-sm text-left text-white bg-main"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={
                index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
              }
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-main">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default VirtualIPTable;
