import React, { useEffect, useRef, useState } from "react";
import { Input, Li } from "./styles";

function PhoneCode({
                     disabled,
                     type,
                     top,
                     countries,
                     selectedCountry,
                     changeSelectedCountry
                   }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [newList, setNewList] = useState([]);

  const filterList = (val) => {
    if (val?.length <= 1) setNewList(countries);
    else
      setNewList(
        newList.filter((x) =>
          x.text.toLowerCase().startsWith(searchText.toLowerCase())
        )
      );
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when user clicks the outside of the item, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    typeof countries !== "undefined" &&
    countries?.length > 0 &&
    setNewList(countries);
  }, [countries]);

  return type === "phone" ? (
    <div ref={wrapperRef}>
      <div
        className={`mr-2 ml-0.5 pl-1 h-6.5 border-r text-main border-primary-disabled border-solid absolute text-sm left-0 cursor-pointer ${top} ${
          selectedCountry.id?.length == 1
            ? "w-7"
            : selectedCountry.id?.length == 2
              ? "w-9"
              : selectedCountry.id?.length == 3
                ? "w-12"
                : selectedCountry.id?.length == 4
                  ? "w-14"
                  : "w-16"
        } ${!disabled && type === "phone" ? "" : "text-passive"}`}
        onClick={() => {
          !disabled && setIsOpen(!isOpen);
        }}
      >
        +{selectedCountry.id}
      </div>

      <div
        className={
          "rounded-lg bg-white max-h-64 overflow-y-auto border-none z-50 mt-10 ring-0 outline-none absolute shadow-md ring-0 w-full " +
          (isOpen ? "visible" : "invisible")
        }
      >
        <Input
          type="text"
          className="my-1 border-none ring-0 outline-none focus:outline-none focus:ring-0 focus:border-none"
          value={searchText}
          onChange={(e) => {
            //store the search text in a state and filter the list
            setSearchText(e.target.value);
            // setNewList(list);
            filterList(e.target.value);
          }}
          placeholder="Arama yap"
        />
        {/*list combobox items*/}
        {typeof newList !== "undefined" &&
          newList?.length > 0 &&
          newList
            .filter((x) =>
              searchText !== ""
                ? x.text.toLowerCase().includes(searchText.toLowerCase())
                : x
            )
            .map((value) => {
              return (
                <>
                  <Li
                    value={value.id}
                    selected={
                      selectedCountry !== {} && value.id === selectedCountry.id
                    }
                    onClick={() => {
                      setIsOpen(false);
                      changeSelectedCountry(value.id, value.text);
                      setSearchText("");
                    }}
                  >
                    {value.text}
                  </Li>
                </>
              );
            })}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default PhoneCode;
