export const isEmail = (email) => {
  // eslint-disable-next-line
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email);
};

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

// return the user data from the local storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the local storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the local storage
export const removeUserLocal = () => {
  localStorage.removeItem("token");
};

// set the token and user from the local storage
export const setUserLocal = (token) => {
  localStorage.setItem("__mp_tk", token);
  localStorage.setItem("__is_logged_in", true);
  localStorage.setItem("ln", "en");
};

export const generatePassword = () => {
  let chars = [
    "ABCDEFGHJKLMNPQRSTUVWXYZ",
    "23456789",
    "abcdefghijkmnopqrstuvwxyz",
    "@#!$:?/|~>()"
  ];
  return [3, 3, 4, 2]
    .map(function(len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function(x) {
          return x[Math.floor(Math.random() * x?.length)];
        })
        .join("");
    })
    .concat()
    .join("")
    .split("")
    .sort(function() {
      return 0.5 - Math.random();
    })
    .join("");
};

export const changeCheckboxItem = (id, list, set) => {
  let newList = [];
  for (let i = 0; i < list?.length; i++) {
    if (list[i].id === id)
      newList.push({
        id: list[i].id,
        text: list[i].text,
        message: list[i].message,
        checked: !list[i].checked,
        disabled: list[i].disabled
      });
    else newList.push(list[i]);
  }
  set(newList);
};

export const disableCheckboxItem = (id, list, set) => {
  let newList = [];
  for (let i = 0; i < list?.length; i++) {
    if (list[i].id === id)
      newList.push({
        id: list[i].id,
        text: list[i].text,
        message: list[i].message,
        checked: list[i].checked,
        disabled: true
      });
    else newList.push(list[i]);
  }
  set(newList);
};

export function isNullOrEmptyOrUndefined(item) {
  return item === null || item === "" || typeof item === "undefined";
}
