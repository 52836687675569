import Button from "components/FormElements/Button";
import Validation from "components/Validation";
import React, { useEffect, useState } from "react";
import { useComboBox } from "hooks";
import {
  checkCustomerActivationCode,
  getLanguageList,
  getOwnInfo,
  sendCustomerActivationCode,
  updateTicketLanguage
} from "services/allServices";
import ProfileInformation from "./ProfileInformation";
import EmailSend from "./EmailSend";
import ErrorModal from "components/Modals/ErrorModal";
import { useAuthStore } from "store/AuthStore";
import moment from "moment/min/moment-with-locales";
import { useToasts } from "react-toast-notifications";
import ProfileInformationCorporate from "./ProfileInformationCorporate";
import { combineIDContent } from "helpers/helpersFunc";
import ComboBox from "components/FormElements/ComboBox";
import AccountDisableOtpModal from "./Modals/AccountDisableOtpModal";
import AlertInfo from "components/ContentAlerts/AlertInfo";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function ProfileSettings() {
  const { addToast } = useToasts();
  const { t } = useTranslation(["userSettings","global"]);
  const [ownInfo, setOwnInfo] = useState();
  const navigate = useNavigate();


  const [OTP, setOTP] = useState("");
  const [viewOTP, setViewOTP] = useState(false);
  const [errorCount, setErrorCount] = useState(5);
  const [banDate, setBanDate] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);

  const [userInformations, setUserInformations] = useAuthStore((state) => [state.userInformations, state.setUserInformations]);
  const [userActivated, setUserActivated] = useState(userInformations?.customer_info.is_activated);

  const [selectedCountry, countryList, changeSelectedCountry, setCountryList] = useComboBox("Ülke Seçiniz");

  const onSendCustomerActivationCode = () => {
    sendCustomerActivationCode().then(res => {
      if (res?.status === false) {
        setErrorCount(0);
        setBanDate(moment(res?.message).format("DD-MM-YYYY , h:mm:ss"));

      } else {
        setViewOTP(true);
      }
    });
  };

  const onCheckCustomerActivationCode = () => {
    checkCustomerActivationCode(OTP).then(res => {
      if (res?.status === "not_match") {
        let newRemainingEntry = 5 - res?.error_count;
        setErrorCount(newRemainingEntry);
        addToast("Girdiğiniz kod hatalı", {
          appearance: "error",
          autoDismiss: true
        });
      }

      if (res?.status === "you_are_banned") {
        setErrorCount(0);
        setBanDate(moment(res?.message).format("DD-MM-YYYY , h:mm:ss"));
      }
      if (res?.status === "mail_validated") {
        setUserActivated(true);
        let newInfo = { ...userInformations };
        newInfo.customer_info.is_activated = true;
        setUserInformations(newInfo);
        navigate("/")
        addToast("Hesabınız Aktive Edildi", {
          appearance: "success",
          autoDismiss: true
        });
      }
    });
  };

  const onSetOTP = (v) => {
    setOTP(v.toUpperCase());
  };

  const onChangeTicketLanguage = () => {
    updateTicketLanguage(selectedCountry.id).then(res => {
      addToast(t("settings:languageSuccessful"), {
        appearance: "success",
        autoDismiss: true
      });
    });
  };

  useEffect(() => {
    const asyncFunction = async () => {
      getOwnInfo("*").then(async (res) => {
        setOwnInfo(await combineIDContent(res.customer_info));

        getLanguageList().then(cntry => {
          setCountryList(cntry, "shortcut", "language");
          let selectedCountry = cntry?.find(c => c.shortcut == res.customer_info.content.ticket_language);
          changeSelectedCountry(selectedCountry.shortcut, selectedCountry.language);

        });
      });
    };
    asyncFunction();
  }, []);

  return (
    <div className="container">
      {userActivated === false &&
        <div>
          <h2 className="mt-5 mb-5 font-semibold">{t("settings:activation")}</h2>
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 lg:col-span-3">
              <p>
               {t("settings:unverifiedAccount")}
              </p>
            </div>
            <div className="col-span-12 lg:col-span-3">
              {!viewOTP &&
                <Button onClick={() => onSendCustomerActivationCode()} primary className="flex items-center px-3">
                  <i className="mr-2 ri-add-circle-line"></i>
                  {t("settings:sendVerificationCode")}
                </Button>
              }

            </div>
          </div>

          {viewOTP &&
            <div className="mt-5">
              <Validation
                value={OTP}
                onChange={onSetOTP}
                autoFocus
                inputClassName={
                  "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                }
                OTPLength={6}
                otpType="string"
                disabled={false}
                secure
              />

              <Button onClick={() => onCheckCustomerActivationCode()} disabled={errorCount === 0 && true} primary
                className="flex items-center px-3 mt-5">
                <i className="mr-2 ri-add-circle-line"></i>
                {t("settings:sendVerificationCode")}
              </Button>
            </div>

          }
          {errorCount < 5 && errorCount !== 0 &&
            <div>
              <p className="mt-3 font-semibold text-alert-red">{t("settings:wrongCode")}</p>
              <p className="mt-1 font-semibold text-alert-red">{t("settings:remainingEntry")} : {errorCount}</p>
            </div>

          }
          {errorCount === 0 &&
            <div>
              <p className="mt-3 font-semibold text-alert-red">{t("settings:banActivation")}</p>
              <p className="mt-1 font-semibold text-alert-red">{t("settings:banDate")} {banDate}</p>
            </div>

          }
        </div>
      }

      {ownInfo && ownInfo?.is_personal &&
        <div>
          <ProfileInformation ownInfo={ownInfo} />
        </div>
      }

      {ownInfo && ownInfo?.is_corporate &&
        <div>
          <ProfileInformationCorporate ownInfo={ownInfo} />
        </div>
      }
      {/* <div>
        <LoginMethod />
      </div> */}

      <div>
        <div className="grid grid-cols-12 gap-4 mt-5">
          <h2 className="col-span-12 font-semibold">{t("settings:communicationLanguage")}</h2>

          <div className="col-span-12 lg:col-span-3">
            <ComboBox
              id="country"
              name="country"
              label={t("settings:communicationLanguage")}
              isSearchable
              selected={selectedCountry}
              setSelected={changeSelectedCountry}
              list={countryList}
            />
          </div>

        </div>
        <div className="flex justify-end w-full mt-3 lg:w-3/4 lg:pr-2">
          <Button
            primary
            className="flex items-center justify-center w-full px-16 lg:w-auto"
            type="button"
            onClick={() => onChangeTicketLanguage()}
          >
            {t("global:save")}
          </Button>
        </div>

        <div className="lg:w-2/5 lg:-mt-10">
          <AlertInfo title={t("global:information")} message={t("contactLanguageInfo")} />
        </div>
      </div>
      <div>
        <EmailSend />
      </div>
      <div className="grid grid-cols-12 gap-4 lg:gap-10">
        <div className="col-span-12 p-8 mt-8 border-2 rounded-md lg:col-span-9 bg-pinkWarning border-pinkWarningBorder">
          <h2 className="mb-1 font-semibold text-alert-red">{t("settings:closeAccount")}</h2>
          <p>{t("settings:activePassiveAccount")}</p>
          <Button
            onClick={() => setOpenModal(true)}
            className="px-8 mt-7"
            border
            red
          >
            {t("settings:closeAccount")}
          </Button>
        </div>
      </div>
      {openModal && (
        <ErrorModal
          title={t("settings:closeAccount")}
          question={t("settings:deleteYourAccount")}
          description={t("settings:deleteDesc")}
          buttonText={t("global:yesDelete")}
          setOpenModal={() => setOpenModal(false)}
          onClick={() => { setOpenDisableModal(true); setOpenModal(false) }}
        />
      )}

      {openDisableModal && (
        <AccountDisableOtpModal setOpenModal={() => setOpenDisableModal(false)}
        />
      )}
    </div>
  );
}

export default ProfileSettings;
