import React from "react";

export const TableCheckbox = React.forwardRef(
  ({ setCheckList, checkList, data, id, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    function removeFromList(list, val, set) {
      let newList = [];
      for (let i = 0; i < list?.length; i++) {
        if (list[i] !== val) newList.push(list[i]);
      }
      set(newList);
    }

    function addToCheckList(list, val, set) {
      let newList = [];
      for (let i = 0; i < list?.length; i++) {
        newList.push(list[i]);
      }
      newList.push(val);
      set(newList);
    }

    function checkIfExist(list, val) {
      for (let i = 0; i < list?.length; i++) {
        if (list[i] === val) return true;
      }
      return false;
    }

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        {/*<input  type="checkbox" ref={resolvedRef} {...rest} />*/}
        <input
          type="checkbox"
          onClick={(e) => {
            if (id === -1) {
              if (checkList?.length > 0) {
                setCheckList([]);
              } else {
                let newData = [];
                for (let i = 0; i < data?.length; i++) {
                  newData.push(data[i].id);
                }
                setCheckList(newData);
              }
            } else if (checkList?.length === 0)
              addToCheckList(checkList, id, setCheckList);
            else {
              if (checkIfExist(checkList, id)) {
                removeFromList(checkList, id, setCheckList);
              } else addToCheckList(checkList, id, setCheckList);
            }
          }}
          className="form-checkbox h-4 w-4 border-primary-disabled rounded-sm text-primary-hover focus:ring-0 "
          ref={resolvedRef}
          {...rest}
        />
        {/*<span className="ml-2 text-gray-700">label</span>*/}
        {/*<input className="styled-checkbox" id="styled-checkbox" type="checkbox" ref={resolvedRef} {...rest} />*/}
        {/*<label htmlFor="styled-checkbox"/>*/}
      </>
    );
  }
);
