import React, { useEffect, useState } from "react";
import { sslCheckStatus } from "services/allServices";
import { useTranslation } from "react-i18next";

function CertificateControlModal({ pageData, setOpenModal }) {
    const { t } = useTranslation(["global","services", "accounting"]);

    const [data, setData] = useState()
    useEffect(() => {
        sslCheckStatus(pageData?.sertifitace_info?.content?.order_id, pageData.hostname).then(res => {
            setData(res)
        })
    }, [])


    return (

        <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    style={{ overflow: "initial" }}
                >
                    <div className="bg-white rounded-md">

                        <div className="flex items-center justify-between px-5 py-3 ">
                            <h3
                                className="text-xl font-semibold leading-6 text-title"
                                id="modal-headline"
                            >
                                {t("services:certificateControl")}
                            </h3>
                            <i
                                onClick={() => setOpenModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-main"
                            ></i>
                        </div>

                        <div className="w-full px-5 pt-5 pb-10 bg-modalBg">
                            {data && data?.ca_code !== "" ?
                                <div>
                                    <p className="mb-1 text-lg font-semibold"> {t("services:caCode")}</p>
                                    <div className="h-40 p-4 overflow-x-scroll whitespace-pre-wrap bg-white lg:overflow-auto">
                                        <p className="text-xs">{data?.ca_code}</p>
                                    </div>

                                    <p className="mt-4 mb-1 text-lg font-semibold">{t("services:crtCode")}</p>
                                    <div className="h-40 p-4 overflow-x-scroll whitespace-pre-wrap bg-white lg:overflow-auto">
                                        <p className="text-xs">{data?.crt_code}</p>
                                    </div>
                                </div>
                                :

                                <div className="flex flex-col items-center w-full">
                                    <img src="/servicesOther.svg" width="403" height="339" />
                                    {data.status === "processing" &&
                                        <h1>{t("services:notVerifiedYet")}</h1>
                                    }

                                    {data.status === "canceled" &&
                                        <h1>{t("accounting:cancelled")}</h1>
                                    }
                                    <h5></h5>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CertificateControlModal;
