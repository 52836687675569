import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";
import React from "react";
import VirtualServersChart from "./Charts/VirtualServersChart";
import { useTranslation } from "react-i18next";

function VirtualServersUsing() {
  const { t } = useTranslation(["global", "services", "accounting"]);

  return (
    <div className="mt-10">
      <div className="grid grid-cols-2 gap-10">
        <div className="relative col-span-2 lg:col-span-1">
          <div className="opacity-25">
            <VirtualServersChart
              data1={t("services:cpuUsage")}
              title={t("services:cpuDetails")}
              label="%"
              inf1={t("services:totalCPU")}
              inf2={t("services:used")}
              inf3={t("services:processor")}
              progressName={t("services:cpuUsage")}
              line1Color="#FFC62F"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
        <div className="relative col-span-2 lg:col-span-1 ">
          <div className="opacity-25">
            <VirtualServersChart
              data1={t("services:ramUsage")}
              title="RAM Detayları"
              label="MB"
              inf1={t("services:totalRam")}
              inf2={t("services:used")}
              inf3="SWAP"
              progressName={t("services:ramUsage")}
              line1Color="#1ACD67"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
        <div className="relative col-span-2 lg:col-span-1">
          <div className="opacity-25">
            <VirtualServersChart
              data1={t("services:diskUsage")}
              title={t("services:diskDetails")}
              label=""
              inf1={`${t("accounting:total")} Disk`}
              inf2={t("services:used")}
              inf3={t("services:use")}
              progressName={t("services:diskUsage")}
              line1Color="#FF4949"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
        <div className="relative col-span-2 lg:col-span-1">
          <div className="opacity-25">
            <VirtualServersChart
              data1={t("services:inodeUsage")}
              title={t("services:inodeDetails")}
              label=""
              inf1= {t("services:inodeDetails")}
              inf2={t("services:used")}
              inf3={t("services:use")}
              progressName={t("services:inodeUsage")}
              line1Color="#24B3DD"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
        <div className="relative col-span-2 lg:col-span-1">
          <div className="opacity-25">
            <VirtualServersChart
              data1={t("services:readSpeed")}
              data2={t("services:writeSpeed")}
              title={t("services:diskDetails")}
              label="MB/s"
              inf1={`I/O ${t("services:readSpeed")}`}
              inf2={`I/O ${t("services:writeSpeed")}`}
              line1Color="#FFC62F"
              line2Color="#24B3DD"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
        <div className="relative col-span-2 lg:col-span-1">
          <div className="opacity-25">
            <VirtualServersChart
              data1={`Download ${t("services:speed2")}`}
              data2={`Upload ${t("services:speed2")}`}
              data3={`${t("accounting:total") + ' ' + t("services:speed")}`}
              title={t("services:networkDetails")}
              label="M"
              inf1={t("services:averageDownloadSpeed")}
              inf2={t("services:averageUploadSpeed")}
              line1Color="#FFC62F"
              line2Color="#24B3DD"
              line3Color="#FF4949"
            />
          </div>
          <AlertLaterInfo style={"top-1/3 lg:left-40"} />

        </div>
      </div>
    </div>
  );
}

export default VirtualServersUsing;
