import React, { useState } from "react";
import Button from "components/FormElements/Button";
import Pagination from "components/Pagination";
import { useTranslation } from "react-i18next";
import VirtualLogTable from "./Tables/VİrtualLogTable";
import VirtualLogFilterModal from "./Modals/VirtualLogFilterModal";
import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";

function VirtualLogRecords() {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [paginationDisplayValue, setPaginationDisplayValue] = useState(7);
  const [paginationValue, setPaginationValue] = useState(1);
  const dummyData = ["Tüm Hizmetler", "Grafik Tasarım", "Finans"];
  const [openModal, setOpenModal] = useState(false);
  const onSetPaginationDisplayValue = (value) => {
    if (value > 20) {
      setPaginationDisplayValue(20);
    } else if (value < 7) {
      setPaginationDisplayValue(7);
    } else {
      setPaginationDisplayValue(value);
    }
  };
  const onSetPaginationValue = (value) => {
    if (value > dummyData?.length) {
      setPaginationValue(dummyData?.length);
    } else if (value < 1) {
      setPaginationValue(1);
    } else {
      setPaginationValue(value);
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="my-5 font-semibold">{t("projects:logRecords")}</h2>
        <Button
          onClick={() => setOpenModal(true)}
          className="flex items-center px-7"
          tertiary
        >
          <i className="ri-equalizer-line" />
          <span>{t("projects:filter")}</span>
        </Button>
      </div>
      <div className="relative">

        <div className="overflow-x-auto opacity-25 ">
          <VirtualLogTable />
        </div>
        <AlertLaterInfo style={"top-1/3 lg:left-[500px]"} />
      </div>

      {openModal && (
        <VirtualLogFilterModal setOpenModal={(value) => setOpenModal(value)} />
      )}

    </div>
  );
}

export default VirtualLogRecords;
