import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox, useMultiselectComboBox } from "hooks";
import {
  addBankTransferBalance,
  addCredit,
  getPaymentMethodList,
  getPaymentMethodListAddCredit,
  getProformaList,
  getProjectList,
  ticketAddFile
} from "../../../services/allServices";
import FileUploader from "../../FileUploader";
import ReactTooltip from "react-tooltip";
import { useAuthStore } from "../../../store/AuthStore";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";
import { changeCurrency, combineIDContent } from "helpers/helpersFunc";
import { useGlobalStore } from "store/GlobalStore";
import CreditCardModal from "./CreditCardModal";

function AddBalanceModal({ setOpenModal }) {

  const { t } = useTranslation(["projects", "global", "accounting", "support"]);
  const [userInformations] = useAuthStore((state) => [state.userInformations]);
  const { addToast } = useToasts();

  const [price, setPrice] = useState(0);
  const [file, uploadFile] = useState();
  const [sendName, setSendName] = useState(userInformations?.customer_info?.is_personal ? userInformations?.customer_info?.customer_name + " " + userInformations?.customer_info?.customer_surname : userInformations?.customer_info?.company_name);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const navigate = useNavigate();

  const [apiMethodList, setApiMethodList] = useState();
  const [selected, list, changeSelected, setList] = useComboBox();
  const [selectedPaymentMethod, listPaymentMethod, changeSelectedPaymentMethod, setListPaymentMethod] = useComboBox();

  const [selectedProforma, proformaList, changeProformaSelected, setProformaList] = useMultiselectComboBox(t("accounting:selectMethod"));
  const [selectedProject, projectList, changeSelectedProject, setProjectList] = useComboBox(
    "Proje Seçiniz"
  );
  const [selectedBankTransfer, bankTransferList, changeSelectedBankTransfer, setBankTransferList] = useComboBox(
    t("support:selectBank"), [{
      id: 1,
      text: "Garanti Bankası",
      otherValues: {
        iban: "TR41 0006 2000 3280 0006 2975 23"
      }
    }, {
      id: 1,
      text: "Ziraat Bankası",
      otherValues: {
        iban: "TR68 0001 0026 4673 9649 6550 01"
      }
    }, {
      id: 1,
      text: "İş Bankası",
      otherValues: {
        iban: "TR42 0006 4000 0011 1280 4255 40"
      }
    }]
  );

  const [selectedFromBankTransfer, fromBankTransferList, changeFromSelectedBanTransfer, setFromBankTransferList] = useComboBox(
    t("support:selectBank"), [{
      id: 1,
      text: "Garanti Bankası",
      otherValues: {
        iban: "TR41 0006 2000 3280 0006 2975 23"
      }
    }, {
      id: 1,
      text: "Ziraat Bankası",
      otherValues: {
        iban: "TR68 0001 0026 4673 9649 6550 01"
      }
    }, {
      id: 1,
      text: "İş Bankası",
      otherValues: {
        iban: "TR42 0006 4000 0011 1280 4255 40"
      }
    }]
  );
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false);
  const [currencyType, currencyRates] = useGlobalStore((state) => [state.currencyType, state.currencyRates]);

  const onGetPaymentMethodListAddCredit = () => {
    let paymentMethots = [
      { id: "credit_card", text: t("accounting:creditCard") },
      { id: "paypal", text: "Paypal" },
      { id: "transfer", text: t("accounting:remittance") }
    ];
    setList(paymentMethots, "id", "text")
    changeSelected(paymentMethots[0].id, paymentMethots[0].text)
    getPaymentMethodListAddCredit().then(async (res) => {
      setApiMethodList(res)
      let findMethod = await combineIDContent(res.find((item) => item.method_type === "credit_card").method_list)
      setListPaymentMethod(findMethod, "method", "bank_name")
      changeSelectedPaymentMethod(findMethod[0].method, findMethod[0].bank_name)
    })
  }

  const addBalance = () => {
    if (price == "" || price < Number(changeCurrency(currencyRates, currencyType, 30)) || price > Number(changeCurrency(currencyRates, currencyType, 25000))) {
      return addToast("Lütfen geçerli bir tutar giriniz", {
        appearance: "error",
        autoDismiss: true
      })
    }
    if (selected.id === "transfer") {
      let uploadedFile = "";
      if (file) {
        let params = new FormData();
        params.append("file", file[0]);
        ticketAddFile(params).then(url => {
          uploadedFile += url + ",";
        }).then(() => {
          uploadedFile = uploadedFile.slice(0, -1);
          let configure = {
            bank_from: selectedBankTransfer.text,
            bank_to: selectedFromBankTransfer.text,
            total_price: price,
            sender_name: sendName,
            proforma_invoice_no_list: selectedProforma.map((item) => item.id).join(","),
            bank_receipt_file: uploadedFile !== "" ? uploadedFile : null,
            payment_date: date
          };
          addBankTransferBalance(configure).then((res) => {
            addToast("Ödeme bildiriminiz oluşturulmuştur", {
              appearance: "success",
              autoDismiss: true
            });
            setOpenModal(false);
            navigate("/ticket-and-support");
          });
        });
      } else {
        let configure = {
          bank_from: selectedBankTransfer.text,
          bank_to: selectedFromBankTransfer.text,
          total_price: price,
          sender_name: sendName,
          proforma_invoice_no_list: selectedProforma.map((item) => item.id).join(","),
          bank_receipt_file: null,
          payment_date: date
        };
        addBankTransferBalance(configure).then((res) => {
          addToast("Ödeme bildiriminiz oluşturulmuştur", {
            appearance: "success",
            autoDismiss: true
          });
          setOpenModal(false);
          navigate("/ticket-and-support")
        });
      }
    } else {
      let currencyCode = currencyType === "₺" ? "TL" : currencyType === "$" ? "USD" : "EURO";

      addCredit(selectedPaymentMethod.id, currencyCode, price, selectedProject.id).then((res) => {
        if (selectedPaymentMethod.id === "cc_garanti_tl") {
          setOpenCreditCardModal(res)
        }
      })
    }
  };

  const onChangePaymentMethodType = async (value) => {
    if (value.id !== "transfer") {
      let findMethod = await combineIDContent(apiMethodList.find((item) => item.method_type === value.id).method_list)
      setListPaymentMethod(findMethod, "method", "bank_name")
      changeSelectedPaymentMethod(findMethod[0].method, findMethod[0].bank_name)
    }
  }


  useEffect(() => {
    getProformaList().then((proforma) => {
      setProformaList(proforma, "proforma_invoice_no", "proforma_invoice_no");
    });
    getPaymentMethodList().then((method) => {
      const bankList = method.filter((method) => method.method_type === "bank_transfer");
      setBankTransferList([...bankList, { id: 0, bank_name: "Diğer" }], "id", "bank_name");
      setFromBankTransferList(bankList, "id", "bank_name");
    });

    getProjectList().then((project) => {
      setProjectList(project, "id", "name");
      let findProject = project.find((item) => item.default_project === true);
      changeSelectedProject(findProject.id, findProject.name);
    })

    onGetPaymentMethodListAddCredit();

  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{t("accounting:addBalance")}</h3>
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>
            <div className="flex flex-col px-5 pt-4 pb-10 bg-modalBg">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 lg:col-span-1">
                  <TextField
                    id="money"
                    name="money"
                    type="currency"
                    ref={null}
                    symbol={currencyType}
                    label={t("accounting:enterAmount")}
                    placeholder="100.00"
                    value={price}
                    onChange={setPrice}
                  />
                </div>

                <div className="col-span-2 lg:col-span-1">
                  <ComboBox
                    id="payment_method_type"
                    name="payment_method_type"
                    selected={selected}
                    setSelected={changeSelected}
                    onSelected={onChangePaymentMethodType}
                    list={list}
                    label={t("accounting:paymentOptions")}
                    isSearchable={false}

                  />
                </div>
                {selected?.id !== "transfer" &&
                  <div className="col-span-1 ">
                    <ComboBox
                      id="payment_method"
                      name="payment_method"
                      selected={selectedPaymentMethod}
                      setSelected={changeSelectedPaymentMethod}
                      list={listPaymentMethod}
                      isSearchable={false}
                      label={t("accounting:paymentMethod")}

                    />
                  </div>
                }
                {selected?.id !== "transfer" &&
                  <div className="col-span-1 ">
                    <ComboBox
                      id="project"
                      name="project"
                      selected={selectedProject}
                      setSelected={changeSelectedProject}
                      list={projectList}
                      isSearchable={false}
                      label={t("accounting:project")}

                    />
                  </div>
                }

              </div>
              {selected?.id === "transfer" && <>
                <TextField
                  id="name_surname"
                  name="name_surname"
                  type="text"
                  ref={null}
                  symbol="₺"
                  label={`${t("accounting:senderName")} *`}
                  placeholder=""
                  value={sendName}
                  onChange={setSendName}
                />
                <div className="grid grid-cols-2 gap-5 mt-2">
                  <div className="col-span-2 lg:col-span-1">
                    <ComboBox
                      id="add_credit_bank_transfer"
                      name="add_credit_bank_transfer"
                      selected={selectedBankTransfer}
                      setSelected={changeSelectedBankTransfer}
                      list={bankTransferList}
                      label={`${t("support:senderBank")} *`}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <ComboBox
                      id="from_bank_transfer"
                      name="from_bank_transfer"
                      selected={selectedFromBankTransfer}
                      setSelected={changeFromSelectedBanTransfer}
                      list={fromBankTransferList}
                      label={`${t("support:recipientBank")} *`}
                      isSearchable={false}
                    />
                  </div>
                </div>
                {/*gönderen kişi (varsayılanda kullanıcının adı ve diğer olacak, combobox) diğer seçilirse isim textbox*/}
                {/*olarak girilecek*/}
                <div className="grid grid-cols-2 gap-5 my-2">
                  <div className="col-span-2 lg:col-span-1">
                    <ComboBox
                      multiselect={true}
                      id="related_proforma"
                      name="related_proforma"
                      selected={selectedProforma}
                      setSelected={changeProformaSelected}
                      list={proformaList}
                      label={<>
                        <span>{t("accounting:selectProforma")}</span>
                        <i
                          className="float-right mt-1 ml-3 text-md ri-information-line text-main"
                          data-tip={t("accounting:paidProforma")}
                          data-place="top"
                          data-multiline={true}
                          data-effect="solid"
                          data-border="true"
                        />
                        <ReactTooltip />
                      </>}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <FileUploader
                      id="download1"
                      file="fileUpload1"
                      name="download1"
                      uploadId="uploadId1"
                      value={file}
                      defaultText="Dosya Seçiniz"
                      required="required"
                      setValue={uploadFile}
                      label={t("support:uploadFile")}
                      placeholder="Dosya seçiniz"
                      inputStyle="w-full py-0 text-xs"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-2 lg:col-span-1">
                    <TextField
                      id="payment_date"
                      name="payment_date"
                      type="date"
                      ref={null}
                      symbol="₺"
                      label={t("accounting:enterHistory")}
                      placeholder="100.00"
                      value={date}
                      onChange={setDate}
                    />
                  </div>
                </div>
              </>}
              <h4 className="mt-5 mb-2 font-semibold">{t("accounting:information")}</h4>
              <p>
                {t("accounting:balanceInformation")}
              </p>
              <div className="flex mt-5">
                <div>
                  <p className="mb-1 font-semibold text-main">{t("accounting:minimumAmount")}</p>
                  <p className="font-semibold text-main">{t("accounting:maximumAmount")}</p>
                </div>
                <div>
                  <div className="flex ml-3">
                    <p className="mb-1 font-semibold text-main">:</p>
                    <p className="mb-1 ml-2 font-semibold text-main">{changeCurrency(currencyRates, currencyType, 30)} {currencyType}</p>
                  </div>
                  <div className="flex ml-3">
                    <p className="font-semibold text-main">:</p>
                    <p className="ml-2 font-semibold text-main">{changeCurrency(currencyRates, currencyType, 25000)} {currencyType}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button className="w-full px-12 mr-0 lg:w-auto" onClick={() => addBalance()} primary>
                {t("global:apply")}
              </Button>
            </div>

          </div>
        </div>
      </div>

      {openCreditCardModal && (
        <CreditCardModal
          setOpenModal={() => setOpenCreditCardModal(false)}
          postValues={openCreditCardModal}
        />
      )}
    </div>
  );
}

export default AddBalanceModal;
