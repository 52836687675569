import styled, { css } from "styled-components";

const Label = styled.label`
  margin: 0.5rem auto;
  color: #555555;
  font-size: 14px;
  //{tw mx-auto my-2 text-main text-2xs dark:text-primary-disabled}
`;
const Error = styled(Label)`
  --tw-ring-color: #ff4949;
  color: #ff4949;
  font-size: 14px;
  //{tw ring-alert-red text-alert-red text-2xs}
`;
const Ul = styled.ul`
  width: 100%;
  background-color: white;
  font-size: 14px;
  padding: 0.625rem 0.5rem;
  margin: 0.125rem 0 0 0;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  appearance: none;
  cursor: pointer;
  --tw-ring-color: #d8d8d8;
  outline: none;
  border-radius: 0.375rem;

  &:focus {
    --tw-ring-color: #ff9e30;
  }

  //{tw w-full bg-white text-2xs py-2.5 px-2 m-0 mt-0.5 ring-1 appearance-none cursor-pointer ring-primary-disabled outline-none rounded-md focus:ring-primary }
  ${(props) =>
  props.disabled &&
  css`
      background-color: #d8d8d8;
      color: #999999;
      cursor: not-allowed;
    `};
  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
  ${(props) =>
  props.isOpen &&
  css`
      --tw-ring-color: #ff7c30;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    `};
`;
const Input = styled.input`
  padding: 0.125rem 0.5rem;
  width: 100%;
  font-size: 14px;
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  right: 0.25rem;
  outline: none;
  color: #555555;
  list-style: none;
  cursor: text;
  //{tw pl-2 pr-2 py-1 w-full text-2xs mt-1 rounded-lg right-1 outline-none text-main list-none cursor-text }
  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
`;
const Li = styled.li`
  padding: 0.5rem;
  margin-top: 0.125rem;
  color: #555555;
  font-size: 14px;
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(254 252 232);
    color: #ff9e30;
  }

  &:active {
    color: white;
    --tw-ring-color: transparent;
    background-color: #ff7c30;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  //{tw pl-2 pr-2 py-2 mt-0.5 text-main text-2xs hover:bg-yellow-50 hover:text-primary list-none cursor-pointer first:rounded-t-lg last:rounded-b-lg}
  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
  ${(props) =>
  props.isGroup &&
  css`
      color: #999999;
      cursor: default;
      &:hover {
        color: #999999;
        background-color: white;
      }
    `};

  input {
    color: #ff7c30;
    margin-right: 0.5rem;
    border-color: #3c4858;
    border-radius: 0.375rem;
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    &:focus {
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: transparent;
    }
    //{tw text-primary-hover mr-2 border-background-passive ring-0 focus:ring-0 focus:ring-transparent rounded-md}
  }

  &:hover {
    input {
      border-color: #ff7c30;
    }
  }
`;

export { Label, Error, Ul, Li, Input };
