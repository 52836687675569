import React, { useEffect, useState } from "react";
import Logo from "assets/Logo.svg";

export default function Loading() {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(!fade);
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className="justify-center content-center w-full h-full">
      <img
        src={Logo}
        id="target"
        style={{
          left: "40%",
          top: "45%",
          transition: "0.8s",
          width: "20%",
          height: "10%",
          position: "absolute",
          opacity: fade ? 0.2 : 0.8
        }}
        alt="Makdos"
      />
    </div>
  );
}
