import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "assets/Logo.svg";
import { useAccountingStore } from "store/AccountingStore";
import { getProforma, getProformaDetail } from "services/allServices";
import { useNavigate } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment/min/moment-with-locales";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { TailSpin } from "react-loader-spinner";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";

function ProformaInformationModal({ setOpenModal, transactions }) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [selectedProforma] = useAccountingStore((state) => [
    state.selectedProforma
  ]);
  const [proformaData, setProformaData] = useState(null);
  const navigate = useNavigate();
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);

  const onGetProforma = async () => {
    if (selectedProforma !== null) {
      await getProformaDetail(selectedProforma, currencyType)
        .then((res) => {
          setProformaData(res);
          if (res.status === "proforma_invoice_not_found") {
            navigate("/accounting");
          }
        })
        .catch((err) => {
          navigate("/accounting");
        });
    }
  };

  const convertPaymentMethodIcon = (payment_method) => {
    if (payment_method === "customer_balance") {
      return (
        <div className="flex ">
          <a title="Kredi">
            <i className="text-lg ri-wallet-3-line"></i>
          </a>
        </div>
      );
    } else if (payment_method === "cc_garanti_tl") {
      return (
        <div className="flex ">
          <a title="Kredi Kartı">
            <i className="text-lg ri-secure-payment-line"></i>
          </a>
        </div>
      );
    } else if (payment_method === "paypal_usa") {
      return (
        <div className="flex ">
          <a title="Paypal">
            <i className="text-lg ri-paypal-line"></i>
          </a>
        </div>
      );
    } else {
      return <div className="ml-2">-</div>;
    }
  };
  useEffect(() => {
    onGetProforma();
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    Proforma
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 pt-5 pb-10 bg-modalBg ">
                  <div className="relative w-full p-8 bg-white">
                    <svg
                      className="absolute top-0 right-0"
                      width="96"
                      height="96"
                      viewBox="0 0 96 96"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M96 0H0L96 96V0Z" fill="#1ACD67" />
                    </svg>
                    <p className="absolute text-lg font-bold text-white transform rotate-45 top-5 right-3">
                      {t("accounting:paid")}
                    </p>

                    <div className="flex items-center justify-between">
                      <img className="" src={Logo} width="199" height="61" />
                      <h1 className="mr-16">PROFORMA</h1>
                    </div>
                    {proformaData ? (
                      <>
                        <div className="mt-10">
                          <p className="text-passive">{t("accounting:billedBy")}</p>
                          <p className="text-lg font-semibold text-title">
                            {proformaData?.billing_infos[0]?.billing_info?.name}{" "}
                            {
                              proformaData?.billing_infos[0]?.billing_info
                                ?.surname
                            }
                          </p>
                          <p className="text-main">
                            {
                              proformaData?.billing_infos[0]?.billing_info
                                ?.address
                            }
                          </p>
                          <p className="text-main">
                            {
                              proformaData?.billing_infos[0]?.billing_info?.cities.text
                            }{" "}
                            /{" "}
                            {
                              proformaData?.billing_infos[0]?.billing_info?.state.text
                            }{" "}
                            -{" "}
                            {
                              proformaData?.billing_infos[0]?.billing_info?.country.text
                            }
                          </p>
                          <div className="w-full h-0.5 bg-borderGray mt-3 mb-3"></div>
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="text-main">
                                {t("accounting:proformaNo")}: {proformaData.proforma_invoice_no}
                              </p>
                              <p className="text-main">
                                {" "}
                                {moment(proformaData.time_create).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                            <p className="text-2xl font-bold text-primary-hover">

                              {changeCurrency(currencyRates, currencyType, proformaData.proforma_content.project_total_price_without_tax)} {currencyType}

                            </p>
                          </div>
                          {console.log(proformaData)}
                          {proformaData?.get_basket_product?.length > 0 &&
                            <div className="grid grid-cols-12 mt-8">
                              <div className="col-span-6">
                                <p>{t("accounting:product")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:price")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:quantity")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:total")}</p>
                              </div>
                            </div>
                          }

                          <div className="w-full h-0.5 bg-borderGray mt-1 mb-3"></div>

                          {proformaData?.get_basket_product?.map(
                            (product, index) => {
                              return (
                                <div className="grid grid-cols-12 mt-2 overflow-y-auto max-h-52">
                                  <div className="col-span-6">
                                    <p className="font-semibold">
                                      {product.content.type_group_info.group_name} -   {product.content.product_name} -  {product.content.get_ip_record}
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-semibold">
                                      {
                                        parseFloat(product.content.without_all_components_total_price).toFixed(2)

                                      }{" "}
                                      {currencyType}
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-semibold">
                                      1
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-bold">
                                      {
                                        parseFloat( product.content.without_all_components_total_price).toFixed(2)
                                      }{" "}
                                      {currencyType}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          {proformaData?.proforma_content.proforma_for_credit &&
                            <p className="font-semibold">{t("accounting:addBalance")}</p>
                          }

                          {proformaData?.transactions?.length > 0 &&
                            <>
                              <div className="grid grid-cols-12 gap-3 mt-8">
                                <div className="col-span-2">
                                  <p>{t("accounting:paymentDate")}</p>
                                </div>
                                <div className="col-span-2">
                                  <p>{t("accounting:paymentOptions")}</p>
                                </div>
                                <div className="col-span-2">
                                  <p>{t("accounting:transactionId")}</p>
                                </div>
                                <div className="col-span-2">
                                  <p>{t("accounting:amountDue")}</p>
                                </div>
                                <div className="col-span-2">
                                  <p>{t("accounting:vat")}</p>
                                </div>
                                <div className="col-span-2">
                                  <p>{t("accounting:total")}</p>
                                </div>
                              </div>
                              <div className="w-full h-0.5 bg-borderGray mt-1 mb-3 "></div>
                            </>
                          }


                          {proformaData?.transactions?.length > 0 &&
                            proformaData?.transactions.map((tra, index) => {
                              return (
                                <div className="grid grid-cols-12 gap-3 mt-2 overflow-y-auto max-h-52">
                                  <div className="col-span-2">
                                    <p className="font-bold">
                                      {" "}
                                      {moment(tra.content["payment_date"]).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-bold">
                                      {convertPaymentMethodIcon(
                                        tra.content["payment_method"]
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-bold">{tra.id}</p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-bold">
                                      {parseFloat(
                                        tra.content["paid_price_without_tax"][findCurrency(currencyType)]?.value
                                      ).toFixed(2)}
                                      &nbsp;{currencyType}
                                    </p>
                                  </div>
                                  <div className="col-span-2">
                                    <p className="font-bold">
                                      {tra.content["paid_price_tax"] !== 0
                                        ? parseFloat(
                                          tra.content["paid_price_tax"][findCurrency(currencyType)]?.value
                                        ).toFixed(2)
                                        : parseFloat(
                                          tra.content["paid_price_commission"][findCurrency(currencyType)]?.value +
                                          tra.content["paid_price_fee"][findCurrency(currencyType)]?.value
                                        ).toFixed(2)}
                                      &nbsp;{currencyType}
                                    </p>
                                  </div>
                                  <div className="flex col-span-2">
                                    <p className="font-bold">
                                      {parseFloat(tra.content["paid_price_total"][findCurrency(currencyType)]?.value).toFixed(
                                        2
                                      )}
                                      &nbsp;{currencyType}

                                    </p>
                                    <p
                                      className="ml-2 font-bold text-alert-red">{tra.content["is_refund"] && " (İade)"}</p>

                                  </div>
                                </div>
                              );
                            })}
                          <div className="mt-8">
                            <AlertDanger
                              title={t("global:warning")}
                              message={t("accounting:warningDesc")}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full mt-20">
                        <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                          type="Oval"
                          color="#ff7c30"
                          height={75}
                          width={75}
                          timeout={3000} //3 secs
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="h-3 col-span-1 bg-title"></div>
                    <div className="h-3 col-span-1 bg-makdosLogoRed"></div>
                    <div className="h-3 col-span-1 bg-makdosLogoYellow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProformaInformationModal;
