import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";

import { useComboBox } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { getCountry, updateDomainDns } from "services/allServices";

const schema = yup.object().shape({
  title: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required()
});

function DNSChangeForm1({ data, onGetPageData, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [openAlert, setOpenAlert] = useState(false);
  const [file, uploadFile] = useState();
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();

  const onSubmit = async (formData) => {
    let configure = {
      dns_server_ns_type: "makdos",
      dns_website_title: formData.title,
      dns_website_email: formData.email,
      dns_phone: { country_code: selectedCountryCode, phone: formData.phone },
      dns_ns1: "",
      dns_ns2: "",
      dns_ns3: "",
      dns_ns4: "",
      dns_ns5: "",
      dns_ip1: "",
      dns_ip2: "",
      dns_ip3: "",
      dns_ip4: "",
      dns_ip5: ""
    };
    updateDomainDns(data.id, configure).then((res) => {
      setOpenModal(false);
      onGetPageData();
    });
  };

  useEffect(() => {
    getCountry().then((ct) => {
      setCountryCodeList(ct, "phone_code", "country_name");
      changeSelectedCountryCode("90");
    });

    if (data.dns_server_ns_type === "makdos") {
      reset({
        title: data.dns_website_title,
        email: data.dns_website_email,
        phone: data.dns_phone.phone
      });
      changeSelectedCountryCode(data.dns_phone.country_code.id, data.dns_phone.country_code.text);

    }

  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-5 pt-4 pb-4 ">
        {openAlert && (
          <AlertDanger message={t("services:dnsChangeForm1Desc")} />
        )}

        <div className="grid grid-cols-2 gap-5 ">
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label={t("services:webSiteTitle")}
              placeholder={t("services:webSiteTitle")}
              innerRef={register}
            />
            {errors.title && errors.title.type === "required" && (
              <span className="text-sm text-alert-red">
                {t("services:enterWebsiteTitle")}
              </span>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="email"
              name="email"
              type="email"
              ref={null}
              label={t("global:email")}
              placeholder="adiniz@alanadiniz.com"
              innerRef={register}
            />
            {errors.email && errors.email.type === "required" && (
              <span className="text-sm text-alert-red">
                {t("services:enterYourEmail")}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          {/* <div className="col-span-2 lg:col-span-1">
            <TextField
              id="file"
              name="file"
              type="file"
              ref={null}
              label="Dosya Yükleyin"
              placeholder="Dosya Seçiniz"
              value={file}
              onChange={uploadFile}
            />
          </div> */}
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="phone"
              name="phone"
              type="phone"
              countries={countryCodeList}
              selectedCountry={selectedCountryCode}
              changeSelectedCountry={changeSelectedCountryCode}
              innerRef={register}
              label={t("global:phoneNumber")}
              placeholder={t("global:phoneNumber")}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
        <Button
          onClick={() => setOpenModal(false)}
          className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
          tertiary
        >
          {t("global:giveUp")}
        </Button>
        <Button type="submit" className="w-full px-12 mr-0 lg:w-auto" primary>
          {t("global:apply")}
        </Button>
      </div>
    </form>
  );
}

export default DNSChangeForm1;
