import React, { useEffect, useState } from "react";
import autosize from "autosize";
import { useTranslation } from "react-i18next";

function TicketTextInput({
                           selectedFile,
                           setSelectedFile,
                           setMessage,
                           message,
                           sendMessage
                         }) {
  const { t } = useTranslation(["support", "global"]);
  const [newName, setNewName] = useState(null);

  const onAddFiles = (files) => {
    if (files?.length < 11) {
      let newVal = [];
      for (const [key, value] of Object.entries(files)) {
        newVal.push(value);
      }
      setNewName(newVal);
      setSelectedFile(files);
    } else {
      alert(`Maksimum ${10} adet dosya yükleyebilirsiniz.`);
    }
  };
  const onDeleteFile = (name) => {
    let newFileList = [];
    newName.map((n) => {
      if (n.name !== name) {
        newFileList.push(n);
      }
    });
    setNewName(newFileList);
    setSelectedFile(newFileList);
  };
  useEffect(() => {
    if (selectedFile === null) {
      setNewName(null);
    }
    document.getElementById("support-message-box").focus();
    autosize(document.getElementById("support-message-box"));
  }, [selectedFile]);
  return (
    <div>
      <div className="flex items-center w-full py-2 pr-5 border rounded-md border-borderGray2">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full border-transparent resize-none focus:outline-none max-h-48 focus:ring-0 focus:border-transparent"
          placeholder={t("support:message")}
          rows="1"
          id="support-message-box"
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.ctrlKey) {
              sendMessage();
            }
          }}
        />
        <div>
          <input
            id="file"
            type="file"
            className="hidden"
            multiple={true}
            accept="image/x-png,image/gif,image/jpeg,image/jpg,application/pdf,text/plain"
            onChange={(e) => onAddFiles(e.target.files)}
          />
          <i
            onClick={() => {
              document.getElementById("file").click();
            }}
            className="ml-5 text-xl cursor-pointer ri-attachment-line text-primary-hover"
          />
        </div>
      </div>

      <div className="flex mt-3 overflow-x-auto customScroll w-6/7">
        {newName &&
          newName.map((n) => {
            return (
              <div className="flex items-center px-2 py-1 mr-3 border rounded-md w-28 border-borderGray2">
                <i
                  onClick={() => onDeleteFile(n.name)}
                  className="mr-2 cursor-pointer ri-close-line text-alert-red"
                ></i>
                <p className="mr-5">
                  {n.name?.length < 6 ? (
                    n.name
                  ) : (
                    <a title={n.name}>{n.name.substring(0, 6) + "..."}</a>
                  )}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TicketTextInput;
