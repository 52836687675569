import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";

function BuyBackupModal({ setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global"," services"]);
  let language = localStorage.getItem("ln");

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 pt-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  ></h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <div className="flex flex-col items-center justify-center text-center">
                    <img src="/backupModalImage.svg" width="378" height="260" />
                    <h3 className="my-2 font-semibold">{t("services:buyBackup")}</h3>
                    <p className="mx-20 mb-1">
                      {t("services:professionalDNSServiceDesc")}
                    </p>
                    {/* <p className="flex">
                      {" "}
                      <p className="mr-1 font-semibold">281</p> gün için toplam
                      <p className="mx-1 font-semibold text-primary-hover">
                        20.00 TL
                      </p>{" "}
                      'ye satın alın
                    </p> */}

                    {language === 'tr' ? <p className="flex">
                      {" "}
                      <p className="mr-1 font-semibold">281</p> gün için toplam
                      <p className="mx-1 font-semibold text-primary-hover">
                        20.00 TL
                      </p>{" "}
                      'ye satın alın
                    </p> :
                    <p className="flex">
                    Buy for {" "}<p className="mr-1 font-semibold">281</p> 
                    days for a total of  <p className="mx-1 font-semibold text-primary-hover">
                        20.00 TL
                      </p>
                    </p>
                    
                    }
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("services:createOrder")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyBackupModal;
