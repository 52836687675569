import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function BalanceModal({
                        title,
                        question,
                        description,
                        setOpenModal,
                        buttonText
                      }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex flex-col items-center px-5 pt-10 pb-10 bg-modalBg">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0002 53.6668C12.2722 53.6668 0.333496 41.7282 0.333496 27.0002C0.333496 12.2722 12.2722 0.333496 27.0002 0.333496C41.7282 0.333496 53.6668 12.2722 53.6668 27.0002C53.6668 41.7282 41.7282 53.6668 27.0002 53.6668ZM24.3415 37.6668L43.1948 18.8108L39.4242 15.0402L24.3415 30.1255L16.7975 22.5815L13.0268 26.3522L24.3415 37.6668Z"
                  fill="#1ACD67"
                />
              </svg>

              <h2 className="font-semibold text-alert-green my-1">{title}</h2>
              <p>{question}</p>
              <p className="mt-5 text-center lg:mx-10">{description}</p>
            </div>

            <div className="flex flex-col-reverse lg:flex-row  justify-center bg-white py-3 px-5 lg:px-0">
              <Link to="/accounting">
                <Button
                  onClick={() => setOpenModal(false)}
                  className="px-12 mr-0   w-full lg:w-auto"
                  success
                >
                  {buttonText}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceModal;
