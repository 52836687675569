import React, { useEffect, useState } from "react";
import CheckBox from "components/FormElements/CheckBox";
import RadioButton from "components/FormElements/RadioButton";
import { Button } from "components";
import BillCard from "components/Accounting/BillCard";
import CreditCardModal from "components/Accounting/AccountingModals/CreditCardModal";
import PaypalModal from "components/Accounting/AccountingModals/PaypalModal";
import MultiplePaymentTransferModal from "components/Accounting/AccountingModals/MultiplePaymentTransferModal";
import { useAccountingStore } from "store/AccountingStore";
import { getProforma, payBasket } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";
import { TailSpin } from "react-loader-spinner";
import PaymentInformationModal from "components/Accounting/AccountingModals/PaymentInformationModal";
import { useForm } from "react-hook-form";
import ErrorModal from "components/Modals/ErrorModal";
import { useNavigate } from "react-router-dom";
import BalanceModal from "components/Accounting/AccountingModals/BalanceModal";
import { useToasts } from "react-toast-notifications";
import useConfigurationComboBox, { useComboBox } from "hooks";
import ComboBox from "components/FormElements/ComboBox";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";
import { useTranslation } from "react-i18next";
import PrivacyPolicy from "./PrivacyPolicy";

function BillPay() {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [isRadioPay, setIsRadioPay] = useState("radio1");
  const handleChange = (value) => {
    setIsRadioPay(value);
  };
  const [selectedProforma] = useAccountingStore((state) => [
    state.selectedProforma
  ]);
  const [currencyRates, currencyType, isResponsible, permissions] = useGlobalStore((state) => [
    state.currencyRates,
    state.currencyType,
    state.isResponsible,
    state.permissions
  ]);
  const [selectedPaymentMethod, listPaymentMethods, changeSelectedPaymentMethod, setPaymentMethods] = useConfigurationComboBox("Seçiniz");
  const [isPrivicyPolicy, setIsPrivicyPolicy] = useState(false);

  const navigate = useNavigate();

  const [payAmount, setPayAmount] = useState("");
  const [isCheckPay, setIsCheckPay] = useState("pay1");
  const [balanceCheck, setBalanceCheck] = useState(false);
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false);
  const [openPaypalModal, setOpenPaypalModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [postValues, setPostValues] = useState();
  const [proformaData, setProformaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [
    openPaymentInformationModal,
    setOpenPaymentInformationModal
  ] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [creditData, setCreditData] = useState(null);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

  const { addToast } = useToasts();

  const { register, handleSubmit, errors } = useForm();

  const onSetPay = async (data) => {
    if ((isResponsible ? (permissions.length > 0 && !permissions?.find((item) => item.id === "order" && item.checked === true)?.checked) : false)) {
      return addToast(t("accounting:orderAuthorizedAlert"), {
        appearance: "error",
        autoDismiss: true
      });

    }
    if (isPrivacyPolicy) {
      let isPayable = true;
      let paymentAmount = null;
      let currencyCode = currencyType === "₺" ? "TL" : currencyType === "$" ? "USD" : "EURO";
      if (isRadioPay === "radio2") {
        if (payAmount !== "") {
          if (parseFloat(payAmount).toFixed(2) <= parseFloat(selectedPaymentMethod.otherInfo?.method_list?.final_price - proformaData.get_minimum_price[findCurrency(currencyType)].value) ||
            parseFloat(selectedPaymentMethod.otherInfo?.method_list?.final_price).toFixed(2) === parseFloat(payAmount).toFixed(2)
          ) {
            if (payAmount !== "") {
              paymentAmount = {
                proforma_invoice_id: proformaData.id,
                proforma_invoice_paid_price: parseFloat(payAmount)
              };
            }
          } else {
            isPayable = false;
          }
        }
      } else {
        if (balanceCheck === true) {
          setButtonLoading(true);
          paymentAmount = {
            proforma_invoice_id: proformaData.id,
            proforma_invoice_paid_price: null
          };
        } else {
          paymentAmount = {
            proforma_invoice_id: proformaData.id,
            proforma_invoice_paid_price: selectedPaymentMethod.otherInfo?.method_list?.final_price
          };
        }

      }

      if (!isPayable && balanceCheck === false) {
        setOpenErrorModal(true);
      } else if (paymentAmount) {
        let payment_method = balanceCheck === true ? "customer_balance" : (isCheckPay === "pay3" ? "transfer" : selectedPaymentMethod.otherInfo.method_list.others.payment_method)
        let configureValue = {
          currencycode: currencyCode,
          project_id_price: paymentAmount,
        };

        if (payment_method === "transfer") {
          await setPostValues(configureValue);
          setOpenTransferModal(true);
        } else {
          await payBasket(paymentAmount, payment_method, currencyCode, isRadioPay === "radio2" ? true : false).then(async (data) => {
            if (balanceCheck) return setOpenBalanceModal(true);
            if (isCheckPay === "pay1") return setOpenCreditCardModal(data);
            if (isCheckPay === "pay2") return setOpenPaypalModal(data);

          });
        }

      }
    } else {
      return addToast(t("accounting:acceptAgreements"), {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  const onSetPayAmount = (value) => {
    let projectTotal = parseFloat(selectedPaymentMethod.otherInfo?.method_list?.final_price - proformaData.get_minimum_price[findCurrency(currencyType)].value).toFixed(2);
    if (value) {
      if (parseFloat(value) > projectTotal) {
        setPayAmount(projectTotal);
      } else {
        setPayAmount(parseFloat(value));
      }
    } else {
      setPayAmount("");
    }
  };

  const onGetProforma = async () => {
    if (selectedProforma !== null) {
      await getProforma(selectedProforma, currencyType, balanceCheck ? "customer_balance" : selectedPaymentMethod?.id)
        .then((res) => {
          if (res?.status === "proforma_invoice_not_found") return goProforma();
          setProformaData(res);
          if (isCheckPay === "pay1") {
            setPaymentMethods(res?.payment_method_list[0].credit_card, "method_type", "text", null, res?.payment_method_list[0].credit_card)
          }
          if (isCheckPay === "pay2") {
            setPaymentMethods(res?.payment_method_list[1].paypal, "method_type", "text", null, res?.payment_method_list[1].paypal)
          }
          if (isCheckPay === "pay3") {
            setPaymentMethods(res?.payment_method_list[2].bank_transfer, "method_type", "text", null, res?.payment_method_list[2].bank_transfer)
          }
          setCreditData(res.payment_method_list[3].balance[0])
          if (selectedPaymentMethod.id == 0) {
            changeSelectedPaymentMethod(res?.payment_method_list[0].credit_card[0].method_type, res?.payment_method_list[0].credit_card[0].text, null, res?.payment_method_list[0].credit_card[0])
          }
          setPayAmount(parseFloat(res.without_tax_total_basket_price).toFixed(2));
          // if (res.status === "completed") {
          //   navigate("/accounting");
          // }
          // if (res.status === "proforma_invoice_not_found") {
          //   navigate("/accounting");
          // }
        })
      // .catch((err) => {
      //   goProforma();
      // });
    }
  };

  const onPayChange = (value) => {
    if (value !== "balance") {
      setIsCheckPay(value);
      if (value === "pay1") {
        changeSelectedPaymentMethod(proformaData?.payment_method_list[0].credit_card[0].method_type, proformaData?.payment_method_list[0].credit_card[0].text, null, proformaData?.payment_method_list[0].credit_card[0])
        setPaymentMethods(proformaData?.payment_method_list[0].credit_card, "method_type", "text", null, proformaData?.payment_method_list[0].credit_card)
      }
      if (value === "pay2") {
        changeSelectedPaymentMethod(proformaData?.payment_method_list[1].paypal[0].method_type, proformaData?.payment_method_list[1].paypal[0].text, null, proformaData?.payment_method_list[1].paypal[0])
        setPaymentMethods(proformaData?.payment_method_list[1].paypal, "method_type", "text", null, proformaData?.payment_method_list[1].paypal)
      }

      if (value === "pay3") {
        changeSelectedPaymentMethod(proformaData?.payment_method_list[2].bank_transfer[0].method_type, proformaData?.payment_method_list[2].bank_transfer[0].text, null, proformaData?.payment_method_list[2].bank_transfer[0])
        setPaymentMethods(proformaData?.payment_method_list[2].bank_transfer, "method_type", "text", null, proformaData?.payment_method_list[2].bank_transfer)
      }
    } else {
      changeSelectedPaymentMethod(proformaData?.payment_method_list[3].balance[0].method_type, proformaData?.payment_method_list[3].balance[0].text, null, proformaData?.payment_method_list[3].balance[0])
    }

  };

  const LoadData = async () => {
    await setLoading(true);
    await onGetProforma();
    await setLoading(false);
  };

  const goProforma = () => {
    navigate("/accounting");
  };


  const onSetBalanceCheck = () => {
    setBalanceCheck(!balanceCheck);

    if (!balanceCheck === true) {
      onPayChange("balance")
    } else {
      onPayChange("pay1")
    }
  };



  useEffect(() => {
    if (!selectedProforma) {
      goProforma();
    }
    LoadData();
  }, [currencyType]);


  return (
    <div className="container">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          {proformaData !== null &&
            proformaData?.get_basket_product?.map((product, index) => {
              return (
                <BillCard
                  name={product.product_infos_dict?.allinfos?.product_name}
                  quantity={product.product_infos_dict?.quantity}
                  productPrice={product.product_infos_dict?.override_total_price ? product.product_infos_dict?.override_total_price : product.product_infos_dict?.without_tax_total_product_price}
                  key={index}
                />
              );
            })}

          {proformaData !== null &&
            proformaData?.get_basket_desc?.map((product, index) => {
              return (
                <BillCard
                  name={product.description}
                  quantity={product.description_quantity}
                  productPrice={product.without_tax_price_description}
                  key={index}
                />
              );
            })}

          {proformaData !== null &&
            proformaData?.renewal_product?.map((product, index) => {
              return (
                <BillCard
                  name={product.content.product_name + (product.content.hostname ? (" - " + product.content.hostname) : "") + (product.content.owned_product_code ? (" (" + product.content.owned_product_code + ")") : "")}
                  quantity={1}
                  productPrice={proformaData.proforma_invoice_no.startsWith("UP") ? proformaData.proforma_total_remaining : product.content.without_all_components_total_price}
                  key={index}
                />
              );
            })}

        </div>
        <div className="h-auto col-span-12 p-8 bg-white rounded-lg shadow-md lg:col-span-4">
          {!loading ? (
            <>
              <form onSubmit={handleSubmit(onSetPay)}>
                <h1 className="mb-4">{t("accounting:paymentTotal")}</h1>
                <div className="mb-4 totalOrderAmount">
                  <p className="text-sm text-passive ">{t("accounting:totalOrderAmount")}</p>
                  <h2 className="text-2xl font-medium">
                    {parseFloat(proformaData?.proforma_total_remaining).toFixed(2)}
                    {currencyType}
                  </h2>
                </div>

                <div className="mb-4 totalOrderAmount">
                  <div className="mt-2 ">
                    <p className="text-sm text-textGray">{t("accounting:tax")}</p>
                    <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.tax.total_price_only_tax).toFixed(2)} {currencyType}</p>
                  </div>

                  <div className="mt-2 ">
                    <p className="text-sm text-textGray">{t("accounting:processingFee")}</p>
                    <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.transaction.total_price_only_transaction_rate).toFixed(2)} {currencyType}</p>
                  </div>

                  <div className="mt-2 ">
                    <p className="text-sm text-textGray">{t("accounting:commission")}</p>
                    <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.fee.total_price_only_fee_rate).toFixed(2)} {currencyType}</p>
                  </div>

                </div>

                <div className="w-full h-0.5 bg-borderGray mt-3"></div>
                {proformaData?.transactions?.length > 0 && (
                  <>
                    <div className="flex items-center justify-between mt-3">
                      <h5 className="font-semibold">{t("accounting:paymentHistory")}</h5>
                      <div
                        onClick={() => setOpenPaymentInformationModal(true)}
                        className="flex items-center cursor-pointer"
                      >
                        <i className="ri-eye-line text-lg mr-1 mt-0.5"></i>
                        <h5 className="font-semibold">{t("accounting:detail")}</h5>
                      </div>
                    </div>
                    <div className="w-full h-0.5 bg-borderGray mt-3"></div>
                  </>
                )}
                <div className="mt-3">
                  <h5 className="text-lg">{t("accounting:amountToBePaid")}</h5>
                  <h1 className="text-primary-hover">
                    {parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.final_price).toFixed(2)}{" "}
                    {currencyType}
                  </h1>
                </div>

                {proformaData?.credit_info?.can_use_credit && (
                  <>
                    <div className="w-full h-0.5 bg-borderGray mt-3"></div>
                    <div className="flex items-center mt-5 ">
                      <CheckBox
                        id="balance"
                        name="balance"
                        label={t("accounting:payWithCredit")}
                        checked={balanceCheck}
                        onChange={() => { onSetBalanceCheck(!balanceCheck); setIsRadioPay("radio1"); }}
                      />
                      <div className="flex ml-2">
                        <p className="font-semibold">
                          {parseFloat(proformaData?.credit_info?.customer_balance[findCurrency(currencyType)].value).toFixed(2)}
                          {currencyType}
                        </p>
                        <p className="ml-4">{t("accounting:willBeUsed")}</p>
                        <p className="ml-2 font-semibold text-alert-green">
                          {parseFloat(proformaData?.credit_info?.usable_credit[findCurrency(currencyType)].value).toFixed(2)}
                          {currencyType}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {!balanceCheck && (
                  <>
                    <div className="flex items-center mt-5">
                      <div className="">
                        <RadioButton
                          id="radio1"
                          label={t("accounting:all")}
                          isCheck={isRadioPay === "radio1"}
                          setIsCheck={() => handleChange("radio1")}
                        />
                      </div>
                      <div className="ml-5 mr-3">
                        <RadioButton
                          id="radio2"
                          label={t("accounting:certainAmount")}
                          isCheck={isRadioPay === "radio2"}
                          setIsCheck={(e) => handleChange("radio2")}
                        />
                      </div>
                      {isRadioPay === "radio2" && (
                        <div className="flex items-center justify-center ml-2 paymentText ">
                          <input
                            name="partial_payment"
                            className="w-20 p-2 border-2 border-r-0 rounded-lg rounded-r-none border-borderGray focus:border-borderGray focus:outline-none focus:ring-0"
                            type="number"
                            step="0.01"
                            placeholder="100.00"
                            ref={{ ...register("partial_payment") }}
                            onChange={(e) => onSetPayAmount(e.target.value)}
                            value={payAmount}
                            min={proformaData.get_minimum_price[findCurrency(currencyType)].value}
                            max={(parseFloat(selectedPaymentMethod.otherInfo?.method_list?.final_price) - proformaData.get_minimum_price[findCurrency(currencyType)].value)}
                            defaultValue={(parseFloat(selectedPaymentMethod.otherInfo?.method_list?.final_price) - proformaData.get_minimum_price[findCurrency(currencyType)].value)}
                          />
                          <div
                            className="flex justify-center w-10 p-2 border-2 border-l-0 rounded-lg rounded-l-none bg-borderGray text-textGray border-borderGray ">
                            {currencyType}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-3 gap-3 mt-5">
                      <div
                        onClick={() => onPayChange("pay1")}
                        id="radiopay1"
                        name="pay1"
                        className={
                          isCheckPay === "pay1"
                            ? "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-textGray rounded-lg cursor-pointer"
                            : "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-borderGray rounded-lg cursor-pointer"
                        }
                      >
                        <i className="text-2xl ri-secure-payment-line text-titleColor"></i>
                        <p className="text-center text-titleColor"> {t("accounting:creditCard")}</p>
                        {/* <p className="text-titleColor"> {t("accounting:card")}</p> */}
                      </div>

                      <div
                        onClick={() => onPayChange("pay2")}
                        id="radiopay2"
                        name="pay2"
                        className={
                          isCheckPay === "pay2"
                            ? "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-textGray rounded-lg cursor-pointer"
                            : "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-borderGray rounded-lg cursor-pointer"
                        }
                      >
                        <i className="text-2xl ri-paypal-line text-titleColor"></i>
                        <p className="text-titleColor">PayPal</p>
                        <p className="text-titleColor">{t("accounting:payment")}</p>
                      </div>
                      <div
                        onClick={() => onPayChange("pay3")}
                        id="radiopay3"
                        name="pay3"
                        className={
                          isCheckPay === "pay3"
                            ? "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-textGray rounded-lg cursor-pointer"
                            : "col-span-1 p-4 flex flex-col items-center justify-center border-2 border-borderGray rounded-lg cursor-pointer"
                        }
                      >
                        <i className="text-2xl ri-send-plane-line text-titleColor"></i>
                        <p className="text-titleColor">{t("accounting:transfer")}</p>
                        <p className="text-titleColor">EFT</p>
                      </div>
                    </div>
                  </>
                )}

                {(!balanceCheck && listPaymentMethods.length > 1) &&
                  <div className="mt-4">
                    <ComboBox
                      id="select"
                      name="select"
                      selected={selectedPaymentMethod}
                      setSelected={changeSelectedPaymentMethod}
                      list={listPaymentMethods}
                      defaultText={t("global:choose")}
                    />
                  </div>
                }
                <Button
                  className="flex items-center w-full mt-5 px-7"
                  secondary
                  icon
                  dark
                  type="submit"
                  disabled={buttonLoading}
                >
                  <i className="ri-checkbox-circle-line" />
                  {buttonLoading ?
                    <span>{t("global:loading")}</span>

                    :
                    <span>{t("accounting:pay")}</span>

                  }
                </Button>

                <div className="flex mt-5">
                  {/* <input
                    type="checkbox"
                    id="privacyPolicy"
                    name="privacyPolicy"
                    className="w-4 h-4 text-primary-hover border-2 border-borderGray mt-0.5  text-sm cursor-pointer rounded-md outline-none ring-0  focus:ring-0 focus:outline-none focus:border-none focus:ring-transparent"
                    value={isPrivicyPolicy}
                    onChange={() => setIsPrivicyPolicy(!isPrivicyPolicy)}
                  /> */}
                  {/* <div className="flex flex-col ml-2">
                    <label
                      className={" w-full text-main mb-0 cursor-pointer"}
                      htmlFor="privacyPolicy"
                    >
                      {t("accounting:pdpa")}
                      {/* Kvkk kısmı buraya gelecek 
                      
                    </label>
                  </div>  */}
                  {/* Kvkk kısmı buraya gelecek */}
                  <PrivacyPolicy isPrivacyPolicy={isPrivacyPolicy} setIsPrivacyPolicy={setIsPrivacyPolicy} />
                </div>
              </form>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center lg:my-40">
              <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                type="Oval"
                color="#ff7c30"
                height={75}
                width={75}
                timeout={3000} //3 secs
              />
            </div>
          )}
        </div>
      </div>

      {openCreditCardModal && (
        <CreditCardModal
          setOpenModal={() => setOpenCreditCardModal(false)}
          postValues={openCreditCardModal}
        />
      )}
      {openPaypalModal && (
        <PaypalModal
          setOpenModal={() => setOpenPaypalModal(false)}
          postValues={openPaypalModal}
        />
      )}
      {openTransferModal && (
        <MultiplePaymentTransferModal
          setOpenModal={() => setOpenTransferModal(false)}
          postValues={postValues}
          price={selectedPaymentMethod?.otherInfo?.method_list?.final_price}
          proformaNo={proformaData?.proforma_invoice_no}
          selectedPaymentMethod={selectedPaymentMethod}
          payAmount={payAmount}
        />
      )}
      {openPaymentInformationModal && (
        <PaymentInformationModal
          transactions={proformaData?.transactions}
          setOpenModal={() => setOpenPaymentInformationModal(false)}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          setOpenModal={() => setOpenErrorModal(false)}
          title="HATA"
          description={`Bir sonraki ödeme için kalan tutarın minimum ${selectedPaymentMethod.otherInfo?.get_minimum_price[findCurrency(currencyType)].value} ${currencyType} olması gerekmektedir.`}
          buttonText="Tamam"
          onClick={() => setOpenErrorModal(false)}
        />
      )}
      {openBalanceModal && (
        <BalanceModal
          setOpenModal={() => setOpenBalanceModal(false)}
          title={t("accounting:paymentSuccesful")}
          description={t("accounting:paymentSuccesWithCredit")}
          buttonText={t("accounting:goToProformas")}
          onClick={() => goProforma()}
        />
      )}
    </div>
  );
}

export default BillPay;
