import axios from "axios";

const api = axios.create({
  // baseURL: "http://0.0.0.0:8005/",
  //baseURL: "https://api-customer.makdos.com",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__mp_tk")}`
  }
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (typeof window !== "undefined") {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        let language = localStorage.getItem("ln");
        localStorage.clear();
        localStorage.setItem("ln", language);
        window.location.href = "/";
      }
    }
    return error;
  }
);
export default api;