import React from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Button from "components/FormElements/Button";
import { useTranslation } from 'react-i18next';
import { onChanceStatus } from 'helpers/helpersFunc';
import moment from "moment";

const SupportServiceCard = ({datas}) => {
    const { t, i18n } = useTranslation(["projects", "global"]);

  return (
    <div className="w-full p-4 mt-4 transition-all duration-300 bg-white rounded-md shadow-sm cursor-pointer group hover:bg-primary-hover">
        <div className="grid grid-cols-9">
        <div className="grid grid-cols-10 col-span-8 gap-3 space-x-18 lg:col-span-5">
          <div className="col-span-4 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:productId")}
            </p>
            <p title={datas?.owned_product_code} className="font-semibold transition-all duration-300 group-hover:text-white">
            {datas?.owned_product_code.slice(0, 10)} ...
            </p>
          </div>
          <div className="col-span-4 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:productName")}
            </p>
            <p title={datas?.project_name} className="font-semibold transition-all duration-300 group-hover:text-white">
            {datas?.product_name.slice(0, 14)} 
            </p>
          </div>
          {/* <div className="col-span-4 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("services:domainName")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.hostname ? datas?.hostname : "-"}
            </p>
          </div> */}

          <div className="col-span-4 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:projectName")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.project_name}
            </p>
          </div>
          {/* <div className="col-span-3 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:assignedIp")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.assigned_ip ? datas?.assigned_ip : "-"}
            </p>
          </div> */}
        </div>

        <div className="grid grid-cols-4 col-span-8 gap-3 mt-5 lg:col-span-4 lg:mt-0">
          <div className="col-span-2 pr-8 lg:col-span-1">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:status")}
            </p>
            <p className="font-semibold transition-all duration-300 text-alert-green group-hover:text-white">
              {onChanceStatus(datas?.status)}
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:daysLeft")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.date_of_expire && moment(datas?.date_of_expire).format("DD-MM-YYYY HH:mm")}
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div
              className="flex items-center justify-center w-10 h-10 p-4 transition-all duration-300 rounded-md bg-background-table group-hover:bg-primary">
              <i data-tip={datas?.customer_note != "" ? datas?.customer_note : t("services:noteDesc")}
                className={`text-xl transition-all duration-300 ri-sticky-note-line ${datas?.customer_note != "" ? "text-primary-hover" : "text-main"} group-hover:text-white`} />
              <ReactTooltip
                place="top"
                backgroundColor="black"
                textColor="white"
                effect="solid"
              />
            </div>
          </div>
          <div className="col-span-4 mt-2 lg:col-span-1 lg:mt-0">
            <Link to={`/services/support/${datas.id}`}>
              <Button
                className="w-full px-10 transition-all duration-300 group-hover:bg-white group-hover:text-primary-hover"
                secondary
                icon
              >
                <i className="ri-settings-3-line"></i>
                <span>{t("projects:manage")}</span>
              </Button>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SupportServiceCard