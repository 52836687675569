import TextField from "components/FormElements/Input/TextField"
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import { TailSpin } from "react-loader-spinner";
import ProxyServiceCard from "./ProxyServiceCard";
import ProxyServiceFilterModal from "./ProxyServiceFilterModal";
import { listProductProxy } from "services/allServices";

const ProxyServices = () => {
    const { t, i18n } = useTranslation(["services", "global"]);
    const [searchValue, setSearchValue] = useState("");
    const [proxyService, setProxyService] = useState();
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [proxyFilterData, setProxyFilterData] = useGlobalStore((state) => [state.proxyFilterData, state.setProxyFilterData]);

    const onListProxy = (configureFilter) => {
        setIsLoading(true);

        listProductProxy(configureFilter ? configureFilter : proxyFilterData).then(res => {
            setProxyService(res);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        const asyncFunction = async () => {
            let configureFilter = {
                content: {
                    project_id_list: null,
                    status_list: ["active", "waiting", "suspended"]
                },
                count: 1
            };
            if (!proxyFilterData || proxyFilterData == "") {
                await setProxyFilterData(configureFilter);
                await onListProxy(configureFilter);

            } else {
                setProxyFilterData({
                    ...proxyFilterData,
                })
                await onListProxy(proxyFilterData);
            }
        };
        asyncFunction();
    }, []);

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row lg:items-center">
                <h2 className="my-5 font-semibold">Proxy</h2>
                <div className="flex flex-col space-x-6 lg:flex-row">
                    <div className="items-center lg:flex">
                        <TextField
                            id="search"
                            name="search"
                            type="search"
                            ref={null}
                            className="relative w-full lg:w-96"
                            value={searchValue}
                            onChange={setSearchValue}
                            placeholder={t("global:enterProductNameAndId")}
                        />
                    </div>

                    <div className="relative">
                        <Button tertiary className="flex items-center mt-2 -ml-6 lg:mt-0 lg:-ml-0 px-7" onClick={() => setOpenFilterModal(true)}>
                            <i className="ri-equalizer-line" />
                            <span>{t("global:filter")}</span>
                        </Button>
                        {(proxyFilterData?.count && proxyFilterData?.count > 0) ?
                            <div className="absolute right-0 flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 bg-primary-hover">
                                {proxyFilterData?.count}
                            </div> :
                            < ></>
                        }
                    </div>
                </div>
            </div>

            <div>
                {!isLoading ?
                    <div>
                        {proxyService && proxyService?.status !== "product_not_found" ?
                            proxyService.filter(item => (
                                item.product_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                item.owned_product_code.toLowerCase().includes(searchValue.toLowerCase())
                            ))?.map((item, index) => {
                                return (
                                    <ProxyServiceCard datas={item} key={index} />
                                )
                            })
                            :
                            <div className="flex flex-col items-center justify-center my-10 lg:my-20">
                                <img src="/servicesOther.svg" width="403" height="339" />
                                <h1>{t("global:noProduct")}</h1>
                                <h5>{t("global:productDesc")}</h5>
                            </div>
                        }

                    </div>
                    :
                    <div className="flex items-center justify-center w-full mt-20">
                        <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                            type="Oval"
                            color="#ff7c30"
                            height={75}
                            width={75}
                            timeout={3000} //3 secs
                        />
                    </div>
                }
            </div>

            {openFilterModal && (
                <ProxyServiceFilterModal openModal={openFilterModal} setOpenModal={setOpenFilterModal}
                    proxyFilterData={proxyFilterData}
                    setProxyFilterData={setProxyFilterData}
                    onListProxy={onListProxy}
                />
            )}
        </div>
    )
}

export default ProxyServices