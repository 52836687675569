import TextField from "components/FormElements/Input/TextField";
import React from "react";
import NotificationCard from "./NotificationCard";
import { useTranslation } from "react-i18next";

function InvoicesNotification() {
  const { t } = useTranslation(["global","services"]);

  let dummyData = [
    { text: "murattoktas.com alan adınızın bitmesine 20 gün kaldı.", date: "06.12.2022 13:00" },
    { text: "Windows Paket 8 sanal sunucunuza ait #9999999 numaralı faturanız oluşturuldu.", date: "06.12.2022 13:00" },
    {
      text: "Windows Paket 8 sanal sunucunuza ait #9999999 numaralı faturanız ödeme bekliyor.",
      date: "06.12.2022 13:00"
    },
    {
      text: "Windows Paket 8 sanal sunucunuza ait #9999999 numaralı faturanız ödeme bekliyor.",
      date: "06.12.2022 13:00"
    },
    { text: "murattoktas.com alan adınızın transfer işlemi başladı. ", date: "06.12.2022 13:00" }

  ];
  return (
    <div className="container w-full mt-8">
      <div className="flex items-center justify-between">
        <h2 className="font-semibold">{t("services:invoiceNotifications")}</h2>
        <TextField
          id="search"
          name="search"
          type="search"
          placeholder={t("global:search")}
          ref={null}
          className="lg:w-[300px]"
        />
      </div>
      <div className="mt-6 space-y-3">
        {dummyData.map((item, index) => {
          return (
            <NotificationCard text={item.text} date={item.date} key={index} />
          );
        })}
      </div>
    </div>
  );
}

export default InvoicesNotification;