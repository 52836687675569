import React from "react";

function ComplaintNotificationDetails() {
  return (
    <div className="container">
      <h2 className="font-semibold text-title">
        E-posta sunucunuz spam maili çıkıyor
      </h2>
      <div className="w-full bg-white mt-8 p-8 rounded-md">
        <h1>
          ABUSE: Network attack recived from an IP (185.122.202.92) from your
          nerwork / Ataqur trcibido desde una ip (185.122.202.92) de su red
        </h1>

        <div className="mt-10">
          <p className="font-bold text-title">
            Gönderme Tarihi: 28 Eylül 2019 10:29
          </p>
          <p className="font-bold text-title">
            Gönderen: Abuse Team - Comvive Servidores SL
            abusenotives@comvive.com{" "}
          </p>
          <p className="font-bold text-title">Sunucu IP: 185.122.202.92 </p>
        </div>

        <div className="bg-passive w-full h-0.5 my-10"></div>

        <div className="mb-5">
          <p>Merhabalar</p>
          <p className="whitespace-pre-wrap">
            Bir okuyucunun, düzenine bakarken bir sayfanın okunabilir
            içeriğinden dikkatinin dağılacağı uzun zamandır bilinen bir
            gerçektir. Lorem Ipsum kullanmanın amacı, 'burada içerik, burada
            içerik' kullanmanın aksine, harflerin aşağı yukarı normal bir
            dağılımına sahip olması ve okunabilir bir İngilizce gibi görünmesini
            sağlamasıdır.
          </p>
        </div>
        <div className="mb-5">
          <p>Merhabalar</p>
          <p>
            Bir okuyucunun, düzenine bakarken bir sayfanın okunabilir
            içeriğinden dikkatinin dağılacağı uzun zamandır bilinen bir
            gerçektir. Lorem Ipsum kullanmanın amacı, 'burada içerik, burada
            içerik' kullanmanın aksine, harflerin aşağı yukarı normal bir
            dağılımına sahip olması ve okunabilir bir İngilizce gibi görünmesini
            sağlamasıdır.
          </p>
        </div>
        <div className="mb-5">
          <p>Merhabalar</p>
          <p>
            Bir okuyucunun, düzenine bakarken bir sayfanın okunabilir
            içeriğinden dikkatinin dağılacağı uzun zamandır bilinen bir
            gerçektir. Lorem Ipsum kullanmanın amacı, 'burada içerik, burada
            içerik' kullanmanın aksine, harflerin aşağı yukarı normal bir
            dağılımına sahip olması ve okunabilir bir İngilizce gibi görünmesini
            sağlamasıdır.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ComplaintNotificationDetails;
