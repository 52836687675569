import React, { useEffect, useState } from "react";
import ProjectServices from "components/Project/ProjectServices";
import LogRecords from "components/Project/LogRecords";
import ProjectSettings from "components/Project/ProjectSettings";
import { useTranslation } from "react-i18next";
import Tabs from "components/Tabs";
import { useGlobalStore } from "store/GlobalStore";
import { useNavigate, useParams } from "react-router";
import { getProjectBySlug } from "services/allServices";
import { isNullOrEmptyOrUndefined } from "utils/common";

function Project() {
  const { t, i18n } = useTranslation(["projects", "global"]);
  let { url, projectName } = useParams();
  const [projectData, setProjectData] = useState();
  const navigate = useNavigate();
  const [isResponsible, permissions] = useGlobalStore((state) => [state.isResponsible, state.permissions]);


  const onGetProject = () => {
    getProjectBySlug(projectName).then(res => {
      setProjectData(res);
      if (!res || res == null) {
        navigate("/");
      }
    }).catch(err => {
      navigate("/");
    });
  };

  const tabs = [
    {
      name: t("projects:services"),
      id: "1",
      component: <ProjectServices projectName={projectName} />,
      slug: "",
      visible: true
    },
    {
      name: t("projects:logRecords"),
      id: "2",
      component: <LogRecords />,
      slug: "log-records",
      visible: false,
    },
    {
      name: t("projects:settings"),
      id: "3",
      component: <ProjectSettings projectData={projectData} onGetProject={onGetProject} />,
      slug: "settings",
      visible: true
    }

  ];


  const [selectedPageTab, setSelectedPageTab] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab
  ]);



  useEffect(() => {
    if (parseInt(selectedPageTab) > 3) {
      setSelectedPageTab("1");
    }
  }, [selectedPageTab]);

  useEffect(() => {
    if (projectName) {
      onGetProject();
    } else {
      navigate("/");
    }
  }, [projectName]);
  return (
    <div className="container relative w-full ">
      <Tabs
        tabs={tabs.filter((x) => x.visible)}
        selectedTab={
          !isNullOrEmptyOrUndefined(url)
            ? tabs.filter((x) => x.visible).find((x) => x.slug === url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
            : tabs.filter((x) => x.visible)[0]?.id
        }
        setSelectedTab={(v) => {
          navigate(
            "/project" + "/" + projectName + "/" +
            tabs.find((x) => x.id === v).slug
          );
        }}
      />


      <div className="mt-6">
        {!isNullOrEmptyOrUndefined(url)
          ? (tabs.filter((x) => x.visible).find((x) => x.slug === url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
          : tabs.filter((x) => x.visible)[0]?.component
        }
      </div>
    </div>
  );
}

export default Project;
