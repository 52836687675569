import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import qs from "qs";

function PaymentMessage() {
  const slug = useParams();
  const navigate = useNavigate();
  const errmsg = qs.parse(window.location.search, { ignoreQueryPrefix: true }).errmsg ? qs.parse(window.location.search, { ignoreQueryPrefix: true }).errmsg : "None";
  const mderrormessage = qs.parse(window.location.search, { ignoreQueryPrefix: true }).mderrormessage ? qs.parse(window.location.search, { ignoreQueryPrefix: true }).mderrormessage : "None";
  const error_detail = qs.parse(window.location.search, { ignoreQueryPrefix: true }).error_detail ? qs.parse(window.location.search, { ignoreQueryPrefix: true }).error_detail : "None";

  return (
    <div className="container">
      {slug.status === "success" &&
        < div className="w-full py-20  flex flex-col items-center justify-center">
          <img src="/paymentSuccess.svg" width="298" height="324" />
          <p className="font-semibold text-5xl mt-10">Ödemeniz Alındı</p>
          <p className="font-semibold text-xl mt-5">Ekibimiz onayladıktan sonra hizmetinizi kullanmaya
            başlayabilirsiniz.</p>
          <p className="font-semibold text-xl mt-5">Sipariş NO: {slug.orderno}</p>
        </div>
      }
      {slug.status === "error" &&
        < div className="w-full py-20  flex flex-col items-center justify-center">
          <img src="/paymentError.svg" width="446" height="320" />
          <p className="font-semibold text-5xl mt-10">Üzgünüz Ödeme Alınamadı</p>
          <p className="font-semibold text-2xl mt-5">Bankadan Dönen Cevap</p>
          <p className="font-semibold text-lg mt-5">{errmsg === "None" ? "Lütfen Bankanızla Görüşün" : errmsg}</p>
          <p className="font-semibold text-lg mt-5">{mderrormessage !== "None" && mderrormessage}</p>
          <p className="font-semibold text-lg mt-5">{error_detail !== "None" && error_detail}</p>
        </div>
      }
    </div>
  );
}

export default PaymentMessage;
