import React from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAccountingStore } from "store/AccountingStore";
import { Link } from "react-router-dom";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";
import { useGlobalStore } from "store/GlobalStore";

function DomainsBillsTable({ invoices, setOpenProformaInformationModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const data = React.useMemo(
    () => invoices,
    []
  );
  const [setSelectedProforma] = useAccountingStore((state) => [
    state.setSelectedProforma
  ]);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "proforma_invoice_no",
        Cell: (props) => <>{props.row.original.proforma_invoice_no.slice(2)}</> //serhat abi baştaki PF ler gözükmesin istedi.
      },
      // {
      //   Header: "Adı",
      //   accessor: "col2",
      // },
      {
        Header: t("accounting:proformaHistory"),
        accessor: "time_create",
        Cell: ({ value }) => {
          return <p>{moment(value).format("DD/MM/YYYY")}</p>;
        }
      },
      {
        Header: t("accounting:paymentDate"),
        accessor: "time_complete",
        Cell: ({ value }) => {
          return <p>{moment(value).format("DD/MM/YYYY")}</p>;
        }
      },
      {
        Header: t("accounting:amount"),
        accessor: "proforma_total_price_base",
        Cell: ({ value }) => {
          return <p>{changeCurrency(currencyRates, currencyType, value)} {currencyType} + {t("accounting:extras")}</p>;
        }
      },
      {
        Header: t("global:status"),
        accessor: "status",
        Cell: status
      },
      {
        Header: t("global:settings"),
        accessor: "id",
        Cell: tableButton
      }
    ],
    [t]
  );

  function tableButton(props) {
    if (props) {
      if (props.row.original.status !== "completed" && props.row.original.status !== "canceled" && props.row.original.status !== "refunded") {
        return (
          <div className="flex items-center">
            <Link to="/bill-pay">
              <div
                onClick={() =>
                  setSelectedProforma(props.row.original.proforma_invoice_no)
                }
                className="ml-3 cursor-pointer text-primary-hover hover:text-primary"
              >
                {t("accounting:pay")}
              </div>
            </Link>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              setSelectedProforma(props.row.original.proforma_invoice_no);
              setOpenProformaInformationModal(true);
            }}
            className="flex items-center justify-center cursor-pointer"
          >
            <i className="ri-eye-line curs"></i>
          </div>
        );
      }
    }
  }
  function status(props) {
    if (props.value === "pending") {
      return <p className="font-semibold text-alert-red">{t("accounting:notPaid")} </p>;
    } else if (props.value === "completed") {
      return <p className="font-semibold text-alert-green">{t("accounting:paid")} </p>;
    } else if (props.value === "canceled") {
      return <p className="font-semibold text-passive">{t("accounting:cancelled")}</p>;
    } else if (props.value === "refunded") {
      return <p className="font-semibold text-alert-blue">{t("accounting:returned")}</p>;
    } else {
      <p className="font-semibold text-alert-green">- </p>;
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="p-3 text-sm text-left text-white bg-main"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={
                index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
              }
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-main">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default DomainsBillsTable;
