import Button from "components/FormElements/Button";
import { onChanceStatus } from "helpers/helpersFunc";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

function DomainsCard({ datas }) {
  const { t, i18n } = useTranslation(["projects", "global"]);

  return (
    <div
      className="w-full p-4 mt-4 transition-all duration-300 bg-white rounded-md shadow-sm cursor-pointer group hover:bg-primary-hover">
      <div className="grid grid-cols-2">
        <div className="grid grid-cols-5 col-span-2 gap-3 lg:grid-cols-6 lg:col-span-1">
          <div className="col-span-2 lg:col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("services:productId")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.owned_product_code}
            </p>
          </div>
          <div className="col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("services:domainName")}
            </p>
            <p title={datas?.hostname} className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.hostname.length > 25 ? datas?.hostname.substring(0, 25) + "..." : datas?.hostname}
            </p>
          </div>
          <div className="col-span-2">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("services:projectName")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.project_name}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 col-span-2 gap-3 mt-5 lg:col-span-1 lg:mt-0">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:status")}
            </p>
            <p className="font-semibold transition-all duration-300 text-alert-green group-hover:text-white">
              {onChanceStatus(datas?.status)}
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <p className="text-sm transition-all duration-300 text-passive group-hover:text-white">
              {t("projects:daysLeft")}
            </p>
            <p className="font-semibold transition-all duration-300 group-hover:text-white">
              {datas?.date_of_expire && moment(datas?.date_of_expire).format("DD-MM-YYYY HH:mm")}
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div
              className="flex items-center justify-center w-10 h-10 p-4 transition-all duration-300 rounded-md bg-background-table group-hover:bg-primary">
              <i data-tip={datas?.customer_note != "" ? datas?.customer_note : t("services:noteDesc")}
                className={`text-xl transition-all duration-300 ri-sticky-note-line ${datas?.customer_note != "" ? "text-primary-hover" : "text-main"} group-hover:text-white`} />
              <ReactTooltip
                place="top"
                backgroundColor="black"
                textColor="white"
                effect="solid"
              />
            </div>
          </div>
          <div className="col-span-4 mt-2 lg:col-span-1 lg:mt-0">
            <Link to={`/services/domain/${datas?.id}`}>
              <Button
                className="w-full px-10 transition-all duration-300 group-hover:bg-white group-hover:text-primary-hover"
                secondary
                icon
              >
                <i className="ri-settings-3-line"></i>
                <span>{t("projects:manage")}</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainsCard;
