import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import { getInvoicesList } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import InvoicesTable from "./AccountingTables/InvoicesTable";
import AddBalanceModal from "./AccountingModals/AddBalanceModal";
import InvoicesFilterModal from "./AccountingModals/InvoicesFilterModal";
import InvoicesInformationModal from "./AccountingModals/InvoicesInformationModal";
import { useLocation } from "react-router";
import { useGlobalStore } from "store/GlobalStore";

function Bills() {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [invoices, setInvoices] = useState();
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [
    openInvoicesInformationModal,
    setOpenInvoicesInformationModal
  ] = useState(false);
  const [pageLimit, invoicesFilterData, setInvoicesFilterData] = useGlobalStore((state) => [state.pageLimit, state.invoicesFilterData, state.setInvoicesFilterData]);
  const location = useLocation();
  const [numberOfPages, setNumberOfPages] = useState(1);

  const onGetInvoicesList = async (page, limit, configureFilter) => {
    await setInvoices();
    await getInvoicesList(page, limit, configureFilter ? configureFilter : invoicesFilterData).then(async (data) => {
      if (data?.status !== "invoice_not_found") {
        await setInvoices(data);
        setNumberOfPages(data[0].number_of_pages);
      }
      if (data?.status === "invoice_not_found") {
        setInvoices("invoice_not_found");
      }
    });
  };

  useEffect(() => {

    const asyncFunction = async () => {

      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }

      let configureFilter = {
        project_id: null,
        is_paid: null,
        price: null,
        billing_date: null,
        payment_date: null

      };
      if (!invoicesFilterData || invoicesFilterData == "") {
        await setInvoicesFilterData(configureFilter);
        await onGetInvoicesList(page, pageLimit, configureFilter);

      } else {
        await onGetInvoicesList(page, pageLimit, invoicesFilterData);
      }


    };
    asyncFunction();

  }, []);
  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("accounting:myBills")}</h2>
        <div className="items-center lg:flex ">
          <div className="flex">
            <Button
              onClick={() => setOpenBalanceModal(true)}
              secondary
              icon
              className="flex items-center mr-5 px-7"
            >
              <i className="ri-add-circle-line" />
              <span>{t("accounting:addBalance")}</span>
            </Button>
            {/*
            <TextField
              id="search"
              name="search"
              type="search"
              placeholder="Fatura Adı Giriniz"
              ref={null}
              onClick={() => alert("clicked!")}
              className="mr-5"
            /> */}
          </div>
          <div className="flex my-5 lg:my-0">
            <div className="relative">
              <Button
                onClick={() => setOpenFilterModal(true)}
                className="flex items-center mr-5 px-7"
                tertiary
              >
                <i className="ri-equalizer-line" />
                <span>{t("projects:filter")}</span>
              </Button>
              {(invoicesFilterData?.count && invoicesFilterData?.count > 0) ?
                <div
                  className="absolute flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 right-3 bg-primary-hover">
                  {invoicesFilterData?.count}
                </div>
                :
                <></>
              }


            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        {(invoices !== "invoice_not_found" && invoices) ? (
          <InvoicesTable
            invoices={invoices}
            openInvoicesInformationModal={() =>
              setOpenInvoicesInformationModal(true)
            }
            onGetInvoicesList={onGetInvoicesList}
            numberOfPages={numberOfPages}
          />
        ) : invoices === "invoice_not_found" && invoices ? (
          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/servicesOther.svg" width="403" height="339" />
            <h1>{t("accounting:noBill")}</h1>
            <h5>{t("global:productDesc")}</h5>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full mt-20">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                      type="Oval"
                      color="#ff7c30"
                      height={75}
                      width={75}
                      timeout={3000} //3 secs
            />
          </div>
        )}
      </div>

      {openBalanceModal && (
        <AddBalanceModal setOpenModal={() => setOpenBalanceModal(false)} />
      )}
      {openFilterModal && (
        <InvoicesFilterModal pageLimit={pageLimit} onClick={onGetInvoicesList} invoicesFilterData={invoicesFilterData}
                             setInvoicesFilterData={setInvoicesFilterData}
                             setOpenModal={() => setOpenFilterModal(false)} />
      )}
      {openInvoicesInformationModal && (
        <InvoicesInformationModal
          setOpenModal={() => setOpenInvoicesInformationModal(false)}
        />
      )}
    </div>
  );
}

export default Bills;
