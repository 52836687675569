import { Button, TextField } from "components";
import TextArea from "components/FormElements/Input/TextArea";
import React, { useEffect, useState } from "react";
import CheckBox from "components/FormElements/CheckBox";
import ErrorModal from "components/Modals/ErrorModal";
import { useTranslation } from "react-i18next";
import { useComboBox } from "hooks";
import ComboBox from "components/FormElements/ComboBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  checkDeleteProjectPurpose,
  deleteProject,
  deleteProjectPurpose,
  getBillingInfoList,
  getPurposeList,
  updateProject
} from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import { useGlobalStore } from "store/GlobalStore";
import AddProjectPurposeModal from "./AddProjectPurposeModal";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import { convertToSlug } from "utils/common";


const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string()
});

function ProjectSettings({ projectData, onGetProject }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const [check, setCheck] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userInformations] = useAuthStore((state) => [state.userInformations]);
  const [refreshSidebar, setRefreshSidebar] = useGlobalStore((state) => [state.refreshSidebar, state.setRefreshSidebar]);
  const [openPurposeModal, setOpenPurposeModal] = useState(false);
  const [openEditPurposeModal, setOpenEditPurposeModal] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [selectedInvoices, listInvoices, changeSelectedInvoices, setListInvoices] = useComboBox("Seçiniz");
  const [selectedPurpose, listPurpose, changeSelectedPurpose, setListPurpose] = useComboBox("Seçiniz");
  const [showError, setShowError] = useState(false);

  const onSubmit = async (data) => {
    if (selectedInvoices.id !== 0 && selectedPurpose.id !== 0) {
      let configure = {
        customer_id: userInformations.customer_info.id,
        billing_info_id: selectedInvoices.id,
        purpose_id: selectedPurpose.id,
        name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        description: data.description,
        default_project: check,
        slug: convertToSlug(data.name)
      };
      updateProject(projectData.id, configure).then(res => {
        setRefreshSidebar(!refreshSidebar);
        if (res?.status === "assign_another_default_project_first") {
          addToast("En az bir adet varsayılan proje seçilmiş olmalıdır", {
            appearance: "error",
            autoDismiss: true
          });
          setCheck(true);
        } else {
          addToast(" Kaydedildi", {
            appearance: "success",
            autoDismiss: true
          });
        }

        onGetProject();
      });
    } else {
      setShowError(true);
    }

  };

  const onGetInvoicesList = () => {
    getBillingInfoList().then(res => {
      if (res) {
        setListInvoices(res, "id", "billing_info_name");
        res.map(item => {
          if (item.id === projectData?.billing_info_id) {
            changeSelectedInvoices(item.id, item.billing_info_name);
          }
        });
      }
    });
  };

  const onGetPurposeList = () => {
    getPurposeList().then(res => {
      if (res) {
        setListPurpose(res, "id", "purpose");
        res.map(item => {
          if (item.id === projectData?.purpose_id) {
            changeSelectedPurpose(item.id, item.purpose);
          }
        });
      }
    });
  };

  const onDeleteProject = () => {
    deleteProject(projectData.id).then(res => {
      if (res?.status === "default_project_cannot_be_deleted") {
        addToast("Varsayılan proje silinemez.", {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        setRefreshSidebar(!refreshSidebar);
        addToast(" Proje Silindi.", {
          appearance: "success",
          autoDismiss: true
        });
        navigate("/");
      }

    });
  };


  const onDeleteProjectPurpose = (id) => {
    checkDeleteProjectPurpose(id).then(res => {
      if (res.status) {
        addToast("Proje amacı kullanılıyor. Lütfen diğer projelerden kaldırdığınıza emin olun.", {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        deleteProjectPurpose(id).then(res => {
          onGetPurposeList();
          addToast(" Proje Amacı Silindi.", {
            appearance: "success",
            autoDismiss: true
          });
        });
      }
    });

  };

  useEffect(() => {
    onGetInvoicesList();
    onGetPurposeList();
    if (projectData) {
      reset(projectData);
      setCheck(projectData.default_project);
    }
  }, [openPurposeModal, openEditPurposeModal,projectData]);

  return (
    <div className="container">
      <h2 className="mt-5 mb-5 font-semibold">{t("projects:editproject")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6">
            <div className="mb-5">
              <TextField
                id="name"
                name="name"
                innerRef={register}
                label={t("projects:projectName")}
                inputClass="capitalize"
                placeholder={t("projects:projectName")}
              />
            </div>
            <div>
              <TextArea
                id="description"
                name="description"
                innerRef={register}
                label={t("projects:projectDescription")}
                placeholder={t("projects:projectDescription")}
                rows="4"
              />
            </div>
            <div className="mt-5">
              <CheckBox
                id="1"
                name="1"
                label={t("projects:defaultproject")}
                description={t("projects:defaultprojectDesc")}
                checked={check}
                onChange={() => setCheck(!check)}
              />
            </div>
            <div className="mt-5 lg:flex">
              <div className="lg:mr-5">
                <ComboBox
                  id="select1"
                  name="select1"
                  selected={selectedPurpose}
                  setSelected={changeSelectedPurpose}
                  list={listPurpose}
                  defaultText="Seçiniz"
                  label={t("projects:goalProject")}
                  addButton={() => setOpenPurposeModal(true)}
                  onClickItemHTML={[<i className="ri-edit-line mr-0.5"></i>,
                    <i className="text-lg ri-close-line text-alert-red"></i>]}
                  onClickItemEvent={[(e) => setOpenEditPurposeModal(e), (e) => onDeleteProjectPurpose(e.id)]}
                />
              </div>
              <div className="mt-3 lg:mt-0 lg:mr-5">
                <ComboBox
                  id="select2"
                  name="select2"
                  selected={selectedInvoices}
                  setSelected={changeSelectedInvoices}
                  list={listInvoices}
                  defaultText="Seçiniz"
                  label={t("projects:invoiceAddress")}

                />
              </div>

              <div>
                <Button primary full className="px-12 mt-5 lg:mt-7">
                  {t("global:save")}
                </Button>
              </div>
            </div>

            <div className="p-8 mt-8 border-2 rounded-md bg-pinkWarning border-pinkWarningBorder">
              <h2 className="mb-1 font-semibold text-alert-red">
                {t("projects:deleteProject")}
              </h2>
              <p>{t("projects:deleteProjectDesc")}</p>
              <Button
                onClick={() => setOpenModal(true)}
                className="px-8 mt-7"
                border
                red
                type="button"
              >
                {t("projects:deleteProject")}
              </Button>
            </div>
          </div>
        </div>
      </form>
      {openModal && (
        <ErrorModal
          title={t("projects:deleteProject")}
          question={t("projects:deleteProjectQuestion")}
          buttonText={t("global:yesDelete")}
          setOpenModal={() => setOpenModal(false)}
          onClick={() => onDeleteProject()}
        />
      )}

      {openPurposeModal &&
        <AddProjectPurposeModal setOpenModal={() => setOpenPurposeModal(false)}
                                onGetPurposeList={() => onGetPurposeList()} />
      }

      {openEditPurposeModal &&
        <AddProjectPurposeModal userInformations={userInformations} editData={openEditPurposeModal}
                                setOpenModal={() => setOpenEditPurposeModal(false)}
                                onGetPurposeList={() => onGetPurposeList()} />
      }
    </div>
  );
}

export default ProjectSettings;
