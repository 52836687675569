import React, { useEffect } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";

function UpgradeServicesModal({ setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");

  const dummyDizi = [
    { id: 1, text: "A" },
    { id: 2, text: "CNAME" },
    { id: 3, text: "MX" },
    { id: 4, text: "TXT" }
  ];
  useEffect(() => {
    setList(dummyDizi, "id", "text");
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:upgradeService")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <div className="grid grid-cols-12 mt-5 gap-y-2">
                    <div className="col-span-3">{t("services:availablePackage")}</div>
                    <div className="col-span-1">:</div>
                    <div className="col-span-8">Windows Paket 8</div>

                    <div className="col-span-3">{t("services:operatingSystem")}</div>
                    <div className="col-span-1">:</div>
                    <div className="col-span-8">Windows 2012 Trial</div>

                    <div className="col-span-3">CPU</div>
                    <div className="col-span-1">:</div>
                    <div className="col-span-8">
                      İntel i7 77500H 4.5 GHz 8 Core
                    </div>

                    <div className="col-span-3">RAM</div>
                    <div className="col-span-1">:</div>
                    <div className="col-span-8">3 GB DDR4</div>

                    <div className="col-span-3">Disk</div>
                    <div className="col-span-1">:</div>
                    <div className="col-span-8">35 GB SSD</div>
                  </div>

                  <h5 className="mt-5 mb-3 font-semibold">{t("services:hardwareUpgrade")}</h5>
                  <div className="grid grid-cols-3 gap-5">
                    <div className="col-span-3 lg:col-span-1 ">
                      <ComboBox
                        id="ram"
                        name="ram"
                        selected={selected}
                        setSelected={changeSelected}
                        list={list}
                        label={`${t("global:extra")} RAM`}
                        isSearchable={false}
                      />
                      <p className="mt-2">
                      {t("services:monthlyAmount")}:{" "}
                        <span className="ml-1 font-semibold text-primary-hover">
                          20.00 TL
                        </span>
                      </p>
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                      <ComboBox
                        id="cpu"
                        name="cpu"
                        selected={selected}
                        setSelected={changeSelected}
                        list={list}
                        label={`${t("global:extra")} CPU`}
                        isSearchable={false}
                      />
                      <p className="mt-2">
                      {t("services:monthlyAmount")}:{" "}
                        <span className="ml-1 font-semibold text-primary-hover">
                          20.00 TL
                        </span>
                      </p>
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                      <ComboBox
                        id="disk"
                        name="disk"
                        selected={selected}
                        setSelected={changeSelected}
                        list={list}
                        label={`${t("global:extra")} Disk`}
                        isSearchable={false}
                      />
                      <p className="mt-2">
                      {t("services:monthlyAmount")}:{" "}
                        <span className="ml-1 font-semibold text-primary-hover">
                          20.00 TL
                        </span>
                      </p>
                    </div>
                  </div>
                  <h5 className="mt-5 mb-3 font-semibold">{t("services:packageUpgrade")}</h5>
                  <div className="grid grid-cols-3 gap-5">
                    <div className="col-span-3 lg:col-span-1">
                      <ComboBox
                        id="paket"
                        name="paket"
                        selected={selected}
                        setSelected={changeSelected}
                        list={list}
                        label={t("services:packagesCanSwitch")}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeServicesModal;
