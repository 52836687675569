import React from "react";

function AlertDanger({ title, message }) {
  return (
    <div className="w-full py-2 px-2 rounded-md bg-redAlertBg border border-redAlertBorder">
      <div className="flex items-center">
        <div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11 15.5V17.5H13V15.5H11ZM11 7.5V13.5H13V7.5H11Z"
              fill="#FF433D"
            />
          </svg>
        </div>

        <div className="ml-3">
          <p className="text-alert-red font-semibold">
            {title ? title : "Hata"}
          </p>
          <p className="text-xs">{message}</p>
        </div>
      </div>
    </div>
  );
}

export default AlertDanger;
