import React from "react";
import Numeric from "components/FormElements/Numeric";

function Pagination({
                      pageItems,
                      paginationValue,
                      setPaginationValue,
                      pageValue,
                      setPageValue
                    }) {
  return (
    <div className="flex items-center mt-5">
      <p className="mr-5">Sayfadaki kayıt</p>
      <Numeric value={pageValue} setNumericValue={setPageValue} />
      <svg
        onClick={() => setPaginationValue(1)}
        className="ml-5"
        width="15"
        height="12"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.82798 4.99998L10.657 7.82798L9.24298 9.24298L4.99998 4.99998L9.24298 0.756981L10.657 2.17198L7.82798 4.99998Z"
          fill="#555555"
        />
        <path
          d="M3.171 4.99998L6 7.82798L4.586 9.24298L0.343001 4.99998L4.586 0.756981L6 2.17198L3.171 4.99998Z"
          fill="#555555"
        />
      </svg>
      <svg
        onClick={() => setPaginationValue(paginationValue - 1)}
        className="ml-2"
        width="15"
        height="12"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.82798 4.99998L5.65698 7.82798L4.24298 9.24298L-1.67952e-05 4.99998L4.24298 0.756981L5.65698 2.17198L2.82798 4.99998Z"
          fill="#555555"
        />
      </svg>
      {pageItems.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => setPaginationValue(index + 1)}
            className={
              paginationValue === index + 1
                ? "py-1 px-2 mx-1 bg-white rounded-md cursor-pointer"
                : "py-1 px-2 mx-1 hover:bg-white rounded-md cursor-pointer"
            }
          >
            {index + 1}
          </div>
        );
      })}

      <svg
        onClick={() => setPaginationValue(paginationValue + 1)}
        className="mr-2"
        width="15"
        height="12"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.17202 5.00002L0.343018 2.17202L1.75702 0.757019L6.00002 5.00002L1.75702 9.24302L0.343018 7.82802L3.17202 5.00002Z"
          fill="#555555"
        />
      </svg>
      <svg
        onClick={() => setPaginationValue(pageItems?.length)}
        width="15"
        height="12"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.17202 4.99996L0.343018 2.17196L1.75702 0.756958L6.00002 4.99996L1.75702 9.24296L0.343018 7.82796L3.17202 4.99996Z"
          fill="#555555"
        />
        <path
          d="M7.829 4.99996L5 2.17196L6.414 0.756958L10.657 4.99996L6.414 9.24296L5 7.82796L7.829 4.99996Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
}

export default Pagination;
