import React from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";

function DNSServicesTable({ dnsList, deleteDNS }) {
  const { t, i18n } = useTranslation(["projects", "global","services"]);
  const data = dnsList;
  const columns = React.useMemo(
    () => [
      {
        Header: t("services:registryName"),
        accessor: "record_name"
      },
      {
        Header: "TTL",
        accessor: "ttl"
      },
      {
        Header: t("global:type"),
        accessor: "type"
      },
      {
        Header: t("services:priority"),
        accessor: "priority"
      },
      {
        Header: t("services:registry"),
        accessor: "record"
      },
      {
        Header: t("global:settings"),
        accessor: "id",
        Cell: settings
      }
    ],
    [t, dnsList]
  );

  function settings(props) {
    return (
      <div className="flex items-start ">
        {/* <i className="text-lg cursor-pointer ri-edit-line hover:text-primary-hover"></i> */}
        <i onClick={() => deleteDNS(props.value)}
           className="ml-3 text-lg cursor-pointer ri-delete-bin-5-line text-alert-red"></i>
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
          {headerGroup.headers.map((column) => (
            <th
              className="p-3 text-sm text-left text-white bg-main"
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render("Header")}
              <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className={
              index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
            }
          >
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps()} className="p-2 text-main">
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}

export default DNSServicesTable;
