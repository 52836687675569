import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import { createProjectPurpose, updateProjectPurpose } from "services/allServices";
import { useToasts } from "react-toast-notifications";

function AddProjectPurposeModal({ userInformations, editData, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [purpose, setPurpose] = useState("");
  const { addToast } = useToasts();

  const onCreatePurpose = () => {
    if (purpose !== "") {

      if (!editData) {
        let configure = {
          purpose: purpose
        };
        createProjectPurpose(configure).then(res => {
          setOpenModal(false);
          addToast(" Kaydedildi", {
            appearance: "success",
            autoDismiss: true
          });
        });
      } else {
        let configure = {
          customer_id: userInformations?.customer_info.id,
          purpose: purpose
        };
        updateProjectPurpose(editData.id, configure).then(res => {
          setOpenModal(false);
          addToast(" Kaydedildi", {
            appearance: "success",
            autoDismiss: true
          });
        });
      }

    }
  };

  useEffect(() => {
    if (editData) {
      setPurpose(editData.text);
    }
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75 " />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start ">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {editData ? t("projects:editProjectPurpose") : t("projects:addProjectPurpose")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-10 bg-modalBg ">
                  <TextField
                    label={t("projects:goalProject")}
                    placeholder={t("projects:enterProjectPurpose")}
                    value={purpose}
                    maxLength={20}
                    onChange={(v) => setPurpose(v)}
                  />
                </div>


                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={() => onCreatePurpose()}
                  >
                    {t("global:add")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProjectPurposeModal;
