import Button from "components/FormElements/Button";
import CheckBox from "components/FormElements/CheckBox";
import TextArea from "components/FormElements/Input/TextArea";
import TextField from "components/FormElements/Input/TextField";
import { useCheckList } from "hooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { listUnusedIP, updateIPAddress } from "services/allServices";
import VirtualIPDetailsTable from "../Tables/VirtualIPDetailsTable";

function VirtualIPDetailsModal({ setOpenModal, data, onListIP }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const { addToast } = useToasts();

  const [openNote, setOpenNote] = useState(false);
  const [openRDNS, setOpenRDNS] = useState(false);
  const [openIP, setOpenIP] = useState(false);

  const [selectedIP, setselectedIP] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const [checkList, setCheckList] = useCheckList(["check1", "check2"]);
  const [ipList, setIPlist] = useState();
  const [note, setNote] = useState("");
  const [rDNS, setRDNS] = useState("");

  const onListUnusedIP = () => {
    if (data.is_class) {
      listUnusedIP(data.id).then(res => {
        setIPlist(res);
      });
    } else {
      setIPlist([{ ...data, subnet: data.subnet_type }]);
    }

  };

  const onOpenNote = async (value, type) => {
    setselectedIP(value);
    setselectedType("note");
    if (selectedIP === null || type !== selectedType || value.ip === selectedIP)
      setOpenNote(!openNote);
    setOpenRDNS(false);
    setOpenIP(false);
    setNote(value.note);
  };
  const onOpenRDNS = (value, type) => {
    setselectedIP(value);
    setselectedType("rdns");
    if (selectedIP === null || type !== selectedType || value.ip === selectedIP)
      setOpenRDNS(!openRDNS);

    setOpenNote(false);
    setOpenIP(false);
    setRDNS(value.rdns);
  };

  const onOpenIP = (value, type) => {
    setselectedIP(value);
    setselectedType("ip");
    if (selectedIP === null || type !== selectedType || value.ip === selectedIP)
      setOpenIP(!openIP);

    setOpenNote(false);
    setOpenRDNS(false);
  };

  const onUpdateIP = (type) => {
    let params = {};
    if (type === "note") {
      params = {
        content: {
          note: note
        }
      };
    }

    if (type === "rdns") {
      params = {
        content: {
          rdns: rDNS
        }
      };
    }
    updateIPAddress(selectedIP.id, params).then(async (res) => {
      addToast(t("services:saveTransaction"), {
        appearance: "success",
        autoDismiss: true
      });
      await onListIP();
      await onListUnusedIP();
      await setOpenModal(false)
    });
  };

  useEffect(() => {
    onListUnusedIP();
  }, []);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {data?.ip_address ? data?.ip_address : data?.ip_class} {t("services:ipDetail")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="flex items-center px-5">
                  <div className="w-3 h-3 rounded-full bg-[#facdcd]" />
                  <p className="ml-2 text-sm text-main">{t("services:unavailable")}</p>

                  <div className="w-3 h-3 rounded-full bg-[#CAECD0] ml-8" />
                  <p className="ml-2 text-sm text-main">{t("services:used")}</p>
                </div>
                {ipList && ipList.length > 0 &&
                  <div className="px-5 pt-5 pb-8 overflow-y-scroll bg-modalBg max-h-96 ">
                    <VirtualIPDetailsTable
                      ipList={ipList}
                      openNote={openNote}
                      onOpenNote={(v, type) => onOpenNote(v, type)}
                      onOpenRDNS={(v, type) => onOpenRDNS(v, type)}
                      onOpenIP={(v, type) => onOpenIP(v, type)}
                    />
                  </div>
                }

                {openNote && (
                  <div className="px-5 pb-5 bg-modalBg">
                    <h4 className="mb-3 font-semibold">{t("services:addNote")}</h4>
                    <TextArea
                      label={`Not (${selectedIP.ip_address})`}
                      placeholder={t("services:note")}
                      rows="4"
                      value={note}
                      onChange={setNote}
                    />
                    <div className="flex justify-end py-2">
                      <Button onClick={() => onUpdateIP("note")} className="px-16" primary>
                      {t("services:addNote")}
                      </Button>
                    </div>
                  </div>
                )}

                {openRDNS && (
                  <div className="px-5 pb-5 bg-modalBg">
                    <h4 className="mb-3 font-semibold">
                      Reverse DNS {t("global:change")}
                    </h4>
                    <p className="font-semibold">{t("services:ipAddress")}: {selectedIP.ip_address}</p>
                    <div className="mt-3 lg:w-1/3">
                      <TextField
                        label="Reverse DNS"
                        placeholder="RDNS Giriniz"
                        type="text"
                        value={rDNS}
                        onChange={setRDNS}
                      />
                    </div>

                    <div className="flex justify-end py-2">
                      <Button onClick={() => onUpdateIP("rdns")} className="px-16" primary>
                        {t("global:save")}
                      </Button>
                    </div>
                  </div>
                )}

                {openIP && (
                  <div className="px-5 pb-5 bg-modalBg">
                    <h4 className="mb-3 font-semibold">{t("services:ipAccessPermissions")}</h4>
                    <p className="font-semibold">{t("services:ipAddress")}: {selectedIP.ip_address}</p>
                    <div className="flex mt-5">
                      <div className="mr-3">
                        <CheckBox
                          id="check1"
                          name="check1"
                          label={t("services:abroad")}
                          checked={true}
                          onChange={() => setCheckList("check1")}
                          disabled={true}
                        />
                      </div>

                      <div className="mr-3">
                        <CheckBox
                          id="check2"
                          name="check2"
                          label={t("services:domestic")}
                          checked={true}
                          onChange={() => setCheckList("check2")}
                          disabled={true}
                        />
                      </div>

               
                    </div>

                    <div className="flex justify-end py-2">
                      <Button className="px-16" primary disabled>
                      {t("global:save")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualIPDetailsModal;
