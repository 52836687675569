import { useEffect, useRef, useState } from "react";
import { getCities, getCountry, getCountryStates } from "services/allServices";

export const useComboBox = (selectedItemText = "", defaultList = []) => {
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    text: selectedItemText,
    otherValues: {}
  });
  const [itemList, setItems] = useState(defaultList);

  const changeSelectedItem = (id, text, otherValues = null) => {
    setSelectedItem({ id: id, text: text, otherValues: otherValues });
  };

  const setItemList = (
    items,
    idParam,
    textParam,
    secondTextParam = null,
    textSeperator = null,
    otherValueFields = null
  ) => {
    let newItemList = [];
    for (let i = 0; i < items?.length; i++) {
      let newOtherValues = {};
      if (otherValueFields) {
        otherValueFields.forEach((field) => {
          newOtherValues[field] = items[i][field];
        });
      }
      if (secondTextParam === null)
        newItemList.push({ id: items[i][idParam], text: items[i][textParam], otherValues: newOtherValues });
      else
        newItemList.push({
          id: items[i][idParam],
          text: items[i][textParam] + textSeperator + items[i][secondTextParam],
          otherValues: newOtherValues
        });
    }
    setItems(newItemList);
  };

  return [selectedItem, itemList, changeSelectedItem, setItemList];
};


const useConfigurationComboBox = (selectedItemText = "Seçiniz") => {
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    text: selectedItemText,
    price: 0,
    otherInfo: {}
  });
  const [itemList, setItems] = useState([]);

  const changeSelectedItem = (id, text, price, otherInfo) => {
    setSelectedItem({
      id: id,
      text: text,
      price: price,
      otherInfo: otherInfo ? otherInfo : itemList.filter(item => item.id === id)[0]?.otherInfo
    });
  };

  const setItemList = (
    [...items],
    idParam = "id",
    textParam = "component_name",
    priceParam = "price"
  ) => {
    if (typeof items !== "undefined") {
      let newItemList = [];
      items.map((item, index) => {
        newItemList.push({
          id: item[idParam],
          text: item[textParam],
          price: item[priceParam],
          otherInfo: item
        });
      });
      setItems(newItemList);
    }
  };

  return [selectedItem, itemList, changeSelectedItem, setItemList];
};

export default useConfigurationComboBox;

export const useMultiselectComboBox = (selectedItemText = "") => {
  const [selectedItems, setSelectedItems] = useState([
    { id: 0, text: selectedItemText }
  ]);
  const [itemList, setItems] = useState([]);

  const checkIfExist = (id) => {
    for (let i = 0; i < selectedItems?.length; i++)
      if (selectedItems[i].id === id) return true;
    return false;
  };

  const removeFromList = (id) => {
    let newList = [];
    for (let i = 0; i < selectedItems?.length; i++) {
      if (selectedItems[i].id !== id) newList.push(selectedItems[i]);
    }
    setSelectedItems(newList);
  };

  const addToList = (id, text) => {
    let newList = [];
    for (let i = 0; i < selectedItems?.length; i++)
      if (selectedItems[0].id !== 0) newList.push(selectedItems[i]);
    newList.push({ id: id, text: text });
    setSelectedItems(newList);
  };

  const toggleItems = (id, text) => {
    if (id > 0)
      if (checkIfExist(id)) removeFromList(id);
      else addToList(id, text);
    else setSelectedItems([{ id: id, text: text }]);
  };

  const setItemList = (
    items,
    idParam,
    textParam,
    secondTextParam = null,
    textSeperator = null
  ) => {
    let newItemList = [];
    for (let i = 0; i < items?.length; i++)
      if (secondTextParam === null)
        newItemList.push({ id: items[i][idParam], text: items[i][textParam] });
      else
        newItemList.push({
          id: items[i][idParam],
          text: items[i][textParam] + textSeperator + items[i][secondTextParam]
        });
    setItems(newItemList);
  };

  return [selectedItems, itemList, toggleItems, setItemList,setSelectedItems];
};

export function useToggle(defaultValue) {
  const [data, setData] = useState(defaultValue);

  function toggle() {
    setData(!data);
  }

  return [data, toggle];
}

export function useToggleSidebar() {
  const [homepageOpen, setHomepageOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [accountingOpen, setAccountingsOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);

  function toggle(value) {
    closeToggles();
    if (value === "1") {
      setHomepageOpen(!homepageOpen);
    }
    if (value === "2") setProjectOpen(!projectOpen);
    if (value === "3") setSupportOpen(!supportOpen);
    if (value === "4") setProductsOpen(!productsOpen);
    if (value === "5") setAccountingsOpen(!accountingOpen);
    if (value === "6") setSettingOpen(!settingOpen);

  }

  function closeToggles() {
    setHomepageOpen(false);
    setProjectOpen(false);
    setSupportOpen(false);
    setProductsOpen(false);
    setAccountingsOpen(false);
    setSettingOpen(false);

  }

  return [
    homepageOpen,
    projectOpen,
    supportOpen,
    productsOpen,
    accountingOpen,
    settingOpen,
    toggle,
    closeToggles
  ];
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useCheckList() {
  const [checkList, setCheckList] = useState([]);

  function onSetCheckList(name) {
    let newList = [];
    if (checkList?.length > 0) {
      if (checkList.some((n) => n === name)) {
        checkList.map((item) => {
          if (item !== name) {
            newList.push(item);
          }
        });
      } else {
        newList = [...checkList];
        newList.push(name);
      }
    } else {
      newList.push(name);
    }
    setCheckList(newList);
  }

  return [checkList, onSetCheckList, setCheckList];
}

export function useCheckListObj() {
  const [checkList, setCheckList] = useState([]);

  function onSetCheckList(obj) {
    let newList = [];
    if (checkList?.length > 0) {
      if (checkList.some((n) => n.id === obj.id)) {
        checkList.map((item) => {
          if (item.id !== obj.id) {
            newList.push(item);
          }
        });
      } else {
        newList = [...checkList];
        newList.push(obj);
      }
    } else {
      newList.push(obj);
    }
    setCheckList(newList);
  }

  return [checkList, onSetCheckList, setCheckList];
}

export function useCountry(disabled) {
  const [
    selectedCountry,
    country,
    setSelectedCountry,
    setCountry
  ] = useComboBox("Ülke Seçiniz");
  const [
    selectedState,
    countryStates,
    setSelectedState,
    setCountryStates
  ] = useComboBox("İl Seçiniz");
  const [selectedCity, cities, setSelectedCity, setCities] = useComboBox(
    "İlçe Seçiniz"
  );


  const onGetCountry = async () => {
    let countries = [];
    countries = await getCountry();
    await setCountry(countries, "country_code", "country_name");
  };

  const onGetStates = async () => {
    let statesarr = [];
    statesarr = await getCountryStates(selectedCountry.id);
    await setCountryStates(statesarr, "state_code", "state_name");
  };

  const onGetCities = async () => {
    let citiessarr = [];
    citiessarr = await getCities(selectedCountry.id, selectedState.id);
    await setCities(citiessarr, "id", "cities_name");
  };

  useEffect(() => {
    const asyncFunction = async () => {
      if (selectedCountry.id != 0) { //disabled özelliği true olursa otomatik dolumlarda sıfırlanma sorunu olmaz 
        await onGetStates();
        if (!disabled) {
          setSelectedState(0, "İl Seçiniz");
          setSelectedCity(0, "İlçe Seçiniz");
        }

      }
    };
    asyncFunction();
  }, [selectedCountry]);

  useEffect(() => {
    const asyncFunction = async () => {
      if (selectedCountry.id != 0 && selectedState.id != 0) { //disabled özelliği true olursa otomatik dolumlarda sıfırlanma sorunu olmaz 
        await onGetStates();
        if (!disabled) {
          setSelectedCity(0, "İlçe Seçiniz");
        }
      }
    };
    asyncFunction();

  }, [selectedState]);

  useEffect(() => {
    const asyncFunction = async () => {
      if (selectedCountry.id != 0 && selectedState.id != 0) {
        await onGetCities();
      }
    };
    asyncFunction();
  }, [selectedCity]);

  useEffect(() => {
    onGetCountry();
  }, []);

  return [
    country,
    countryStates,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    onGetCountry,
    onGetStates,
    onGetCities,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState
  ];
}

export function useCloseSelectBox(func) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return [wrapperRef];
}
