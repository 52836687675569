export const generatePassword = () => {
  let chars = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    "0123456789",
    "abcdefghijklmnopqrstuvwxyz",
    "@#=:.!"
  ];
  return [3, 3, 4, 2]
    .map(function(len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function(x) {
          return x[Math.floor(Math.random() * x?.length)];
        })
        .join("");
    })
    .concat()
    .join("")
    .split("")
    .sort(function() {
      return 0.5 - Math.random();
    })
    .join("");
};

export const changeCurrency = (currencyRates, currencyType, price, defaultCurrency = "TL") => {
  let newPrice = price;
  if (currencyRates) {
    let defaultRate = currencyRates?.find(x => x.currency_type === defaultCurrency)?.currency_price;
    currencyRates?.map((rate) => {
      if (rate.symbol === currencyType && defaultRate) {
        newPrice = (price * defaultRate) / rate.currency_price;
      }
    });
    return parseFloat(newPrice).toFixed(2);
  }
};

export const findCurrency = (currencyType) => {
    if(currencyType === "₺"){
      return "TL"
    }else if(currencyType === "$"){
      return "USD"
    }else if(currencyType === "€"){
      return "EURO"
    }else if(currencyType === "₿"){
      return "BTC"
    }else if(currencyType === "₽"){
      return "RUBLE"
    }
};



export function isNullOrEmptyOrUndefined(item) {
  return item === null || item === "" || typeof item === "undefined";
}

export function isUndefined(item) {
  return typeof item === "undefined";
}

export const combineIDContent = async (data) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data?.length !== "undefined")
      await data.map((d) => {
        result.push({ id: d.id, ...d.content });
      });
    else
      result = { ...data.content, id: data.id };
  return result;
};


export const onChanceStatus = (status) => {
  if (status === "waiting") {
    return (
      <p className="font-bold text-alert-yellow">Bekliyor</p>
    );
  } else if (status === "completed") {
    return (
      <p className="font-bold text-alert-blue">Tamamlandı</p>

    );
  } else if (status === "active") {
    return (
      <p className="font-bold text-alert-green">Aktif</p>

    );
  } else if (status === "suspended") {
    return (
      <p className="font-bold text-garantiGreen">Askıya Alındı</p>
    );
  } else if (status === "closed") {
    return (
      <p className="font-bold text-passive">Kapatıldı</p>
    );
  } else if (status === "canceled") {
    return (
      <p className="font-bold text-alert-red">İptal Edildi</p>
    );
  } else if (status === "abuse") {
    return (
      <p className="font-bold text-alert-red">Kötüye Kullanım</p>
    );
  } else if (status === "deleted") {
    return (
      <p className="font-bold text-alert-red">Silindi</p>
    );
  } else if (status === "refunded") {
    return (
      <p className="font-bold text-alert-red">İade Edildi</p>
    );
  }else {
    return (
      <p className="">-</p>
    );
  }
};


export const toggleCheckBoxItem = (id, list, set, overrideList = null) => {
  let newList = [];
  if (overrideList) {
    for (let i = 0; i < list.length; i++) {
      newList.push({
        ...list[i],
        id: list[i].id,
        text: list[i].text,
        message: list[i].message,
        checked: overrideList.filter((x) => x === list[i].id).length === 1,
        disabled: list[i].disabled
      });
    }
  } else {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id)
        newList.push({
          ...list[i],
          id: list[i].id,
          text: list[i].text,
          message: list[i].message,
          checked: !list[i].checked,
          disabled: list[i].disabled
        });
      else newList.push(list[i]);
    }
  }
  set(newList);
};
