import React, { useEffect } from "react";
import Button from "components/FormElements/Button";
import TextField from "components/FormElements/Input/TextField";
import { getMailSendingInfos, updateMailSendingInfos } from "services/allServices";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  authorized_email: yup.string(),
  accounting_email: yup.string(),
  technical_email: yup.string()

});

function EmailSend() {
  const { t, i18n } = useTranslation(["global", "nav", "settings"]);

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const { addToast } = useToasts();

  const onGetMailSendingInfos = () => {
    getMailSendingInfos().then(res => {
      reset(res);
    });
  };

  const onSubmit = (data) => {
    let configure = {
      authorized_email: data.authorized_email,
      accounting_email: data.accounting_email,
      technical_email: data.technical_email
    };
    updateMailSendingInfos(configure).then(res => {
      addToast(t("global:itSaved"), {
        appearance: "success",
        autoDismiss: true
      });
    });
  };


  useEffect(() => {
    onGetMailSendingInfos();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mt-5 mb-2 font-semibold">{t("settings:emailSending")}</h2>
      <p>
        {t("settings:emailDesc")}
      </p>
      <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="authorized_email"
            name="authorized_email"
            type="text"
            innerRef={register}
            label={t("settings:authorizedEmail")}
            placeholder="yetkili@alanadi.com"
          />
        </div>
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="accounting_email"
            name="accounting_email"
            type="text"
            innerRef={register}
            label={t("settings:accountingEmail")}
            placeholder="muhasebe@alanadi.com"
          />
        </div>
        <div className="col-span-12 lg:col-span-3">
          <TextField
            id="technical_email"
            name="technical_email"
            type="text"
            innerRef={register}
            label={t("settings:technicalEmail")}
            placeholder="teknik@alanadi.com"
          />
        </div>
      </div>
      <div className="flex justify-end w-full mt-3 lg:w-3/4 lg:pr-2">
        <Button
          primary
          className="flex items-center justify-center w-full px-16 lg:w-auto"
        >
          {t("global:save")}
        </Button>
      </div>
    </form>
  );
}

export default EmailSend;
