import { changeCurrency } from "helpers/helpersFunc";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "store/GlobalStore";

function HomePriceCard({ credit }) {
  const { t, i18n } = useTranslation(["homepage"]);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  return (
    <div
      className="flex items-center justify-around w-full px-2 py-8 transition-all duration-300 bg-white border-b-4 rounded-md shadow-md cursor-pointer border-alert-green hover:shadow-lg ">
      <div className="flex items-center">
        <svg
          className="hidden lg:block"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.5 11H35V26H33.5V30.5C33.5 30.8978 33.342 31.2794 33.0607 31.5607C32.7794 31.842 32.3978 32 32 32H5C4.60218 32 4.22064 31.842 3.93934 31.5607C3.65804 31.2794 3.5 30.8978 3.5 30.5V6.5C3.5 6.10218 3.65804 5.72064 3.93934 5.43934C4.22064 5.15804 4.60218 5 5 5H32C32.3978 5 32.7794 5.15804 33.0607 5.43934C33.342 5.72064 33.5 6.10218 33.5 6.5V11ZM30.5 26H21.5C19.5109 26 17.6032 25.2098 16.1967 23.8033C14.7902 22.3968 14 20.4891 14 18.5C14 16.5109 14.7902 14.6032 16.1967 13.1967C17.6032 11.7902 19.5109 11 21.5 11H30.5V8H6.5V29H30.5V26ZM32 23V14H21.5C20.3065 14 19.1619 14.4741 18.318 15.318C17.4741 16.1619 17 17.3065 17 18.5C17 19.6935 17.4741 20.8381 18.318 21.682C19.1619 22.5259 20.3065 23 21.5 23H32ZM21.5 17H26V20H21.5V17Z"
            fill="#1ACD67"
          />
        </svg>
        <div className="ml-3">
          <p>{t("creditBalance")}</p>

          <p
            className="text-lg font-semibold text-alert-green">{credit ? parseFloat(changeCurrency(currencyRates, currencyType, credit)).toFixed(2) : "0"} {currencyType}</p>
          {/* <p className="text-lg font-semibold text-alert-green">{credit} ₺</p> */}

        </div>
      </div>
    </div>
  );
}

export default HomePriceCard;
