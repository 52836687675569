import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { useGlobalStore } from "store/GlobalStore";
import MakdosTable from "components/MakdosTable";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";

function PaymentHistoryTable({ transactions, onGetTransactionList, numberOfPages }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);

  const [data, setData] = useState(transactions);

  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);

  const columns = React.useMemo(
    () => [
      {
        Header: t("accounting:trnNo"),
        accessor: "transaction_no",
        Cell: (props) => <>{props.row.original.transaction_no.slice(2)}</> //serhat abi baştaki CT ler gözükmesin istedi.
      },
      {
        Header: t("global:date"),
        accessor: "payment_date",
        Cell: (props) => moment(props.value).format("DD-MM-YYYY HH:mm")
      },

      {
        Header: t("accounting:proformaNo"),
        accessor: "proforma_invoice_no",
        Cell: incoiveNo
      },
      {
        Header: t("accounting:paymentOptions"),
        accessor: "payment_method",
        Cell: paymentType
      },
      {
        Header: t("global:description"),
        accessor: "description"
      },
      {
        Header: t("accounting:amountPaid"),
        accessor: "paid_price_total",
        Cell: price
      }
    ],
    [t, currencyType]
  );

  function paymentType(props) {
    if (props.value === "customer_balance") {
      return <i title="Kredi" className="mr-2 text-lg ri-wallet-3-line"></i>;
    } else if (props.value === "customer_credit") {
      return <i title="Kredi Manuel" className="mr-2 text-lg ri-wallet-3-line"></i>;
    } else if (props.value === "cc_garanti_tl") {
      return <i title="Kredi Kartı" className="mr-2 text-lg ri-bank-card-line"></i>;
    } else if (props.value === "paypal_tr" || props.value === "paypal_usa") {
      return <i title="Paypal" className="mr-2 text-lg ri-paypal-line"></i>;
    } else if (props.value === "bank_transfer_garanti_tl" || props.value === "bank_transfer_isbank_tl" || props.value === "bank_transfer_ziraat_tl") {
      return <i title="Havale" className="mr-2 text-lg ri-send-plane-line"></i>
    } else if (props.value === "cash_manuel") {
      return <i title="Cash Manuel" className="mr-2 text-lg ri-hand-coin-line"></i>;
    } else if (props.value === "cash_usa") {
      return <i title="Cash USA" className="mr-2 text-lg ri-money-dollar-circle-line"></i>;
    } else if (props.value === "cash_eur") {
      return <i title="Cash EURO" className="mr-2 text-lg ri-money-euro-circle-line"></i>;
    } else if (props.value === "cash_tr") {
      return <i title="Cash TR" className="mr-2 text-lg ri-cash-line"></i>;
    } else {
      return <p>-</p>
    }

  }



  function incoiveNo(props) {
    if (props.value) {
      return (
        <p className="font-semibold">{props.value}</p>
      );
    } else {
      return <p className="font-semibold">-</p>;
    }
  }

  function price(props) {
    if (props.value) {
      return (
        <p className={`font-semibold ${props.row.original.is_refund ? " text-alert-red " : "text-black"}`}>{changeCurrency(currencyRates, currencyType, props.value)} {currencyType}</p>
      );
    } else {
      return <p className="font-semibold">-</p>;
    }
  }

  useEffect(() => {
    setData(transactions);
  }, [transactions]);


  return (
    <div className="w-full mb-12 ">
      <MakdosTable
        isCheckboxVisible={false}
        setCheckList={() => {
        }}
        checkList={[]}
        data={data}
        setData={setData}
        showPagination={true}
        columns={columns}
        pageLimits={pageLimits}
        isBackendPagination={true}
        backendPaginationMethod={(page, limit) => {
          onGetTransactionList(page, limit);
        }}
        backendNumberOfPages={numberOfPages}
        activePageSize={activePageSize}
        setActivePageSize={setActivePageSize}
        isDndAvailable={false}
      />
    </div>
  );
}

export default PaymentHistoryTable;
