import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import { useGlobalStore } from "store/GlobalStore";
import { createPaymentNotificationProforma, ticketCreatePaymentNotification } from "services/allServices";
import { useNavigate } from "react-router-dom";

function MultiplePaymentTransferModal({ setOpenModal, tableData, price, proformaNo, selectedPaymentMethod, proformaNoList, payAmount }) {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation(["projects", "global", "accounting", "support"]);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const [isCheck, setIsCheck] = useState("1");
  const [bankReceiptID, setBankReceiptID] = useState("");

  const [senderWarning, setSenderWarning] = useState(false);
  const [receivingWarning, setReceivingWarning] = useState(false);
  const senderBankDizi = [
    { id: "bank_transfer_garanti_tl", text: "Garanti Bankası" },
    { id: "bank_transfer_isbank_tl", text: "İş Bankası" },
    { id: "bank_transfer_ziraat_tl", text: "Ziraat Bankası" },
    { id: 4, text: "Finans Katılım Bankası" },
    { id: 5, text: "Halk Bankası" },
    { id: 6, text: "Vakıflar Bankası" },
    { id: 7, text: "Yapı ve Kredi Bankası" },
    { id: 8, text: "Finans Bank" },
    { id: 9, text: "Akbank" },
    { id: 10, text: "Bank Asya" },
    { id: 11, text: "Şekerbank" },
    { id: 12, text: "Adabank" },
    { id: 13, text: "Albaraka Türk Katılım Bankası" },
    { id: 14, text: "Alternatif Bank" },
    { id: 15, text: "Anadolubank" },
    { id: 16, text: "Burgan Bank" },
    { id: 17, text: "Citibank" },
    { id: 18, text: "Denizbank" },
    { id: 19, text: "Deutsche Bank" },
    { id: 20, text: "Fibabanka" },
    { id: 21, text: "Habib Bank Limited" },
    { id: 22, text: "HSBC" },
    { id: 23, text: "ING Bank" },
    { id: 24, text: "Kuveyt Türk Katılım Bankası" },
    { id: 25, text: "Odeabank" },
    { id: 26, text: "T-Bank" },
    { id: 27, text: "Tekstilbank" },
    { id: 28, text: "Turkish Bank" },
    { id: 29, text: "Türk Ekonomi Bankası" },
    { id: 30, text: "Diğer" }
  ];
  const receivingBankDizi = [
    { id: "bank_transfer_garanti_tl", text: "Garanti Bankası (TL)" },
    { id: "bank_transfer_isbank_tl", text: "İş Bankası (TL)" },
    { id: "bank_transfer_ziraat_tl", text: "Ziraat Bankası (TL)" }
  ];

  const [selectedSenderBank, senderBankList, changeSelectedSenderBank, setSenderBankList] = useComboBox(t("support:selectBank"));

  const [selectedReceivingBank, receivingBankList, changeSelectedReceivingBank, setReceivingBankList] = useComboBox(t("support:selectBank"));

  const onCreatePaymentNotification = () => {
    if (selectedSenderBank.id === 0) {
      setSenderWarning(true);
    }
    if (selectedReceivingBank.id === 0) {
      setReceivingWarning(true);
    }
    if (selectedSenderBank.id !== 0 && selectedReceivingBank.id !== 0) {
      let prformaNoString = "";
      if (tableData) {
        tableData.map((pf, index) => {
          if (index + 1 === tableData?.length) {
            prformaNoString += pf.proforma_no;
          } else {
            prformaNoString += pf.proforma_no + ",";

          }
        });
      } else {
        if(proformaNoList){
          proformaNoList.map((pf, index) => {
            if (index + 1 === proformaNoList?.length) {
              prformaNoString += pf;
            } else {
              prformaNoString += pf + ",";
  
            }
          });
        }else{
          prformaNoString = proformaNo;
        }
      }
      let configure = {
        bank_from: selectedSenderBank.text,
        bank_to: selectedReceivingBank.text,
        amount: price,
        proforma_invoice_no: prformaNoString,
        bank_receipt_id: bankReceiptID
      };

      ticketCreatePaymentNotification(configure).then(res => {
        setOpenModal("false");
        navigate("/ticket-and-support");

      });
    }
  };
 
  useEffect(() => {
    setSenderBankList(senderBankDizi, "id", "text");
    setReceivingBankList(receivingBankDizi, "id", "text");

    receivingBankDizi.map((bank) => {
      if (bank.id === selectedPaymentMethod.id) {
        changeSelectedReceivingBank(bank.id, bank.text);
      }
    })

  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("accounting:payWithTransferEft")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg">
                  <div className="flex items-center justify-between">
                    <div>
                      <h5 className="font-semibold">{t("accounting:amountToBePaid")}</h5>
                      <p className="text-3xl font-bold text-primary-hover">
                        {(payAmount > 0 && payAmount !== "") ? parseFloat(payAmount).toFixed(2) : parseFloat(price).toFixed(2)} {currencyType}
                      </p>
                    </div>
                    {selectedReceivingBank.text !== t("support:selectBank") &&
                      selectedSenderBank.text !== t("support:selectBank") && (
                        <div className="flex flex-col items-center mt-2">
                          <p>{t("accounting:paymentOptions")}</p>
                          <p className="text-lg font-semibold ">
                            {selectedReceivingBank.id === selectedSenderBank.id
                              ? t("accounting:transfer")
                              : selectedReceivingBank.id !==
                                selectedSenderBank.id
                                ? "EFT"
                                : ""}
                          </p>
                        </div>
                      )}
                  </div>

                  <div className="mt-2">
                    <ComboBox
                      id="sendingBank"
                      name="sendingBank"
                      selected={selectedSenderBank}
                      setSelected={changeSelectedSenderBank}
                      list={senderBankList}
                      label={`${t("support:senderBank")}*`}
                      isSearchable
                    />
                    {senderWarning &&
                      <p className="text-alert-red">Bu alan zorunludur</p>
                    }
                  </div>
                  <div className="mt-2">
                    <ComboBox
                      id="receivingBank"
                      name="receivingbank"
                      selected={selectedReceivingBank}
                      setSelected={changeSelectedReceivingBank}
                      list={receivingBankList}
                      label={`${t("support:recipientBank")}*`}
                      isSearchable={false}
                    />
                    {receivingWarning &&
                      <p className="text-alert-red">Bu alan zorunludur</p>
                    }
                  </div>

                  <div className="mt-2">
                    <TextField
                      id="dekont"
                      name="dekont"
                      type="text"
                      label={t("support:bankReceiptNo")}
                      placeholder={t("support:bankReceiptNo")}
                      maxLength="30"
                      value={bankReceiptID}
                      onChange={setBankReceiptID}
                    />
                    <p className="mt-2">
                      {t("accounting:thereIsReceipt")}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:space-x-3 lg:flex-row lg:px-4">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    secondary
                    icon
                    dark
                    className="w-full px-8 mr-0 lg:w-auto"
                    onClick={() => onCreatePaymentNotification()}
                  >
                    <i className="ri-checkbox-circle-line" />
                    <span>{t("accounting:pay")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiplePaymentTransferModal;
