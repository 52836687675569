import React from 'react';
import {Button} from 'components'

const TextModal = ({setOpenModal, title, text,}) => {
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
   

    <div className="fixed inset-0 z-40 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    style={{ overflow: "initial" }}
                >
                    <div className="bg-white rounded-md lg:h-[650px] overflow-y-auto relative customScroll">
                        <div className="sticky top-0 flex items-center justify-between px-3 py-3 bg-white border-b">
                            <p className="mt-3 text-2xl text-titleColor">{title}</p>
                            <i
                                onClick={() => setOpenModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-passive"
                            ></i>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full px-5 ">
                            

                            <p>{text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
  )
}

export default TextModal