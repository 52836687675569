import React from "react";
import { useTranslation } from "react-i18next";

function AlertLaterInfo({ style }) {
  const { t, i18n } = useTranslation(["settings", "global", "nav"]);

  return (
    <div className={`absolute flex flex-col items-center opacity-100  ${style ? style : ""}`}>
      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.4997 26.1666C8.05617 26.1666 2.83301 20.9434 2.83301 14.4999C2.83301 8.05642 8.05617 2.83325 14.4997 2.83325C20.9432 2.83325 26.1663 8.05642 26.1663 14.4999C26.1663 20.9434 20.9432 26.1666 14.4997 26.1666ZM13.333 13.3333V20.3333H15.6663V13.3333H13.333ZM13.333 8.66659V10.9999H15.6663V8.66659H13.333Z"
          fill="#24B3DD" />
      </svg>
      <p className="text-xl font-semibold text-alert-blue">{t("global:information")}</p>
      <p className="font-bold">{t("settings:logRecordsDesc")}</p>
    </div>
  );
}

export default AlertLaterInfo;