import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import ResponsibleCard from "./ResponsibleCard";
import ErrorModal from "components/Modals/ErrorModal";
import ResponsibleCreateModal from "./ResponsibleCreateModal";
import { deleteAuthorizedStaff, listAuthorizedStaff } from "services/allServices";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function ResponsibleAndSubAccountsSettings() {
  const { t, i18n } = useTranslation(["settings", "global", "nav"]);
  
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [responsibleList, setResponsibleList] = useState();
  const { addToast } = useToasts();

  const onGetResponsibleList = () => {

    listAuthorizedStaff().then(res => {
      setResponsibleList(res);
    });
  };

  const onDeleteResponsible = (id) => {
    deleteAuthorizedStaff(id).then(res => {
      onGetResponsibleList();
      addToast(t("global:successfully"), {
        appearance: "success",
        autoDismiss: true
      });
      setOpenModal(false);
    });
  };

  useEffect(() => {
    onGetResponsibleList();
  }, []);


  return (
    <div className="container">
      <div className="items-center justify-between lg:flex">
        <div>
          <h2 className="mt-5 mb-1 font-semibold">
            {t("nav:responsible-and-sub-accounts")}
          </h2>
          <p>
            {t("settings:editOrAssignPerson")}
          </p>
        </div>
        <Button
          onClick={() => setOpenCreateModal(true)}
          primary
          className="flex items-center px-3 mt-3 lg:mt-0"
        >
          <i className="mr-2 ri-add-circle-line"></i>
          {t("settings:createNewResponsible")}
        </Button>
      </div>
      <div className="grid grid-cols-4 gap-10 mt-10">
        {
          responsibleList?.map((item, index) => {
            return (
              <div key={index} className="col-span-4 lg:col-span-1">
                <ResponsibleCard
                  data={item}
                  title={item.customer_name}
                  description={item.group_name}
                  setOpenModal={() => setOpenModal(item.authorized_account_id)}
                  setOpenCreateModal={(v) => setOpenCreateModal(v)}
                  isResponsible
                />
              </div>
            );
          })
        }


      </div>
      {openModal && (
        <ErrorModal
          title={t("settings:deleteResponsible")}
          question={t("settings:sureDeleteResponsible")}
          buttonText={t("global:yesDelete")}
          setOpenModal={() => setOpenModal(false)}
          onClick={() => onDeleteResponsible(openModal)}
        />
      )}
      {openCreateModal && (
        <ResponsibleCreateModal openModal={openCreateModal} setOpenModal={(v) => setOpenCreateModal(v)}
                                onGetResponsibleList={onGetResponsibleList} />
      )}
    </div>
  );
}

export default ResponsibleAndSubAccountsSettings;
