import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useGlobalStore } from "store/GlobalStore";
import { useTranslation } from "react-i18next";

function Pagination({
                      data,
                      setPageSize,
                      pageLimits, // list of page sizes
                      activePageSize, // active page size
                      gotoPage, // triggered when pagination handled from frontend
                      isBackendPagination, // is pagination handled from backend or frontend
                      backendPaginationMethod, // triggered when pagination handled from backend
                      backendNumberOfPages // page number return from backend
                    }) {
  const { t, i18n } = useTranslation([ "global"]);
  const navigate = useNavigate();
  const location = useLocation();
  const [setPageLimit] = useGlobalStore((state) => [state.setPageLimit]);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (data) => {
    let selected = data.selected;
    setActivePage(selected + 1);
    if (isBackendPagination) {
      backendPaginationMethod(selected + 1, activePageSize);
      if (selected + 1 > 1) {
        navigate({
          pathname: location.pathname, search: createSearchParams({
            p: selected + 1
          }).toString()
        });
      } else {
        navigate(location.pathname);
      }
      setActivePage(1);
    } else {
      gotoPage(Number(selected));
    }
  };


  useEffect(() => {
    let page = 1;
    if (location?.search) {
      page = location.search.split("=")[1];
    }
    setActivePage(Number(page));
  }, []);


  return (
    <div className="flex justify-end w-full">
      {data?.length > 0 && activePage && pageLimits !== null ? (
        <div className="flex items-center mx-4 my-2">
          <div className="flex flex-row -mt-0.5">
            <p className="mt-2 mr-4">{t("global:registrationOnPage")}</p>
            <select
              onChange={async (e) => {
                setPageSize(Number(e.target.value));
                if (isBackendPagination) {
                  await setActivePage();
                  await setActivePage(1);
                  backendPaginationMethod(1, e.target.value);
                  setPageLimit(e.target.value);
                  navigate(location.pathname);

                }
              }}
              className="w-20 h-8 p-0 pl-2 pr-8 mt-1 mr-8 text-black border-gray-500 rounded-md border-1 active:ring-0 "
              name="filterType"
              id="filterType"
            >
              {pageLimits.map((pageSize) => {
                return (
                  <option
                    value={pageSize}
                    selected={activePageSize === pageSize}
                  >
                    {pageSize}
                  </option>
                );
              })}
            </select>
          </div>
          <ReactPaginate
            previousLabel={<i className="ri-arrow-left-s-line" />}
            nextLabel={<i className="ri-arrow-right-s-line" />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={
              isBackendPagination
                ? backendNumberOfPages
                : Math.ceil(data?.length / activePageSize)
            }
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={"pagination flex gap-4"}
            activeClassName={
              "text-main font-semibold px-2 rounded-md bg-white shadow-md"
            }
            forcePage={activePage - 1}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Pagination;
