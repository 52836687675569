import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Error, Input, Label } from "./styles";
import Eye from "./components/Eye";
import Spinner from "./components/Spinner";
import Search from "./components/Search";
import PhoneCode from "./components/PhoneCode";
import Currency from "./components/Currency";
import NumberFormat from "react-number-format";
import File from "./components/File";
import { isNullOrEmptyOrUndefined } from "utils/common";

const TextField = styled(
  ({
     id,
     name,
     value,
     label,
     placeholder,
     type,
     max,
     min,
     innerRef = () => {
     },
     selectedCountry,
     changeSelectedCountry,
     error,
     countries,
     onChange,
     onCondition,
     defaultText,
     onClick,
     symbol,
     onKeyPress,
     pattern,
     step,
     disabled,
     maxLength,
     className,
     inputClass,
     required,
     autoComplete
   }) => {
    const [newType, setNewType] = useState("");

    useEffect(() => {
      if (type === "currency" || type === "file") setNewType("text");
      else if (type === "phone") setNewType("number");
      else setNewType(type);
    }, []);

    return (
      <div className={"relative " + className}>
        {typeof label !== "undefined" && label !== "" && (
          <Label htmlFor={name}>{label}</Label>
        )}
        <PhoneCode
          countries={countries}
          selectedCountry={selectedCountry}
          changeSelectedCountry={changeSelectedCountry}
          type={type}
          top={
            typeof label !== "undefined" && label !== ""
              ? "top-8.5 pt-0.5"
              : "top-3"
          }
          disabled={disabled}
        />
        <File
          value={value}
          onChange={onChange}
          type={type}
          top={
            typeof label !== "undefined" && label !== "" ? "top-9 " : "top-4"
          }
          disabled={disabled}
        />
        {type === "currency" ? (
          <NumberFormat
            className="w-full border-none ring-0 outline-none text-2xs text-main py-2 pr-2 pl-3 m-0 mt-0.5 ring-1 ring-primary-disabled outline-none rounded-md focus:ring-main disabled:bg-primary-disabled  disabled:cursor-not-allowed disabled:text-passive"
            id={id}
            name={name}
            value={value}
            displayType={"input"}
            type={"text"}
            decimalSeparator="."
            thousandSeparator={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              typeof onChange !== "undefined" && onChange(value);
              typeof onCondition !== "undefined" && onCondition(value);
            }}
            disabled={disabled}
          />
        ) : (
          <Input
            id={id}
            name={name}
            type={newType}
            realType={type}
            {...innerRef(name, { autoComplete: "disabled" })}
            placeholder={placeholder}
            value={
              typeof defaultText !== "undefined"
                ? defaultText
                : type === "file" && value?.name
                  ? value.name
                  : value
            }
            className={inputClass}
            step={step}
            autoComplete={autoComplete}
            onClick={type !== "search" ? onClick : () => {
            }}
            countryCode={selectedCountry}
            pattern={pattern}
            onChange={(e) => {
              if (typeof onChange !== "undefined") {
                if (type !== "file") {
                  if (typeof maxLength !== "undefined") {
                    if (e.target.value?.length <= maxLength) {
                      onChange(e.target.value);
                    } else {
                      onChange(value);
                    }
                  } else {
                    onChange(e.target.value);
                  }
                  if (
                    !isNullOrEmptyOrUndefined(max) &&
                    parseInt(max) <= e.target.value
                  )
                    onChange(max);
                  if (
                    !isNullOrEmptyOrUndefined(min) &&
                    e.target.value <= parseInt(min)
                  )
                    onChange(min);
                }
              }
              typeof onCondition !== "undefined" && onCondition(e.target.value);
            }}
            onKeyPress={(e) => {
              typeof onKeyPress !== "undefined" &&
              e.key === "Enter" &&
              value?.length >= 3 &&
              onKeyPress();
            }}
            required={required}
            error={error}
            min={min}
            max={max}
            maxLength={maxLength}
            disabled={disabled}
          />
        )}
        <Eye
          type={type}
          top={typeof label !== "undefined" && label !== "" ? "top-8" : "top-2"}
          disabled={disabled}
          newType={newType}
          setNewType={setNewType}
        />
        <Spinner
          type={type}
          top={
            typeof label !== "undefined" && label !== ""
              ? "top-0 mt-7.5"
              : "top-0 mt-1.5"
          }
          disabled={disabled}
          value={value}
          setValue={onChange}
          onCondition={onCondition}
        />
        <Currency
          symbol={symbol}
          type={type}
          top={typeof label !== "undefined" && label !== "" ? "top-8" : "top-2"}
          disabled={disabled}
        />
        <Search
          type={type}
          top={typeof label !== "undefined" && label !== "" ? "top-8" : "top-2"}
          disabled={disabled}
          onClick={onClick}
        />
        {error && <Error>{error}</Error>}
      </div>
    );
  }
)``;

export default TextField;
