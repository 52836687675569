import React, { useEffect, useState } from "react";
import { useComboBox } from "hooks";
import { getCountry } from "services/allServices";
import TextArea from "components/FormElements/Input/TextArea";
import TextField from "components/FormElements/Input/TextField";
import ComboBox from "components/FormElements/ComboBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RemoveLockModal from "components/Modals/RemoveLockModal";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  customer_name: yup.string(),
  customer_surname: yup.string(),
  customer_tc_no: yup.string(),
  email: yup.string(),
  personal_phone: yup.number()
});

function ProfileInformation({ ownInfo }) {
  const { t, i18n } = useTranslation(["settings", "global", "signup", "reset"]);
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();
  //const [country, countryStates, cities, selectedCountry, selectedState, selectedCity, onGetCountry, onGetStates, onGetCities, setSelectedCity, setSelectedCountry, setSelectedState] = useCountry()
  const [
    selectedCountryPhone,
    countries,
    setSelectedCountryPhone,
    setCountries
  ] = useComboBox("Ülke Seçiniz");

  const [gsm, setGsm] = useState();
  const [email, setEmail] = useState();

  const [openValidateModal, setOpenValidateModal] = useState(false);

  const onUpdateProfilInformation = (otp) => {
    let configure = {
      personal_phone: selectedCountryCode.id + "_" + gsm,
      email: email
    };
  };

  useEffect(() => {
    getCountry().then((ct) => {
      setCountryCodeList(ct, "phone_code", "country_name");
    });

    reset(ownInfo);
    setGsm(ownInfo?.customer_phone.phone);
    changeSelectedCountryCode(ownInfo?.customer_phone.country_code.id);
  }, [ownInfo]);


  return (
    <>
      <form>
        <div className="mt-5">
          <h2 className="mt-5 mb-5 font-semibold">{t("settings:profileInformation")}</h2>
          <div className="grid grid-cols-12 gap-4 lg:gap-10">
            <div className="col-span-12 lg:col-span-3">
              <TextField
                id="customer_name"
                name="customer_name"
                type="text"
                innerRef={register}
                label={t("global:name")}
                placeholder="Adınızı Giriniz"
                disabled
              />
            </div>
            <div className="col-span-12 lg:col-span-3">
              <TextField
                id="customer_surname"
                name="customer_surname"
                innerRef={register}
                type="text"
                ref={null}
                label={t("signup:surname")}
                placeholder="Soyadınızı Giriniz"
                disabled
              />
            </div>
            <div className="col-span-12 lg:col-span-3">
              <TextField
                id="customer_tc_no"
                name="customer_tc_no"
                type="text"
                ref={null}
                innerRef={register}
                label={t("signup:identity")}
                placeholder="12345678910"
                disabled
                maxLength={11}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
          <div className="col-span-12 lg:col-span-3 ">
            <TextField
              id="personal_phone"
              name="personal_phone"
              type="phone"
              innerRef={register}
              countries={countryCodeList}
              selectedCountry={selectedCountryCode}
              changeSelectedCountry={changeSelectedCountryCode}
              label={t("global:phoneNumber")}
              placeholder="Telefon Numaranızı Giriniz"
              value={gsm}
              onChange={setGsm}
              disabled
            />
          </div>
          <div className="col-span-12 lg:col-span-3">
            <TextField
              id="email"
              name="email"
              type="text"
              innerRef={register}
              ref={null}
              label={t("global:email")}
              placeholder="info@alanadiniz.com"
              value={email}
              onChange={setEmail}
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
          <div className="col-span-12 lg:col-span-3">
            <ComboBox
              id="country"
              name="country"
              label={t("global:country")}
              isSearchable
              selected={
                ownInfo && {
                  id: ownInfo?.customer_country?.id,
                  text: ownInfo?.customer_country?.text
                }
              }
              disabled
            />
          </div>
          <div className="col-span-12 lg:col-span-3">
            <ComboBox
              id="state"
              name="state"
              label={t("global:province")}
              isSearchable
              selected={
                ownInfo && {
                  id: ownInfo?.customer_state?.id,
                  text: ownInfo?.customer_state?.text
                }
              }
              disabled
            />
          </div>
          <div className="col-span-12 lg:col-span-3">
            <ComboBox
              id="state"
              name="state"
              label={t("global:district")}
              isSearchable
              selected={
                ownInfo && {
                  id: ownInfo?.customer_cities?.id,
                  text: ownInfo?.customer_cities?.text
                }
              }
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
          <div className="col-span-12 lg:col-span-3">
            <TextField
              id="customer_post_code"
              name="customer_post_code"
              type="text"
              ref={null}
              innerRef={register}
              label={t("global:postCode")}
              placeholder="99999"
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mt-5 lg:gap-10">
          <div className="col-span-12 lg:col-span-9">
            <TextArea
              id="customer_address"
              name="customer_address"
              label={t("global:openAddress")}
              placeholder="Açık adresinizi giriniz."
              rows="4"
              innerRef={register}
              disabled
            />
          </div>
        </div>

        {/* <div className="flex justify-end w-full mt-3 lg:w-3/4 lg:pr-2">
          <Button
            primary
            className="flex items-center justify-center w-full px-16 lg:w-auto"
            type="button"
            onClick={() => setOpenValidateModal(true)}
          >
            Kaydet
          </Button>
        </div> */}
      </form>
      {openValidateModal &&
        <RemoveLockModal
          title={t("settings:profileInformationChange")}
          question={t("settings:mailCode")}
          buttonText={t("global:send")}
          setOpenModal={() => setOpenValidateModal(false)}
          onClick={(otp) => {
            onUpdateProfilInformation(otp);
          }}
        />}
    </>
  );
}

export default ProfileInformation;
