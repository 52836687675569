import React, { useState } from "react";
import { Area, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "01",
    Used: 50,
    Upload: 80,
    Download: 30
  },
  {
    name: "02",
    Used: 150,
    Upload: 100,
    Download: 60
  },
  {
    name: "03",
    Used: 350,
    Upload: 40,
    Download: 150
  },
  {
    name: "04",
    Used: 450,
    Upload: 300,
    Download: 100
  },
  {
    name: "05",
    Used: 50,
    Upload: 500,
    Download: 120
  },
  {
    name: "06",
    Used: 750,
    Upload: 100,
    Download: 230
  },
  {
    name: "07",
    Used: 250,
    Upload: 700,
    Download: 500
  },
  {
    name: "08",
    Used: 550,
    Upload: 100,
    Download: 360
  },
  {
    name: "09",
    Used: 50,
    Upload: 200,
    Download: 1050
  },
  {
    name: "10",
    Used: 1050,
    Upload: 300,
    Download: 1000
  },
  {
    name: "11",
    Used: 350,
    Upload: 800,
    Download: 1200
  },
  {
    name: "12",
    Used: 550,
    Upload: 100,
    Download: 130
  },
  {
    name: "13",
    Used: 50,
    Upload: 300,
    Download: 500
  },
  {
    name: "14",
    Used: 50,
    Upload: 500,
    Download: 60
  },
  {
    name: "15",
    Used: 850,
    Upload: 40,
    Download: 650
  },
  {
    name: "16",
    Used: 350,
    Upload: 300,
    Download: 100
  },
  {
    name: "17",
    Used: 550,
    Upload: 2000,
    Download: 120
  },
  {
    name: "17",
    Used: 50,
    Upload: 100,
    Download: 30
  },
  {
    name: "18",
    Used: 850,
    Upload: 300,
    Download: 100
  },
  {
    name: "19",
    Used: 350,
    Upload: 2000,
    Download: 60
  },
  {
    name: "10",
    Used: 550,
    Upload: 40,
    Download: 450
  },
  {
    name: "20",
    Used: 750,
    Upload: 300,
    Download: 100
  },
  {
    name: "21",
    Used: 1050,
    Upload: 500,
    Download: 120
  },
  {
    name: "22",
    Used: 100,
    Upload: 100,
    Download: 130
  },
  {
    name: "23",
    Used: 1050,
    Upload: 300,
    Download: 100
  },
  {
    name: "24",
    Used: 1050,
    Upload: 100,
    Download: 830
  },
  {
    name: "25",
    Used: 550,
    Upload: 300,
    Download: 100
  },
  {
    name: "26",
    Used: 1050,
    Upload: 1000,
    Download: 60
  },
  {
    name: "27",
    Used: 1050,
    Upload: 30,
    Download: 150
  },
  {
    name: "28",
    Used: 950,
    Upload: 200,
    Download: 100
  },
  {
    name: "29",
    Used: 50,
    Upload: 2000,
    Download: 120
  },
  {
    name: "30",
    Used: 50,
    Upload: 100,
    Download: 30
  },
  {
    name: "31",
    Used: 50,
    Upload: 300,
    Download: 100
  }
];

function VirtualTrafficChart() {
  const { t } = useTranslation([ "global","services"]);

  const [visibleUpload, setVisibleUpload] = useState(true);
  const [visibleDownload, setVisibleDownload] = useState(true);
  const [visibleUsed, setVisibleUsed] = useState(true);

  const onVisible = (value) => {
    if (value === "Upload") {
      setVisibleUpload(!visibleUpload);
    }
    if (value === "Download") {
      setVisibleDownload(!visibleDownload);
    }
    if (value === "Used") {
      setVisibleUsed(!visibleUsed);
    }
  };

  function renderColorfulLegendText(value) {
    return (
      <span
        onClick={() => onVisible(value)}
        className="text-sm cursor-pointer text-title "
      >
        {value}
      </span>
    );
  }

  return (
    <div className="relative w-full h-80">
      <div className="px-6 my-2 lg:flex lg:absolute -top-1">
        <p>Limit : 500 GB</p>
        <p className="lg:ml-3">{t("services:used")} : 0,44 GB</p>
        <p className="lg:ml-3">% {t("services:general")} : 0,09 GB</p>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{ left: 15 }}
        >
          <XAxis dataKey="name" />
          <YAxis
            label={{
              value: "Mbps",
              angle: -90,
              position: "insideLeft",
              offset: -8
            }}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="right"
            wrapperStyle={{ paddingBottom: "15px" }}
            formatter={renderColorfulLegendText}
            iconType="circle"
            iconSize={10}
          />
          <Line
            type="monotone"
            dataKey="Used"
            stroke="#FFC62F"
            strokeWidth={2}
            dot={false}
            hide={!visibleUsed}
          />
          <Area
            type="monotone"
            dataKey="Upload"
            stroke="#1acd67"
            fill="#1acd67"
            strokeWidth={2}
            hide={!visibleUpload}
          />
          <Line
            type="monotone"
            dataKey="Download"
            stroke="#24B3DD"
            strokeWidth={2}
            dot={false}
            hide={!visibleDownload}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VirtualTrafficChart;
