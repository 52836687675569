import styled, { css } from "styled-components";

const Label = styled.label`
  margin: 0.5rem auto;
  color: #555555;
  font-size: 14px;
`;
const Error = styled(Label)`
  --tw-ring-color: #ff4949;
  color: #ff4949;
  font-size: 14px;
`;
const Ul = styled.ul`
  width: 100%;
  background-color: white;
  font-size: 14px;
  color: #555555;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  margin: 0.125rem 0 0 0;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  appearance: none;
  cursor: pointer;
  --tw-ring-color: #d8d8d8;
  outline: none;
  border-radius: 0.375rem;

  &:focus {
    --tw-ring-color: #ff9e30;
  }

  ${(props) =>
  props.disabled &&
  css`
      background-color: #d8d8d8;
      color: #999999;
      cursor: not-allowed;
    `};

  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
  ${(props) =>
  props.isOpen &&
  css`
      --tw-ring-color: #ff7c30;
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    `};
`;
const Input = styled.input`
  padding: 0.25rem 0.5rem;
  width: 100%;
  font-size: 14px;
  margin-top: 0.25rem;
  border-radius: 0.375rem;
  right: 0.25rem;
  outline: none;
  color: #555555;
  list-style: none;
  cursor: text;

  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
`;
const Li = styled.li`
  padding: 0.5rem;
  margin-top: 0.125rem;
  color: #555555;
  font-size: 14px;
  list-style: none;
  cursor: pointer;

  &:hover {
    color: #ff9e30;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
  ${(props) =>
  props.isGroup &&
  css`
      color: #999999;
      cursor: default;
      &:focus {
        color: #999999;
        background-color: white;
      }
    `};

  input {
    color: #ff7c30;
    margin-right: 0.5rem;
    border-color: #3c4858;
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-radius: 0.375rem;

    &:focus {
      box-shadow: var(--tw-ring-inset) 0 0 0
        calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      --tw-ring-color: transparent;
    }
  }

  &:hover {
    input {
      border: #ff7c30;
    }
  }
`;

export { Label, Error, Ul, Li, Input };
