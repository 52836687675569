import React from "react";

function AlertSuccess({ title, message }) {
  return (
    <div className="w-full py-2 px-2 rounded-md bg-greenAlertBg border border-greenAlertBorder">
      <div className="flex items-center">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11.003 16.5L18.073 9.429L16.659 8.015L11.003 13.672L8.174 10.843L6.76 12.257L11.003 16.5Z"
            fill="#45D85B"
          />
        </svg>

        <div className="ml-3">
          <p className="text-alert-green font-semibold mb-1">{title}</p>
          <p className="text-xs">{message}</p>
        </div>
      </div>
    </div>
  );
}

export default AlertSuccess;
