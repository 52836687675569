import React from "react";

function Currency({ disabled, type, top, symbol }) {
  return (
    <span
      className={
        "float-right border-l mr-2 bg-white pl-3 h-6.5 w-8 border-primary-disabled border-solid absolute text-main text-base -right-1 text-primary-disabled " +
        top +
        " " +
        (!disabled &&
        type === "currency" &&
        typeof symbol !== "undefined" &&
        symbol !== ""
          ? "visible"
          : "invisible")
      }
      onClick={() => {
      }}
    >
      {symbol}
    </span>
  );
}

export default Currency;
