import React, { useState } from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import MakdosTable from "components/MakdosTable";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment";

function LogRecordsTable({ data, setData, numberOfPages, onGetLogList }) {
  const { t, i18n } = useTranslation(["projects", "global", "settings"]);

  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);

  const columns = React.useMemo(
    () => [
 
      {
        Header: t("projects:user"),
        accessor: "customer_name"
      },
      {
        Header: t("settings:process"),
        accessor: "table_name",
        Cell: ({value}) => {return <p>Proje Bilgileri</p>}

      },
      {
        Header: t("projects:action"),
        accessor: "operation_type"
      },
      {
        Header:  t("settings:process") + ' IP',
        accessor: "ip_address"
      },
      {
        Header: t("settings:processingTime"),
        accessor: "operation_time",
        Cell: ({value}) => {return <p>{moment(value).format("DD/MM/YYYY hh:mm")}</p>}
      }
    ],
    [t]
  );



  return (
    <div className="w-full mb-12 ">
      <MakdosTable
        isCheckboxVisible={false}
        setCheckList={() => {
        }}
        checkList={[]}
        data={data}
        setData={setData}
        showPagination={true}
        columns={columns}
        pageLimits={pageLimits}
        isBackendPagination={true}
        backendPaginationMethod={(page, limit) => {
          onGetLogList(page, limit);
        }}
        backendNumberOfPages={numberOfPages}
        activePageSize={activePageSize}
        setActivePageSize={setActivePageSize}
        isDndAvailable={false}
      />
    </div>
  );
}

export default LogRecordsTable;
