import React, { useState } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import Validation from "components/Validation";
import moment from "moment";

function RemoveLockModal({
                           title,
                           titleColor,
                           question,
                           description,
                           setOpenModal,
                           buttonText,
                           onClick,
                           svg,
                           banCount
                         }) {
                          
  const language = localStorage.getItem("ln");
  const { t, i18n } = useTranslation(["projects", "global","settings"]);
  const [OTP, setOTP] = useState("");

  const onSetOTP = (v) => {
    setOTP(v.toUpperCase());
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex justify-end px-2 py-1">
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>

            <div className="flex flex-col items-center px-5 pt-4 pb-10 text-center bg-modalBg">
              {svg}

              <h2 className={`font-semibold text-${titleColor} my-1`}>
                {title}
              </h2>
              <p className="mt-2 text-center lg:mx-10">{question}</p>
              <p className="mt-5 text-center lg:mx-10">{description}</p>
              <div className="flex flex-col items-center justify-center">
                <Validation
                  value={OTP}
                  onChange={onSetOTP}
                  autoFocus
                  inputClassName={
                    "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                  }
                  OTPLength={6}
                  otpType="string"
                  disabled={false}
                  secure
                />
                {/* <div className="flex mt-5">
                  <i className="mr-1 ri-timer-line text-passive"></i>
                  <p className="mt-0.5 text-passive">60 sn</p>
                </div> */}
              </div>

              {banCount && banCount < 5 && banCount?.status !== "you_are_banned" && banCount?.status !== "account_blocked" ?
                <div className="mt-3">
                  {t("settings:remainingEntry")} : {banCount}
                </div>
                :
                ( language === 'tr' ?
                  banCount?.status === "you_are_banned" || banCount?.status === "account_blocked" ?
                    <div className="mt-3">
                      Hesabınız {moment(banCount?.ban_end_time).format("DD-MM-YYYY HH:mm")} tarihine kadar banlandı.
                    </div>
                    :
                    <div></div>

                    :

                    banCount?.status === "you_are_banned" || banCount?.status === "account_blocked" ?
                    <div className="mt-3">
                      Your account has been banned until {moment(banCount?.ban_end_time).format("DD-MM-YYYY HH:mm")} 
                    </div>
                    :
                    <div></div>

                )
              }

            </div>

            <div className="flex flex-col-reverse items-end justify-center px-5 py-3 bg-white lg:flex-row lg:px-0">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button
                onClick={() => onClick(OTP)}
                className="w-full px-12 mr-0 lg:w-auto"
                primary

              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveLockModal;
