import React from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function VirtualServersChart({
                               data1,
                               data2,
                               data3,
                               title,
                               inf1,
                               inf2,
                               inf3,
                               progressName,
                               line1Color,
                               line2Color,
                               line3Color,
                               label
                             }) {
  const data = [
    {
      name: "01",
      [data1]: 50,
      [data2]: 80,
      [data3]: 30
    },
    {
      name: "05",
      [data1]: 150,
      [data2]: 100,
      [data3]: 60
    },
    {
      name: "10",
      [data1]: 350,
      [data2]: 40,
      [data3]: 150
    },
    {
      name: "15",
      [data1]: 450,
      [data2]: 300,
      [data3]: 100
    },
    {
      name: "20",
      [data1]: 50,
      [data2]: 500,
      [data3]: 120
    },
    {
      name: "25",
      [data1]: 750,
      [data2]: 100,
      [data3]: 230
    },
    {
      name: "30",
      [data1]: 250,
      [data2]: 700,
      [data3]: 500
    }
  ];

  function renderColorfulLegendText(value) {
    return <span className="text-title text-sm cursor-pointer ">{value}</span>;
  }

  return (
    <div className="bg-white w-full p-8 rounded-md shadow-md">
      <h3 className="mb-4"> {title}</h3>
      <div className="flex items-center justify-between mb-3">
        <div>
          {inf1 && <p>{inf1} : 3000.00</p>}
          <div className="flex mt-1">
            {inf2 && <p>{inf2} : 3.31 GB</p>}
            {inf3 && <p className="ml-5">{inf3}: Intel</p>}
          </div>
        </div>
        {progressName && (
          <div className="mt-3">
            <p>
              {progressName} : <span className="text-primary-hover">25%</span>
            </p>
            <div className="h-2 mt-1 relative max-w-xl rounded-full overflow-hidden">
              <div className="w-full h-full bg-borderGray absolute"></div>
              <div
                className="h-full rounded-full bg-primary-hover absolute"
                style={{ width: "25%" }}
              ></div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full h-80 relative">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={500} height={300} data={data} margin={{ left: 15 }}>
            <XAxis dataKey="name" />
            <YAxis
              label={{
                value: label,
                angle: -90,
                position: "insideLeft",
                offset: -8
              }}
            />
            <Tooltip />
            <Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ paddingBottom: "15px" }}
              formatter={renderColorfulLegendText}
              iconType="circle"
              iconSize={10}
            />
            {data1 && (
              <Line
                type="monotone"
                dataKey={data1}
                stroke={line1Color}
                strokeWidth={2}
                dot={false}
              />
            )}
            {data2 && (
              <Line
                type="monotone"
                dataKey={data2}
                stroke={line2Color}
                strokeWidth={2}
                dot={false}
              />
            )}

            {data3 && (
              <Line
                type="monotone"
                dataKey={data3}
                stroke={line3Color}
                strokeWidth={2}
                dot={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default VirtualServersChart;
