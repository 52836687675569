//region Imports
import React, { useEffect } from "react";
import { useExpanded, useFilters, useGroupBy, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { TableCheckbox } from "./components/TableCheckbox";
import Pagination from "./components/Pagination";
//endregion

const Table = ({
                 columns,
                 data,
                 setData,
                 isCheckboxVisible,
                 setCheckList,
                 isDndAvailable,
                 checkList,
                 showPagination,
                 pageLimits,
                 activePageSize,
                 trStyle,
                 setActivePageSize,
                 dndStyle,
                 optionItemsStyle,
                 DndItem,
                 OtherOptionItems,
                 isBackendPagination,
                 backendNumberOfPages,
                 backendPaginationMethod,
                 isMultiSort
               }) => {
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  //region useTable state
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      filters,
      selectedRowIds
    }
  } = useTable(
    {
      data,
      columns,
      getRowId,
      disableMultiSort: !isMultiSort,
      initialState: {
        hiddenColumns: columns.map(column => { return column.hidden ? column.accessor : null }),
      }
    },
    
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isCheckboxVisible) {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              // Make this column a groupByBoundary. This ensures that groupBy columns
              // are placed after it
              groupByBoundary: true,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a Checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <TableCheckbox
                    setCheckList={setCheckList}
                    data={data}
                    checkList={checkList}
                    id={-1}
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a Checkbox
              Cell: ({ row }) => (
                <div>
                  <TableCheckbox
                    setCheckList={setCheckList}
                    data={data}
                    checkList={checkList}
                    id={row.original.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              )
            },
            ...columns
          ];
        });
      }
    }
  );
  //endregion

  //region Move Row function for Drag and Drop
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex];
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord]
        ]
      })
    );
  };
  //endregion

  useEffect(() => {
    setPageSize(activePageSize);
  }, [activePageSize]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <table {...getTableProps()} className="w-full">
          <thead>
          <>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {/*<th className="p-3 text-sm text-left bg-makdos-table-th-bg" />*/}
                {isDndAvailable && !DndItem && (
                  <th className="py-3 pl-2 text-sm text-left text-white bg-makdos-table-th-bg" />
                )}
                {headerGroup.headers.filter(x => x.id !== "customer_read_receipt").map((column) => (
                  <th
                    {...column.getSortByToggleProps()}
                    className="py-3 pl-2 text-sm text-left text-white bg-makdos-table-th-bg"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.render("Header")?.length > 0 && (
                      <span className="relative flex items-center justify-between">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <svg
                                className="absolute right-0 -top-3"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                                  fill="#D8D8D8"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="absolute right-0 -top-3"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                                  fill="#D8D8D8"
                                />
                              </svg>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                    )}
                  </th>
                ))}
                {DndItem && (
                  <th className="py-3 pl-2 text-sm text-left text-white bg-makdos-table-th-bg">
                    Ayarlar
                  </th>
                )}
              </tr>
            ))}
          </>
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  trStyle={trStyle}
                  isDndAvailable={isDndAvailable}
                  dndStyle={dndStyle}
                  optionItemsStyle={optionItemsStyle}
                  DndItem={DndItem}
                  OtherOptionItems={OtherOptionItems}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                />
              )
          )}
          </tbody>
        </table>
      </DndProvider>
      {showPagination && (
        <Pagination
          isBackendPagination={isBackendPagination}
          data={data}
          pageLimits={pageLimits}
          activePageSize={activePageSize}
          backendPaginationMethod={backendPaginationMethod}
          backendNumberOfPages={backendNumberOfPages}
          setPageSize={(val) => {
            setPageSize(val);
            setActivePageSize(val);
          }}
          gotoPage={gotoPage}
        />
      )}
    </>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({
               row,
               index,
               moveRow,
               dndStyle,
               isDndAvailable,
               optionItemsStyle,
               trStyle,
               DndItem,
               OtherOptionItems
             }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    type: DND_ITEM_TYPE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      style={{ opacity }}
      ref={dropRef}
      {...row.getRowProps()}
      className={
        (index % 2 === 0 ? " bg-white p-2" : " bg-makdos-table-tr-even p-2") +
        " " +
        trStyle
      }
    >
      {isDndAvailable && !DndItem && (
        <td ref={dragRef} className="p-2 text-black cursor-pointer">
          <i className="ml-2 ri-menu-line" />
        </td>
      )}
      {row.cells.filter(x => x.column.id !== "customer_read_receipt").map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={`p-2 text-sm text-makdos-table-td ${row.cells.find(x => x.column.id === "customer_read_receipt")?.value === false ? " font-bold" : ""}`}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
      {isDndAvailable && DndItem && (
        <td className={optionItemsStyle}>
          <div className={dndStyle} ref={dragRef}>
            <DndItem />
          </div>
          {OtherOptionItems.map((item) => {
            return <div onClick={() => item?.event(row.id)}> {item.html}</div>;
          })}
        </td>
      )}
    </tr>
  );
};

const MakdosTable = ({
                       data,
                       setData,
                       columns,
                       isCheckboxVisible = false,
                       setCheckList,
                       checkList,
                       showPagination = false,
                       pageLimits,
                       activePageSize,
                       setActivePageSize,
                       isDndAvailable,
                       optionItemsStyle,
                       trStyle = "",
                       dndStyle = null,
                       DndItem = null,
                       OtherOptionItems = null,
                       isMultiSort = false,
                       isBackendPagination = false,
                       backendPaginationMethod,
                       backendNumberOfPages
                     }) => {
  return (
    <Table
      columns={columns}
      data={data}
      setData={setData}
      dndStyle={dndStyle}
      trStyle={trStyle}
      optionItemsStyle={optionItemsStyle}
      DndItem={DndItem}
      OtherOptionItems={OtherOptionItems}
      isCheckboxVisible={isCheckboxVisible}
      setCheckList={setCheckList}
      checkList={checkList}
      isDndAvailable={isDndAvailable}
      showPagination={showPagination}
      pageLimits={pageLimits}
      activePageSize={activePageSize}
      setActivePageSize={setActivePageSize}
      backendNumberOfPages={backendNumberOfPages}
      isBackendPagination={isBackendPagination}
      backendPaginationMethod={backendPaginationMethod}
      isMultiSort={isMultiSort}
    />
  );
};

export default MakdosTable;
