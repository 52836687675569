import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import CheckBox from "components/FormElements/CheckBox";
import { Link } from "react-router-dom";
import { useAccountingStore } from "store/AccountingStore";
import { useGlobalStore } from "store/GlobalStore";
import { changeCurrency, findCurrency } from "helpers/helpersFunc/index";
import MakdosTable from "components/MakdosTable";

function ProformaTable({
  proformas,
  onSetCheck,
  onSetCheckList,
  checkList,
  openProformaInformationModal,
  numberOfPages,
  onGetProformaList
}) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [setSelectedProforma] = useAccountingStore((state) => [
    state.setSelectedProforma
  ]);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const [data, setData] = useState(proformas);

  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);
  const columns = React.useMemo(
    () => [
      {
        Header: (
          data.find(item => (item.status !== "completed" && item.status !== "canceled" && item.status !== "refunded")) ?
            <CheckBox name="tableHeaderCheck" onChange={() => onSetCheck()} />
            :
            <div></div>
        ),
        accessor: "id2",
        Cell: Checkbox,
        disableSortBy: true,
        // hidden: data.find((i) => i.status === "completed" || i.status === "canceled" || i.status === "refunded") ? true : false
      },
      {
        Header: "NO",
        accessor: "proforma_invoice_no"
      },
      {
        Header: t("global:project"),
        accessor: "billing_infos[0].projectname"
      },
      {
        Header: t("projects:invoiceAddress"),
        accessor: "billing_infos[0].billing_info.billing_info_name"
      },
      {
        Header: t("accounting:proformaHistory"),
        accessor: "time_expires",
        Cell: (props) => moment(props.value).format("DD-MM-YYYY")
      },
      {
        Header: t("accounting:paymentHistory"),
        accessor: "time_complete",
        Cell: (props) =>
          props.value !== null ? moment(props.value).format("DD-MM-YYYY") : "-"
      },
      {
        Header: t("accounting:paymentMethod"),
        accessor: "payment_methods",
        Cell: paymentType
      },

      {
        Header: t("accounting:totalAmount"),
        accessor: "proforma_total_price_base",
        Cell: (props) => <>{parseFloat(changeCurrency(currencyRates, currencyType, props.value)).toFixed(2)} {currencyType} + <span className="font-semibold" title={t("accounting:extrasDesc")}> {t("accounting:extras")}</span></>
      },
      {
        Header: t("accounting:amountPaid"),
        accessor: "proforma_total_paid_base",
        Cell: (props) => <>{parseFloat(changeCurrency(currencyRates, currencyType, props.value)).toFixed(2)} {currencyType}</>
      },

      {
        Header: t("accounting:extraPaid"),
        accessor: "ekstra",
        Cell: (props) => <>{parseFloat(changeCurrency(currencyRates, currencyType, props.row.original.proforma_total_paid_tax + props.row.original.proforma_total_paid_fee + props.row.original.proforma_total_paid_transaction)).toFixed(2)} {currencyType}</>
      },
      {
        Header: t("accounting:remainingAmount"),
        accessor: "proforma_total_remaining_base",
        Cell: (props) => <>{parseFloat(changeCurrency(currencyRates, currencyType, props.value)).toFixed(2)} {currencyType} + <span className="font-semibold" title={t("accounting:extrasDesc")}>{t("accounting:extras")}</span></>
      },

      // {
      //   Header: "Ekstra",
      //   accessor: "ekstra",
      //   Cell: priceEkstra
      // },

      {
        Header: t("global:status"),
        accessor: "status",
        Cell: status
      },
      {
        Header: t("global:settings"),
        accessor: "id",
        Cell: tableButton
      }
    ],
    [t, checkList, currencyType]
  );


  function paymentType(props) {
    if (props.value) {
      let methods = [];
      props.value.map((method) => {
        if (method === "customer_balance") {
          methods.push({ icon: "wallet-3", text: "Bakiye" });
        } else if (method === "customer_credit") {
          methods.push({ icon: "wallet-3", text: "Kredi Manual" });
        } else if (method === "cc_garanti_tl") {
          methods.push({ icon: "bank-card", text: "Kredi Kartı" });
        }
        else if (method === "bank_transfer_garanti_tl" || method === "bank_transfer_isbank_tl" || method === "bank_transfer_ziraat_tl") {
          methods.push({ icon: "send-plane", text: "Havale" });
        }
        else if (method === "paypal_tr" || method === "paypal_usa") {
          methods.push({ icon: "paypal", text: "Paypal" });
        } else if (method === "cash_manuel") {
          methods.push({ icon: "hand-coin", text: "Cash Manuel" })
        } else if (method === "cash_usa") {
          methods.push({ icon: "money-dollar-circle", text: "Cash Usa" })
        }
        else if (method === "cash_tr") {
          methods.push({ icon: "cash", text: "Cash TR" })
        }
      });
      return (
        <div className="flex ">
          {methods.map((m, i) => {
            return (
              <div key={i} title={m.text}>
                <i className={`ri-${m.icon}-line  text-lg mr-2 text-main`} />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div className="ml-2">-</div>;
    }
  }

  function status(props) {
    if (props.value === "pending") {
      return <p className="font-semibold text-alert-red">{t("accounting:notPaid")} </p>;
    } else if (props.value === "completed") {
      return <p className="font-semibold text-alert-green">{t("accounting:paid")} </p>;
    } else if (props.value === "canceled") {
      return <p className="font-semibold text-passive">{t("accounting:cancelled")}</p>;
    } else if (props.value === "refunded") {
      return <p className="font-semibold text-alert-blue">İade Edildi</p>;
    } else {
      <p className="font-semibold text-alert-green">- </p>;
    }
  }

  function price(props) {
    if (props.value) {
      return (
        <p className="font-semibold">{changeCurrency(currencyRates, currencyType, props.value)} {currencyType}</p>
      );
    } else {
      return <p className="font-semibold">-</p>;
    }
  }

  function priceEkstra(props) {
    return (
      <p className="font-semibold" title="Vergi, Komisyon, İşlem Ücreti">{changeCurrency(currencyRates, currencyType, props.row.original.proforma_total_paid_tax + props.row.original.proforma_total_paid_fee + props.row.original.proforma_total_paid_transaction)} {currencyType}</p>
    )

  }

  function remainingPrice(props) {
    if (props.value) {
      return (
        <p className="font-semibold">{changeCurrency(currencyRates, currencyType, props.value)} {currencyType} + <span title="Vergi, Komisyon, İşlem Ücreti">Ekstralar</span></p>
      );
    } else {
      return <p className="font-semibold">-</p>;
    }
  }

  function Checkbox(props) {
    if (props && props.row.original.status !== "completed" && props.row.original.status !== "refunded" && props.row.original.status !== "canceled") {
      let configure = {
        id: props.row.original.id,
        project_name: props.row.original.billing_infos[0]?.projectname,
        price: props.row.original.with_tax_total_price_project
      };
      return (
        <div className="ml-1">
          <CheckBox
            id={"proformaTable" + props.row.original.id}
            name={"proformaTable" + props.row.original.id}
            onChange={() => onSetCheckList(configure)}
            checked={checkList.some((i) => i.id === props.row.original.id)}
          />
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }

  function tableButton(props) {
    if (props) {
      if (props.row.original.status !== "completed" && props.row.original.status !== "canceled" && props.row.original.status !== "refunded") {
        return (
          <div className="flex items-center">
            <Link to="/bill-pay">
              <div
                onClick={() =>
                  setSelectedProforma(props.row.original.proforma_invoice_no)
                }
                className="ml-3 cursor-pointer text-primary-hover hover:text-primary"
              >
                {t("accounting:pay")}
              </div>
            </Link>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              setSelectedProforma(props.row.original.proforma_invoice_no);
              openProformaInformationModal(true);
            }}
            className="flex items-center justify-center cursor-pointer"
          >
            <i className="ri-eye-line curs"></i>
          </div>
        );
      }
    }
  }

  return (
    <div className="w-full mb-12 ">
      <MakdosTable
        isCheckboxVisible={false}
        setCheckList={() => {
        }}
        checkList={[]}
        data={data}
        setData={setData}
        showPagination={true}
        columns={columns}
        pageLimits={pageLimits}
        isBackendPagination={true}
        backendPaginationMethod={(page, limit) => {
          onGetProformaList(page, limit);
        }}
        backendNumberOfPages={numberOfPages}
        activePageSize={activePageSize}
        setActivePageSize={setActivePageSize}
        isDndAvailable={false}
      />
    </div>
  );
}

export default ProformaTable;
