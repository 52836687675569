import React, { useEffect, useState } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import useConfigurationComboBox, { useComboBox } from "hooks";
import MultiplePaymentTable from "../AccountingTables/MultiplePaymentTable";
import CheckBox from "components/FormElements/CheckBox";
import { TailSpin } from "react-loader-spinner";
import { useGlobalStore } from "store/GlobalStore";
import CreditCardModal from "./CreditCardModal";
import { multiplePaymentProformaGet } from "services/allServices";
import ComboBox from "components/FormElements/ComboBox";
import { findCurrency } from "helpers/helpersFunc";

function MultiplePaymentModal({ setOpenModal, tableData, onSetPay }) {
  const { t } = useTranslation(["projects", "global"]);

  const [postValues, setPostValues] = useState();
  const [isCheckPay, setIsCheckPay] = useState("pay1");
  const [balanceCheck, setBalanceCheck] = useState(false);
  const [allPrices, setAllPrices] = useState();
  const [selectedPaymentMethod, listPaymentMethods, changeSelectedPaymentMethod, setPaymentMethods] = useConfigurationComboBox("Seçiniz");

  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false);

  const onPay = async () => {
    let paymentAmount = [];
    allPrices.proforma_list.map(pf => {
      paymentAmount.push(pf.proforma_invoice_id);
    });


    let currencycode = currencyType === "₺" ? "TL" : currencyType === "$" ? "USD" : "EURO";
    let payment_method = balanceCheck === true ? "customer_balance" : (isCheckPay === "pay3" ? "transfer" : selectedPaymentMethod.otherInfo.method_list.others.payment_method)

    onSetPay(paymentAmount, currencycode, payment_method, isCheckPay,selectedPaymentMethod.otherInfo.method_list,allPrices )
  };

  const onPayChange = (value) => {
    if (value !== "balance") {
      setIsCheckPay(value);
      if (value === "pay1") {
        changeSelectedPaymentMethod(allPrices?.payment_method_list[0].credit_card[0].method_type, allPrices?.payment_method_list[0].credit_card[0].text, null, allPrices?.payment_method_list[0].credit_card[0])

      }
      if (value === "pay2") {
        changeSelectedPaymentMethod(allPrices?.payment_method_list[1].paypal[0].method_type, allPrices?.payment_method_list[1].paypal[0].text, null, allPrices?.payment_method_list[1].paypal[0])
      }

      if (value === "pay3") {
        changeSelectedPaymentMethod(allPrices?.payment_method_list[2].bank_transfer[0].method_type, allPrices?.payment_method_list[2].bank_transfer[0].text, null, allPrices?.payment_method_list[2].bank_transfer[0])
      }
    } else {
      changeSelectedPaymentMethod(allPrices?.payment_method_list[3].balance[0].method_type, allPrices?.payment_method_list[3].balance[0].text, null, allPrices?.payment_method_list[3].balance[0])
    }

  };

  const onMultiplePaymentProformaPrices = async () => {
    let proforma_ids = [];
    if (tableData) {
      tableData.map(d => {
        proforma_ids.push(d.id);
      });
    }
    await setAllPrices(null);

    multiplePaymentProformaGet(proforma_ids).then(async (res) => {
      setAllPrices(res);
      await setPaymentMethods(res?.payment_method_list[0].credit_card, "method_type", "text", null, res?.payment_method_list[0].credit_card)
      changeSelectedPaymentMethod(res?.payment_method_list[0].credit_card[0].method_type, res?.payment_method_list[0].credit_card[0].text, null, res?.payment_method_list[0].credit_card[0])
    });

  };

  const onSetBalanceCheck = () => {
    setBalanceCheck(!balanceCheck);

    if (!balanceCheck === true) {
      onPayChange("balance")
    } else {
      onPayChange("pay1")
    }
  };


  useEffect(() => {
    onMultiplePaymentProformaPrices();
  }, []);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3 className="text-xl font-semibold leading-6 text-title">  {t("accounting:multiplePayment")}  </h3>
                  <i onClick={() => setOpenModal(false)} className="text-2xl cursor-pointer ri-close-line text-main"></i>
                </div>
                <div className="px-5 py-5 bg-modalBg">
                  <div className="overflow-y-auto max-h-36">
                    {allPrices ?
                      <MultiplePaymentTable tableData={allPrices?.proforma_list} paymentType={isCheckPay} />
                      :
                      <div className="flex items-center justify-center w-full mt-5">
                        <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                          type="Oval"
                          color="#ff7c30"
                          height={50}
                          width={50}
                          timeout={3000} //3 secs
                        />
                      </div>
                    }
                  </div>
                  {allPrices &&
                    <div className="flex items-center justify-between mt-1">
                      <div className="mt-2 ">
                        <p className="text-sm text-textGray">{t("accounting:totalAmount")}</p>
                        <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.paid_price).toFixed(2)} {currencyType}</p>
                      </div>
                      <div className="mt-2 ">
                        <p className="text-sm text-textGray">{t("accounting:tax")}</p>
                        <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.tax.total_price_only_tax).toFixed(2)} {currencyType}</p>
                      </div>

                      <div className="mt-2 ">
                        <p className="text-sm text-textGray">{t("accounting:processingFee")}</p>
                        <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.transaction.total_price_only_transaction_rate).toFixed(2)} {currencyType}</p>
                      </div>

                      <div className="mt-2 ">
                        <p className="text-sm text-textGray">{t("accounting:commission")}</p>
                        <p className="text-2xl font-medium">{parseFloat(selectedPaymentMethod?.otherInfo?.method_list?.fee.total_price_only_fee_rate).toFixed(2)} {currencyType}</p>
                      </div>
                    </div>
                  }
                  {allPrices && selectedPaymentMethod &&
                    <div className="mt-4">
                      <h5 className="font-semibold">{t("accounting:amountToBePaid")}</h5>
                      <p className="text-3xl font-bold text-primary-hover">
                        {parseFloat(selectedPaymentMethod.otherInfo?.method_list.final_price).toFixed(2)} {currencyType}
                      </p>
                    </div>
                  }

                  {allPrices?.credit_info.can_use_credit &&
                    <div className="flex items-center mt-5 ">
                      <CheckBox
                        id="balance"
                        name="balance"
                        label={t("accounting:payWithCredit")}
                        onChange={() => onSetBalanceCheck()}
                      />
                      <div className="flex ml-2">
                        <p className="font-semibold">
                          {parseFloat(allPrices?.credit_info?.customer_balance[findCurrency(currencyType)].value).toFixed(2)}
                          {currencyType}
                        </p>
                        <p className="ml-4">{t("accounting:willBeUsed")}</p>
                        <p className="ml-2 font-semibold text-alert-green">
                          {parseFloat(allPrices?.credit_info?.usable_credit[findCurrency(currencyType)].value).toFixed(2)}
                          {currencyType}
                        </p>
                      </div>
                    </div>
                  }
                  {!balanceCheck && (
                    <div className="mt-4">
                      <div className="grid grid-cols-3 gap-3 mt-5">
                        <div
                          onClick={() => onPayChange("pay1")}
                          id="radiopay1"
                          name="pay1"
                          className={`${isCheckPay === "pay1" ? "border-primary-hover" : "border-borderGray"} col-span-1 p-4 flex flex-col items-center justify-center border-2 rounded-lg cursor-pointer `}>
                          <i className={`${isCheckPay === "pay1" ? "text-primary-hover" : "text-titleColor"} ri-secure-payment-line text-2xl`} />
                          <p className={isCheckPay === "pay1" ? "text-primary-hover" : "text-titleColor"}> {t("accounting:credit")} </p>
                          <p className={isCheckPay === "pay1" ? "text-primary-hover" : "text-titleColor"} > {t("accounting:card")} </p>
                        </div>

                        <div
                          onClick={() => onPayChange("pay2")}
                          id="radiopay2"
                          name="pay2"
                          className={`${isCheckPay === "pay2" ? "border-primary-hover" : "border-borderGray"} col-span-1 p-4 flex flex-col items-center justify-center border-2 rounded-lg cursor-pointer `}
                        >
                          <i className={`${isCheckPay === "pay2" ? "text-primary-hover" : "text-titleColor"} ri-paypal-line text-2xl`}></i>
                          <p className={isCheckPay === "pay2" ? "text-primary-hover" : "text-titleColor"}>  PayPal</p>
                          <p className={isCheckPay === "pay2" ? "text-primary-hover" : "text-titleColor"}> {t("accounting:payment")}  </p>
                        </div>
                        <div
                          onClick={() => onPayChange("pay3")}
                          id="radiopay3"
                          name="pay3"
                          className={`${isCheckPay === "pay3" ? "border-primary-hover" : "border-borderGray"} col-span-1 p-4 flex flex-col items-center justify-center border-2 rounded-lg cursor-pointer `}
                        >
                          <i className={`${isCheckPay === "pay3" ? "text-primary-hover" : "text-titleColor"} ri-send-plane-line text-2xl`} />
                          <p className={isCheckPay === "pay3" ? "text-primary-hover" : "text-titleColor"}>{t("accounting:transfer")}</p>
                          <p className={isCheckPay === "pay3" ? "text-primary-hover" : "text-titleColor"}>EFT</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-4">
                    <ComboBox
                      id="select"
                      name="select"
                      selected={selectedPaymentMethod}
                      setSelected={changeSelectedPaymentMethod}
                      list={listPaymentMethods}
                      defaultText="Seçiniz"
                    />
                  </div>

                  <div className="flex mt-5">
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      name="privacyPolicy"
                      className="w-4 h-4 text-primary-hover border-2 border-borderGray mt-0.5  text-sm cursor-pointer rounded-md outline-none ring-0  focus:ring-0 focus:outline-none focus:border-none focus:ring-transparent"
                    />

                    <div className="flex flex-col ml-2">
                      <label
                        className={" w-full text-main mb-0 cursor-pointer"}
                        htmlFor="privacyPolicy"
                      >
                        {t("accounting:pdpa")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row ">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>

                  <Button
                    onClick={() => onPay()}
                    secondary
                    icon
                    dark
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                  >
                    <i className="ri-checkbox-circle-line" />
                    <span>{t("accounting:pay")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openCreditCardModal && (
        <CreditCardModal
          setOpenModal={() => setOpenCreditCardModal(false)}
          postValues={postValues}
          multiple
        />
      )}
    </div>
  );
}

export default MultiplePaymentModal;
