import React from "react";

function Eye({ disabled, type, newType, setNewType, top }) {
  return type === "password" ? (
    <i
      className={`${
        newType === "password"
          ? "ri-eye-off-line"
          : newType === "text" && "ri-eye-line"
      } float-right mr-2 pl-1.5 h-6.5 w-8 border-l border-primary-disabled border-solid absolute text-xl -right-1 text-primary-disabled cursor-pointer ${top} '${
        !disabled && type === "password" ? "visible" : "invisible"
      }`}
      onClick={() => {
        !disabled && newType !== "text"
          ? setNewType("text")
          : setNewType("password");
      }}
    />
  ) : (
    <></>
  );
}

export default Eye;
