import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RadioButton from "components/FormElements/RadioButton";
import DNSChangeForm1 from "./DNSChangeForm1";
import DNSChangeForm2 from "./DNSChangeForm2";
import DNSChangeForm3 from "./DNSChangeForm3";

function DNSChangeModal({
                          title,
                          question,
                          description,
                          setOpenModal,
                          buttonText,
                          data,
                          onGetPageData
                        }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [isCheck, setIsCheck] = useState("1");
  const handleChange = (e) => {
    setIsCheck(e.target.value);
  };

  useEffect(() => {
    if (data?.dns_server_ns_type === "makdos") {
      setIsCheck("1");
    } else if (data?.dns_server_ns_type === "existing") {
      setIsCheck("2");
    } else {
      setIsCheck("3");
    }
  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{t("services:dnsChange")}</h3>
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>
            <div className="flex flex-col bg-modalBg rounded-b-md">
              <h5 className="mx-3 my-3 font-semibold">
                {t("services:selectDNS")}
              </h5>
              <div className="mx-3">
                <div className="mb-2">
                  <RadioButton
                    id="1"
                    name="DNStype"
                    label={t("services:parkInMakdeos")}
                    isCheck={isCheck === "1"}
                    setIsCheck={(e) => handleChange(e)}
                  />
                </div>
                <div className="mb-2">
                  <RadioButton
                    id="2"
                    name="DNStype"
                    label={t("services:determineNsServer")}
                    isCheck={isCheck === "2"}
                    setIsCheck={(e) => handleChange(e)}
                  />
                </div>
                <div className="">
                  <RadioButton
                    id="3"
                    name="DNStype"
                    label={t("services:createMyNsServer")}
                    isCheck={isCheck === "3"}
                    setIsCheck={(e) => handleChange(e)}
                  />
                </div>
              </div>

              {isCheck === "1" && (
                <>
                  <DNSChangeForm1 data={data} onGetPageData={onGetPageData} setOpenModal={() => setOpenModal(false)} />
                </>
              )}

              {isCheck === "2" && (
                <>
                  <DNSChangeForm2 data={data} onGetPageData={onGetPageData} setOpenModal={() => setOpenModal(false)} />
                </>
              )}

              {isCheck === "3" && (
                <>
                  <DNSChangeForm3 data={data} onGetPageData={onGetPageData} setOpenModal={() => setOpenModal(false)} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DNSChangeModal;
