import Tabs from "components/Tabs";
import React, { useEffect, useState } from "react";
import FastTransactions from "components/Services/VirtualServers/FastTransactions";
import { Button } from "components";
import BuyBackupModal from "components/Services/ServicesGlobalModals/BuyBackupModal";
import UpgradeServicesModal from "components/Services/ServicesGlobalModals/UpgradeServicesModal";
import VirtualNetwork from "components/Services/VirtualServers/VirtualNetwork";
import VirtualServersUsing from "components/Services/VirtualServers/VirtualServersUsing";
import VirtualSettings from "components/Services/VirtualServers/VirtualSettings";
import VirtualLogRecords from "components/Services/VirtualServers/VirtualLogRecords";
import VirtualIPRecords from "components/Services/VirtualServers/VirtualIPRecords";
import { getCloudServer } from "services/allServices";
import { useNavigate, useParams } from "react-router";
import PhysicalVPNUsers from "components/Services/PhysicalServers/PhysicalVPNUsers";
import { isNullOrEmptyOrUndefined } from "utils/common";
import ServicesTickets from "components/Services/VirtualServers/ServicesTickets";
import { useTranslation } from "react-i18next";

function CloudServerDetails() {
  const { t } = useTranslation([ "global", "services", "nav", "support"]);


  const [serverStart, setServerStart] = useState(false);
  const [openBuyBackupModal, setOpenBuyBackupModal] = useState(false);
  const [openBackupServicesModal, setOpenBackupServicesModal] = useState(false);
  const [openUpgradeServicesModal, setOpenUpgradeServicesModal] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let slug = useParams();
  const [pageData, setPageData] = useState();


  const onGetPageData = () => {
    setIsLoading(true);
    getCloudServer(slug.id).then(res => {
      setIsLoading(false);
      setPageData(res);
      if (!res) {
        navigate("/");
      }
    });
  };

  const tabs = [
    {
      name: t("services:quickTransactions"),
      id: "1",
      component: <FastTransactions data={pageData} onGetPageData={onGetPageData} />,
      slug: "",
      visible: true
    },
    {
      name: "Network",
      id: "2",
      component: <VirtualNetwork data={pageData} />,
      slug: "network",
      visible: true
    },
    {
      name: t("nav:server-usage"),
      id: "3",
      component: <VirtualServersUsing data={pageData} />,
      slug: "server-usage",
      visible: true
    },
    {
      name: t("global:settings"),
      id: "4",
      component: <VirtualSettings data={pageData} />,
      slug: "settings",
      visible: true
    },
    {
      name: t("nav:log-records"),
      id: "5",
      component: <VirtualLogRecords data={pageData} />,
      slug: "log-records",
      visible: true
    },
    {
      name: t("nav:vpn-users"),
      id: "6",
      component: <PhysicalVPNUsers data={pageData} />,
      slug: "vpn-users",
      visible: true
    },
    {
      name: t("nav:ip-records"),
      id: "7",
      component: <VirtualIPRecords data={pageData} />,
      slug: "ip-records",
      visible: true
    },
    {
      name: t("support:tickets"),
      id: "8",
      component: <ServicesTickets data={pageData} />,
      slug: "tickets",
      visible: true
    }
  ];


  useEffect(() => {
    onGetPageData();
  }, [slug.id]);


  return (
    (pageData && pageData?.status !== "waiting") ?
      <div className="container relative w-full ">
        <div className="items-center justify-between p-4 bg-white rounded-md shadow-sm lg:flex">
          <div>
            <h2 className="font-semibold">{pageData?.product_group_name + " - " + pageData?.product_name}</h2>
            <p className="text-sm text-passive">ID: {pageData?.owned_product_code}</p>
          </div>
          <div className="mt-5 lg:flex lg:mt-0">
            <div className="items-center lg:flex">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  <i className="mr-1 text-3xl ri-flashlight-fill text-passive" />
                  <p className="text-sm text-passive">{t("global:active")}</p>
                </div>
                <div className="flex items-center mr-4 cursor-pointer group">
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-terminal-box-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                    Terminal
                  </p>
                </div>
                <div
                  // onClick={() => setOpenBuyBackupModal(true)}
                  className="flex items-center mr-4 cursor-pointer group"
                >
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-database-2-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                    {t("services:backup")}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div
                  // onClick={() => setOpenUpgradeServicesModal(true)}
                  className="flex items-center mr-4 cursor-pointer group"
                >
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-stack-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                  {t("services:upgrade")}
                  </p>
                </div>
                <div className="flex items-center mr-4 cursor-pointer group">
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-restart-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                    Restart
                  </p>
                </div>
                {!serverStart ? (
                  <div
                    // onClick={() => setServerStart(true)}
                    className="flex items-center mr-4 cursor-pointer"
                  >
                    <i className="mr-1 text-3xl ri-stop-circle-fill text-passive" />
                    <p className="text-sm text-passive">{t("global:stop")}</p>
                  </div>
                ) : (
                  <div
                    onClick={() => setServerStart(false)}
                    className="flex items-center mr-4 cursor-pointer"
                  >
                    <i className="mr-1 text-3xl ri-play-circle-fill text-alert-green" />
                    <p className="text-sm text-alert-green">{t("global:start")}</p>
                  </div>
                )}
              </div>
            </div>
            <Button disabled className="px-8 mt-3 lg:mt-0" secondary icon>
              <i className="ri-history-line" />
              <span>{t("services:extendService")}</span>
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Tabs
            tabs={tabs.filter((x) => x.visible)}
            selectedTab={
              !isNullOrEmptyOrUndefined(slug.url)
                ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
                : tabs.filter((x) => x.visible)[0]?.id
            }
            setSelectedTab={(v) => {
              navigate(
                "/services/cloud-server/" + slug.id + "/" +
                tabs.find((x) => x.id === v).slug
              );
            }}
          />


          <div>
            {!isNullOrEmptyOrUndefined(slug.url)
              ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
              : tabs.filter((x) => x.visible)[0]?.component
            }
          </div>
        </div>

        {openBuyBackupModal && (
          <BuyBackupModal setOpenModal={() => setOpenBuyBackupModal(false)} />
        )}
        {openUpgradeServicesModal && (
          <UpgradeServicesModal
            setOpenModal={() => setOpenUpgradeServicesModal(false)}
          />
        )}
      </div>
      :
      <div className="flex flex-col items-center justify-center mt-20">
        <img src="/servicesWaiting.svg" width="352" height="330" />
        <p className="mt-3 text-5xl font-semibold text-primary-hover">{t("services:preparedService")}</p>
        <p className="my-2">{t("services:preparedServiceDesc1")}</p>
        <p>{t("services:preparedServiceDesc2")}</p>
      </div>
  );
}

export default CloudServerDetails;
