import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { getProjectList, getTicketOwnedProductList, ticketCreatePaymentNotification } from "services/allServices";
import { useComboBox } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AlertDanger from "components/ContentAlerts/AlertDanger";

const schema = yup.object().shape({
  money: yup.string()
});

function ComplaintNotificationForm({ setOpenModal, setSupportRequestType }) {
  const { t, i18n } = useTranslation(["projects", "global", "support", "services"]);
  const [openAlert, setOpenAlert] = useState(false);

  const [money, setMoney] = useState(0);
  const [moneyError, setMoneyError] = useState(false);
  const [allProduct, setAllProduct] = useState();
  const [disableButton, setDisableButton] = useState(false);


  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const senderBankDizi = [
    { id: 1, text: "Garanti Bankası" },
    { id: 2, text: "İş Bankası" },
    { id: 3, text: "Ziraat Bankası" },
    { id: 4, text: "Finans Katılım Bankası" },
    { id: 5, text: "Halk Bankası" },
    { id: 6, text: "Vakıflar Bankası" },
    { id: 7, text: "Yapı ve Kredi Bankası" },
    { id: 8, text: "Finans Bank" },
    { id: 9, text: "Akbank" },
    { id: 10, text: "Bank Asya" },
    { id: 11, text: "Şekerbank" },
    { id: 12, text: "Adabank" },
    { id: 13, text: "Albaraka Türk Katılım Bankası" },
    { id: 14, text: "Alternatif Bank" },
    { id: 15, text: "Anadolubank" },
    { id: 16, text: "Burgan Bank" },
    { id: 17, text: "Citibank" },
    { id: 18, text: "Denizbank" },
    { id: 19, text: "Deutsche Bank" },
    { id: 20, text: "Fibabanka" },
    { id: 21, text: "Habib Bank Limited" },
    { id: 22, text: "HSBC" },
    { id: 23, text: "ING Bank" },
    { id: 24, text: "Kuveyt Türk Katılım Bankası" },
    { id: 25, text: "Odeabank" },
    { id: 26, text: "T-Bank" },
    { id: 27, text: "Tekstilbank" },
    { id: 28, text: "Turkish Bank" },
    { id: 29, text: "Türk Ekonomi Bankası" },
    { id: 30, text: "Diğer" }
  ];
  const receivingBankDizi = [
    { id: 1, text: "Garanti Bankası (TL)" },
    { id: 2, text: "İş Bankası (TL)" },
    { id: 3, text: "Ziraat Bankası (TL)" }
  ];
  const [
    selectedProject,
    projectList,
    changeSelectedProject,
    setProjectList
  ] = useComboBox(t("support:selectProject"));
  const [
    selectedSenderBank,
    senderBankList,
    changeSelectedSenderBank,
    setSenderBankList
  ] = useComboBox(t("support:selectBank"));
  const [
    selectedReceivingBank,
    receivingBankList,
    changeSelectedReceivingBank,
    setReceivingBankList
  ] = useComboBox(t("support:selectBank"));
  const [
    selectedProduct,
    productList,
    changeSelectedProduct,
    setProductList
  ] = useComboBox(t("support:selectService"));
  const onSubmit = async (data) => {
    if (money != 0 && money != "0,00" && money != "") {
      if (
        selectedProject.id !== 0 &&
        selectedSenderBank.id !== 0 &&
        selectedReceivingBank.id !== 0
      ) {
        let findProduct = "";
        if (allProduct) {
          findProduct = allProduct?.find((item) => item.owned_product_id === selectedProduct.id)?.type_group_info?.type_id;
        }
        setDisableButton(true);
        let configure = {
          bank_from: selectedSenderBank.text,
          bank_to: selectedReceivingBank.text,
          related_product_id: selectedProduct.id === 0 ? null : selectedProduct.id,
          related_product_name: selectedProduct.id === 0 ? null : selectedProduct.text,
          project_id: selectedProject.id,
          amount: parseFloat(money),
          order_id: data.orderID,
          bank_receipt_id: data.dekont,
          product_type_id: findProduct ? findProduct : null
        };
        await ticketCreatePaymentNotification(configure);
        setOpenModal(false);
        setDisableButton(false);
      } else {
        setOpenAlert(true);
        setDisableButton(false);
      }
    } else {
      setMoneyError(true);
      setDisableButton(false);
    }

  };

  const onSelectProduct = (val) => { // burada seçilen hizmete göre proje seçimi yapılıyor
    if (val.id !== 0) {
      allProduct?.map((item) => {
        if (item.owned_product_id === val.id) {
          changeSelectedProject(item?.project_info?.project_id, item?.project_info?.project_name);
        }
      });
    }
  };

  const onSelectProject = async (val) => { // burada seçilen projeye göre hizmet sıfırlanıyor
    if (val?.text !== "Proje Seçiniz") {
      changeSelectedProduct(0, t("support:selectService"));
      await getTicketOwnedProductList(val.id).then((products) => {
        products?.unshift({ owned_product_id: 0, product_name: t("support:selectService") });
        setProductList(products, "owned_product_id", "product_name");
        setAllProduct(products);
      });
    }
  };

  useEffect(() => {
    getTicketOwnedProductList().then((products) => {
      products?.unshift({ owned_product_id: 0, product_name: "Hizmet Seçiniz" });
      setProductList(products, "owned_product_id", "product_name");
      setAllProduct(products);
    });
    getProjectList().then((project) => {
      setProjectList(project, "id", "name");
    });
    setSenderBankList(senderBankDizi, "id", "text");
    setReceivingBankList(receivingBankDizi, "id", "text");
  }, []);

  useEffect(() => {
    if (money != 0 && money != "0,00" && money != "") {
      setMoneyError(false);
    }

  }, [money]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-5 pb-4 ">
        {openAlert && (
          <AlertDanger message="Lütfen projenizi, veya bankaları seçip tekrar deneyin." />
        )}

        <div className="grid grid-cols-3 gap-5 mt-3 ">
          <div className="col-span-2 lg:col-span-1">
            <ComboBox
              id="proje"
              name="proje"
              selected={selectedProject}
              setSelected={changeSelectedProject}
              list={projectList}
              label={`*${t("global:project")}`}
              isSearchable={false}
              onSelected={(val) => onSelectProject(val)}
            />
          </div>
          {selectedProject.id !== 0 && allProduct?.length > 1 &&
            <div className="col-span-2 lg:col-span-1">
              <ComboBox
                id="hizmet"
                name="hizmet"
                selected={selectedProduct}
                setSelected={changeSelectedProduct}
                list={productList}
                label={t("global:associatedService")}
                isSearchable={false}
                onSelected={(val) => onSelectProduct(val)}
              />
            </div>
          }

          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="money"
              name="money"
              type="currency"
              symbol="₺"
              innerRef={register}
              label={`*${t("support:amountPaid")}`}
              placeholder="100,00"
              value={money}
              onChange={(v) => setMoney(v)}
            />
            {moneyError &&
              <p className="text-sm text-alert-red">{t("services:amountGreatedThan0")}.</p>
            }
          </div>

        </div>

        <div className="grid grid-cols-3 gap-5 mt-5">
          <div className="col-span-3 lg:col-span-1">
            <ComboBox
              id="sendingBank"
              name="sendingBank"
              selected={selectedSenderBank}
              setSelected={changeSelectedSenderBank}
              list={senderBankList}
              label={t("support:senderBank")}
              isSearchable
            />
          </div>
          <div className="col-span-3 lg:col-span-1">
            <ComboBox
              id="receivingBank"
              name="receivingbank"
              selected={selectedReceivingBank}
              setSelected={changeSelectedReceivingBank}
              list={receivingBankList}
              label={t("support:recipientBank")}
              isSearchable={false}
            />
          </div>
          {selectedReceivingBank.id !== 0 &&
            selectedSenderBank.id !== 0 && (
              <div className="flex flex-col items-center justify-center col-span-3 lg:col-span-1">
                <p>{t("support:paymentMethod")}</p>
                <p className="mt-2 text-lg font-semibold">
                  {selectedReceivingBank.id === selectedSenderBank.id
                    ? "Havale"
                    : selectedReceivingBank.id !== selectedSenderBank.id
                      ? "EFT"
                      : ""}
                </p>
              </div>
            )}
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="orderID"
              name="orderID"
              type="text"
              ref={null}
              label={t("support:proformaNo")}
              placeholder={t("support:example")}
              innerRef={register}
            />
            <p className="mt-2">
              {t("support:proformaDesc")}
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="dekont"
              name="dekont"
              type="text"
              ref={null}
              label={t("support:bankReceiptNo")}
              placeholder={t("support:example2")}
              innerRef={register}
            />
            <p className="mt-2">
            {t("support:receiptDesc")}
            </p>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
        <Button
          onClick={() => {
            setOpenModal(false);
            setSupportRequestType("1");
          }}
          className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
          tertiary
        >
          {t("global:giveUp")}
        </Button>
        <Button disabled={disableButton} type="submit" className="w-full px-12 mr-0 lg:w-auto" primary>
          {t("global:apply")}
        </Button>
      </div>
    </form>
  );
}

export default ComplaintNotificationForm;
