import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import DNSServicesTable from "../Tables/DNSServicesTable";
import { updateProfessionalDNS } from "services/allServices";
import { useToasts } from "react-toast-notifications";

function ProfessionalDNSModal({ data, onGetPageData, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const { addToast } = useToasts();

  const types = [
    { id: "A", type_name: "A" },
    { id: "AA", type_name: "AA" },
    { id: "CNAME", type_name: "CNAME" },
    { id: "MX", type_name: "MX" },
    { id: "TXT", type_name: "TXT" }
  ];
  const [dnsList, setDnsList] = useState([]);
  const [recordName, setRecordName] = useState("");
  const [ttl, setTtl] = useState("");

  const [priority, setPriority] = useState("");
  const [record, setRecord] = useState("");
  const [lasID, setLastID] = useState(0);

  const onGetData = async () => {
    let arr = [];
    data?.professional_dns_records.map((item, index) => {
      arr.push({
        id: index + 1,
        ...item
      });
      setLastID(index + 1);
    });
    setDnsList(arr);

  };

  const onAddDnsList = () => {
    let configureData = {
      id: lasID,
      record_name: recordName,
      type: selected.text,
      ttl: ttl,
      priority: priority,
      record: record
    };

    setLastID(lasID + 1);
    setDnsList([...dnsList, configureData]);
  };

  const onDeleteDnsList = async (id) => {
    let newList = [...dnsList];
    await setDnsList(newList.filter(item => item.id != id));

  };

  const onUpdataDNSList = async () => {
    let configure = {
      professional_dns_records: [...dnsList]
    };
    updateProfessionalDNS(data.id, configure).then(async (res) => {
      addToast(" Güncellendi", { appearance: "success", autoDismiss: true });
      await onGetPageData();
      await setOpenModal(false);
    }).catch(err => {
      addToast(" Güncellenemedi", { appearance: "error", autoDismiss: true });
    });

  };
  useEffect(() => {
    setList(types, "id", "type_name");
    onGetData();
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}

        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                   {t("services:professionalDNSServiceTitle")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 lg:col-span-1">
                      <TextField
                        id="registiration-name"
                        name="registiration-name"
                        type="text"
                        ref={null}
                        label=  {t("services:registryName")}
                        placeholder="makdos.com"
                        value={recordName}
                        onChange={setRecordName}
                      />
                    </div>
                    <div className="col-span-6 lg:col-span-1">
                      <TextField
                        id="ttl"
                        name="ttl"
                        type="text"
                        ref={null}
                        label="TTL"
                        placeholder="T4400"
                        value={ttl}
                        onChange={setTtl}
                      />
                    </div>
                    <div className="col-span-6 lg:col-span-1">
                      <ComboBox
                        id="tip"
                        name="tip"
                        selected={selected}
                        setSelected={changeSelected}
                        list={list}
                        label={t("global:type")}
                        isSearchable={false}
                      />
                    </div>
                    <div className="col-span-6 lg:col-span-1">
                      <TextField
                        id="priority"
                        name="priority"
                        type="text"
                        ref={null}
                        label={t("services:priority")}
                        placeholder={t("services:priority")}
                        value={priority}
                        onChange={setPriority}
                      />
                    </div>
                    <div className="col-span-6 lg:col-span-1">
                      <TextField
                        id="kayıt"
                        name="kayıt"
                        type="text"
                        ref={null}
                        label={t("services:registry")}
                        placeholder="IPv4 Adresi"
                        value={record}
                        onChange={setRecord}
                      />
                    </div>
                    <div className="flex items-end col-span-5 lg:col-span-1">
                      <Button onClick={onAddDnsList} className="w-full " secondary icon dark>
                        <i className="mr-2 ri-add-circle-line" />
                        {t("services:addRegistry")}
                      </Button>
                    </div>
                  </div>

                  {dnsList.length > 0 &&
                    <div className="mt-10 overflow-y-scroll h-80">
                      <DNSServicesTable dnsList={dnsList} deleteDNS={onDeleteDnsList} />
                    </div>
                  }

                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={onUpdataDNSList}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalDNSModal;
