import React, { useEffect, useState } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import CheckBox from "components/FormElements/CheckBox";
import moment from "moment";
import { changeCurrency } from "helpers/helpersFunc";
import { useGlobalStore } from "store/GlobalStore";

function ExtendServiceModal({ data, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);


  useEffect(() => {
    let newPriceList = [];
    data.domain_product_prices.map((item) => {
      newPriceList.push({
        id: item.year,
        text: item.year + " " + t("global:yearly") + " " + changeCurrency(currencyRates, currencyType, item.price, "USD") +  " " +currencyType
      });
    })
    setList(newPriceList, "id", "text");
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}

        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:extendService")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <div className="flex items-center justify-between">
                    <h4 className="font-semibold text-title">
                      {data.hostname}
                    </h4>
                    <p className="text-alert-green">Bitiş Tarihi: {moment(data.expire).format("DD/MM/YYYY HH:mm")}</p>
                  </div>
                  <div className="mt-5">
                    <ComboBox
                      id="time"
                      name="time"
                      label={t("services:extendedPeriod")}
                      list={list}
                      selected={selected}
                      setSelected={changeSelected}
                    />
                  </div>
                  {/* <div className="flex mt-8">
                    <div className="flex flex-col items-center justify-center">
                      <CheckBox
                        id="idProtection"
                        name="idProtection"
                        label={t("services:identityProtection")}
                        onChange={() => setCheck1(!check1)}
                      />
                      <p className="font-semibold text-primary-hover">
                        1.00 ₺ / {t("services:month")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center ml-10">
                      <CheckBox
                        id="dns"
                        name="dns"
                        label={t("services:dnsRegistryManagement")}
                        onChange={() => setCheck2(!check2)}
                      />
                      <p className="font-semibold text-primary-hover">
                        0.50 ₺ / {t("services:month")}
                      </p>
                    </div>
                  </div> */}
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtendServiceModal;
