import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";

function SuccessModal({
                        title,
                        question,
                        description,
                        setOpenModal,
                        buttonText,
                        onClick
                      }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex justify-end px-2 py-1">
              <i
                onClick={() => {
                  onClick && onClick();
                  setOpenModal(false);
                }}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>

            <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0002 53.6668C12.2722 53.6668 0.333496 41.7282 0.333496 27.0002C0.333496 12.2722 12.2722 0.333496 27.0002 0.333496C41.7282 0.333496 53.6668 12.2722 53.6668 27.0002C53.6668 41.7282 41.7282 53.6668 27.0002 53.6668ZM24.3415 37.6668L43.1948 18.8108L39.4242 15.0402L24.3415 30.1255L16.7975 22.5815L13.0268 26.3522L24.3415 37.6668Z"
                  fill="#1ACD67"
                />
              </svg>

              <h2 className="my-1 font-semibold text-alert-green">{title}</h2>
              <p>{question}</p>
              <p className="mt-5 text-center lg:mx-10">{description}</p>
            </div>

            <div className="flex flex-col-reverse justify-center px-5 py-3 bg-white lg:flex-row lg:px-0">
              <Button
                onClick={() => {
                  onClick && onClick();
                  setOpenModal(false);
                }}
                className="w-full px-12 mr-0 lg:w-auto"
                success
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
