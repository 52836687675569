import React, { useEffect, useState } from "react";
import { Button } from "components";
import AlertSuccess from "components/ContentAlerts/AlertSuccess";
import Tabs from "components/Tabs";
import { useGlobalStore } from "store/GlobalStore";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { useToasts } from "react-toast-notifications";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

function CSRCreatedModal({ setOpenModal, data }) {
  const { t } = useTranslation(["global","services", "settings"]);

  const [selectedPageTab, setSelectedPageTab] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab
  ]);
  const { addToast } = useToasts();

  const tabs = [
    { name: "CSR Kodu", id: "1", visible: true },
    { name: "Private Key", id: "2", visible: true }
  ];

  useEffect(() => {
    if (parseInt(selectedPageTab) > 2) {
      setSelectedPageTab("1");
    }
  }, [selectedPageTab]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:csrCreated")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="w-full px-5 pt-5 pb-10 bg-modalBg">
                  <div className="mb-2">

                    <AlertDanger
                      title={t("services:attention")}
                      message={t("services:attentionDesc")}
                    />
                  </div>
                  <AlertSuccess
                    title={t("services:csrCodeCreated")}
                    message={t("services:csrCodeCreatedDesc")}
                  />
                  <div className="mt-4">
                    <Tabs tabs={tabs} selectedTab={selectedPageTab} setSelectedTab={setSelectedPageTab} />
                  </div>
                  {selectedPageTab === "1" && (
                    <div>
                      <div className="flex items-center justify-between">
                        <p>{t("services:csrCode")}</p>
                        <CopyToClipboard text={data.csr} onCopy={() => addToast("Kopyalandı", {
                          appearance: "success",
                          autoDismiss: true
                        })}>
                          <Button>{t("settings:copy")}</Button>
                        </CopyToClipboard>
                      </div>
                      <div className="p-2 overflow-x-auto bg-white w-96 lg:w-full">
                        <p className="text-xs whitespace-pre-wrap">
                          {data.csr}
                        </p >
                      </div>
                    </div>
                  )}
                  {selectedPageTab === "2" && (
                    <div>
                      <div className="flex items-center justify-between">
                        <p>{t("services:privateKey")}</p>
                        <CopyToClipboard text={data.key} onCopy={() => addToast("Kopyalandı", {
                          appearance: "success",
                          autoDismiss: true
                        })}>
                          <Button>{t("settings:copy")}</Button>
                        </CopyToClipboard>
                      </div>
                      <div className="p-2 overflow-x-auto bg-white w-96 lg:w-full">
                        <p className="text-xs whitespace-pre-wrap">
                          {data.key}
                        </p>
                      </div>
                    </div>
                  )}

                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSRCreatedModal;
