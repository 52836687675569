import { Link } from "react-router-dom";
import { useSortBy, useTable } from "react-table"
import ReactTooltip from "react-tooltip";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import moment from "moment/min/moment-with-locales";
import { onChanceStatus } from "helpers/helpersFunc";

const SupportTable = ({ datas }) => {
    const { t, i18n } = useTranslation(["projects", "global", "services"]);
    const data = React.useMemo(
        () => datas,
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("projects:productId"),
                accessor: "owned_product_code"
            },
            {
                Header: t("projects:productName"),
                accessor: "product_name"
            },
            // {
            //     Header: "Alan Adı",
            //     accessor: "hostname",
            // },

            {
                Header: t("projects:projectName"),
                accessor: "project_name"
            },
            {
                Header: t("projects:assignedIp"),
                accessor: ""
            },

            {
                Header: t("projects:status"),
                accessor: "status",
                Cell: (props) => onChanceStatus(props.value)
            },

            {
                Header: t("projects:endDate"),
                accessor: "date_of_expire",
                Cell: (props) =>
                    props.value !== null
                        ? moment(props.value).format("DD-MM-YYYY HH:mm")
                        : "-"
            },

            {
                Header: t("projects:action"),
                accessor: "col4",
                Cell: tableButton
            }
        ],
        [t]
    );

    function tableButton(props) {
        return (
            <div className="flex items-center">
                <i
                    data-tip={props.row.original?.customer_note != "" ? props.row.original?.customer_note : t("services:noteDesc")}
                    className={`cursor-pointer ri-information-line ${props.row.original?.customer_note != "" ? " text-primary-hover" : " text-main"}`}
                ></i>
                <Link to={`/services/support/${props.row.original.id}`}>
                    <Button name={props.cell.row.id}>{t("projects:manage")}</Button>
                </Link>
            </div>
        );
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data }, useSortBy);

    return (
        <table {...getTableProps()} className="w-full">
            <ReactTooltip
                place="top"
                backgroundColor="black"
                textColor="white"
                effect="solid"
            />
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {/* {headerGroup.headers.map(column => (
                              <th
                                  {...column.getHeaderProps()}
                                  className="p-3 text-sm text-left text-white bg-main"
                              >
                                  {column.render('Header')}
                              </th>
                          ))} */}
                        {headerGroup.headers.map((column) => (
                            <th
                                className="p-3 text-sm text-left text-white bg-main"
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                {column.render("Header")}
                                <span className="relative flex items-center justify-between">
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <svg
                                                className="absolute right-0 -top-3"
                                                width="10"
                                                height="6"
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                                                    fill="#D8D8D8"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                className="absolute right-0 -top-3"
                                                width="10"
                                                height="6"
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                                                    fill="#D8D8D8"
                                                />
                                            </svg>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={
                                index % 2 === 0 ? " bg-white p-1" : " bg-borderGray p-1"
                            }
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="p-1 text-main">
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    )
}

export default SupportTable