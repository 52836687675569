import React from "react";
import { Error, Label, Textarea } from "./styles";

const TextArea = ({
                    id,
                    name,
                    value,
                    label,
                    placeholder,
                    innerRef = () => {
                    },
                    rows,
                    error,
                    onChange,
                    disabled,
                    className,
                    required,
                    defaultValue,
                    max,
                    min,

                  }) => {
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <div>
        <Textarea
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            typeof onChange !== "undefined" && onChange(e.target.value);
          }}
          value={value}
          {...innerRef(name)}
          rows={rows}
          required={required}
          disabled={disabled}
          defaultValue={defaultValue && defaultValue}
          min={min}
          max={max}
        />
        {error && <Error>{error}</Error>}
      </div>
    </div>
  );
};

export default TextArea;
