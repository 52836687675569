import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import RadioButton from "components/FormElements/RadioButton";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import { getProjectList } from "services/allServices";

function ProformaFilterModal({ setOpenModal, onClick, pageLimit, proformaFilterData, setProformaFilterData }) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting", "support"]);

  const dummyDizi2 = [
    { id: "completed", text: "Ödendi" },
    { id: "pending", text: "Ödenmedi" },
    { id: "canceled", text: "İptal Edildi" }
  ];
  const [selectedPaymentMedhod, listPaymentMethod, changeSelectedPaymentMedhod, setPaymentMethodList] = useComboBox(t("global:choose"));
  const [selectedStatus, listStatus, changeSelectedStatus, setListStatus] = useComboBox(t("global:choose"));
  const [selectedProject, projectList, changeSelectedProject, setProjectList] = useComboBox(t("support:selectProject"));
  const [isCheck, setIsCheck] = useState("1");
  const [isCheckPayment, setIsCheckPayment] = useState("3");
  const [isCheckPrice, setIsCheckPrice] = useState("5");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [date4, setDate4] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");

  const handleChange = (e) => {
    setIsCheck(e.target.value);
    setDate1("");
    setDate2("");
  };
  const handleChangePayment = (e) => {
    setIsCheckPayment(e.target.value);
    setDate3("");
    setDate4("");
  };
  const handleChangePrice = (e) => {
    setIsCheckPrice(e.target.value);
    setPrice1("");
    setPrice2("");
    setPrice3("");
  };

  const onApply = () => {

    let configureFilter = {
      project_id: selectedProject && selectedProject.id != 0 ? selectedProject.id : null,
      status: selectedStatus && selectedStatus.id != 0 ? selectedStatus.id : null,
      price: isCheckPrice === "5" ? (price1 ? {
        fixed_price: price1,
        start_price: null,
        end_price: null
      } : null) : { start_price: price2 ? price2 : null, end_price: price3 ? price3 : null },
      billing_date: (date1 || date2) ? { start_date: date1 ? date1 : null, end_date: date2 ? date2 : null } : null,
      payment_date: (date3 || date4) ? { start_date: date3 ? date3 : null, end_date: date4 ? date4 : null } : null
    };
    setProformaFilterData({
      ...configureFilter,
      count: Object.entries(configureFilter).filter(item => item[1] !== null).length
    });
    onClick(1, pageLimit, configureFilter);

    setOpenModal(false);
  };

  const onClear = () => {
    let configureFilter = {
      project_id: null,
      status: null,
      price: null,
      billing_date: null,
      payment_date: null
    };
    setProformaFilterData({ ...configureFilter, count: 0 });
    onClick(1, pageLimit, configureFilter);
    setOpenModal(false);

  };


  useEffect(() => {
    getProjectList().then((project) => {
      setProjectList(project, "id", "name");
      if (proformaFilterData.project_id) {
        let find = project.find(item => item.id == proformaFilterData.project_id);
        changeSelectedProject(find.id, find.name);
      }
    });

    // getPaymentMethodList().then((method) => {
    //   setPaymentMethodList(method, "method", "method_name");
    // });

    setListStatus(dummyDizi2, "id", "text");
    if (proformaFilterData?.status) {
      let find = dummyDizi2.find(item => item.id == proformaFilterData.status);
      changeSelectedStatus(find.id, find.text);
    }

    if (proformaFilterData?.price) {
      if (proformaFilterData.price.fixed_price) {
        setIsCheckPrice("5");
        setPrice1(proformaFilterData.price.fixed_price);
      } else {
        setIsCheckPrice("6");
        setPrice2(proformaFilterData.price.start_price);
        setPrice3(proformaFilterData.price.end_price);
      }
    }

    if (proformaFilterData?.billing_date) {
      setIsCheck("2");
      setDate1(proformaFilterData.billing_date.start_date);
      setDate2(proformaFilterData.billing_date.end_date);
    }

    if (proformaFilterData?.payment_date) {
      setIsCheckPayment("4");
      setDate3(proformaFilterData.payment_date.start_date);
      setDate4(proformaFilterData.payment_date.end_date);
    }

  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("accounting:filterProformas")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg">

                  <div className="grid w-full grid-cols-2 gap-5">
                    <div className="z-30 col-span-2 lg:col-span-1">
                      <p>{t("accounting:project")}</p>
                      <div>
                        <ComboBox
                          id="select"
                          name="select"
                          selected={selectedProject}
                          setSelected={changeSelectedProject}
                          list={projectList}
                          defaultText="Seçiniz"
                        />
                      </div>
                    </div>
                    <div className="z-20 col-span-2 lg:col-span-1">
                      <p>{t("global:status")}</p>
                      <div>
                        <ComboBox
                          id="select"
                          name="select"
                          selected={selectedStatus}
                          setSelected={changeSelectedStatus}
                          list={listStatus}
                          defaultText="Seçiniz"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-5">
                    <p className="mr-10 lg:w-28">{t("accounting:billHistory")}</p>
                    <div className="w-32 mr-10">
                      <RadioButton
                        id="1"
                        name="invoices"
                        label={t("projects:useThisDay")}
                        isCheck={isCheck === "1"}
                        setIsCheck={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="2"
                        name="invoices"
                        label={t("projects:betweenDifferentDates")}
                        isCheck={isCheck === "2"}
                        setIsCheck={(e) => handleChange(e)}

                      />
                    </div>
                  </div>
                  {isCheck === "2" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date1}
                          onChange={setDate1}
                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date2}
                          onChange={setDate2}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex items-center mt-5">
                    <p className="mr-10 lg:w-28">{t("accounting:paymentDate")}</p>
                    <div className="w-32 mr-10">
                      <RadioButton
                        id="3"
                        name="payment"
                        label={t("projects:useThisDay")}
                        isCheck={isCheckPayment === "3"}
                        setIsCheck={(e) => handleChangePayment(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="4"
                        name="payment"
                        label={t("projects:betweenDifferentDates")}
                        isCheck={isCheckPayment === "4"}
                        setIsCheck={(e) => handleChangePayment(e)}
                      />
                    </div>
                  </div>
                  {isCheckPayment === "4" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date3}
                          onChange={setDate3}
                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date4}
                          onChange={setDate4}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex items-center mt-5">
                    <p className="mr-10 lg:w-28">{t("accounting:amount")}</p>
                    <div className="w-32 mr-10">
                      <RadioButton
                        id="5"
                        name="Amount"
                        label={t("accounting:fixedAmount")}
                        isCheck={isCheckPrice === "5"}
                        setIsCheck={(e) => handleChangePrice(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="6"
                        name="Amount"
                        label={t("accounting:betweenDifferentAmounts")}
                        isCheck={isCheckPrice === "6"}
                        setIsCheck={(e) => handleChangePrice(e)}
                      />
                    </div>
                  </div>
                  {isCheckPrice === "5" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price1}
                          onChange={setPrice1}
                        />
                      </div>
                    </div>
                  )}
                  {isCheckPrice === "6" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price2}
                          onChange={setPrice2}
                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price3}
                          onChange={setPrice3}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={() => onClear()}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    secondary
                  >
                    {t("global:reset")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={() => onApply()}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProformaFilterModal;
