import create from "zustand";
import { devtools } from "zustand/middleware";

export const useAccountingStore = create(
  devtools((set, get) => ({
    selectedProforma: localStorage.getItem("__selectedProforma") || null,
    setSelectedProforma: (value) => {
      set((state) => ({
        selectedProforma: value
      }));

      get().addToLocalStorageAccounting();
    },

    selectedInvoices: localStorage.getItem("__selectedInvoices") || null,
    setSelectedInvoices: (value) => {
      set((state) => ({
        selectedInvoices: value
      }));

      get().addToLocalStorageAccounting();
    },

    addToLocalStorageAccounting: () => {
      // localStorage.removeItem("__products")
      localStorage.setItem("__selectedProforma", get().selectedProforma);
      localStorage.setItem("__selectedInvoices", get().selectedInvoices);
    },
    checkLocalStorageAccounting: () => {
      if (
        typeof localStorage.getItem("__selectedProforma") !== "undefined" &&
        localStorage.getItem("__selectedProforma") !== null &&
        localStorage.getItem("__selectedProforma") !== ""
      )
        set((state) => ({
          selectedProforma: JSON.parse(
            localStorage.getItem("__selectedProforma")
          )
        }));

      if (
        typeof localStorage.getItem("__selectedInvoices") !== "undefined" &&
        localStorage.getItem("__selectedInvoices") !== null &&
        localStorage.getItem("__selectedInvoices") !== ""
      )
        set((state) => ({
          selectedInvoices: JSON.parse(
            localStorage.getItem("__selectedInvoices")
          )
        }));
    }
  }))
);
