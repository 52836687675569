import CheckBox from "components/FormElements/CheckBox";
import React, { useEffect, useState } from "react";
import { useCheckList } from "hooks";
import Button from "components/FormElements/Button";
import { getNotificationAndNews, updateNotificationAndNews } from "services/allServices";
import AlertSuccess from "components/ContentAlerts/AlertSuccess";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function NotificationAndNewsSettings() {
  const { t, i18n } = useTranslation(["settings", "global", "nav"]);


  const { addToast } = useToasts();


  const [checkList1, onSetCheckList1, setCheckList1] = useCheckList();
  const [checkList2, onSetCheckList2, setCheckList2] = useCheckList();
  const [checkList3, onSetCheckList3, setCheckList3] = useCheckList();
  const [checkList4, onSetCheckList4, setCheckList4] = useCheckList();
  const [checkList5, onSetCheckList5, setCheckList5] = useCheckList();
  const [checkList6, onSetCheckList6, setCheckList6] = useCheckList();
  const [checkList7, onSetCheckList7, setCheckList7] = useCheckList();
  const [checkList8, onSetCheckList8, setCheckList8] = useCheckList();

  const [openAlert, setOpenAlert] = useState(false);

  const onFillCheckList = () => {
    getNotificationAndNews().then(res => {
      if (res?.status != "not_found") {
        res?.map(async (data, index) => {
          let list = [];
          if (data.by_email) {
            list.push("E-Posta");
          }
          if (data.by_sms) {
            list.push("SMS");
          }
          if (data.by_voice_call) {
            list.push("Sesli Arama");
          }
          if (data.by_whatsapp) {
            list.push("Whatsapp");
          }

          if (index + 1 === 1) {
            await setCheckList1(list);
          }
          if (index + 1 === 2) {
            await setCheckList2(list);
          }
          if (index + 1 === 3) {
            await setCheckList3(list);
          }
          if (index + 1 === 4) {
            await setCheckList4(list);
          }
          if (index + 1 === 5) {
            await setCheckList5(list);
          }
          if (index + 1 === 6) {
            await setCheckList6(list);
          }
          if (index + 1 === 7) {
            await setCheckList7(list);
          }
          if (index + 1 === 8) {
            await setCheckList8(list);
          }

        });
      }

    });

  };

  const onUpdateNotificationAndNews = () => {
    let configure = [
      {
        notifications_news_id: 1,
        by_sms: checkList1.some((i) => i === "SMS"),
        by_voice_call: checkList1.some((i) => i === "Sesli Arama"),
        by_email: checkList1.some((i) => i === "E-Posta"),
        by_whatsapp: checkList1.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 2,
        by_sms: checkList2.some((i) => i === "SMS"),
        by_voice_call: checkList2.some((i) => i === "Sesli Arama"),
        by_email: checkList2.some((i) => i === "E-Posta"),
        by_whatsapp: checkList2.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 3,
        by_sms: checkList3.some((i) => i === "SMS"),
        by_voice_call: checkList3.some((i) => i === "Sesli Arama"),
        by_email: checkList3.some((i) => i === "E-Posta"),
        by_whatsapp: checkList3.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 4,
        by_sms: checkList4.some((i) => i === "SMS"),
        by_voice_call: checkList4.some((i) => i === "Sesli Arama"),
        by_email: checkList4.some((i) => i === "E-Posta"),
        by_whatsapp: checkList4.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 5,
        by_sms: checkList5.some((i) => i === "SMS"),
        by_voice_call: checkList5.some((i) => i === "Sesli Arama"),
        by_email: checkList5.some((i) => i === "E-Posta"),
        by_whatsapp: checkList5.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 6,
        by_sms: checkList6.some((i) => i === "SMS"),
        by_voice_call: checkList6.some((i) => i === "Sesli Arama"),
        by_email: checkList6.some((i) => i === "E-Posta"),
        by_whatsapp: checkList6.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 7,
        by_sms: checkList7.some((i) => i === "SMS"),
        by_voice_call: checkList7.some((i) => i === "Sesli Arama"),
        by_email: checkList7.some((i) => i === "E-Posta"),
        by_whatsapp: checkList7.some((i) => i === "Whatsapp")
      },
      {
        notifications_news_id: 15,
        by_sms: checkList8.some((i) => i === "SMS"),
        by_voice_call: checkList8.some((i) => i === "Sesli Arama"),
        by_email: checkList8.some((i) => i === "E-Posta"),
        by_whatsapp: checkList8.some((i) => i === "Whatsapp")
      }
    ];

    updateNotificationAndNews(configure).then(res => {
      addToast(t("global:itSaved"), {
        appearance: "success",
        autoDismiss: true
      });
    });
  };


  useEffect(() => {
    onFillCheckList();
  }, []);
  return (
    <div className="container ">
      <h2 className="mt-5 mb-1 font-semibold">{t("nav:notification-and-news")}</h2>
      <p className="mb-8 text-main">
        {t("settings:customizeNotification")}
      </p>


      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 ">
          <p className="font-semibold text-primary-hover">
            Hesap Giriş Bildirimi
          </p>
          <p className="mt-1 text-xs text-textGray">
            Hesabınıza giriş yapıldığında haberiniz olsun.
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="login1"
                name="login1"
                label="SMS"
                checked={checkList8.some((i) => i === "SMS")}
                //onChange={() => onSetCheckList8("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="login2"
                name="login2"
                label={t("settings:voiceCall")}
                checked={checkList8.some((i) => i === "Sesli Arama")}
                // onChange={() => onSetCheckList8("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="login3"
                name="login3"
                label={t("global:email")}
                checked={checkList8.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList8("E-Posta")}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="login4"
                name="login4"
                label="Whatsapp"
                checked={checkList8.some((i) => i === "Whatsapp")}
                //onChange={() => onSetCheckList8("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">
            {t("settings:newProductAndUpdates")}
          </p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:newProductDesc")}
          </p>
          <div className="mt-3 lg:flex b">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="update1"
                name="update1"
                label="SMS"
                checked={checkList1.some((i) => i === "SMS")}
                //onChange={() => onSetCheckList1("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="update2"
                name="update2"
                label={t("settings:voiceCall")}
                checked={checkList1.some((i) => i === "Sesli Arama")}
                //onChange={() => onSetCheckList1("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="update3"
                name="update3"
                label={t("global:email")}
                checked={checkList1.some((i) => i === "E-Posta")}
                //onChange={() => onSetCheckList1("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="update4"
                name="update4"
                label="Whatsapp"
                checked={checkList1.some((i) => i === "Whatsapp")}
                //onChange={() => onSetCheckList1("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">{t("settings:tutorialResources")}</p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:tutorialResourcesDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="learn1"
                name="learn1"
                label="SMS"
                checked={checkList2.some((i) => i === "SMS")}
                onChange={() => onSetCheckList2("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="learn2"
                name="learn2"
                label={t("settings:voiceCall")}
                checked={checkList2.some((i) => i === "Sesli Arama")}
                onChange={() => onSetCheckList2("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="learn3"
                name="learn3"
                label={t("global:email")}
                checked={checkList2.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList2("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="learn4"
                name="learn4"
                label="Whatsapp"
                checked={checkList2.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList2("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60 ">
          <p className="font-semibold text-primary-hover">
            {t("settings:eventsAndMeetings")}
          </p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:eventsAndMeetingsDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="events1"
                name="events1"
                label="SMS"
                checked={checkList3.some((i) => i === "SMS")}
                onChange={() => onSetCheckList3("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="events2"
                name="events2"
                label={t("settings:voiceCall")}
                checked={checkList3.some((i) => i === "Sesli Aram")}
                onChange={() => onSetCheckList3("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="events3"
                name="events3"
                label={t("global:email")}
                checked={checkList3.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList3("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="events4"
                name="events4"
                label="Whatsapp"
                checked={checkList3.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList3("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">
            {t("settings:userExperienceResearch")}
          </p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:userExperienceResearchDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="experience1"
                name="experience1"
                label="SMS"
                checked={checkList4.some((i) => i === "SMS")}
                onChange={() => onSetCheckList4("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="experience2"
                name="experience2"
                label={t("settings:voiceCall")}
                checked={checkList4.some((i) => i === "Sesli Arama")}
                onChange={() => onSetCheckList4("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="experience3"
                name="experience3"
                label={t("global:email")}
                checked={checkList4.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList4("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="experience4"
                name="experience4"
                label="Whatsapp"
                checked={checkList4.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList4("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">Webinar</p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:webinarDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="webinar1"
                name="webinar1"
                label="SMS"
                checked={checkList5.some((i) => i === "SMS")}
                onChange={() => onSetCheckList5("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="webinar2"
                name="webinar2"
                label={t("settings:voiceCall")}
                checked={checkList5.some((i) => i === "Sesli Arama")}
                onChange={() => onSetCheckList5("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="webinar3"
                name="webinar3"
                label={t("global:email")}
                checked={checkList5.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList5("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="webinar4"
                name="webinar4"
                label="Whatsapp"
                checked={checkList5.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList5("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">{t("settings:makdosNews")}</p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:makdosNewsDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="makdos1"
                name="makdos1"
                label="SMS"
                checked={checkList6.some((i) => i === "SMS")}
                onChange={() => onSetCheckList6("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="makdos2"
                name="makdos2"
                label={t("settings:voiceCall")}
                checked={checkList6.some((i) => i === "Sesli Arama")}
                onChange={() => onSetCheckList6("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="makdos3"
                name="makdos3"
                label={t("global:email")}
                checked={checkList6.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList6("E-Posta")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="makdos4"
                name="makdos4"
                label="Whatsapp"
                checked={checkList6.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList6("Whatsapp")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-9">
        <div className="col-span-9 p-3 mt-5 bg-white rounded-md shadow-md lg:col-span-4 opacity-60">
          <p className="font-semibold text-primary-hover">
            {t("settings:opportunitiesAndCampaigns")}
          </p>
          <p className="mt-1 text-xs text-textGray">
            {t("settings:opportunitiesAndCampaignsDesc")}
          </p>
          <div className="mt-3 lg:flex">
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="opportunity1"
                name="opportunity1"
                label="SMS"
                checked={checkList7.some((i) => i === "SMS")}
                onChange={() => onSetCheckList7("SMS")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="opportunity2"
                name="opportunity2"
                label={t("settings:voiceCall")}
                checked={checkList7.some((i) => i === "Sesli Arama")}
                onChange={() => onSetCheckList7("Sesli Arama")}
                disabled={true}
              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="opportunity3"
                name="opportunity3"
                label={t("global:email")}
                checked={checkList7.some((i) => i === "E-Posta")}
                onChange={() => onSetCheckList7("E-Posta")}
                disabled={true}

              />
            </div>
            <div className="mt-2 mr-2 lg:mt-0 lg:mr-8">
              <CheckBox
                id="opportunity4"
                name="opportunity4"
                label="Whatsapp"
                checked={checkList7.some((i) => i === "Whatsapp")}
                onChange={() => onSetCheckList7("Whatsapp")}
                disabled={true}

              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-5">
        <Button onClick={() => onUpdateNotificationAndNews()} className="w-full px-18 lg:w-auto" primary>
          {t("global:save")}
        </Button>
      </div>
      {openAlert &&
        <div className="absolute w-full -bottom-4 lg:left-64 lg:w-auto">
          <AlertSuccess title={t("global:successfully")} message={t("settings:successTransaction")} />
        </div>
      }
    </div>
  );
}

export default NotificationAndNewsSettings;
