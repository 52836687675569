import React, { useEffect, useState } from "react";
import { Bounce, Zoom } from "react-reveal";

function HomeCard({ count, description, animate }) {
  const [isAnimate, setIsAnimate] = useState(false)


  useEffect(() => {
    if (animate && count > 0) {
      setTimeout(() => {
        setIsAnimate(!isAnimate)
      }, 1000);
    }
  }, [isAnimate, count])


  return (
    <div
      className="flex items-center w-full h-full px-4 py-8 transition-all duration-300 bg-white rounded-md shadow-md cursor-pointer hover:shadow-lg">
      <div className="">
        {animate && count > 0 ?
          <Zoom when={isAnimate} >
            <p className="text-4xl font-bold text-primary-hover">{count}</p>
          </Zoom>
          :
          <p className="text-4xl font-bold text-primary-hover">{count}</p>

        }
      </div>
      <div className="ml-2">
        <p className="">{description}</p>
      </div>
    </div>
  );
}

export default HomeCard;
