import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { updateDomainDns } from "services/allServices";

function DNSChangeForm3({ data, onGetPageData, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global"," services"]);
  const [openAlert, setOpenAlert] = useState(false);

  const [NS1, setNS1] = useState("");
  const [NS2, setNS2] = useState("");
  const [NS3, setNS3] = useState("");
  const [NS4, setNS4] = useState("");
  const [NS5, setNS5] = useState("");

  const [IP1, setIP1] = useState("");
  const [IP2, setIP2] = useState("");
  const [IP3, setIP3] = useState("");
  const [IP4, setIP4] = useState("");
  const [IP5, setIP5] = useState("");

  const onSubmit = async () => {
    let configure = {
      dns_server_ns_type: "new",
      dns_website_title: "",
      dns_website_email: "",
      dns_phone: "",
      dns_ns1: NS1,
      dns_ns2: NS2,
      dns_ns3: NS3,
      dns_ns4: NS4,
      dns_ns5: NS5,
      dns_ip1: IP1,
      dns_ip2: IP2,
      dns_ip3: IP3,
      dns_ip4: IP4,
      dns_ip5: IP5
    };
    updateDomainDns(data.id, configure).then((res) => {
      setOpenModal(false);
      onGetPageData();
    });
  };

  useEffect(() => {
    if (data?.dns_server_ns_type === "new") {
      setNS1(data?.dns_ns1);
      setNS2(data?.dns_ns2);
      setNS3(data?.dns_ns3);
      setNS4(data?.dns_ns4);
      setNS5(data?.dns_ns5);
      setIP1(data?.dns_ip1);
      setIP2(data?.dns_ip2);
      setIP3(data?.dns_ip3);
      setIP4(data?.dns_ip4);
      setIP5(data?.dns_ip5);
    }
  }, []);

  return (
    <div>
      <div className="px-5 pt-4 pb-4 ">
        {openAlert && (
          <AlertDanger message={t("services:dnsChangeForm1Desc")} />
        )}

        <p className="mb-1">
          {t("services:processingHours")}
        </p>

        <div className="grid grid-cols-2 gap-5">
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="NS 1"
              placeholder="ns1.makdos.com"
              value={NS1}
              onChange={setNS1}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="IP 1"
              placeholder="999.999.999.999"
              value={IP1}
              onChange={setIP1}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="NS 2"
              placeholder="ns2.makdos.com"
              value={NS2}
              onChange={setNS2}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="IP 2"
              placeholder="999.999.999.999"
              value={IP2}
              onChange={setIP2}
            />
          </div>
          <div className="col-span-2 lg:col-span-1 ">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="NS 3"
              placeholder="ns5.makdos.com"
              value={NS3}
              onChange={setNS3}
            />
          </div>
          <div className="col-span-2 lg:col-span-1 ">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="IP 3"
              placeholder="999.999.999.999"
              value={IP3}
              onChange={setIP3}
            />
          </div>
          <div className="col-span-2 lg:col-span-1 ">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="NS 4"
              placeholder="ns4.makdos.com"
              value={NS4}
              onChange={setNS4}
            />
          </div>
          <div className="col-span-2 lg:col-span-1 ">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="IP 4"
              placeholder="999.999.999.999"
              value={IP4}
              onChange={setIP4}
            />
          </div>
          <div className="col-span-2 mb-3 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="NS 5"
              placeholder="ns5.makdos.com"
              value={NS5}
              onChange={setNS5}
            />
          </div>
          <div className="col-span-2 mb-3 lg:col-span-1">
            <TextField
              id="title"
              name="title"
              type="text"
              ref={null}
              label="IP 5"
              placeholder="999.999.999.999"
              value={IP5}
              onChange={setIP5}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
        <Button
          onClick={() => setOpenModal(false)}
          className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
          tertiary
        >
          {t("global:giveUp")}
        </Button>
        <Button onClick={onSubmit} className="w-full px-12 mr-0 lg:w-auto" primary>
          {t("global:Apply")}
        </Button>
      </div>
    </div>
  );
}

export default DNSChangeForm3;
