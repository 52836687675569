class Auth {
  constructor() {
    this.authenticated = "";
  }

  login() {
    this.authenticated = true;
  }

  logout() {
    localStorage.removeItem("__mp_tk");
    localStorage.removeItem("__is_logged_in");
    localStorage.removeItem("logo");
    localStorage.removeItem("domain_name");
    this.authenticated = false;
  }

  isAuthenticated() {
    this.authenticated = localStorage.getItem("__is_logged_in");
    return this.authenticated;
  }
}

export default new Auth();
