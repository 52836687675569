import React from "react";

function PhoneCode({ value, onChange, type, top, disabled }) {
  return type === "file" && !disabled ? (
    <div>
      <label htmlFor="file-input">
        <i
          className={
            "ri-attachment-line absolute cursor-pointer text-primary-hover text-sm ml-2 " +
            top
          }
        />
      </label>
      <input
        id="file-input"
        type="file"
        className="hidden"
        onChange={(e) => {
          if (e.target.files?.length > 0) onChange(e.target.files[0]);
        }}
      />
    </div>
  ) : (
    <></>
  );
}

export default PhoneCode;
