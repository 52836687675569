import React, { useEffect } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import AlertWarning from "components/ContentAlerts/AlertWarning";
import { getProjectList } from "services/allServices";
import { Link } from "react-router-dom";

function ChangeProductProjectModal({ setOpenModal, setOpenWarningModal, data }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const language = localStorage.getItem("ln");

  const [selected, list, changeSelected, setList] = useComboBox(
    "Proje Seçiniz"
  );


  const onListProject = () => {
    getProjectList().then((res) => {
      setList(res, "id", "name");
      let findProject = res?.find((item) => item.id === data.project_id);
      changeSelected(findProject?.id, findProject?.name);
    });
  };
  useEffect(() => {
    onListProject();
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:changeProductProject")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <div className="">
                    <ComboBox
                      id="select"
                      name="select"
                      selected={selected}
                      setSelected={changeSelected}
                      list={list}
                      label={t("services:yourProjects")}
                      defaultText="Seçiniz"
                    />
                  </div>
                  <div className="mt-3">
                    <AlertWarning
                      title={t("services:beCareful")}
                      message={t("services:beCarefulDesc")}
                    />
                  </div>

                  <div className="my-3">
                    <div className="w-full px-2 py-2 border rounded-md bg-blueAlertBg border-blueAlertBorder">
                      <div className="flex items-center">
                        <div>
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11 11.5V17.5H13V11.5H11ZM11 7.5V9.5H13V7.5H11Z"
                              fill="#44B1FF"
                            />
                          </svg>
                        </div>

                        <div className="ml-3">
                          <p className="mb-1 font-semibold text-alert-blue">
                          {t("global:information")}
                          </p>

                         {language === 'tr' ? <p className="mr-5 text-xs font-semibold ">
                            Eğer yeni bir proje içerisine taşımak istiyorsanız{" "}
                            <Link to={"/newproject"}>
                              <a href="/newproject" className="text-xs cursor-pointer text-primary-hover">
                                buradan
                              </a>
                            </Link>

                            {" "} yeni bir proje oluşturabilirsiniz.
                          </p> : <p> If you want to move it into a new project you can create a new project  {" "}
                          <Link to={"/newproject"}>
                              <a href="/newproject" className="text-xs cursor-pointer text-primary-hover">
                                here.
                              </a>
                            </Link>
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      setOpenWarningModal(selected.id);

                    }}
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeProductProjectModal;
