import React from 'react'

const GeneralTerms = () => {
  return (
    <div>
            Tüm paketlerimiz için Kullanıcı Sözleşmesi haricinde aşağıda yer alan kullanım maddeleri geçerlidir.
            <br />
            <br />
            1- Tüm paketlerimiz için hesap başına e-posta kotası 250 MB'dir. E-Posta gönderim limiti 50 Adet / 1 Saat' e kadardır. Makdos Bilişim saatlik gönderimlerde değişikliğe gidebilir.
            <br />
            2- Paketler dosya paylaşımı, veri deposu, yedekleme gibi web hosting kavramına aykırı düşecek amaçlar için kullanılamaz.
            <br />
            3- Paketler toplu / spam e-posta gönderimleri için kullanılamaz. makdos.com gerekli gördüğünde saatlik gönderimlerde kısıtlamaya gidebilir.
            <br />
            4- Müşteri sahip olduğu verileri yedeklemekle yükümlüdür. Olası veri kayıplarından firmamız sorumlu tutulamaz.
            <br />
            5- Ücretsiz site taşıma hizmeti için %100 taşıma garantisi verilememektedir.
            <br />
            6- Sınırsız özellikler iyi niyetli kullanım esasına dayanmaktadır. Buradaki amaç müşterilerimizin herhangi bir limite takılmadan iyi niyet çerçevesinde paketlerini kullanmalarıdır. Paketlerde bu esasa bağlı CPU limiti uygulaması mevcuttur.
            <br />
            7- Paketlerimizde aksi belirtilmedikçe CPU kullanım limiti bir işlemci çekirdeğinin %25'i kadardır. Belirli paketlerde bu oran %50'ye kadar çıkabilmektedir.
            <br />
            8- Makdos Bilişim belli aralıklarla kaynak kullanımını (CPU, dosya adedi / boyutu, MySQL işlemleri vb.) takip edebilir ve müşteriden kaynaklarını düşürmesini isteyebilir.
            <br />
            9- Makdos Bilişim'nin neden göstermeksizin her zaman hizmeti sonlandırma ve iade hakkı vardır.
            <br />
            10- Makdos Bilişim, hosting paketlerinde ve bu paketleri etkileyen maddelerde ve tüm diğer unsurlarda değişiklik yapma hakkını saklı tutar.
            <br />
            11- Makdos Bilişim, 3RD program kurmak, düzenlemek veya konfigürasyon işlemlerini yapmak ile yükümlü değildir. Kurulum talepleri, Makdos Bilişim Hizmetleri insiyatifinde gerçekleştirilebilir.
            <br />
            12- Makdos Bilişim tarafından kurulan 3RD yazılımların oluşturacağı sorun ve veri kayıpları firmamız sorumluluğu altında değildir.
            <br />
            <br />


            <b>Kullanımına İzin Verilmeyen İçerikler</b>
            <br />
            Aşağıda sıralanan sitelerin / içeriklerin sunucularımızda barındırılmasına izin verilmemektedir.
            <br />
            <br />
            • Toplist Siteleri
            <br />
            • Sunucularda DOWNLOAD / UPLOAD yapmak,
            <br />
            • IRC Scriptleri / Botlar
            <br />
            • IRCD (IRC Sunucuları)
            <br />
            • Proxy Scriptleri / Anonimleştiriciler
            <br />
            • Resim Scriptleri (Photobucket veya Tinypic gibi)
            <br />
            • Otomatik Sörf / PTC / PTS / PPC Siteleri
            <br />
            • IP Tarayıcılar
            <br />
            • Bruteforce Programları / Scriptleri / Uygulamaları
            <br />
            • E-Posta Bombalayıcılar / Spam Scriptleri
            <br />
            • Banner Dönüşüm Ağları (Ticari banner dönüşüm siteleri)
            <br />
            • Dosya Yükleme / Mirror Scriptleri (Rapidshare, Hotfile gibi)
            <br />
            • Ticari Ses / Radyo yayını yapan siteler
            <br />
            • Haciz / Banka borç senetleri
            <br />
            • Yüksek faiz programları (HYIP) veya ilişkili siteler
            <br />
            • Yatırım siteleri (FOREX, E-altın borsası, İkinci Hayat/Linden Borsası, Ponzi, MLM / Saadet Zinciri)
            <br />
            • Devlet tarafından kontrol edilen maddelerin satışı için gerekli izinlerin bulunmaması
            <br />
            • Yüksek faizli bankacılık programları
            <br />
            • Loto / Kumar Siteleri
            <br />
            • MUDs / RPGs / PBBGs
            <br />
            • Hacker odaklı siteler / arşivler / programlar
            <br />
            • DDoS Attacker vb. saldırı amacıyla kullanılan programlar
            <br />
            • Yasadışı aktiviteleri teşvik eden siteler
            <br />
            • Warez / korsan / yasa dışı içerikleri dağıtan veya bağlantı veren forumlar veya diğer internet siteleri
            <br />
            • Banka borç senetleri ve bu senetlerin takas programları
            <br />
            • Sahte Siteler (aa419.org & escrow-fraud.com adreslerinde belirtilenler dahil ancak kısıtlamayan şekilde)
            <br />
            • Mailer Pro
            <br />
            • Butona tıklayarak mail gönderme yazılımları
            <br />
            • Canlı spor müsabakalarının yayınlanması (UFC, NASCAR, FIFA, NFL, MLB, NBA, WWE, WWF vb.)
            <br />
            • Otomatik arkadaşa gönder yazılımları
            <br />
            • Anonim veya toplu SMS gönderme uygulamaları
            <br />
            • Bitcoin Madencileri
            <br />
            • PayDay Borç siteleri (PayDay borçları veya borç ortaklıkları ile ilgili programlar)
            <br />
            • Çocuk pornografisi
            <br />
            Çocuklara ve yaşı küçüklere potansiyel olarak zarar verebilecek çocuk pornografisi dahil diğer tüm içerikler kesinlikle yasaktır. Çocuk pornografisi veya bağlantılarını içeren herhangi bir web sitesinin, bildirim olmaksızın derhal yayını durdurulacaktır.
            <br />
            <br />
            <b>Politikalar</b> 

            <br />
            1- Tüm kullanıcılarımıza sorunsuz ve yüksek standartlarda hizmet sağlamak en büyük önceliğimizdir. Paket detaylarında sözü edilen kurallar ve limitler bu öncelik kapsamında belirlenmiştir. Buna göre; disk, trafik, CPU ve diğer kaynakların tüketimlerinin ana sistemimizi ve bu sistemi paylaşan kullanıcılarımızı herhangi bir şekilde olumsuz yönde etkileyecek düzeyde olmasına izin verilmez.
            <br />
            2- Bireysel ve kurumsal tüm kullanıcılarımız sağlıklı bir e-posta hizmeti kullanma hakkına sahiptir. Spama karşı sahip olduğumuz sıfır tolerans politikamız gibi aldığımız diğer tüm önlemler bu hizmetin sürekliliğini sağlamak üzeredir.
            <br />
            3- Hangi hizmete sahip olursa olsunlar verilerinin kullanıcılarımız için değerli olabileceginin farkındayız. Ancak bu degerin ne kadar oldugunu bilemeyiz. Kullanicilarimizdan bu degerin hosting hizmet bedelinin aylik degerinden daha fazla olmasi durumunda bize bildirmelerini bekleriz. Bu nedenle kullanicilarimizin yedekleme hizmeti alsalar dahi duzenli olarak bilgisayarlarina sitelerinin yedegini almasini tavsiye ederiz. Her kullanıcımızın, aldığı hizmet ne olursa olsun yedek alma sorumluluğunun %100 kendisine ait oldugunu bilmesini isteriz. Olası veri kayıpları durumunda bizden ek yedekleme hizmeti satın alınmamış olsa dahi, elimizde sistem hatalarına karşı alınmış yedeklerin mevcut olması halinde bu yedekleri kendileriyle paylaşmaktan mutluluk duyarız.
            <br />
            4- Paketlerimizin ve paketlerin sahip olduğu kaynakların, limitlerin her zaman iyi niyetli kullanım esasına dayalı olarak kontrol edildiğini, tüm işlemlerin yine bu esas göz önünde bulundurularak gerçekleştirildiğini belirtmek isteriz.
            <br />
            5- makdos.com adı altında hizmet alan hiç bir müşteri bu yapı altındaki diğer herhangi bir müşteriye kötü amaçlı kullanılan programlar(DDoS vb.) aracılığıyla etkileşimde bulunamaz. Tespit edildiği an hizmeti sorgusuz sonlandırılır.
            <br />
            <br />
            <b>Makdos Bilişim Teknolojileri</b> 
        </div>
  )
}

export default GeneralTerms