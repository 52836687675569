import React from "react";

function RadioButton({ id, name, label, isCheck, setIsCheck }) {
  return (
    <div>
      <input
        type="radio"
        id={id}
        value={id}
        name={name}
        checked={isCheck}
        onClick={(e) => setIsCheck(e)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default RadioButton;
