import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import { useComboBox } from "hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generatePassword } from "helpers/helpersFunc";

function VirtualEditVPNUserModal({ setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services", "signup", "settings"]);
  const dummyDizi = [
    { id: 1, text: "Sitesi" },
    { id: 2, text: "Grafik Tasarım" },
    { id: 3, text: "Finans" }
  ];
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const [isCheck, setIsCheck] = useState("1");
  const [generatedPassword, setGeneratedPassword] = useState();

  const onGeneratePassword = () => {
    setGeneratedPassword(generatePassword());
  };
  const handleChange = (e) => {
    setIsCheck(e.target.value);
  };
  useEffect(() => {
    setList(dummyDizi, "id", "text");
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:editUser")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="w-full px-5 py-10 bg-modalBg">
                  <div className="w-full">
                    <TextField
                      label={t("global:userName")}
                      placeholder={t("global:userName")}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <TextField label={t("global:email")} placeholder="info@makdos.com" />
                  </div>
                  <div className="w-full mt-4">
                    <TextField
                      label={t("settings:password")}
                      placeholder="••••••••"
                      type="password"
                      value={generatedPassword}
                      onChange={(e) => setGeneratedPassword(e)}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <TextField
                      label={t("settings:repassword")}
                      placeholder="••••••••"
                      type="password"
                      value={generatedPassword}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-5 lg:gap-10">
                    <div className="col-span-2 lg:col-span-1">
                      <Button
                        onClick={() => onGeneratePassword()}
                        className="w-full"
                        secondary
                      >
                        {t("signup:generatepass")}
                      </Button>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <CopyToClipboard text={generatedPassword}>
                        <Button className="w-full" secondary>
                          {t("settings:copy")}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualEditVPNUserModal;
