import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import PaymentHistoryTable from "./AccountingTables/PaymentHistoryTable";
import PaymentHistoryModal from "./AccountingModals/PaymentHistoryModal";
import { getTransactionList } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import { useGlobalStore } from "store/GlobalStore";
import { useLocation } from "react-router";

function PaymentHistory() {
  const { t } = useTranslation(["projects", "global"]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [transactions, setTransactions] = useState();
  const [pageLimit, paymentHistoryFilterData, setPaymentHistoryFilterData] = useGlobalStore((state) => [state.pageLimit, state.paymentHistoryFilterData, state.setPaymentHistoryFilterData]);
  const location = useLocation();
  const [numberOfPages, setNumberOfPages] = useState(1);

  const onGetTransactionList = (page, limit, configureFilter) => {
    getTransactionList(page, limit, configureFilter ? configureFilter : paymentHistoryFilterData).then((res) => {
      if (res?.status === "transaction_not_found") {
        setTransactions("transaction_not_found");
      } else {
        setTransactions(res);
        setNumberOfPages(res[0]?.number_of_pages);
      }
    })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    const asyncFunction = async () => {
      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }

      let configureFilter = {
        payment_method: null,
        price: null,
        payment_date: null
      };
      if (!paymentHistoryFilterData || paymentHistoryFilterData == "") {
        await setPaymentHistoryFilterData(configureFilter);
        await onGetTransactionList(page, pageLimit, configureFilter);

      } else {
        await onGetTransactionList(page, pageLimit, paymentHistoryFilterData);
      }

    };
    asyncFunction();
  }, []);
  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="my-5 font-semibold">{t("accounting:paymentHistory")}</h2>
        <div className="relative">
          <Button
            onClick={() => setOpenFilterModal(true)}
            className="flex items-center mr-5 px-7"
            tertiary
          >
            <i className="ri-equalizer-line" />
            <span>{t("projects:filter")}</span>
          </Button>
          {(paymentHistoryFilterData?.count && paymentHistoryFilterData?.count > 0) ?
            <div
              className="absolute flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 right-3 bg-primary-hover">
              {paymentHistoryFilterData?.count}
            </div>
            :
            <></>
          }


        </div>
      </div>
      <div className="overflow-x-auto">
        {transactions !== "transaction_not_found" && transactions ? (
          <PaymentHistoryTable
            transactions={transactions}
            onGetTransactionList={onGetTransactionList}
            numberOfPages={numberOfPages}
          />
        ) : transactions === "transaction_not_found" && transactions ? (
          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/servicesOther.svg" width="403" height="339" />
            <h1>{t("accounting:noPaymentHistory")}</h1>
            <h5>{t("global:productDesc")}</h5>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full mt-20">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                      type="Oval"
                      color="#ff7c30"
                      height={75}
                      width={75}
                      timeout={3000} //3 secs
            />
          </div>
        )}
      </div>
      {openFilterModal && (
        <PaymentHistoryModal pageLimit={pageLimit} paymentHistoryFilterData={paymentHistoryFilterData}
                             setPaymentHistoryFilterData={setPaymentHistoryFilterData} onClick={onGetTransactionList}
                             setOpenModal={() => setOpenFilterModal(false)} />
      )}
    </div>
  );
}

export default PaymentHistory;
