import React, { useState } from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";

function ManagementPortUsersTable({
                                    setOpenDeleteModal,
                                    setOpenEditModal,
                                    setDeleteID,
                                    list
                                  }) {
  const { t, i18n } = useTranslation(["projects", "global","settings"]);
  const [passID, setPassID] = useState(false);
  const data = React.useMemo(
    () => list,
    []
  );

  const columns = React.useMemo(
    () => [
   
      {
        Header: "IP",
        accessor: "management_ip"
      },
      {
        Header: t("projects:user"),
        accessor: "management_username"
      },

      {
        Header: t("serttings:password"),
        accessor: "management_password",
        Cell: password
      }
      // {
      //   Header: "Ayarlar",
      //   accessor: "col5",
      //   Cell: settings,
      // },
    ],
    [t, passID]
  );

  function password(props) {
    return (
      <div className="flex items-center space-x-2">
        <p>
          {passID === props.cell.row.original.col1
            ? props.value
            : "•••••••••••"}
        </p>
        <i
          onClick={() =>
            setPassID(
              passID === props.cell.row.original.col1
                ? ""
                : props.cell.row.original.col1
            )
          }
          className={
            passID === props.cell.row.original.col1
              ? "ri-eye-off-line cursor-pointer"
              : "ri-eye-line cursor-pointer"
          }
        ></i>
      </div>
    );
  }

  function settings(props) {
    return (
      <div className="flex items-center ">
        <i
          onClick={() => setOpenEditModal()}
          className="cursor-pointer ri-edit-line"
        ></i>
        {/* E-posta gönderecek basınca */}
        <i className="ml-3 cursor-pointer ri-mail-send-line"></i>
        <i
          onClick={() => {
            setOpenDeleteModal();
            setDeleteID(props.cell.row.original.col1);
          }}
          className="ml-3 cursor-pointer ri-delete-bin-5-line text-alert-red"
        ></i>
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
          {headerGroup.headers.map((column) => (
            <th
              className="p-3 text-sm text-left text-white bg-main"
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render("Header")}
              <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className={
              index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
            }
          >
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps()} className="p-2 text-main">
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}

export default ManagementPortUsersTable;
