import React, { useState } from "react";
import Button from "components/FormElements/Button";
import Pagination from "components/Pagination";
import LogTable from "./LogTable";
import LogFilterModal from "./LogFilterModal";
import { useTranslation } from "react-i18next";

function LogRecords() {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [paginationDisplayValue, setPaginationDisplayValue] = useState(7);
  const [paginationValue, setPaginationValue] = useState(1);
  const dummyData = ["Tüm Hizmetler", "Grafik Tasarım", "Finans"];
  const [openModal, setOpenModal] = useState(false);
  const onSetPaginationDisplayValue = (value) => {
    if (value > 20) {
      setPaginationDisplayValue(20);
    } else if (value < 7) {
      setPaginationDisplayValue(7);
    } else {
      setPaginationDisplayValue(value);
    }
  };
  const onSetPaginationValue = (value) => {
    if (value > dummyData?.length) {
      setPaginationValue(dummyData?.length);
    } else if (value < 1) {
      setPaginationValue(1);
    } else {
      setPaginationValue(value);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="font-semibold my-5">{t("projects:logRecords")}</h2>
        <Button
          onClick={() => setOpenModal(true)}
          className="flex items-center px-7"
          tertiary
        >
          <i className="ri-equalizer-line" />
          <span>{t("projects:filter")}</span>
        </Button>
      </div>
      <div className="overflow-x-auto">
        <LogTable />
      </div>
      <div className="flex justify-end">
        <Pagination
          pageItems={dummyData}
          paginationValue={paginationValue}
          setPaginationValue={(value) => onSetPaginationValue(value)}
          pageValue={paginationDisplayValue}
          setPageValue={(value) => onSetPaginationDisplayValue(value)}
        />
      </div>
      {openModal && (
        <LogFilterModal setOpenModal={(value) => setOpenModal(value)} />
      )}
    </div>
  );
}

export default LogRecords;
