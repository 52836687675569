import React from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

function VirtualIPDetailsTable({
  onOpenIP,
  onOpenRDNS,
  onOpenNote,
  setOpenIPDetailsModal,
  ipList
}) {
  const { t, i18n } = useTranslation(["projects", "global", "services","settings"]);
  const data = React.useMemo(
    () => ipList,
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t("services:ipAddress"),
        accessor: "ip_address"
      },
      {
        Header: "Subnet",
        accessor: "subnet"
      },
      {
        Header: t("services:restraint"),
        accessor: "restriction",
        Cell: tickAndCross
      },

      {
        Header: t("services:trafficRestriction"),
        accessor: "traffic_restriction",
        Cell: tickAndCross
      },
      {
        Header: "Backup",
        accessor: "backup",
        Cell: tickAndCross
      },
      {
        Header: t("projects:productCode"),
        accessor: "owned_product_code",
      },
      {
        Header: t("settings:process"),
        accessor: "protection",
        Cell: protection
      },
      {
        Header: t("global:settings"),
        accessor: "id",
        Cell: settings
      }
    ],
    [t, onOpenNote]
  );

  function settings(props) {
    return (
      <div className="flex items-center space-x-2">
        <i
          onClick={() => onOpenNote(props.cell.row.original, "note")}
          className="cursor-pointer ri-sticky-note-line "
        ></i>
        {props.row.original.is_public &&
          <p
            onClick={() => onOpenRDNS(props.cell.row.original, "rdns")}
            className="font-semibold cursor-pointer "
          >
            RDNS
          </p>
        }
        <p
          onClick={() => onOpenIP(props.cell.row.original, "ip")}
          className="font-semibold cursor-pointer "
        >
          IP
        </p>

        <div data-for={props.row.original.id.toString()}
          data-tip='' data-delay-update='1000'>
          <i
            className={`cursor-pointer ri-information-line ${props.row.original?.note != "" ? " text-primary-hover" : " text-main"}`}
          ></i>
          <ReactTooltip
            id={props.row.original.id.toString()}
            place="top"
            backgroundColor="black"
            delayUpdate={1000}
            getContent={() => { return props.row.original?.note ? <div className="whitespace-pre-wrap">{props.row.original?.note}</div> : t("services:noteDesc") }}
          />

        </div>
        {/* E-posta gönderecek basınca */}
      </div>
    );
  }

  function tickAndCross(props) {
    return (
      <div className="flex items-center justify-center ">
        {props.value === true ? (
          <i className="text-xl font-bold ri-check-line text-alert-green"></i>
        ) : (
          <i className="text-xl font-bold ri-close-line text-alert-red"></i>
        )}
      </div>
    );
  }

  function protection(props) {
    let protection = props.row.original;
    return (
      <div>
        {(protection.waf_id !== null || protection.ips_id !== null || protection.firewall_id !== null || protection.ddos_id !== null) && (
          <i className="pr-2 text-lg ri-shield-cross-line text-alert-red" />
        )}
        {protection.nat_input !== null && protection.nat_output !== null && (
          <i title={protection.nat_input} className="pr-2 text-lg ri-arrow-left-right-line" />
        )}
        {protection.nat_output !== null && protection.nat_input === null &&
          <i className="text-lg ri-arrow-right-line" />}
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="p-3 text-sm text-left text-white bg-main"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={
                (index % 2 === 0 ? " bg-white p-2" : " bg-makdos-table-tr-even p-2") +
                " hover:bg-primary-hover hover:cursor-pointer hover:text-white" +
                (row.original.ip_status == "Network"
                  ? " !bg-[#facdcd]"
                  : row.original.ip_status === "Broadcast"
                    ? " !bg-[#facdcd]"
                    : row.original.ip_status === "Gateway"
                      ? " !bg-[#facdcd]"
                      : row.original.ip_status === "Optional Gateway"
                        ? " !bg-[#abf8ff]"
                        : row.original.ip_status === "used"
                          ? " !bg-[#CAECD0]"
                          : "")

              }
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-main">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default VirtualIPDetailsTable;
