import React from "react";

function AlertInfo({ title, message, message2,message3 }) {
  return (
    <div className="w-full px-2 py-2 border rounded-md bg-blueAlertBg border-blueAlertBorder">
      <div className="flex items-center">
        <div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11 11.5V17.5H13V11.5H11ZM11 7.5V9.5H13V7.5H11Z"
              fill="#44B1FF"
            />
          </svg>
        </div>

        <div className="ml-3">
          <p className="mb-1 font-semibold text-alert-blue">{title}</p>
          <p className="text-xs">{message}</p>
          {message2 && <p className="text-xs">{message2}</p>}
          {message3 && <p className="text-xs">{message3}</p>}
        </div>
      </div>
    </div>
  );
}

export default AlertInfo;
