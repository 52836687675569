import React from "react";

function Search({ disabled, type, onClick, top }) {
  return type === "search" ? (
    <i
      className={`${
        type === "search" ? "ri-search-line" : ""
      } float-right mr-2 pl-1.5 h-6 w-8 absolute text-lg -right-1 text-primary-disabled cursor-pointer ${top} '${
        !disabled ? "visible" : "invisible"
      }`}
      onClick={() => {
        if (!disabled && typeof onClick !== "undefined")
          onClick();
      }}
    />
  ) : (
    <></>
  );
}

export default Search;
