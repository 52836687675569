import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";
import Button from "components/FormElements/Button";
import CheckBox from "components/FormElements/CheckBox";
import ComboBox from "components/FormElements/ComboBox";
import TextField from "components/FormElements/Input/TextField";
import { useComboBox } from "hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function VirtualSettings() {
  const { t } = useTranslation([ "global","services", "settings"]);

  const dummyDizi = [
    { id: 1, text: "Geçici Veri" },
    { id: 2, text: "Geçici Veri 2" },
    { id: 3, text: "Geçici Veri 3" }
  ];
  const [selected, list, changeSelected, setList] = useComboBox(t("global:choose"));

  useEffect(() => {
    setList(dummyDizi, "id", "text");
  }, []);
  return (
    <div className="mt-10">
      <div className="grid grid-cols-3 gap-10">
        <div className="col-span-3 lg:col-span-1 ">
          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:serverName")}</h3>
            <div className="opacity-25 ">
              <TextField
                id="name"
                name="name"
                type="text"
                ref={null}
                label={t("services:newServerName")}
                placeholder="Yeni Sunucu Adı Giriniz..."
              />
            </div>
            <p className="mt-4 opacity-25">{t("services:currentServerName")}: Windows</p>
            <Button className="w-full mt-5 opacity-25" secondary>
              {t("services:changeServerName")}
            </Button>

            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>
          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md mt-7">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:additional") + ' ' + t("global:settings")}</h3>
            <div className="opacity-25 ">
              <ComboBox
                id="boot"
                name="boot"
                selected={selected}
                setSelected={changeSelected}
                list={list}
                defaultText={"Seçiniz"}
                label={t("services:bootOrder")}
              />
            </div>
            <div className="mt-4 opacity-25">
              <ComboBox
                id="ISO"
                name="ISO"
                selected={selected}
                setSelected={changeSelected}
                list={list}
                defaultText="Seçiniz"
                label="ISO"
              />
            </div>
            <div className="mt-4 opacity-25">
              <ComboBox
                id="CDROM"
                name="CDROM"
                selected={selected}
                setSelected={changeSelected}
                list={list}
                defaultText="Seçiniz"
                label="CDROM"
              />
            </div>
            <div className="mt-4 opacity-25">
              <ComboBox
                id="consoleKeyboard"
                name="consoleKeyboard"
                selected={selected}
                setSelected={changeSelected}
                list={list}
                defaultText="Seçiniz"
                label={t("services:consoleKeyboard")}
              />
            </div>
            <div className="mt-4 opacity-25">
              <CheckBox id="VNC" name="VNC" label="VNC" />
            </div>
            <Button className="w-full mt-5 opacity-25" secondary>
              {t("services:saveAdditionalSettings")}
            </Button>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>

        </div>

        <div className="col-span-3 lg:col-span-1 ">
          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:changePassword")}</h3>
            <div className="opacity-25">
              <TextField
                id="pass"
                name="pass"
                ref={null}
                type="password"
                label={t("settings:password")}
                placeholder="••••••••"
              />
            </div>
            <div className="mt-4 opacity-25">
              <TextField
                id="passAgain"
                name="passAgain"
                ref={null}
                type="password"
                label={t("settings:repassword")}
                placeholder="••••••••"
              />
            </div>
            <Button className="w-full mt-5 opacity-25" secondary>
            {t("services:changePassword")}
            </Button>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>

          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md mt-7">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:VNCInformation")}</h3>
            <div className="grid grid-cols-12 mt-5 opacity-25 gap-y-1">
              <div className="col-span-4">VNC IP</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">999.999.999.999</div>

              <div className="col-span-4">VNC Port</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">4434</div>
            </div>
            <div className="opacity-25 lg:flex mt-7">
              <Button className="w-full " secondary>
                {t("services:HTMLLink")}
              </Button>
              <Button className="w-full mt-5 lg:mt-0 lg:ml-5" secondary>
                {t("services:javaLink")}
              </Button>
            </div>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>
        </div>
        <div className="col-span-3 lg:col-span-1 ">
          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md ">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:ipSettings")}</h3>
            <div className="opacity-25">
              <ComboBox
                id="IP"
                name="IP"
                selected={selected}
                setSelected={changeSelected}
                list={list}
                defaultText="Seçiniz"
                label={t("services:mainIpAddress")}
              />
            </div>

            <Button className="w-full mt-5 opacity-25" secondary>
              {t("services:changeIPAddress")}
            </Button>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />
          </div>

          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md mt-7">
            <h3 className="mb-5 font-semibold opacity-25">{t("services:SSHInformation")}</h3>
            <div className="grid grid-cols-12 mt-5 opacity-25 gap-y-1">
              <div className="col-span-4">SSH IP</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">999.999.999.999</div>

              <div className="col-span-4">SSH Port</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">4434</div>
            </div>
            <div className="opacity-25 lg:flex mt-7">
              <Button className="w-full " secondary>
              {t("services:HTMLLink")}
              </Button>
              <Button className="w-full mt-5 lg:mt-0 lg:ml-5" secondary>
              {t("services:javaLink")}
              </Button>
            </div>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />


          </div>

          <div className="relative w-full p-8 bg-white bg-opacity-25 rounded-md shadow-md mt-7">
            <h3 className="mb-5 font-semibold opacity-25 ">SSH Key</h3>
            <div className="grid grid-cols-12 mt-5 opacity-25 gap-y-1 ">
              <div className="col-span-4">SSH Key</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">999.999.999.999</div>

              <div className="col-span-4">SSH Port</div>
              <div className="col-span-1">:</div>
              <div className="col-span-7">4434</div>
            </div>
            <div className="opacity-25 lg:flex mt-7 ">
              <Button className="w-full " secondary>
              {t("services:HTMLLink")}
              </Button>
              <Button className="w-full mt-5 lg:mt-0 lg:ml-5" secondary>
              {t("services:javaLink")}
              </Button>
            </div>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualSettings;
