import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import RadioButton from "components/FormElements/RadioButton";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import { getPaymentMethodList } from "services/allServices";

function PaymentHistoryModal({
                               setOpenModal,
                               onClick,
                               pageLimit,
                               paymentHistoryFilterData,
                               setPaymentHistoryFilterData
                             }) {
  const { t, i18n } = useTranslation(["projects", "global"]);

  const [selectedPaymentMedhod, listPaymentMethod, changeSelectedPaymentMedhod, setPaymentMethodList] = useComboBox("Seçiniz");
  const [isCheck, setIsCheck] = useState("1");
  const [isCheckPrice, setIsCheckPrice] = useState("5");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");

  const handleChange = (e) => {
    setIsCheck(e.target.value);
    setDate1("");
    setDate2("");
  };

  const handleChangePrice = (e) => {
    setIsCheckPrice(e.target.value);
    setPrice1("");
    setPrice2("");
    setPrice3("");
  };


  const onApply = () => {

    let configureFilter = {
      payment_method: selectedPaymentMedhod && selectedPaymentMedhod.id != 0 ? selectedPaymentMedhod.id : null,
      price: isCheckPrice === "5" ? (price1 ? {
        fixed_price: price1,
        start_price: null,
        end_price: null
      } : null) : { start_price: price2 ? price2 : null, end_price: price3 ? price3 : null },
      payment_date: (date1 || date2) ? { start_date: date1 ? date1 : null, end_date: date2 ? date2 : null } : null
    };
    setPaymentHistoryFilterData({
      ...configureFilter,
      count: Object.entries(configureFilter).filter(item => item[1] !== null).length
    });
    onClick(1, pageLimit, configureFilter);
    setOpenModal(false);
  };

  const onClear = () => {
    let configureFilter = {
      payment_method: null,
      price: null,
      payment_date: null
    };
    setPaymentHistoryFilterData({ ...configureFilter, count: 0 });
    onClick(1, pageLimit, configureFilter);
    setOpenModal(false);

  };

  useEffect(() => {
    getPaymentMethodList().then((method) => {
      setPaymentMethodList(method.filter(item => item.method != "customer_balance"), "method", "method_name");
      if (paymentHistoryFilterData.payment_method) {
        let find = method.find(item => item.method == paymentHistoryFilterData.payment_method);
        changeSelectedPaymentMedhod(find.method, find.method_name);
      }

      if (paymentHistoryFilterData?.price) {
        if (paymentHistoryFilterData.price.fixed_price) {
          setIsCheckPrice("5");
          setPrice1(paymentHistoryFilterData.price.fixed_price);
        } else {
          setIsCheckPrice("6");
          setPrice2(paymentHistoryFilterData.price.start_price);
          setPrice3(paymentHistoryFilterData.price.end_price);
        }
      }


      if (paymentHistoryFilterData?.payment_date) {
        setIsCheck("2");
        setDate1(paymentHistoryFilterData.payment_date.start_date);
        setDate2(paymentHistoryFilterData.payment_date.end_date);
      }
    });

  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("accounting:filterPayments")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg">
                  <div className="grid w-full grid-cols-2 gap-5">
                    <div className="col-span-2 ">
                      <p>{t("accounting:paymentOptions")}</p>
                      <div>
                        <ComboBox
                          id="select"
                          name="select"
                          selected={selectedPaymentMedhod}
                          setSelected={changeSelectedPaymentMedhod}
                          list={listPaymentMethod}
                          defaultText={t("global:choose")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-5">
                    <p className="w-24 mr-10">{t("global:date")}</p>
                    <div className="w-32 mr-10">
                      <RadioButton
                        id="1"
                        name="invoices"
                        label={t("projects:useThisDay")}
                        isCheck={isCheck === "1"}
                        setIsCheck={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="2"
                        name="invoices"
                        label={t("projects:betweenDifferentDates")}
                        isCheck={isCheck === "2"}
                        setIsCheck={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  {isCheck === "2" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date1}
                          onChange={setDate1}
                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date"
                          name="date"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date2}
                          onChange={setDate2}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex items-center mt-5">
                    <p className="w-24 mr-10">{t("accounting:amount")}</p>
                    <div className="w-32 mr-10">
                      <RadioButton
                        id="5"
                        name="Amount"
                        label={t("accounting:fixedAmount")}
                        isCheck={isCheckPrice === "5"}
                        setIsCheck={(e) => handleChangePrice(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="6"
                        name="Amount"
                        label={t("accounting:betweenDifferentAmounts")}
                        isCheck={isCheckPrice === "6"}
                        setIsCheck={(e) => handleChangePrice(e)}
                      />
                    </div>
                  </div>
                  {isCheckPrice === "5" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price1}
                          onChange={setPrice1}
                        />
                      </div>
                    </div>
                  )}
                  {isCheckPrice === "6" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price2}
                          onChange={setPrice2}
                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="money"
                          name="money"
                          type="currency"
                          ref={null}
                          symbol="₺"
                          label={t("accounting:price")}
                          placeholder="100.00"
                          value={price3}
                          onChange={setPrice3}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={() => onClear()}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    secondary
                  >
                    {t("global:reset")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={onApply}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentHistoryModal;
