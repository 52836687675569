import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox, useCountry } from "hooks";
import { getCountry, updateDomainWhois } from "services/allServices";
import TextArea from "components/FormElements/Input/TextArea";

function WHOISModal({ data, onGetPageData, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "signup", "services"]);
  const dummyDizi = [
    { id: 1, text: "Sitesi" },
    { id: 2, text: "Grafik Tasarım" },
    { id: 3, text: "Finans" }
  ];
  const [disabled, setDisabled] = useState(true);

  const [
    country,
    countryStates,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    onGetCountry,
    onGetStates,
    onGetCities,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState
  ] = useCountry(disabled);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const [isCheck, setIsCheck] = useState("1");


  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [postCode, setPostCode] = useState("");
  const [address, setAddress] = useState("");

  const handleChange = (e) => {
    setIsCheck(e.target.value);
  };
  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();


  const onUpdateWhoisInformation = () => {
    let configure = {
      whois_name: name,
      whois_surname: surname,
      whois_company_name: companyName,
      whois_phone: { country_code: selectedCountryCode, phone: phoneNumber },
      whois_email: email,
      whois_country: selectedCountry,
      whois_state: selectedState,
      whois_city: selectedCity,
      whois_postal_code: postCode,
      whois_address: address

    };

    updateDomainWhois(data.id, configure).then((res) => {
      onGetPageData();
      setOpenModal(false);
    });
  };

  const onGetAllData = async () => {
    setName(data?.whois_name);
    setSurname(data?.whois_surname);
    setCompanyName(data?.whois_company_name);
    setEmail(data?.whois_email);
    setPostCode(data?.whois_postal_code);
    setAddress(data?.whois_address);
    setPhoneNumber(data?.whois_phone?.phone);

    getCountry().then(async (ct) => {
      setCountryCodeList(ct, "phone_code", "country_name");
      if (data?.whois_phone?.country_code) {
        await changeSelectedCountryCode(data?.whois_phone?.country_code.id);
      } else {
        await changeSelectedCountryCode("90");
      }
    });


    if (data.whois_country != "") {
      await setSelectedCountry(data?.whois_country?.id, data?.whois_country?.text);
      await setSelectedState(data?.whois_state?.id, data?.whois_state?.text);
      await setSelectedCity(data?.whois_city?.id, data?.whois_city?.text);
      setTimeout(() => {
        setDisabled(false);

      }, 500);
    } else {
      setSelectedCountry("TR", "Turkey");
    }

  };

  useEffect(() => {
    setList(dummyDizi, "id", "text");


    if (data) {
      onGetAllData();
    } else {
    }
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:whoIsChange")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  <p>
                   {t("services:whoIsDesc")}
                  </p>
                  <div className="grid w-full grid-cols-3 gap-5 mt-3">
                    <div className="col-span-2 lg:col-span-1">
                      <TextField
                        id="name"
                        name="name"
                        type="text"
                        ref={null}
                        label={t("global:name")}
                        placeholder={t("global:name")}
                        value={name}
                        onChange={setName}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <TextField
                        id="surname"
                        name="surname"
                        type="text"
                        ref={null}
                        label={t("global:lastName")}
                        placeholder={t("global:lastName")}
                        value={surname}
                        onChange={setSurname}

                      />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <TextField
                        id="company"
                        name="company"
                        type="text"
                        ref={null}
                        label={t("signup:companytitle")}
                        placeholder={t("signup:companytitle")}
                        value={companyName}
                        onChange={setCompanyName}
                      />
                    </div>
                    <div className="z-30 col-span-2 lg:col-span-1">
                      <div>
                        <TextField
                          id="personal_phone"
                          name="personal_phone"
                          type="phone"
                          countries={countryCodeList}
                          selectedCountry={selectedCountryCode}
                          changeSelectedCountry={changeSelectedCountryCode}
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          label={t("global:phoneNumber")}
                          placeholder={t("global:phoneNumber")}
                        />
                      </div>
                    </div>
                    <div className="z-30 col-span-2 lg:col-span-1">
                      <TextField
                        id="email"
                        name="email"
                        type="email"
                        ref={null}
                        label={t("global:email")}
                        placeholder="mail@alanadiniz.com"
                        value={email}
                        onChange={setEmail}
                      />
                    </div>
                  </div>
                  <h5 className="mt-4 mb-2 font-semibold">{t("global:addressInfo")}</h5>
                  <div className="grid grid-cols-3 gap-5 ">
                    <div className="col-span-3 lg:col-span-1">
                      <ComboBox
                        id="country"
                        name="country"
                        label={t("global:country")}
                        isSearchable
                        list={country}
                        selected={selectedCountry}
                        setSelected={setSelectedCountry}
                      />
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                      {selectedCountry &&
                        selectedCountry.text !== "Ülke Seçiniz" && (
                          <ComboBox
                            id="state"
                            name="state"
                            label={t("global:province")}
                            isSearchable
                            list={countryStates}
                            selected={selectedState}
                            setSelected={setSelectedState}
                          />
                        )}
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                      {selectedState && selectedState.text !== "İl Seçiniz" && (
                        <ComboBox
                          id="cities"
                          name="cities"
                          label={t("global:district")}
                          isSearchable
                          list={cities}
                          selected={selectedCity}
                          setSelected={setSelectedCity}
                        />
                      )}
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                      <TextField
                        id="post-code"
                        name="post-code"
                        type="text"
                        ref={null}
                        label={t("global:postCode")}
                        placeholder="34000"
                        value={postCode}
                        onChange={setPostCode}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <TextArea
                      id="customer_address"
                      name="customer_address"
                      label={t("global:openAddress")}
                      placeholder={t("global:openAddress")}
                      rows="4"
                      value={address}
                      onChange={setAddress}
                    />
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={onUpdateWhoisInformation}
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WHOISModal;
