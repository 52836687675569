import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "components";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation(["global","services", "nav"]);

  useEffect(() => {
    if (!localStorage.getItem("__is_logged_in")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex flex-col items-center h-screen lg:py-20 bg-mainBg">
      <Link to={"/dashboard"}>
        <img className="mb-10" src={"/Logo.png"} alt="logo" width={300} height={100} />
      </Link>
      <img className="mb-10" src="/notFound.svg" alt="404" width={480} height={310} />
      <h1 className="text-primary-hover">{t("global:notFoundPage")}</h1>
      <p className=""> {t("global:notFoundPageDesc")}</p>

      <Link to={"/dashboard"}>
        <Button className="mt-4" primary>
          {t("nav:homepage")}
        </Button>
      </Link>
    </div>
  );
}

export default NotFound;