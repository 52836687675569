import React from "react";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "store/GlobalStore";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";

function PaymentInformationsTable({ transactions }) {
  const { t, i18n } = useTranslation(["projects", "global"]);

  const data = React.useMemo(() => transactions, []);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Toplam",
        accessor: "content.paid_price_total",
        Cell: commissionAndFeeAndTax
      },
      {
        Header: "Ana Para",
        accessor: "content.paid_price_without_tax",
        Cell: commissionAndFeeAndTax
      },
      {
        Header: "Vergi",
        accessor: "content.paid_price_tax",
        Cell: commissionAndFeeAndTax
      },
      {
        Header: "Komisyon",
        accessor: "content.paid_price_commission",
        Cell: commissionAndFeeAndTax
      },
      {
        Header: "İşlem Ücreti",
        accessor: "content.paid_price_fee",
        Cell: commissionAndFeeAndTax
      },
      {
        Header: "Yöntem",
        accessor: "content.payment_method",
        Cell: method
      },
      {
        Header: "Durum",
        accessor: "content.is_refund",
        Cell: status
      }
    ],
    [t]
  );

  function commissionAndFeeAndTax(props) {
    if (props.value !== null && props.value !== 0) {
      return <div
        className="">{parseFloat( props.value[findCurrency(currencyType)]?.value).toFixed(2)} {currencyType} </div>;
    } else {
      return <div className="ml-2">-</div>;
    }
  }

  function status(props) {
    if (props.value === true) {
      return <div className="font-semibold text-alert-red">İade</div>;
    } else {
      return <div className="font-semibold text-alert-green">Başarılı</div>;
    }
  }

  function method(props) {
    if (props.value === "customer_balance") {
      return (
        <div className="flex justify-center">
          <a title="Kredi">
            <i className="text-lg ri-wallet-3-line"></i>
          </a>
        </div>
      );
    } else if (props.value === "cc_garanti_tl") {
      return (
        <div className="flex justify-center">
          <a title="Kredi Kartı">
            <i className="text-lg ri-secure-payment-line"></i>
          </a>
        </div>
      );
    } else if (props.value === "paypal_usa") {
      return (
        <div className="flex justify-center">
          <a title="Paypal">
            <i className="text-lg ri-paypal-line"></i>
          </a>
        </div>
      );
    } else {
      return <div className="ml-2">-</div>;
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="p-3 text-sm text-left text-white bg-main"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
          {headerGroup.headers.map((column) => (
            <th
              className="p-3 text-sm text-left text-white bg-main"
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render("Header")}
              <span className="relative flex items-center justify-between">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className={
              index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
            }
          >
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps()} className="p-2 text-main">
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}

export default PaymentInformationsTable;
