import React from "react";

function AlertWarning({ title, message, message2 }) {
  return (
    <div className="w-full py-2 px-2 rounded-md bg-yellowAlertBg border border-yellowAlertBorder">
      <div className="flex items-center">
        <div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8661 3.49996L22.3921 20C22.4799 20.152 22.5261 20.3244 22.5261 20.5C22.5261 20.6755 22.4799 20.8479 22.3921 20.9999C22.3043 21.152 22.1781 21.2782 22.0261 21.366C21.8741 21.4537 21.7016 21.5 21.5261 21.5H2.4741C2.29856 21.5 2.12612 21.4537 1.9741 21.366C1.82209 21.2782 1.69585 21.152 1.60809 20.9999C1.52032 20.8479 1.47412 20.6755 1.47412 20.5C1.47412 20.3244 1.52033 20.152 1.6081 20L11.1341 3.49996C11.2219 3.34795 11.3481 3.22172 11.5001 3.13396C11.6521 3.0462 11.8246 3 12.0001 3C12.1756 3 12.3481 3.0462 12.5001 3.13396C12.6521 3.22172 12.7783 3.34795 12.8661 3.49996ZM11.0001 16.5V18.5H13.0001V16.5H11.0001ZM11.0001 9.49996V14.5H13.0001V9.49996H11.0001Z"
              fill="#FFDF0F"
            />
          </svg>
        </div>

        <div className="ml-3">
          <p className="text-alert-yellow font-semibold mb-1">{title}</p>
          <p className="text-xs">{message}</p>
          {message2 && <p className="text-xs">{message2}</p>}
        </div>
      </div>
    </div>
  );
}

export default AlertWarning;
