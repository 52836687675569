import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import CheckBox from "components/FormElements/CheckBox";
import { useCheckList } from "hooks";
import { useAccountingStore } from "store/AccountingStore";
import { useGlobalStore } from "store/GlobalStore";
import MakdosTable from "components/MakdosTable";
import { changeCurrency } from "helpers/helpersFunc";

function InvoicesTable({ invoices, openInvoicesInformationModal, onGetInvoicesList, numberOfPages }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [check, setCheck] = useState(false);
  const [checkArr, setCheckArr] = useState([]);
  const [checkList, onSetCheckList, setCheckList] = useCheckList();
  const [setSelectedInvoices] = useAccountingStore((state) => [
    state.setSelectedInvoices
  ]);
  const [data, setData] = useState(invoices);

  const [pageLimit, currencyType, currencyRates] = useGlobalStore((state) => [
    state.pageLimit,
    state.currencyType,
    state.currencyRates
  ]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);
  const onSetCheck = () => {
    const tempArr = [];
    if (check === true) {
      setCheck(false);
      setCheckList([]);
    } else {
      setCheck(true);
      invoices.map((d) => {
        tempArr.push("proformaTable" + d.id);
      });
      setCheckList(tempArr);
    }
  };
  const columns = React.useMemo(
    () => [
      // {
      //   Header: (
      //     <CheckBox name="tableHeaderCheck" onChange={() => onSetCheck()} />
      //   ),
      //   accessor: "id2",
      //   Cell: Checkbox,
      // },
      {
        Header: t("accounting:billNumber"),
        accessor: "invoice_no"
      },
      {
        Header: t("accounting:billingAddress"),
        accessor: "billing_info.content.name"
      },
      {
        Header: t("accounting:billHistory"),
        accessor: "invoice_create_date",
        Cell: (props) => moment(props.value).format("DD-MM-YYYY")
      },
      // {
      //   Header: t("accounting:paymentDueDate"),
      //   accessor: "invoice_due_date",
      //   Cell: (props) => props.value !== null ? moment(props.value).format("DD-MM-YYYY") : "-"
      // },
      {
        Header: t("accounting:commission"),
        accessor: "paid_price_commission",
        Cell: price
      },
      {
        Header: t("accounting:processingFee"),
        accessor: "paid_price_fee",
        Cell: price
      },
      {
        Header: t("accounting:tax"),
        accessor: "paid_price_tax",
        Cell: price
      },
      {
        Header: t("accounting:amount"),
        accessor: "paid_price_without_tax",
        Cell: price
      },
      {
        Header: t("global:settings"),
        accessor: "parasut_pdf_path",
        Cell: tableButton
      }
    ],
    [t, data, currencyType]
  );



  function price(props) {
    if (props.value) {
      // return <p className="font-semibold">{changeCurrency(currencyRates, currencyType, props.value, props.row.original.company_info[0].content.company_name === "Makdos LLC" ? "USD" : "TL")} {currencyType}</p>;
      return <p className="font-semibold">{changeCurrency(currencyRates, currencyType, props.value, "TL")} {currencyType}</p>;

    }
  }



  function tableButton(props) {
    if (props.row.original.parasut_pdf_status) {
      return <a href={props.value} target="_blank">
        <i className="ri-eye-line text-main" />
      </a>
    } else {
      return (
        <i onClick={() => {
          setSelectedInvoices(props.row.original.id);
          openInvoicesInformationModal(true)
        }} className="cursor-pointer ri-eye-line text-main" />
      )



    }
  }

  return (
    <div className="w-full mb-12 ">
      <MakdosTable
        isCheckboxVisible={false}
        setCheckList={() => {
        }}
        checkList={[]}
        data={data}
        setData={setData}
        showPagination={true}
        columns={columns}
        pageLimits={pageLimits}
        isBackendPagination={true}
        backendPaginationMethod={(page, limit) => {
          onGetInvoicesList(page, limit);
        }}
        backendNumberOfPages={numberOfPages}
        activePageSize={activePageSize}
        setActivePageSize={setActivePageSize}
        isDndAvailable={false}
      />
    </div>
  );
}

export default InvoicesTable;
