import React from 'react'

const ServiceAgreement = () => {
  return (
    <div className='text-cardText'>
            <b>Hizmet Sözleşmesi </b>
            <br />
            <br />
            Bu sayfada yer alan sözleşme Makdos Bilişim hizmetlerini kullanmaya başladığınız andan itibaren sizin için de geçerli ve bağlayıcı olacaktır. Bu sözleşmeyi kabul etmeniz halinde sözleşme şartları ve sözleşme içeriğinde atıfta bulunulan Genel Kullanım Koşulları, Gizlilik Politikası, İade Şartlarını da kabul etmiş olacaksınız. Lütfen hizmetlerimizi kullanmaya başlamadan bu sözleşmeyi dikkatlice okuyunuz.

            <br />
            GİRİŞ
            Bu Siteden ve bu Sitede bulunan Hizmetlerden sadece, yürürlükte bulunan kanunlar kapsamında yasal olarak bağlayıcı sözleşmeler yapabilecek olanlar yararlanabilir. Bu Siteyi ve bu Sitede bulunan hizmetleri kullanmakla kendinizin; (i) en az on sekiz (18) yaşında olduğunuzu ve/veya (ii) yürürlükte bulunan kanunlar kapsamında yasal olarak bağlayıcı sözleşmeler yapabilme ehliyetine başkaca yollarla sahip olduğunuzu kabul beyan ve taahhüt etmektesiniz.
            <br />


            Bu Sözleşmeyi kurumsal niteliği ve/veya tüzel kişiliği olan herhangi bir kurum adına akdediyorsanız, söz konusu kurumsal yapıyı işbu Sözleşmede yer alan hüküm ve şartlara göre temsil ve ilzam etmek için gerekli yasal yetkiyi haiz olduğunuzu kabul, beyan ve taahhüt etmektesiniz. Bu durumda “Müşteri” terimi söz konusu kurumsal yapıyı ifade eder. Şayet, işbu sözleşmeyi elektronik ortamda kabul etmenizden sonra Makdos Bilişim tarafından, söz konusu kurumsal yapıyı temsil ve ilzam etmek için yasal yetkinizin olmadığı tespit edilirse, ödeme yükümlülükleri de dahil ve bununla sınırlı olmamak üzere, işbu sözleşmede yer alan yükümlülüklerden kişisel olarak sorumlu olacağınızı bildiğinizi kabul ve taahhüt etmektesiniz.

            <br />

            Her türlü tasarım, görsel, animasyon, video, ses dosyası, yazı tipi, logo, kod, çizim, derleme, sanat çalışması, arayüz, kullanıcı adı, alt alan adı oluşturmak amacıyla verdiğiniz bilgi, metin, edebi eser ve diğer bilumum materyaller (“İçerik”) dahil olmak üzere tarafınızca Kullanıcı Platformunuza yüklenen veya sağlanan ya da Makdos Bilişim tarafından sizin için Kullanıcı Platformunuza içe aktarılan, kopyalanan veya karşıya yüklenen tüm içeriğe (“Kullanıcı İçeriği”) ait olan ve bunlardan doğan tüm hakların sahibi olduğunuzu ya da Kullanıcı İçeriğinin sizin tarafınızdan ve bizim tarafımızdan veya iştiraklerimizden herhangi biri tarafından yasal olarak erişilmesi, söz konusu içeriğin içe aktarılması, kopyalanması, kullanılması, yayınlanması, devredilmesi veya lisansa bağlanması için gerekli yetki, hak, lisans, onay ve izinlerin tümüne sahip olduğunuzu onaylamaktasınız.
            <br />

            Ayrıca, Kullanıcı İçeriğinizi içe aktarmak, kopyalamak, sergilemek, karşıya yüklemek, iletmek ve/veya diğer bir şekilde kullanmak için Makdos Bilişimin her türlü web sitesine, web sayfasına ve/veya başka bir çevrimiçi hizmete erişilmesine izin vermek için gerekli tam yetkiye, mülkiyete, lisanslara, izinlere ve salahiyete sahip olduğunuzu (ve sahip olmaya devam edeceğinizi) taahhüt etmektesiniz.

            <br />

            Kullanıcı İçeriğinin doğru, güncel ve sahih olduğunu, üçüncü kişilerin hiçbir hakkını ihlal etmediğini ve gerek tarafınızın gerekse Kullanıcı Platformunuzun ziyaretçi ve kullanıcılarının ikamet ettiği ülkede karşıya yüklemeniz, içe aktarmanız, kopyalamanız, bulundurmanız, yayımlamanız, iletmeniz, görüntülemeniz veya diğer bir şekilde kullanmanız açısından ve ayrıca Makdos Bilişim ve/veya Nihai Kullanıcılarınızın Makdos Bilişim Hizmetleriyle bağlantılı olarak erişmesi, içe aktarması, kopyalaması, karşıya yüklemesi, kullanması veya bulundurması açısından herhangi bir şekilde hukuka aykırılık teşkil etmediğini (ve öyle kalmaya devam edeceğini) kabul ve beyan etmektesiniz.
            <br />

            Sözleşmedeki diğer taahhütlere ek olarak, kerhangi bir kişisel bilginin ve/veya Kullanıcı İçeriğinin bir parçası olan herhangi bir kişi, kurum ya da mülkün görüntü veya tasvirinin ilan edilmesi, iletilmesi ve yayımlanması için yürürlükteki kanunlar kapsamında gerekli olan tüm onay ve izinleri temin etmiş olduğunuzu ve bu konuda geçerli tüm kanunlara uyacağınızı, herhangi bir kişisel bilginin ve/veya Kullanıcı İçeriğinin bir parçası olan herhangi bir kişi, kurum ya da mülkün görüntü veya tasvirinin ilan edilmesi, iletilmesi ve yayımlanması için yürürlükteki kanunlar kapsamında gerekli olan tüm onay ve izinleri temin etmiş olduğunuzu ve bu konuda geçerli tüm kanunlara uyacağınızı da beyan, kabul ve taahhüt etmektesiniz.

            <br />


            Makdos Bilişim, kaynağı kurumsal yapınızın yetkili bir temsilcisinden geldiği izlenimini makul surette uyandıracak herhangi bir talimata, ihbara, belgeye ya da benzeri iletişime itimat etmesi sonucu meydana gelecek hiçbir kayıp ya da zarardan sorumlu olmayacaktır. Bu talimat, ihbar, belge ya da iletişimin sahihliği hakkında makul şüphe olması durumunda, Makdos Bilişim ek olarak söz konusu bilgilerin sıhhatini onaylamanızı isteyebilir. Ancak bu onaylama talebi hiçbir şekilde Makdos Bilişim‘in mükellefiyeti olarak kabul edilemez.

            Makdos Bilişim yürürlükteki mevzuata uygun şekilde Gizlilik Politikası, İade Şartları ve Genel Kullanım Koşullarında değişiklik yapabilir. Bu takdirde, değişiklikler, değişikliğin web sitesinde yer aldığı tarih itibariyle yürürlüğe girmiş sayılır.

            <br />

            <br />
            <b>  1- TARAFLAR</b>
            <br />

            1.1. Bu sözleşme hizmetler bölümünde belirtilen hizmetleri sağlayan 0611062852800010 mersis numaralı MAKDOS BİLİŞİM TEKNOLOJİLERİ SANAYİ TİCARET LTD. ŞTİ. ( “Makdos Bilişim” olarak anılacaktır ) ile yeni müşteri kaydı bölümünde belirtilen detayları ile belirtilen kişi/kurum (“Müşteri” olarak anılacaktır) arasında https://www.makdos.com adresinde faaliyet gösteren internet sitesinin (“Site” olarak anılacaktır) kullanımı ve bu site üzerinden satın alınacak hizmetleri aşağıda belirtilen madde ve koşullar ile imza altına alınmış sayılacaktır.

            <br />
            1.2. Müşteri, işbu Sözleşmenin kuruluş aşamasında beyan ettiği TC Kimlik Numarası dahil tüm kimlik bilgilerinin, işbu Sözleşmenin geçerli bir şekilde kurulması için zorunlu ve önkoşul niteliğinde olduğunu kabul eder. Müşteri’ye ait kimlik bilgilerinin yanlış veya eksik olduğunun sonradan tespit edilmesi halinde, Makdos Bilişim’in yapacağı bildirim üzerine, söz konusu yanlışlık veya eksiklik müşteri tarafından 2 gün içerisinde giderilecektir. Aksi halde, Makdos Bilişim, işbu sözleşmeyi haklı sebeple ve tazminat ve benzeri herhangi bir yükümlülük doğmaksızın derhal feshetme hakkına sahiptir. Müşteri, Makdos Bilişim’in, söz konusu bilgilerdeki yanlışlık veya eksikliklerden kaynaklanan her türlü zararını tazmin etmeyi kabul ve taahhüt eder.

            <br />
            1.3. İşbu Sözleşme metninde Makdos Bilişim ve Müşteri tek başına “Taraf” ve müştereken “Taraflar” olarak anılacaktır.

            <br />
            1.4. Taraflar işbu Sözleşmede yazılı bilgilerin doğruluğunu beyan, kabul ve taahhüt ederler.

            <br />
            <br />
            <b> 2- KONU</b>
            <br />
            2.1. İşbu Sözleşme, Müşteri tarafından site üzerindeki işlemlerinde, siparişlerinde, gönderdiği mesajlarında kayıt işleminde vermiş olduğu bilgiler doğrultusunda yapmaya ve yapmamaya izni olan bölümleri, siparişlerinde tercihleri uyarınca alacağı hizmetler karşılığında Makdos Bilişim'e ödeyeceği ücretleri düzenleyecektir. Bu hizmet ve işlem açıklamaları aşağıda belirtilmektedir.
            <br />
            2.2. Üyelik bilgileri Müşteri tarafından üye olunma sırasında girilen bilgilerdir. Bu bilgiler yapılan işlemlerde baz alınacağından Müşteri ve üyenin bu bilgileri hatasız eksiksiz ve doğru girdiği varsayılmaktadır. Bilgilerin yanlış, eksik veya güncel olmaması sebebiyle meydana gelecek zarardan dolayı Müşteri sorumludur. Bilgilerin yanlış, eksik veya güncel olmaması halinde Makdos Bilişim, hizmeti, kendi belirleyeceği süre zarfında askıya alabilir.
            <br />

            <br />
            <b> 3- TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</b>
            <br />
            3.1. Makdos Bilişim, müşterinin talebi üzerine sipariş olarak ilettiği hizmetleri sağlayacaktır. Makdos Bilişim, müşterinin siparişini aldıktan sonra siparişi kısmen veya tamamen kabul ettiğine dair Siparişin kabulü mesajı gönderecektir. Makdos Bilişim ilgili hizmet karşılığı ücretin tahsil edilmesi kaydıyla söz konusu siparişte belirtilen hizmeti vereceğini taahhüt edecektir.
            <br />

            Müşteri, Hizmetlerden faydalanmak için kayıt yaptırmak veya bilgilerini vermekle, Makdos Bilişim’e (gerek doğrudan, gerekse bağlı kuruluşları, iştirakleri veya diğer üçüncü taraflar vasıtasıyla) ödeme sağlayıcısından veya Müşteri tarafından tayin edilen banka hesabından, paypal hesabından yada kredi kartından ödeme talep ve tahsil etme (veya başka şekillerde ücret yansıtma, iade yapma veya diğer faturalandırma işlemlerini gerçekleştirme) yetkisini vermiştir.

            <br />
            Müşteri, Makdos Bilişim’in veya bağlı kuruluşlarının ödemelerin zamanında yapılmasını teminen ödeme, kredi kartı, paypal hesabı veya banka hesabı sağlayıcısından güncel ödeme bilgilerinin alınmasına yönelik olanlar da dâhil olmak üzere (örneğin, kredi kartı son kullanma tarihi veya kredi kartı numarası bilgileri) Müşteri tarafından tayin edilen ödeme hesabının veya mali bilgilerin doğrulanması için gerekli olduğunu mütalaa ettiği her türlü sorgulamayı yapma konusunda yetki vermiştir.

            <br />
            3.2. Müşteri’nin Makdos Bilişim’in sunduğu aylık, yıllık ya da taraflarca karşılıklı olarak belirlenecek süre için ödeme planına göre tercih edeceği ödeme planına istinaden Makdos Bilişim, Müşteri’ye hizmet karşılığı olan KDV dahil toplam bedeli siparişin onaylanmasından sonra bildirecektir. Müşteri, Makdos Bilişim tarafından bildirilecek KDV dahil toplam bedeli peşinen kabul ettiğini beyan ve taahhüt eder. Hizmet bedelleri tahsil edilmedikçe, Makdos Bilişim’in hizmet verme yükümlülüğü bulunmamaktadır.
            <br />

            3.3. Sipariş kabulü ve işlemlerin onaylanmasından sonra Makdos Bilişim, müşteri siparişi detayında bulunan hizmete ilişkin sunucu bilgisi, kontrol panel, ftp, sql ve e-mail kullanıcı adları ve şifrelerini müşteriye iletecek ve hizmet başlamış olacaktır. İlgili hesapların ve şifrelerin sorumluğu müşterinin sorumluluğunda olup, şifrelerin yeterli güvenlik seviyesinde belirlenmemesi veya kolay tahmin edilebilir nitelikte belirlenmesi, bu şifrelerin başkasının eline geçmesi, yetkisiz kişilerce kullanımı vb. durumlarda doğabilecek zarar ve ziyandan münhasıran müşteri sorumlu olacaktır. Bu kapsamda müşterinin, Makdos Bilişim’e karşı herhangi bir talep hakkı bulunmamaktadır.
            <br />

            3.4. Müşteri aldığı hizmet dahilinde Makdos Bilişim tarafından 5651 sayılı Kanun ve ilgili mevzuat gereği Makdos Bilişim’in sorumlu olduğu tüm konular ile ilgili verilecek talimat ve uyarılarına uyacağını kabul ve taahhüt eder. Ayrıca, Müşteri, hesabından faydalanırken Makdos Bilişim tarafından web sitesinde yayınlanan ya da kendisine gönderilen her türlü ihtar ya da bildirime uymayı beyan, kabul ve taahhüt eder. Müşteri, Makdos Bilişim tarafından verilen hizmetlerin münhasıran Makdos Bilişim’in mülkiyetinde olduğunu, hizmete ilişkin tüm yazılım, program ve içeriğin fikri ve sınai mülkiyet hakkının Makdos Bilişim’e ait olduğunu kabul ve taahhüt eder.
            <br />
            Müşteri, işbu maddede sayılanlarla sınırlı olmaksızın; bu hizmetlerin aynısını veya benzerini kopyalayamaz, çoğaltamaz, üçüncü kişilere dağıtamaz, pazarlayamaz ya da satamaz.
            <br />

            3.5. Müşteri hizmet dahilinde sahip olduğu yazılım ve programları kullanarak erişim hakkı bulunmayan dosya veya programlara erişmemeyi kabul ve taahhüt eder. Aksi halde, kusuru ile ortaya çıkan zararlar ile mevcut zararların büyümesi gibi durumlarda Makdos Bilişim’in uğradığı sair her türlü zararı aynen nakden ve defaten tazmin etmeyi kabul ve taahhüt eder.
            <br />

            3.6. Müşteri sunucu, sunucu barındırma, web sitesi barındırma, alan adı veya aldığı hizmetlerin kullanılması sırasında yürürlükte olan veya sözleşme süresince yürürlüğe girecek olan vergi, harç ve benzer yükümlülüklerin kendisine ait olduğunu ve karşılayacağını kabul ve taahhüt eder.
            <br />

            3.7. Müşteri hizmeti dahilinde barındırdığı tüm dosya, doküman ve programlardan, web sitesi ve e-mail hizmetleri ile kullanacağı ve faydalanacağı tüm işlemlerden kendisinin münhasıran sorumlu olduğunu, söz konusu veri, bilgi ve beyanların yasalara aykırılığından doğabilecek tüm hukuki ve cezai sorumluluğun münhasıran kendisinde olduğunu kabul ve taahhüt eder. İş bu konuda doğabilecek hukuki ve cezai yaptırımlardan dolayı Makdos Bilişim 'e herhangi bir kusur atfedilemez. Makdos Bilişim 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkındaki Kanun gereği sayfaları göndermeden önce içeriklerinin hukuka aykırı olup olmadıklarını ve sorumluluğu gerektirip gerektirmediğini kontrol etmekle yükümlü olmadığından sayfaları gözden geçirmez, doğrulamaz, ciro etmez veya kullanıcı tarafından yapılmış sayfalar için herhangi bir şekilde bir sorumluluk almaz.

            <br />
            3.8. Makdos Bilişim, sağladığı hizmet içerisinde bulunan müşteri verilerinin hatalı kullanımlarından, veri içeriklerinden, kullanılan tüm verilerden doğabilecek hiçbir maddi veya manevi zararlardan sorumlu tutulamaz. Bu verilerin yedekleme ve saklama yükümlükleri münhasıran müşteriye aittir. Makdos Bilişim hizmetlerinde meydana gelebilecek kesinti veya veri kaybından dolayı oluşabilecek hatalardan, zarardan ve ziyandan Makdos Bilişim sorumlu değildir. Verilerin yedeklenmesi sözleşme metninde aksi belirtilmediği sürece müşterinin sorumluluğundadır.
            <br />

            3.9. Makdos Bilişim, müşteri tarafından sipariş verilmiş ve ödemesi sorunsuz olarak gerçekleştirilmiş domain name tescil işlemlerini yürütecektir. Tescil edilen ve sipariş ile kabul edilen tescil talebi ile ücreti ödenen domain name'in sahibi müşteridir. Makdos Bilişim bu konuda müşterinin talepleri doğrultusunda domain name üzerinde işlem yapabilecektir. Müşteri tarafından domain name üzerinde bir düzenleme, değişiklik ve transfer taleplerini en kısa sürede yapacaktır.

            <br />
            3.10. Makdos Bilişim, müşteriye kullandırdığı kontrol paneli, sunucu yazılımları gibi başka firmalardan alınan yazılım ve hizmetlerin kullanımı esnasında oluşacak veri kayıpları ve zararlardan sorumlu olmaz. Müşteri bu yazılımları sorumluluğu kendinde olmak üzere kullanır.
            <br />
            Ayrıca hizmet alınan sunucuya kurulacak 3. Parti yazılımlardan ve kullanılacak olan lisanslı ürünlerden Müşteri sorumludur. 3. Parti programlar hakkında Makdos Bilişim yazılım desteği vememektedir. Sunucu içerisindeki lisanslı ürünlerin tamamı Müşteri sorumluluğundadır.
            <br />

            3.11. Müşteri kendi verilerini periyodik olarak yedeklemekle yükümlüdür. Makdos Bilişim Müşteri'nin yedekleme yükümlülüğünü ihmal etmesi ya da ihlal etmesi halinde ortaya çıkacak zararlardan sorumlu değildir. Yedekleme hizmeti alınmamış hiçbir sunucu için (sunucu bilgisi verilmediğinden) , ücretsiz olarak veri kurtarma ve yedek işlemi Makdos Bilişim tarafından gerçekleştirilmemektedir.
            <br />
            Yedekleme hizmeti alan müşteri almış olduğu hizmetinde mutlaka kendisine tahsis edilen alanın en az 20% sini boş tutmakla yükümlüdür. Aksi durumda yedek almada yaşananacak sorun Makdos Bilişim’in sorumluluğunda değildir.
            <br />
            Yedekleme hizmetinden dönüş sağlanan sunucularda, dönüş yapılan sunucunun en fazla 24 saat içinde onaylanması ve geçici olarak bir sunucu istendi ise tüm dosyaların taşındığından emin olunması veya ana sunucuya geçişi sağlaması gereklidir. Eğer 24 saat içinde onay verilmez ve/veya sunucu yedek sistem üzerinden çalışmaya devam ederse sonrasında oluşabilecek sorunlardan Makdos Bilişim sorumlu değildir.
            <br />

            3.12. Makdos Bilişim web sitesini ve panelini, kısmen ya da tamamen sunduğu hizmetleri herhangi bir şekilde kopyalamak, değiştirmek, tekrar kullanmak, bunlara dayalı türev eserler yaratmak, bunları indirmek, uyarlamak, tersine mühendislik uygulamak, benzerini yapmaya çalışmak, başka bir sunucuya taşımak, tercüme etmek, derlemek, kaynak koda dönüştürmek veya parçalara ayırmak ve yukarıda anılanların herhangi birini kamuya açık olarak sergilemek, icra etmek, iletmek veya dağıtmak kesinlikle sözleşmeye aykırıdır.
            <br />

            3.13. Müşteri, herhangi bir Kullanıcı İçeriğini veya Lisanslı İçeriğini karalayıcı, hakaretamiz, müstehcen, taciz edici, tehditkâr, kışkırtıcı, istismar edici, ırkçı, saldırgan, yanıltıcı veya hileli ya da suça veya suiistimale teşvik edici olarak addedilebilecek biçimde kullanamaz. Makdos Bilişim’in veya herhangi bir üçüncü kişinin haklarını başka şekillerde ihlal eden veya önceden açık onaylarını almadan herhangi bir kişi, kurum ya da markayı kötüleyen veya küçük düşüren başka şekillerde sunamaz, iletemez veya sergileyemez.
            <br />

            3.14. Müşteri, başka bir kişi veya kurumu taklit eden veya üçüncü şahıslarla bağlantısı hakkında yanıltıcı beyanlar içerecek şekilde gerçek dışı beyanlarda bulunamaz.
            <br />

            3.15. Kullanıcı İçeriği ve kendisi tarafından oluşturulan tüm diğer materyallerle ilgili fikri mülkiyet, Müşterinin yarattığı her türlü tasarım, görsel, animasyon, video, ses dosyası, yazı tipi, logo, çizim, derleme, sanat eseri, kod, arayüz, metin ve edebi eser de dahil olmak üzere tümüyle Müşteriye aittir. Makdos Bilişim, bu şekilde üretilen içerik üzerinde mülkiyet hakkı talep etmemektedir. Makdos Bilişim sadece ve sadece hizmetleri sağlayabilmek amacı doğrultusunda, gösterim ayarlamaları yapmak, yedeklemek amacıyla çoğaltmak için gereken uygun tüm diğer teknik işlemleri ve/veya kullanımları gerçekleştirebilmek için, bulut hizmetleri ve içerik dağıtım ağları da dâhil, Müşterinin Kullanıcı İçeriğine erişebilir, platformuna yükleyebilir ve/veya kopyalayabilir.
            <br />
            3.16. Makdos Bilişim, uygun gördüğü takdirde kullanıcı Platformlarında videoların yönetimi için bir video hizmetleri sağlayabilir. Bu durumda video hizmetlerinin kullanılması bir lisans alınmasını gerektirebilir. Müşteri, faaliyetinin lisans gerektirip gerektirmediği konusunda araştırmayı yapmak, karar vermek ve gerekli lisansları almaktan bizzat sorumludur.
            <br />
            <br />
            <b> 4- MÜŞTERİ'NİN MEVZUATTAN KAYNAKLANAN SORUMLULUKLARI</b>
            <br />
            4.1. Müşteri sunucu, alan adı veya aldığı hizmetlerin kullanılması sırasında pornografik, erotik içerikte yayınlara; casino, başta loto, toto, iddia, tahmin olmak ancak bunlarla sınırlı olmamak üzere şans oyunlarına ilişkin ve/veya bu tür oyunları teşvik edici yayınlara yer vermeyeceğini; başta yürürlükte olan 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi Ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun’un 8. MADDESİ ile diğer ilgili hükümleri ve 5237 sayılı Türk Ceza Kanunu’nun 10. Bölümü ile diğer ilgili hükümleri olmak üzere ilgili veya sözleşme süresince yürürlüğe girecek olan ilgili ulusal ve uluslararası mevzuat gereği üstüne düşen yükümlülükleri tam ve eksiksiz yerine getireceğini, Müşteri’nin, kanun ve nizamlarda belirtilen yükümlülükleri ile işbu sözleşme kapsamında edim ve taahhütlerini hiç veya gereği gibi yerine getirmemesi halinde veya suç teşkil eden eylemlerde bulunduğunun fark edilmesi halinde, Makdos Bilişim'e karşı üçüncü şahıslar tarafından yöneltilecek tüm taleplerden münhasıran ve sınırsız olarak sorumlu olduğunu kabul ve taahhüt eder. Müşteri bu nedenlerle Makdos Bilişim'e ve/veya bildireceği 3. kişi ya da resmi kurum ve kuruluşlara ödemeyi ve Makdos Bilişim’in bu sebeple uğradığı sair her türlü zararı başkaca ihtara ve hükme gerek kalmaksızın aynen, nakden ve defaten tazmin etmeyi kabul ve taahhüt eder.
            <br />

            4.2. Müşteri ayrıca erişimin mümkün olduğu yabancı ülkelere ait hukuk mevzuatı kapsamında suç teşkil eden içerikte yayınlara yer vermeyeceğini kabul ve taahhüt eder. Web Sitesinde, işbu madde kapsamında yasak içerik niteliğinde olan yayınlara yer verilen başkaca web sitelerine ait linklere yer verilemez, söz konusu web sitelerinin tanıtımı yapılamaz, download izni verilemez. Web Sitesinde “.exe” uzantılı dosya kullanımı yasaktır.
            <br />

            4.3. İşbu maddeye aykırılık halinde Makdos Bilişim’in ilgili içeriği değiştirme, düzenleme, engelleme ve silme hakkı yanında Web Sitesinin yayınını durdurma, yayından kaldırma ve Sözleşmeyi tazminat ödeme veya başkaca bir yükümlülük altına girmeksizin derhal feshetme hakkı saklıdır.
            <br />
            <br />

            <b> 5- SÜRE</b>
            <br />
            5.1. İşbu Sözleşme sipariş ve ödeme işlemlerinin internet ortamından Makdos Bilişim'e iletilmesi ile birlikte tarafların belirtilen hak ve yükümlülükleri başlar.
            <br />

            5.2. Sözleşme süresi müşterinin ilgili hizmet için sipariş sırasında seçmiş olduğu ödeme periyodu kadardır.
            <br />

            5.3. Taraflar Sözleşmenin sona ermesinden 10 iş günü öncesine kadar Sözleşmenin süre sonunda sona ereceğini ihtar etmemişler ise Sözleşme aynı şart ve hükümler ile önceki sözleşme süresi kadar uzar. (Makdos Bilişim ücret konusunda meydana gelen değişiklikleri yeni sözleşme dönemine yansıtma hakkını saklı tutar.)
            <br />

            5.4. Sözleşme süresi içerisinde ya da Sözleşme süresi sona ermiş olup yeni dönem içerisinde hizmet karşılığı ödeme yapılmaması halinde, Makdos Bilişim Müşteriye ödeme yapılması hususunda 2 iş günü süre verebilir. Bu süreyi takip eden 2 iş günü içerisinde ise Müşteri tarafından halen ödeme yapılmadığı hallerde Makdos Bilişim’in hizmetini durdurduğu güne kadarki hizmet bedeli alacaklarının vade farkı ile birlikte tahsili için yasal yolları saklı olup, sağlanılan hizmet iptal edilip ve içerisindeki tüm veriler silinerek, yeni müşteriler için tekrar satışa çıkarılacaktır.


            <br />
            <br />
            <b>  6- ÜCRET</b>
            <br />
            6.1. İşbu Sözleşmede belirtilen hizmetler karşılığı olarak ödenecek ücret sipariş işlemi sırasında belirtilen miktar kadardır. Sipariş kabulü ile belirtilen ücreti ve KDV dahil toplam hizmet bedelini içeren fatura düzenlenecektir. Müşteri, fatura içeriği ve miktarına itiraz etmeyeceğini kabul ve taahhüt eder. Müşteri tarafından gerçekleştirilecek ödeme sonrasında kesilecek faturayı müteakiben hizmet aktivasyonu gerçekleşecektir.
            <br />

            6.2. Makdos Bilişim önceden haber vermeksizin fiyatlar ve tarifeler üzerinde ileriye dönük olarak değişiklik yapma hakkını saklı tutar. Müşteri işbu değişiklikler konusunda oluşabilecek değişiklikleri peşinen kabul, beyan ve taahhüt eder.
            <br />

            6.3. Türk Lirası ile satılmayan hizmetler için, ücret fatura tarihindeki merkez bankası efektif satış kuru üzerinden Türk Lirasına çevrilerek ödenir.
            <br />

            6.4. Müşteri hizmet ücretini sipariş ile birlikte aynı gün içinde havale/eft yoluyla, sipariş işlemi sırasında kredi kartı veya paypal aracılığı ile veya Makdos Bilişim'e elden ödemekle yükümlüdür. Ödeme için herhangi bir karta para yüklemesi yapılmış olması, ödemenin yapıldığı anlamına gelmez. Makdos Bilişim’in tahsil edebileceği vasıtalarla ödemenin yapılması şarttır.

            <br />
            a) Kredi kartı ve paypal aracılığı ile ödemelerde Müşteri işbu Sözleşmenin imzalanması ile Makdos Bilişim’nın internet sitesi üzerinden talep ettiği ve bu site üzerinden satın aldığı tüm hizmetler için tahakkuk ettirilecek aylık/3 aylık/6 aylık/ yıllık hizmet faturaları ve ara ödeme bedelleri, belirttiği kredi kartı/paypal hesabından tahsil edileceğine peşinen muvafakat eder.
            <br />

            b) Müşteri, döviz üzerinden satışı yapılan hizmetlerin kura bağlı fiyat artışını ve bu fiyatın, ödeme gününe ait dövizin TCMB’nin belirlediği efektif satış kuru olan Türk Lira karşılığı olan tutarın kredi kartından tahsilini Makdos Bilişim’in ayrı bir ihbar ve ihtarı gerekmeksizin peşinen kabul, beyan ve taahhüt eder.

            <br />
            c) Müşteri, kredi kartı ve paypal bilgilerinde ve geçerlilik süresinde olabilecek değişiklikleri Makdos Bilişim’e yazılı olarak bildirmemesi, kredi kartından ve/veya paypal hesabından herhangi bir sebeple tahsilat yapılamaması ya da bunlara ait bilgilerin gerçeğe aykırı, sahte, yanlış ya da hatalı olması durumunda oluşacak her tür hukuki, cezai ve mali sorumluluğun kendisine ait olduğunu, işbu kredi kartıyla veya paypal ile düzenli ödeme talimatını, en erken ilk tahakkuk edilecek faturanın ödendiği tarihe kadar iptal etmeyeceğini, ilgili kredi kartının/paypal hesabının ya da ödeme talimatının iptal edilmesi halinde ise iptal durumunu DERHAL Makdos Bilişim’e bildireceğini kabul ve taahhüt eder. Bu durumda Taraflarca beraber farklı bir ödeme şekli belirlenecektir. Müşteri, iptal durumunu bildirmez ise, Makdos Bilişim’in işbu Sözleşme konusu hizmetleri durdurması, askıya alması ve/veya işbu Sözleşmeyi derhal feshetme hakları ile birlikte hizmet bedeli alacağı için yasal yollara başvurma hakkı saklıdır. Müşteri, ödemenin tahsil edilememiş olması sebebi ile gerçekleştirilecek olan işlemlerin tamamı bakımından dava, şikâyet, idari yaptırım vb. yollara başvuramaz.
            <br />

            d) Ödeme metodu olarak Banka Havalesi / EFT seçilmesi durumunda Makdos Bilişim’e ait Banka hesabına, en geç 3 (üç) takvim günü içerisinde ödemenin yapılması ve hizmet numarası ile birlikte Makdos Bilişim panelinden ödeme bildiriminde bulunulması sonucu Makdos Bilişim hizmet aktivasyonunu yapar ve Müşteri’yi bilgilendirir. Müşteri Banka Havale/EFT işlemlerinde mutlaka bu panelden ödeme bildiriminde bulunması şarttır. Aksi taktirde ödeme günü geçen hizmetler için yapılacak işlemlerde Makdos Bilişimin hiç bir sorumluluğu yoktur.
            <br />
            Banka Havalesi/EFT metodu ile yapılan ödemelerde, ödeme açıklamasında sipariş kodunun belirtilmesi gerekmektedir. Ödemelere ait tüm masraflar Müşteri’ye aittir.

            <br />
            6.5. Ödemenin gecikmesi durumunda Makdos Bilişim kur veya vade farkı faturası kesme hakkını saklı tutar.
            <br />

            6.6. Makdos Bilişim, Müşteri tarafından gerçekleştirilecek ödeme sonrasında kesilecek faturayı müteakiben hizmet aktivasyonunu gerçekleştirecektir. Hizmet süresinin uzatılması için, uzama dönemine ilişkin ücretin, hizmet süresinin bitiminden önce ödenmesi zorunlu olup, Makdos Bilişim’in ilgili ödemelere ilişkin olarak hatırlatma veya haber verme yükümlülüğü bulunmamaktadır. Müşteri tarafından hizmet süresini uzatmaya yönelik söz konusu ödemenin yapılmaması halinde, işbu Sözleşme hizmet süresinin sona ermesi üzerine, kendiliğinden sona erecektir.
            <br />

            6.7. Sipariş esnasında belirtildiği gibi domain/alan adı, SSL sertifikaları, Kontrol Panelleri, Dışarıdan alınan 3. Parti programlar ve sunucu hizmetlerinde (colocation, dedicated, vps/vds sanal sunucular) işlemlerinde geri ödeme yapılmaz.
            <br />

            6.8. Müşterinin yaptığı yıllık peşin ödemelerde, almış olduğu hizmetin maliyetinde beklenmeyen artış olması durumunda oluşan maliyet farkı yıllık ödeme yapmış olan müşteriden defaten talep edilir.

            <br />
            6.9. Makdos Bilişim'in uyguladığı üyeliğe ilişkin kampanyalar, aksi bir durum web sitesi veya forumlar üzerinde belirtilmedikçe müşteriyi kapsamamakta olup, işbu Sözleşme kapsamındaki hizmetlere dahil değildir. Müşterinin, üyelik kampanyasından faydalanmak istemesi halinde, bu amaçla yapacağı sipariş/başvuru onaylanmaz, eğer ödeme yapıldı ise, bu ödeme halin icabına göre mevcut hizmet için sayılır ya da iade edilir.
            <br />

            <br />
            <b>7- HİZMETİN GEÇİCİ OLARAK VEYA TAMAMEN DURDURULMASI</b>
            <br />
            7.1. Ödeme konusunda problem yaşanması, kredi kartı veya paypal ile ödeme talimatı olan müşterilerde provizyon sorunları veya hüküm ve yükümlülükler ile ilgili maddelerden dolayı Makdos Bilişim, müşteriye verilen hizmetlerin tümünü, e-mail, web, ftp hesaplarının tamamını veya bir kısmını durdurma hakkını saklı tutar.
            <br />

            7.2. Ödemenin yapılmaması durumunda müşteri adına e-mail, web, ftp erişimleri yapılamaz ve e-mail hesapları bloklanarak gelen e-mailler geri çevrilir.

            <br />
            7.3. Sunucu üzerinde Müşteriye ait içerik ve dosyaların güvenliği müşterilerimize aittir. Dosyalara gereksiz yetki verilmesi (chmod 777) gibi durumlardan kaynaklanabilecek ya da yazılımınız ile ilgili kaynaklanabilecek herhangi bir konuda hiçbir şekilde Makdos Bilişim sorumlu değildir. Makdos Bilişim, Müşterinin sitesindeki programlama, tasarım vb. hataları düzeltmekle yükümlü değildir.

            <br />
            7.4. Müşteri kullandığı hizmetleri hack, crack, reverse engineering, mp3, mp4, video, v.b. içerik sunmak ve dağıtmak, telif haklarını ihlal edici içerik bulundurmak veya bu maddede bahsedilen tipte içerik sağlayan sitelere yönlendirmek, bilgilendirmek için kullanamaz.
            <br />

            7.5. Müşteri kendisine sağlanan e-mail hesaplarını toplu mail, spam, kimliksiz mail gönderimi için kullanamaz.

            <br />
            7.6. Paylaşımlı hizmetleri kullanan Müşteri, sistem kaynaklarını aşırı kullanan dosyalar, yazılımlar bulunduramaz, çalıştıramaz. Sunucu üzerinde site başına en fazla CPU ve RAM kullanım oranı %20 'dir. %20 'i geçen kullanıcı hesapları, ilk uyarının ardından askıya alınır.
            <br />

            7.7. Makdos Bilişim herhangi bir Müşteriye ait site ile ilgili kurum ve kuruluşlar ve/veya T.C. Mahkemeleri veya Cumhuriyet Savcıları tarafından verilecek erişimin engellenmesi – içeriğin yayından çıkarılması, talep ve kararları Müşteriye sormadan uygular. Yine Müşteri’nin kimlik bilgileri, ödeme şekilleri ve detayları, faturalar, giriş çıkış kayıtları vb. evrak ve bilgilerin Emniyet Birimleri, Savcılıklar ve Mahkemeler tarafından talep edilmesi halinde bu bilgiler Müşteri’ye bildirilmeksizin talep eden mercilerle paylaşılacaktır. Müşteri, bu kapsamda Makdos Bilişim’e kayıt olurken kullandığı bilgilerin gerçeğe uygun ve kendisine ait şekilde kullanılmak zorunda olduğunu bilmekle birlikte, gerçeğe aykırı bilgiler verilmesi halinde bundan doğan her türlü zarar, 3. Kişilerin uğraması muhtemel zararlar da dahil olmak üzere Müşteri’ye aittir. Müşteri, Makdos Bilişim’in resmi mercilerden gelen erişimin engellenmesi – içeriğin yayından çıkarılması kararına istinaden Müşteri’ye ait siteyi kapatmasından doğacak zararlardan sorumlu olmadığını, hiçbir talepte bulunamayacağını kabul ve taahhüt eder.
            <br />

            7.8. Müşteri, Makdos Bilişim sunucularında yetkisi olmayan bölümlere erişmeye çalışamaz.

            <br />
            7.9. Kısa süre içerisinde 2 defa ağını(network) ve diğer müşterilerini etkileyecek şekilde DDoS attack vb. saldırı alan sunucular derhal kapatılır, aynı şekilde kullanım adına yeniden açılabilmesi mümkün değildir. İlk saldırı neticesinde müşteri bilgilendirilir ve Müşteri saldırıya uğrayan site/program/oyun vb. içeriğin erişimini kesmekle yükümlüdür.

            <br />
            7.10. Müşteri, kullanmakta olduğu network ağından diğer müşterileri etkileyecek şekilde DDoS attack vb. saldırı çeşitlerini uyguladığı tespit edildiği an sunucu veya sunucuları derhal kapatılır, aynı şekilde kullanım adına yeniden açılabilmesi mümkün değildir.

            <br />
            <br />
            <b>  8- FESİH</b>
            <br />
            8.1. Makdos Bilişim kullanıcı hesaplarının alenen ilgili mevzuatta yer alan hükümleri ihlal ettiğini ve bir veya birkaç suç unsurunun varlığını fark etmesi veya Müşteri’nin ödeme yükümlülüğünü yerine getirmediği gibi başka sebeplerin olduğu veya Makdos Bilişim'in kendi veya kullanıcılarından herhangi birinin işine zararlı olduğuna inandığı hallerde işbu Sözleşmeyi tek taraflı olarak ve hiçbir ek yükümlülük altına girmeksizin derhal feshedebilir. Makdos Bilişim hukuka aykırılık eden filleri ve eylemleri öğrendiğinden itibaren müşteriye haber vermeden silmek hakkını saklı tutar. Bundan dolayı, Müşteri Makdos Bilişim’e hiçbir sorumluluk yükleyemez.

            <br />
            8.2. Müşteri, işbu Sözleşmenin herhangi bir maddesine aykırı davranarak başta ödeme yükümlülüğünü olmak üzere sorumluluklarını ve taahhütlerini yerine getirmediği takdirde ya da üye kaydı esnasında beyan ettiği veya daha sonradan güncellediği bilgilerin doğru olmadığının tespiti halinde, yukarıda belirtilen sözleşmeyi askıya alma halinin 3 (üç) günden fazla devam etmesi halinde, Makdos Bilişim hiçbir ihtar ve ihbara gerek kalmaksızın sözleşmeyi hiçbir ek yükümlülük altına girmeksizin tek taraflı olarak feshetme hakkına sahiptir.
            <br />

            8.3. Bu şekilde gerçekleşecek fesih sonrasında Müşteri; hizmetin kalan süresine bakılmaksızın ödemiş olduğu son sözleşme ücretini geri isteyemeyeceğini, fesih tarihinde yürürlükte olan emsal sözleşme bedelinin 5 katı cezai şart ödemeyi beyan, kabul ve taahhüt eder.
            <br />

            8.4. Müşteri haklı bir gerekçe ile işbu Sözleşmeyi süresinden evvel 30 gün önce yazılı olarak ihtar etmek şartı ile feshetme hakkına sahiptir.

            <br />
            8.5. Sözleşmenin sona erme süresinden önce ihbar süresine uymadan ve haklı bir gerekçe bildirilmeden Müşteri tarafından feshedilmesi halinde Sözleşme sonuna kadar ödenecek olan ücretlerin 1/2 sini defaten ve peşin olarak ödemeyi beyan, kabul ve taahhüt eder.

            <br />
            <br />
            <b> 9- İLETİŞİM VE BİLGİ ADRESLERİ</b>
            <br />
            9.1. Müşteri, Makdos Bilişim sistemi üzerindeki adres, telefon, e-mail gibi irtibat bilgilerini doğru olarak girmekle ve güncel tutmakla yükümlüdür.

            <br />
            9.2. Taraflar konusu sözleşmeden kaynaklanan her türlü tebligat için taraflar sipariş adresinde belirtilen posta adreslerini yasal ikametgâh olarak kabul, beyan ve taahhüt etmişlerdir.

            <br />
            9.3. Müşteri’nin işbu Sözleşmede belirtilen adresi kanuni tebligat adresi olup, söz konusu adresin değişmesi ve bu değişikliğin karşı tarafa 7 gün içerisinde yazılı olarak ve sistem üzerinden bildirilmemesi halinde, Sözleşmede belirtilen adrese yapılan tebligatlar usulüne uygun ve geçerli sayılacaktır. İhtilaf halinde, Müşteri bu adrese yapılan tebligatı almadığı, içeriğinden haberdar olmadığı iddiasında bulunamaz.
            <br />

            9.4. Makdos Bilişim Sözleşme süresi içinde Müşterinin Makdos Panel e girişte kullandığı elektronik posta adresine veya müşteri paneline mesaj, bilgi, yazı, ihtar, ödeme bildirimi, hesap hareket çizelgesi, hesap ekstresi gönderebilir. Müşteri söz konusu elektronik iletilerin alınmadığı ya da kendisine ulaşmadığı iddiasında bulunamayacağı gibi, söz konusu iletilerin gönderildikleri tarihten 1 gün sonra yasal anlamda tebliğ edilmiş sayılacağını beyan, kabul ve taahhüt eder.
            <br />
            <br />

            <b> 10- ÜCRET İADE KOŞULLARI</b>
            <br />
            10.1 Kiralık sunucu,paylaşımlı sunucu, SSL Sertifikaları, Lisanslı ürünler, alan adı, web sitesi ve hosting hizmetleri için ücret iadesi bulunmamaktadır. Sipariş ancak mücbir sebep ve/veya Müşteri kusurundan kaynaklanan sebepler haricinde Makdos Bilişim’in kusurundan dolayı hazırlanmamışsa ücret iadesi yapılabilmektedir. Sunucu barındırma hizmeti için Müşteri, aldığı hizmetlerle ilgili olarak Makdos Bilişim ana sayfasında belirtilen ve işbu Sözleşmenin eki İade Koşulları içerisinde yer alan geri ödeme süresi dolmadan önce hizmetleri kullanmaktan vazgeçip ücret iadesi isteyebilir.
            <br />

            10.2. Makdos Bilişim ücret iadesini, ücreti iade edilecek hizmetin durdurulmasından sonraki 7 iş günü içinde yapar. Müşterinin kredi kartı ile yaptığı ödeme banka sistemi, paypal ile yapılan ödeme paypal sistemi üzerinden iade edilir. İade işlemi sırasında banka sisteminden kaynaklanan masraf ve kesintilerden ve kur farklarından Makdos Bilişim sorumlu tutulamaz.
            <br />

            10.3. Makdos Bilişim eğer Müşteriye iadeye konu hizmet için fatura kesti ve ulaştırdıysa ücret iadesi esnasında bu fatura için aynı bedelle iade faturası kesilmesini isteyebilir. Müşteri iade faturası kesmezse veya Makdos Bilişim'e ulaştırmazsa ödeme yapılmaz.
            <br />
            <br />
            <b>  11- ÜCRETİN ÖDENMESİNDE TEMERRÜDE DÜŞÜLMESİ</b>
            <br />
            11.1. Müşteri, aldığı hizmetlere karşılık başvuru tarihini takip eden 7 gün içinde ödeme gerçekleştirmediği takdirde mütemerrit sayılacaktır. Bu durumda Makdos Bilişim kur farkı faturası kesebileceği gibi dilerse fatura tarihinden itibaren aylık %15 gecikme faizi talep edebilir. Müşteri, bu gecikme faizi ve kur farkı faturasını ödemeyi beyan ve kabul eder. Ancak, 3095 sayılı Kanuni Faiz ve Temerrüt Faizine İlişkin Kanun’un, ticari işlerde uygulanacak temerrüt faizi oranı, işbu madde ile kabul edilen faiz oranından daha yüksek olduğu takdirde, Taraflar, gecikme faizi oranı olarak, kanun gereği ticari işlerde uygulanacak temerrüt faizi oranın uygulanacağını kabul ederler.

            <br />
            11.2. Müşteri, işbu Sözleşmeden doğan her tür alacak için Makdos Bilişim'in dava ya da icra takibi açması halinde de aylık %15 gecikme faizi, bakiye borç miktarının %50'si kadar cezai şart, avukatlık ücreti ve diğer tüm yasal giderleri ödemeyi beyan, kabul ve taahhüt eder.
            <br />

            Müşteri, işbu Sözleşmeden doğan alacaklarının tahsili için yasal mercilere İhtiyati Haciz ve İhtiyati tedbir için başvurması halinde Makdos Bilişim'in teminatsız İhtiyati Haciz ve İhtiyati Tedbir kararı almaya yetkili olduğunu ancak buna rağmen mahkemelerce teminat istendiğinde, bankalardan alınacak teminat mektuplarından doğacak komisyon ve her türlü ücretin kendileri tarafından ödeneceğini ve bu konulara hiçbir itirazda bulunmayacağını beyan, kabul ve taahhüt eder.
            <br />
            <br />

            <b> 12- YETKİLİ MAHKEME VE İCRA DAİRELERİ</b>
            <br />
            İşbu sözleşmenin uygulanması sırasında doğacak her türlü uyuşmazlıkların çözümünde İstanbul Mahkemeleri ve İcra Daireleri yetkilidir. Tüketicilerin ayrıca Tüketici Hakem Heyetleri ve Tüketici Mahkemelerine başvuru hakkı saklıdır.
            <br />
            <br />

            <b> 13- GİZLİLİK KURALLARI</b>
            <br />


            13.1. Makdos Bilişim hizmetlerini kullanan müşterilerinin bütün isim, adres, telefon, e-mail gibi bilgilerini saklamayı ve 2. maddede zikredilen koşullar ile emniyet, mahkemeler ve savcılık makamları ile idare makamlardan talep edilmesi durumları dışında hiç kimseyle paylaşmamayı taahhüt eder.

            <br />
            13.2. Makdos Bilişim Müşteri bilgilerini Telekomünikasyon Kurumu üyeliği nedeniyle bağlayıcı kurallara tabi olduğu için, Telekomünikasyon Kurumu dahil bütün T.C. Resmi Kurum ve Kuruluşlar tarafından talep edilmesi durumunda adli birimlere verebilir.

            <br />
            13.3. Makdos Bilişim Kredi Kartı bilgilerinizi saklamaz. Bu bilgiler ödeme esnasında SSL ( Güvenli Bağlantı ) kullanılarak sadece bankaya iletilir ve bankadan geri dönen ödeme yapıldı bilgisini saklar.
            <br />

            13.4. Makdos Bilişim Müşteri itiraz etmediği sürece, hizmet verdiği sitelerin adreslerini ve hizmet türünü referans olarak sitesinde yayınlayabilir. Müşteri’nin sitesinin adresinin yayınlanmasına itiraz etmesi durumunda bu adres referans listesinden çıkarılır.
            <br />

            13.5. Makdos Bilişim Sitesinin Ödeme, Üyelik gibi güvenlik gerektiren bölümlerinde Rapid SSL tarafından sağlanan SSL Sertifikası, webinu hizmetinde ise SSL sertifikası ile güvenlik sağlar. Bunun dışında kötü niyetli sanal saldırılara karşı mümkün olan bütün yazılımsal ve donanımsal tedbirleri alır.
            <br />

            13.6. Müşteri, işbu Sözleşme kapsamındaki işin ifası sırasında ve işbu Sözleşme dolayısıyla elde edeceği, Makdos Bilişim’e ait ve Makdos Bilişim tarafından gizli bilgi olarak belirtilmiş olsun olmasın, hiçbir bilgiyi, Makdos Bilişim’in yazılı izni olmaksızın üçüncü kişi ya da kurumlara açıklamamayı ve bu tür bilgilerin gizliliğinin işbu gizlilik prensibi dahilinde korunacağını, aksi takdirde Makdos Bilişim’in bu nedenle uğrayacağı her türlü zararı ilk talep anında nakden ve defaten tazmin etmeyi kabul, beyan ve taahhüt eder.
            <br />

            <br />
            <b>  14- MÜCBİR SEBEP</b>
            <br />
            14.1. Tarafların kontrolü ve iradesi dışında gelişen ve makul denetim gücü dışında kalan ve tarafların işbu Sözleşme ile yüklendiği borçlarını yerine getirmelerini engelleyici ve/veya geciktirici önceden tahmin edilmesi mümkün olmayan, sayılanlarla sınırlı olmak kaydı ile savaş, iç savaş, terör eylemleri, deprem, yangın, sel benzeri tabi afetlerin meydana gelmesi mücbir sebep olarak değerlendirilecektir.
            <br />

            14.2. Taraflardan biri, bir mücbir sebep ortaya çıktığında zaman kaybetmeksizin bunu derhal diğer tarafa bildirmek zorundadır. Böyle bir durumla karşılaşılması halinde taraflar birlikte değerlendirme yaparak alınacak tedbirleri beraberce tespit edecek ve uygulayacaklardır. Taraflar mücbir sebep yüzünden yükümlülüklerini tam veya zamanında yerine getirememekten dolayı sorumlu tutulmayacaktır, mücbir sebep durumunun kesintisiz 30 (otuz) gün devam etmesi halinde işbu Sözleşme kendiliğinden münfesih hale gelecektir. Ancak Tarafların fesihten önce tahakkuk eden hak ve alacakları saklı kalacaktır. Makdos Bilişim mücbir sebepleri sitesinden duyurması halinde karşı tarafa yapılması gereken bildirim şartını yerine getirmiş sayılacaktır.
            <br />
            <br />

            <b>  15- DELİL SÖZLEŞMESİ</b>
            <br />
            Müşteri, işbu Sözleşmeden doğabilecek uyuşmazlıklarda Makdos Bilişim’e ait her türlü defter ve kayıtlarının geçerli, bağlayıcı, kesin ve münhasır delil olacağını kabul eder. Bu madde H.M.K 193. maddesi uyarınca yazılı delil niteliğinde olup yukarıda belirtilen delillerden başka delil ikame edilemez.

            <br />
            <br />
            <b> 16- SAİR HÜKÜMLER</b>
            <br />


            16.1. DEVİR VE TEMLİK YASAĞI
            <br />
            Taraflar, işbu sözleşme gereği hak ve yükümlülüklerini hiçbir şekilde devir ve temlik edemez. Müşteri, Makdos Bilişim adına ya da hesabına üçüncü bir şahısla bir işlem ya da anlaşma yapamaz. Bu hükme aykırı olarak devir ve temlik söz konusu olsa dahi Tarafların her türlü sorumluluğu devam eder.

            <br />

            16.2. BÖLÜNEBİLİRLİK

            <br />

            İşbu Sözleşmenin madde başlıkları sadece atıfta bulunulmasını kolaylaştırmak içindir ve Sözleşme’nin yorumunda kullanılmaz. İşbu Sözleşmedeki her bir madde, ayrı ve bağımsız bir beyan veya mutabakat olarak yorumlanacaktır. Sözleşmenin herhangi bir hükmünün yasa dışı, geçersiz ya da diğer biçimlerde icra edilemez olduğuna karar verilmesi durumunda, bu Sözleşmenin geri kalan hükümleri bundan etkilenmeyecek ve ayakta kalmaya devam edecektir.

            <br />

            16.3. FİKRİ MÜLKİYET

            <br />

            Tescil edilmiş ve/veya tescil edilebilir olsun ya da olmasın her türlü sanat eseri, grafik, görsel, web sitesi şablonu ve pencere öğesi, edebi eser, kaynak ve hedef kodu, bilgisayar kodu (html dahil), uygulama, ses, müzik, video ve diğer ortamlar, tasarım, animasyon, arayüz, dokümantasyon, bunların türevleri ve versiyonları, Makdos Bilişim Hizmetlerinin görünümü, yöntemi, ürün, algoritma, veri, etkileşimli özellikler ve nesneler, reklam ve edinme araç ve yöntemi, buluş, ticari sır, logo, alan adı, özelleştirilmiş URL, ticari marka, hizmet markası, ticari ad ve mülkiyete ilişkin diğer tanımlayıcı unsur da dahil olmak üzere telif hakkına konu edilebilir her türlü materyal veya bu materyallerin yürürlükteki herhangi bir kanun kapsamında herhangi bir telif hakkına konu olan veya olabilecek diğer herhangi bir içeriği de dâhil olmak üzere Makdos Bilişim Hizmetlerine ait olan ve bunlardan kaynaklanan tüm hak, mülkiyet ve menfaatler (bundan böyle "Fikri Mülkiyet" olarak anılacaktır) ve bunların bilumum türevleri Makdos Bilişim'e aittir.
            <br />
            Sözleşme koşullarında yer alan hiçbir husus hiçbir kanun kapsamında Makdos Bilişim'in Fikri Mülkiyet hakları bakımından herhangi bir devir veya feragat teşkil etmez.

            <br />
            <br />
            <b> 17- SON HÜKÜM</b>

            <br />
            İş bu sözleşme bununla birlikte 17 ana madde ve alt başlıklardan ibaret olup, taraflarca okunup anlaşılarak imza altına alınmıştır. (İmza altına alınma, siparişin internet ortamında Makdos Bilişim'e gönderilmesi ile gerçekleşmiş kabul edilir). Makdos Bilişim gerek gördüğü takdirde yeni maddeler ve/veya alt başlıklar ekleyebilir, çıkartabilir ya da maddeler üzerinde değişiklik yapabilir. Müşteri bu değişiklikleri önceden kabul ettiğini beyan ve taahhüt eder.

            <br />
            İade Şartları, Genel Kullanım Koşulları ve Gizlilik Politikası işbu sözleşmenin eki ve ayrılmaz parçasıdır. İşbu sözleşmenin kabul edilmiş olması, eklerinin de okunduğu ve kabul edildiği anlamına gelmektedir.
            <br />
            <br />
            <b>Makdos Bilişim Teknolojileri</b>
            <br />

            makdos.com bir Makdos Bilişim Teknolojileri San. Tic. Ltd. Şti. markasıdır.
        </div>
  )
}

export default ServiceAgreement