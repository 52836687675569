import Button from "components/FormElements/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "assets/Logo.svg";
import { useAccountingStore } from "store/AccountingStore";
import { getInvoice } from "services/allServices";
import { useNavigate } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment/min/moment-with-locales";
import { TailSpin } from "react-loader-spinner";


function InvoicesInformationModal({ setOpenModal, transactions }) {

  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [selectedInvoices] = useAccountingStore((state) => [
    state.selectedInvoices
  ]);
  const [invoicesData, setInvoicesData] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  const navigate = useNavigate();
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);
  const [setSelectedInvoices] = useAccountingStore((state) => [
    state.setSelectedInvoices
  ]);
  const onGetInvoices = async () => {
    if (selectedInvoices !== null) {
      await getInvoice(selectedInvoices, currencyType)
        .then((res) => {
          setInvoicesData(res);

          if (res.status === "invoice_not_found") {
            navigate("/accounting");
          }
        })
        .catch((err) => {
          navigate("/accounting");
        });
    }
  };


  useEffect(() => {
    onGetInvoices();
  }, []);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("accounting:bill")}
                  </h3>


                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 pt-5 pb-10 bg-modalBg ">
                  {/* <div className="h-96 mb-52">
                    <PDFViewer height="100%" style={{ border: 'none' }} width="100%">
                      <InvoicesPDF
                        invoicesData={invoicesData}
                      />
                    </PDFViewer>
                  </div> */}

                  <div className="relative w-full bg-white">
                    <div className="p-8">
                      <svg
                        className="absolute top-0 right-0"
                        width="96"
                        height="96"
                        viewBox="0 0 96 96"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M96 0H0L96 96V0Z" fill="#1ACD67" />
                      </svg>
                      <p className="absolute text-lg font-bold text-white transform rotate-45 top-5 right-3">
                        {t("accounting:paid")}
                      </p>

                      <div className="flex items-center justify-between">
                        <img className="" src={Logo} width="199" height="61" />
                        <h1 className="mr-16">{t("accounting:bills")}</h1>
                      </div>
                      {invoicesData && invoicesData?.billing_info?.length !== 0 ? (
                        <>
                          <div className="mt-10">
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="text-passive">{t("accounting:billedBy")}</p>
                                <p className="text-lg font-semibold text-title">
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.name
                                  }{" "}
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.surname
                                  }
                                </p>
                                <p className="text-main">
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.address
                                  }
                                </p>
                                <p className="text-main">
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.cities?.text
                                  }{" "}
                                  /{" "}
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.state?.text
                                  }{" "}
                                  -{" "}
                                  {
                                    invoicesData?.billing_info[0]?.billing_info?.content?.country?.text
                                  }
                                </p>
                              </div>

                              <div>
                                <p className="text-lg font-semibold text-title">
                                  {invoicesData?.company_info[0].content.company_name}{" "}
                                </p>
                                <p className="text-main">
                                  {invoicesData?.company_info[0].content.address}
                                </p>
                                <p className="text-main">
                                  {
                                    invoicesData?.company_info[0].content.cities.text
                                  }{" "}
                                  /{" "}
                                  {
                                    invoicesData?.company_info[0].content.state.text
                                  }{" "}
                                  -{" "}
                                  {
                                    invoicesData?.company_info[0].content.country.text
                                  }
                                </p>
                              </div>
                            </div>

                            <div className="w-full h-0.5 bg-borderGray mt-3 mb-3"></div>
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="text-main">
                                  {t("accounting:billNumber")}: {invoicesData?.invoice_no}
                                </p>
                                <p className="text-main">
                                  {" "}
                                  {moment(invoicesData?.time_create).format(
                                    "DD-MM-YYYY"
                                  )}
                                </p>
                              </div>
                              <p className="text-2xl font-bold text-primary-hover">
                                {parseFloat(invoicesData?.with_tax_total_basket_price).toFixed(2)}{" "}
                                $
                              </p>
                            </div>

                            <div className="grid grid-cols-12 mt-8">
                              <div className="col-span-6">
                                <p>{t("accounting:product")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:price")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:quantity")}</p>
                              </div>
                              <div className="col-span-2">
                                <p>{t("accounting:total")}</p>
                              </div>
                            </div>
                            <div className="w-full h-0.5 bg-borderGray mt-1 mb-3"></div>

                            {invoicesData?.get_basket_product?.map(
                              (product, index) => {
                                return (
                                  <div className="grid grid-cols-12 mt-2 overflow-y-auto max-h-52">
                                    <div className="col-span-6">
                                      <p className="font-semibold">
                                        {product.parasut_product_name}
                                      </p>
                                    </div>
                                    <div className="col-span-2">
                                      <p className="font-semibold">
                                        { product.without_tax_price_description}{" "}$
                                      </p>
                                    </div>
                                    <div className="col-span-2">
                                      <p className="font-semibold">
                                        1
                                      </p>
                                    </div>
                                    <div className="col-span-2">
                                      <p className="font-bold">
                                        {product.without_tax_price_description} $
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            )}

                            <div className="flex flex-col items-end justify-end mt-40">
                              <div className="flex justify-between w-2/5">
                                <p>{t("accounting:subTotal")}</p>
                                <p className="font-bold">
                                  {parseFloat(invoicesData?.without_tax_total_basket_price).toFixed(2)} $
                                </p>
                              </div>
                              <div className="flex justify-between w-2/5">
                                <p>Komisyon</p>
                                <p className="font-bold">
                                  {parseFloat(invoicesData?.commission_price).toFixed(2)} $
                                </p>
                              </div>
                              <div className="flex justify-between w-2/5">
                                <p>İşlem Ücreti</p>
                                <p className="font-bold">
                                  {parseFloat(invoicesData?.fee_price).toFixed(2)} $
                                </p>
                              </div>
                              <div className="flex justify-between w-2/5">
                                <p>{t("accounting:tax")}</p>
                                <p className="font-bold">
                                  {parseFloat(invoicesData?.tax_total_price).toFixed(2)} $
                                </p>
                              </div>
                              <div className="w-2/5 h-0.5 bg-borderGray mt-2 mb-2"></div>

                              <div className="flex justify-between w-2/5 mt-1">
                                <p>{t("accounting:total")}</p>
                                <p className="font-bold">
                                  {parseFloat(invoicesData?.with_tax_total_basket_price).toFixed(2)}{" "} $
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center justify-center w-full mt-20">
                          <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                            type="Oval"
                            color="#ff7c30"
                            height={75}
                            width={75}
                            timeout={3000} //3 secs
                          />
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="h-3 col-span-1 bg-title"></div>
                      <div className="h-3 col-span-1 bg-makdosLogoRed"></div>
                      <div className="h-3 col-span-1 bg-makdosLogoYellow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
            <Button
              onClick={() => setOpenModal(false)}
              className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
              tertiary
            >
              {t("global:giveUp")}
            </Button> */}
            {/* {invoicesData && invoicesData.billing_info?.length !== 0 &&
              <PDFDownloadLink
                document={
                  <InvoicesPDF invoicesData={invoicesData}

                  />
                }
                fileName="invoice"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                  primary
                >
                  Dosyayı İndir
                </Button>
              </PDFDownloadLink>
            } */}


          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default InvoicesInformationModal;
