import React, { useEffect, useState } from "react";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox, useCountry } from "hooks";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import { getCountry } from "services/allServices";
import TextArea from "components/FormElements/Input/TextArea";

const schema = yup.object().shape({

  address: yup.string(),
  post_code: yup.string().required(),
  company_name: yup.string().required(),
  tax_office: yup.string().required(),
  tax_no: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required()

});

function BillingCorporateForm({ setOpenModal, onCreateBillingInfo, onEditBillingInfo, datas, disableButton, setDisableButton }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const { addToast } = useToasts();
  const [disabled, setDisabled] = useState(true);

  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();
  const [
    country,
    countryStates,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    onGetCountry,
    onGetStates,
    onGetCities,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState
  ] = useCountry(disabled);
  const { t, i18n } = useTranslation(["projects", "global", "signup", "support"]);
  const [showErrorCountry, setShowErrorCountry] = useState(false);
  const [showErrorState, setShowErrorState] = useState(false);
  const [showErrorCity, setShowErrorCity] = useState(false);
  const [gsm, setGsm] = useState();

  const onSubmit = (data) => {
    if (selectedCountry.text !== "Ülke Seçiniz") {
      if (selectedState.text !== "İl Seçiniz") {
        if (selectedCity.text !== "İlçe Seçiniz") {
          let allData = {
            ...data,
            phone: { country_code: selectedCountryCode, phone: data.phone },
            country: { id: selectedCountry?.id, text: selectedCountry?.text },
            state: { id: selectedState?.id, text: selectedState?.text },
            cities: { id: selectedCity?.id, text: selectedCity?.text }
          };

          if (datas) {
            setDisableButton(true);
            onEditBillingInfo(allData);
          } else {
            setDisableButton(true);
            onCreateBillingInfo(allData);
          }
        } else {
          setShowErrorCity(true);
        }
      } else {
        setShowErrorState(true);
      }

    } else {
      setShowErrorCountry(true);
    }
  };


  const onSetLocations = async () => {
    await setSelectedCountry(datas.country.id, datas.country.text);
    await setSelectedState(datas.state.id, datas.state.text);
    await setSelectedCity(datas.cities.id, datas.cities.text);

  };

  useEffect(() => {
    const asyncFunction = async () => {
      getCountry().then((ct) => {
        setCountryCodeList(ct, "phone_code", "country_name");
      });
      if (datas) {
        reset(datas);
        changeSelectedCountryCode(datas.phone.country_code.id, datas.phone.country_code.text);
        setGsm(datas.phone.phone);
        await onSetLocations();
        setTimeout(() => {
          setDisabled(false);

        }, 500);
      } else {
        changeSelectedCountryCode("90", "Turkey");
        setDisabled(false);

      }


    };
    asyncFunction();
  }, []);

  useEffect(() => {
    if (selectedCountry.text !== "Ülke Seçiniz") {
      setShowErrorCountry(false);
    }
    if (selectedState.text !== "İl Seçiniz") {
      setShowErrorState(false);
    }
    if (selectedCity.text !== "İlçe Seçiniz") {
      setShowErrorCity(false);
    }
  }, [selectedCountry, selectedState, selectedCity]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-5 px-5 mt-5 ">
          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="company_name"
              name="company_name"
              type="text"
              label={t("signup:companytitle")}
              placeholder={t("signup:companytitle")}
              innerRef={register}

            />
            {errors.company_name && errors.company_name.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}
          </div>
          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="tax_office"
              name="tax_office"
              type="text"
              label={t("signup:companytaxadminlabel")}
              placeholder={t("signup:companytaxadminlabel")}
              innerRef={register}

            />
            {errors.tax_office && errors.tax_office.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}
          </div>
          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="tax_no"
              name="tax_no"
              type="text"
              label={t("signup:companytaxnumberlabel")}
              placeholder="9999999999"
              innerRef={register}
              maxLength={11}

            />
            {errors.tax_no && errors.tax_no.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}
          </div>

          <div className="col-span-3 lg:col-span-1">
            <ComboBox
              id="country"
              name="country"
              label={t("global:country")}
              isSearchable
              list={country}
              selected={selectedCountry}
              setSelected={setSelectedCountry}
            />
            {showErrorCountry &&
              <span className="mt-2 text-sm text-alert-red">{t("global:fieldRequired")}</span>
            }
          </div>
          <div className="col-span-3 lg:col-span-1">
            {selectedCountry &&
              selectedCountry.text !== "Ülke Seçiniz" && (
                <ComboBox
                  id="state"
                  name="state"
                  label={t("global:province")}
                  isSearchable
                  list={countryStates}
                  selected={selectedState}
                  setSelected={setSelectedState}
                />
              )}
            {showErrorState &&
              <span className="mt-2 text-sm text-alert-red">{t("global:fieldRequired")}</span>
            }
          </div>
          <div className="col-span-3 lg:col-span-1">
            {selectedState && selectedState.text !== "İl Seçiniz" && (
              <ComboBox
                id="cities"
                name="cities"
                label={t("global:district")}
                isSearchable
                list={cities}
                selected={selectedCity}
                setSelected={setSelectedCity}
              />
            )}
            {showErrorCity &&
              <span className="mt-2 text-sm text-alert-red">{t("global:fieldRequired")}</span>
            }
          </div>
        </div>

        <div className="grid grid-cols-3 gap-5 px-5 mt-5 ">

          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="post_code"
              name="post_code"
              type="text"
              label={t("global:postCode")}
              placeholder="34000"
              innerRef={register}

            />
            {errors.post_code && errors.post_code.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}

          </div>

          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="email"
              name="email"
              type="text"
              label={t("global:email")}
              placeholder={t("global:email")}
              innerRef={register}

            />
            {errors.email && errors.email.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}
          </div>

          <div className="col-span-3 lg:col-span-1">
            <TextField
              id="phone"
              name="phone"
              type="phone"
              innerRef={register}
              countries={countryCodeList}
              selectedCountry={selectedCountryCode}
              changeSelectedCountry={changeSelectedCountryCode}
              label={t("global:phoneNumber")}
              placeholder={t("global:phoneNumber")}
              value={gsm}
              onChange={setGsm}

            />
            {errors.phone && errors.phone.type === "required" && (
              <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
            )}
          </div>
        </div>
        <div className="px-5 pb-10 mt-5">
          <TextArea
            id="address"
            name="address"
            label={t("global:openAddress")}
            placeholder={t("global:openAddress")}
            rows="4"
            innerRef={register}

          />

        </div>

        <div
          className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
          <Button
            onClick={() => setOpenModal(false)}
            className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
            tertiary
          >
            {t("global:giveUp")}
          </Button>
          <Button disabled={disableButton} className="w-full px-12 mr-0 lg:w-auto" primary>
            {t("global:apply")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default BillingCorporateForm;
