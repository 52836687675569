import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import RadioButton from "components/FormElements/RadioButton";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox } from "hooks";
import { getTicketDepartmentsList, getTicketOwnedProductList } from "services/allServices";

function SupportRequestFilterModal({ setOpenModal, onClick, pageLimit, ticketFilterData, setTicketFilterData }) {
  const { t } = useTranslation(["projects", "global", "support"]);

  const [selectedProduct, productList, changeSelectedProduct, setProductList] = useComboBox(t("support:selectService"));
  const [selectedDep, depList, changeSelectedDep, setDepList] = useComboBox(t("global:department"));
  const [title, setTitle] = useState("");
  const [ID, setID] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  const [isCheck, setIsCheck] = useState("1");

  const handleChange = (e) => {
    setIsCheck(e.target.value);
    setDate1("");
    setDate2("");
  };

  const onApply = () => {
    let configureFilter = {
      title: title && title != "" ? title : null,
      ticket_code: ID && ID != "" ? ID?.trim() : null,
      department_id: selectedDep && selectedDep?.id != 0 ? selectedDep?.id : null,
      related_product_id: selectedProduct && selectedProduct?.id != 0 ? selectedProduct?.id : null,
      date: (date1 || date2) ? { start_date: date1 ? date1 : null, end_date: date2 ? date2 : null } : null

    };

    setTicketFilterData({
      ...configureFilter,
      count: Object.entries(configureFilter).filter(item => (item[1] !== null)).length
    });
    onClick(1, pageLimit, configureFilter);
    setOpenModal(false);
  };

  const onClear = () => {
    let configureFilter = {
      title: null,
      ticket_code: null,
      department_id: null,
      related_product_id: null,
      date: null
    };
    setTicketFilterData({ ...configureFilter, count: 0 });
    onClick(1, pageLimit, configureFilter);
    setOpenModal(false);

  };

  const translateDepartmentName = (name, lang) => {
    if (lang === 'tr') return name;
    switch (name) {
      case 'Muhasebe':
        return 'Accounting';
      case 'Teknik':
        return 'Technical';
      case 'Satış':
        return 'Sales';
      default:
        return name;
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem('ln');

    getTicketOwnedProductList().then((products) => {
      setProductList(products, "owned_product_id", "product_name");
      if (ticketFilterData.related_product_id) {
        let find = products.find(item => item.owned_product_id == ticketFilterData.related_product_id);
        changeSelectedProduct(find.owned_product_id, find.product_name);
      }
    });

    getTicketDepartmentsList().then((deps) => {
      const translatedDeps = deps.map((item) => ({
        ...item,
        department_name: translateDepartmentName(item.department_name, lang),
      }));
      setDepList(translatedDeps, "id", "department_name");
      if (ticketFilterData.department_id) {
        let find = translatedDeps.find(item => item.id == ticketFilterData.department_id);
        changeSelectedDep(find.id, find.department_name);
      }
    });

    if (ticketFilterData.title) {
      setTitle(ticketFilterData.title);
    }

    if (ticketFilterData.ticket_code) {
      setID(ticketFilterData.ticket_code);
    }

    if (ticketFilterData.date) {
      setIsCheck("2");
      setDate1(ticketFilterData.date.start_date);
      setDate2(ticketFilterData.date.end_date);
    }

  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}

        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("support:filterRequests")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg">
                  <div className="mb-5">
                    <TextField
                      label={t("support:subjectTitle")}
                      placeholder={t("support:subjectTitle")}
                      value={title}
                      onChange={setTitle}
                    />
                  </div>
                  <div className="grid w-full grid-cols-2 gap-5">
                    <div className="z-30 col-span-2 lg:col-span-1">
                      <TextField
                        label="ID"
                        placeholder="#999999"
                        value={ID}
                        onChange={setID}
                      />
                    </div>
                    <div className="z-20 col-span-2 lg:col-span-1">
                      <div>
                        {depList && (
                          <ComboBox
                            id="department"
                            name="department"
                            selected={selectedDep}
                            setSelected={changeSelectedDep}
                            list={depList}
                            label={t("global:department")}
                            isSearchable={false}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-span-2 lg:col-span-2">
                      <ComboBox
                        id="hizmet"
                        name="hizmet"
                        selected={selectedProduct}
                        setSelected={changeSelectedProduct}
                        list={productList}
                        label={t("support:associatedService")}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="flex items-center mt-5">
                    <p className="mr-10">{t("support:finalAnswer")}</p>
                    <div className="mr-10">
                      <RadioButton
                        id="1"
                        name="invoices"
                        label={t("projects:useThisDay")}
                        isCheck={isCheck === "1"}
                        setIsCheck={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="">
                      <RadioButton
                        id="2"
                        name="invoices"
                        label={t("projects:betweenDifferentDates")}
                        isCheck={isCheck === "2"}
                        setIsCheck={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  {isCheck === "2" && (
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date1"
                          name="date1"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date1}
                          onChange={setDate1}

                        />
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <TextField
                          id="date2"
                          name="date2"
                          type="date"
                          ref={null}
                          label={t("projects:selectDate")}
                          value={date2}
                          onChange={setDate2}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={() => onClear()}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    secondary
                  >
                    {t("global:reset")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={onApply}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportRequestFilterModal;
