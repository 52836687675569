import React, { useState } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import TextArea from "components/FormElements/Input/TextArea";

function ErrorModal({
                      title,
                      question,
                      description,
                      errDescription,
                      setOpenModal,
                      buttonText,
                      onClick,
                      reasonLabel,
                      reasonPlaceholder
                    }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [reason, setReason] = useState("");
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex justify-end px-2 py-1">
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>

            <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 58.6667C17.272 58.6667 5.33337 46.728 5.33337 32C5.33337 17.272 17.272 5.33337 32 5.33337C46.728 5.33337 58.6667 17.272 58.6667 32C58.6667 46.728 46.728 58.6667 32 58.6667ZM29.3334 40V45.3334H34.6667V40H29.3334ZM29.3334 18.6667V34.6667H34.6667V18.6667H29.3334Z"
                  fill="#FF4949"
                />
              </svg>
              <h2 className="my-1 font-semibold text-alert-red">{title}</h2>
              {question && <p>{question}</p>}
              {description && (
                <p className="mt-5 text-center lg:mx-10">{description}</p>
              )}
              {errDescription && (
                <p className="mt-5 text-center lg:mx-10">{errDescription}</p>
              )}

              {reasonLabel &&
                <div className="flex justify-center w-full mt-5">
                  <TextArea
                    id="reason"
                    name="reason"
                    label={reasonLabel}
                    placeholder={reasonPlaceholder}
                    Value={reason}
                    onChange={setReason}
                    rows="4"
                    className="w-full"
                  />
                </div>
              }

            </div>

            <div className="flex flex-col-reverse items-end justify-center px-5 py-3 bg-white lg:flex-row lg:px-0">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button
                onClick={() => onClick(reason)}
                className="w-full px-12 mr-0 lg:w-auto"
                red
                border
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
