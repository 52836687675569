import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: "01",
    Used: 50,
    Upload: 80,
    Download: 30
  },
  {
    name: "02",
    Used: 150,
    Upload: 100,
    Download: 60
  },
  {
    name: "03",
    Used: 350,
    Upload: 40,
    Download: 150
  },
  {
    name: "04",
    Used: 450,
    Upload: 300,
    Download: 100
  },
  {
    name: "05",
    Used: 50,
    Upload: 500,
    Download: 120
  },
  {
    name: "06",
    Used: 750,
    Upload: 100,
    Download: 230
  },
  {
    name: "07",
    Used: 250,
    Upload: 700,
    Download: 500
  },
  {
    name: "08",
    Used: 550,
    Upload: 100,
    Download: 360
  },
  {
    name: "09",
    Used: 50,
    Upload: 200,
    Download: 1050
  },
  {
    name: "10",
    Used: 1050,
    Upload: 300,
    Download: 1000
  },
  {
    name: "11",
    Used: 350,
    Upload: 800,
    Download: 1200
  },
  {
    name: "12",
    Used: 550,
    Upload: 100,
    Download: 130
  }
];

function VirtualMonthlyTrafficBarChart() {
  function renderColorfulLegendText(value, entry) {
    const { color } = entry;

    return <span className="text-title text-sm ">{value}</span>;
  }

  return (
    <div className="w-full h-80 relative">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={data} margin={{ left: 15 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            label={{
              value: "Mbps",
              angle: -90,
              position: "insideLeft",
              offset: -8
            }}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="right"
            iconType="circle"
            iconSize={10}
            wrapperStyle={{ paddingBottom: "15px" }}
          />
          <Bar dataKey="Upload" fill="#24B3DD" />
          <Bar dataKey="Download" fill="#FF4949" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VirtualMonthlyTrafficBarChart;
