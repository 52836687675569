import React, { useEffect, useState } from "react";
import Validation from "components/Validation";
import AlertWarning from "components/ContentAlerts/AlertWarning";
import AlertInfo from "components/ContentAlerts/AlertInfo";
import Button from "components/FormElements/Button";
import { accountDisableCheckCode, accountDisableSendCode, logout } from "services/allServices";
import { useToasts } from "react-toast-notifications";
import { useAuthStore } from "store/AuthStore";
import { useTranslation } from "react-i18next";


function AccountDisableOtpModal({ setOpenModal }) {
    const { t } = useTranslation(["global", "settings", "accounting"]);

    const [mailOTP, setMailOTP] = useState("");
    const [smsOTP, setSMSOTP] = useState("");
    const [mailCheck, setMailCheck] = useState(false);
    const [smsCheck, setSMSCheck] = useState(false);
    const { addToast } = useToasts();
    const [setIsLoggin, setUserInformations, userInformations] = useAuthStore((state) => [state.setIsLoggin, state.setUserInformations, state.userInformations]);


    const onAccountDisableCheckCode = async (type) => {
        await accountDisableCheckCode(type, type === "email" ? mailOTP : smsOTP).then(res => {
            if (res?.status === "code_verified") {
                if (type === "email") {
                    setMailCheck(true);
                } else {
                    setSMSCheck(true);
                }
            } else if (res?.status === "code_not_found") {
                addToast(t("setting:wrongCode"), {
                    appearance: "error",
                    autoDismiss: true
                });
            }
            else if (res?.status === "code_expired") {
                addToast(t("settings:expiredCode"), {
                    appearance: "error",
                    autoDismiss: true
                });
            }
        })
    }

    const onLogout = () => {
        logout()
            .then((data) => {
                if (data.status === "logout") {
                    setIsLoggin(false);
                    setUserInformations([]);
                    let language = localStorage.getItem("ln");
                    localStorage.clear();
                    localStorage.setItem("ln", language);
                }
            })
            .catch((err) => {
                setIsLoggin(false);
                setUserInformations([]);
                let language = localStorage.getItem("ln");
                localStorage.clear();
                localStorage.setItem("ln", language);
            });
    };

    useEffect(() => {
        accountDisableSendCode()
    }, [])



    useEffect(() => {
        if (userInformations?.customer_info?.customer_phone?.country_code?.id == "90" ? (mailCheck && smsCheck) : (mailCheck)) {
            setOpenModal(false);
            onLogout();
            addToast(t("settings:closedAccount"), {
                appearance: "success",
                autoDismiss: true
            });
        }
    }, [mailCheck, smsCheck])


    return (
        <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-modalBg">
                        <div className="flex justify-between px-2 py-2">
                            <p className="text-xl font-semibold ">{t("settings:closeAccount")}</p>
                            <i
                                onClick={() => setOpenModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-passive"
                            ></i>
                        </div>

                        <div className="px-5 pt-4 pb-10 bg-modalBg">
                            <AlertInfo title={t("accounting:information")}
                                message={t("settings:verifyMailAndPhone")}
                                message2={t("settings:verifyMailAndPhoneDesc")}
                            />
                            <div className="flex items-end justify-between mt-4">
                                <div>
                                    <p className="mb-1">{t("settings:emailCode")}</p>
                                    <Validation
                                        value={mailOTP}
                                        onChange={(v) => setMailOTP(v.toUpperCase())}
                                        autoFocus
                                        inputClassName={
                                            "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                                        }
                                        OTPLength={6}
                                        otpType="string"
                                        disabled={false}
                                        secure
                                    />
                                </div>

                                {mailCheck ?
                                    <i className="text-3xl mr-14 text-primary-hover ri-check-line" />
                                    :
                                    <Button onClick={() => onAccountDisableCheckCode("email")} className="px-8 mt-3 lg:mt-0" secondary icon>
                                        <span>{t("settings:verify")}</span>
                                    </Button>

                                }

                            </div>

                            {userInformations?.customer_info?.customer_phone?.country_code?.id == "90" &&
                                <div className="flex items-end justify-between mt-4">
                                    <div>
                                        <p className="mb-1">{t("settings:smsCode")}</p>
                                        <Validation
                                            value={smsOTP}
                                            onChange={(v) => setSMSOTP(v.toUpperCase())}
                                            autoFocus
                                            inputClassName={
                                                "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                                            }
                                            OTPLength={6}
                                            otpType="string"
                                            disabled={false}
                                            secure
                                        />
                                    </div>

                                    {smsCheck ?
                                        <i className="text-3xl mr-14 text-primary-hover ri-check-line" />
                                        :
                                        <Button onClick={() => onAccountDisableCheckCode("sms")} className="px-8 mt-3 lg:mt-0" secondary icon>
                                            <span>{t("settings:verify")}</span>
                                        </Button>

                                    }
                                </div>
                            }


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountDisableOtpModal;
