import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TextModal from 'components/Modals/TextModal';
import ServiceAgreement from './Documents/ServiceAgreement';
import GeneralTerms from './Documents/GeneralTerms';
import KVKKPolicy from './Documents/KVKKPolicy';

const PrivacyPolicy = ({ isPrivacyPolicy, setIsPrivacyPolicy }) => {
    const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
    const language = localStorage.getItem("ln");
    const [openServiceAgreementsModal, setOpenServiceAgreementModal] = useState(false);
    const [openGeneralTermsModal, setOpenGeneralTermsModal] = useState(false);
    const [openKVKKPolicyModal, setOpenKVKKPolicyModal] = useState(false);

    console.log();
  return (
    <div>
        <div className="flex">
            <input type="checkbox" className="w-4 h-4 text-primary-hover border-2 border-borderGray mt-0.5  text-sm cursor-pointer rounded-md outline-none ring-0  focus:ring-0 focus:outline-none focus:border-none focus:ring-transparent" name="privacyPolicy" id="privacyPolicy" checked={isPrivacyPolicy}
                        onChange={() => setIsPrivacyPolicy(!isPrivacyPolicy)} />
            <div>
                <label  className='w-full mb-0 cursor-pointer text-main"' htmlFor="privacyPolicy">
                {
                    language === 'tr' ?
                    <span> İlgili
                        <span onClick={() => setOpenServiceAgreementModal(true)} className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        Hizmet Sözleşmelerini,
                        </span>
                        <span  onClick={() => setOpenGeneralTermsModal(true)} className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        Genel Servis Şartları Sözleşmesi
                        </span> ve
                        <span  onClick={() => setOpenKVKKPolicyModal(true)} className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        Gizlilik & KVKK politikasını 
                        </span> 
                        okudum ve kabul ediyorum.
                    </span>
                    : 
                    <span> I have read and accept the relevant 
                        <span onClick={() => setOpenServiceAgreementModal(true)}  className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        Service Agreements,
                        </span>
                        <span onClick={() => setOpenGeneralTermsModal(true)}  className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        General Terms of Service Agreement
                        </span> and
                        <span onClick={() => setOpenKVKKPolicyModal(true)}  className="mx-2 font-semibold no-underline text-cardText hover:underline">
                        Privacy & KVKK policy.
                        </span> 
                        
                    </span>
                }
                </label>
            </div>
        </div>

        {openServiceAgreementsModal && (
            <TextModal 
            title={t("accounting:serviceAgreement")}
            text={<ServiceAgreement />}
        setOpenModal={() => setOpenServiceAgreementModal(false)}
            />
        )}

        {openGeneralTermsModal && (
            <TextModal 
            title={t("accounting:generalTerms")}
            text={<GeneralTerms />}
            setOpenModal={() => setOpenGeneralTermsModal(false)}
            />
        )}

        {openKVKKPolicyModal && (
            <TextModal 
            title={t("accounting:privacyKVKKPolicy")}
            text={<KVKKPolicy />}
            setOpenModal={() => setOpenKVKKPolicyModal(false)}
            />
        )}
    </div>
  )
}

export default PrivacyPolicy