import React from "react";

function Spinner({ disabled, type, value, setValue, onCondition, top }) {
  return (
    <div
      className={
        "absolute flex flex-col right-0 text-3xl " +
        top +
        " " +
        (!disabled && type === "number" ? "visible" : "invisible")
      }
    >
      <i
        className={
          (type === "number" && "ri-arrow-drop-up-fill") +
          " mr-1 leading-3 py-0.5 pl-1.5 w-8 mb-0.5 text-primary-disabled cursor-pointer"
        }
        onClick={() => {
          setValue(value !== "" ? parseInt(value) + 1 : 1);
          typeof onCondition !== "undefined" &&
          onCondition(parseInt(value) + 1);
        }}
      />
      <i
        className={
          (type === "number" && "ri-arrow-drop-down-fill") +
          " mr-1 leading-3 py-0.5 pl-1.5 w-8 text-primary-disabled cursor-pointer"
        }
        onClick={() => {
          setValue(value !== "" ? parseInt(value) - 1 : -1);
          typeof onCondition !== "undefined" &&
          onCondition(parseInt(value) - 1);
        }}
      />
    </div>
  );
}

export default Spinner;
