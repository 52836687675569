import ComboBox from 'components/FormElements/ComboBox'
import { Button, } from "components";
import { useTranslation } from 'react-i18next';
import { useMultiselectComboBox } from 'hooks';
import { getProjectList } from 'services/allServices';
import { useEffect } from 'react';

const SupportServiceFilterModal = ({openModal, setOpenModal, supportFilterData, setSupportFilterData, onListSupport}) => {
    const { t, i18n } = useTranslation(["projects", "global", "support", "services"]);

    const [selectedProject, listProject, changeSelectedProject, setProjectList, setAllSelected] = useMultiselectComboBox("Proje Seçiniz");
  const [selectedStatus, listStatus, changeSelectedStatus, setListStatus, setAllStatusSelected] = useMultiselectComboBox("Durum Seçiniz");

  const statusArray = [
      { id: 1, text: t("services:closed") },
      { id: 2, text: t("services:waiting") },
      { id: 3, text: t("services:refunded") },
      { id: 4, text: t("services:active") },
      { id: 5, text: t("services:deleted") },
      { id: 6, text: t("services:cancelled") },
      { id: 7, text: t("services:suspended") },
  ]

  const statusCodeArray = [
      { id: 1, text: "closed" },
      { id: 2, text: "waiting" },
      { id: 3, text: "refunded" },
      { id: 4, text: "active" },
      { id: 5, text: "deleted" },
      { id: 6, text: "canceled" },
      { id: 7, text: "suspended" },
  ]

  const listProjectFunc = () => {
    getProjectList().then(async (res) => {
        await setProjectList(res, "id", "name");
    })
  }

  const onApply = () => {
    const project_id_list = selectedProject.map(proj => proj.id.toString());
    const status_list = selectedStatus.map(status => status.id);
    const isOnlyZero = project_id_list.length === 1 && project_id_list[0] === "0";
    const isOnlyZeroStatus = status_list.length === 1 && status_list[0] == "0";

    const configureFilter = {
        content: {
            project_id_list: isOnlyZero ? null : project_id_list,
            status_list: isOnlyZeroStatus ? null : statusCodeArray.filter(status => status_list.includes(status.id)).map(status => status.text)
        }
    }
    setSupportFilterData({
        ...configureFilter,
        count: Object.entries(configureFilter).filter(item => item[1] !== null).length
    });

    onListSupport(configureFilter)
    setOpenModal(false);
    };

    const onClear = () => {
        const configureFilter = {
            content: {
                project_id_list: null,
                status_list: null
            }
        }
        setSupportFilterData({ ...configureFilter, count: 0 });
        onListSupport(configureFilter)
        setOpenModal(false);
    };

    useEffect(() => {

        if (supportFilterData?.content?.project_id_list) {
            const selectedProjects = listProject.filter(project => supportFilterData.content.project_id_list.includes(project.id.toString()));
            setAllSelected(selectedProjects);
        }
    
        if (supportFilterData?.content?.status_list) {
            const selectedStatuses = statusCodeArray.filter(status => supportFilterData.content.status_list.includes(status.text));
            let newFilter = selectedStatuses.map(status => {
                return { id: status.id, text: statusArray.find(item => item.id === status.id).text }
            })
            setAllStatusSelected(newFilter);
        }
    }, [listProject, listStatus, supportFilterData])
    
    
    useEffect(() => {
        listProjectFunc();
        setListStatus(statusArray, "id", "text");
    }, []);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto"> 
    <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div
            className="inline-block overflow-visible text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div className="bg-white ">
                <div className="sm:flex sm:items-start">
                    <div className="w-full">
                        <div className="flex items-center justify-between px-5 py-3 ">
                            <h3 className="text-xl font-semibold leading-6 text-title" id="modal-headline">
                                {t("services:filterControlPanel")}
                            </h3>
                            <i
                                onClick={() => setOpenModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-main"
                            ></i>
                        </div>
                        <div className="px-5 py-5 bg-modalBg">
                            <div className="grid grid-cols-2 gap-5">
                                <div className="col-span-1">
                                    <ComboBox
                                        id="projectName"
                                        name="projectName"
                                        selected={selectedProject}
                                        setSelected={changeSelectedProject}
                                        list={listProject}
                                        label={t("services:projectName")}
                                        multiselect={true}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <ComboBox
                                        id="status"
                                        name="status"
                                        selected={selectedStatus}
                                        setSelected={changeSelectedStatus}
                                        list={listStatus}
                                        label={t("global:status")}
                                        multiselect={true}
                                        isSearchable={false}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                            <Button
                                onClick={() => setOpenModal(false)}
                                className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                                tertiary
                            >
                                {t("global:giveUp")}
                            </Button>
                            <Button
                                onClick={() => onClear()}
                                className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                                secondary
                            >
                                {t("global:reset")}
                            </Button>
                            <Button
                                className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                                primary
                                onClick={() => onApply()}
                            >
                                {t("global:apply")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default SupportServiceFilterModal