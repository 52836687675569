import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";

function DownloadModal({
                         title,
                         question,
                         description,
                         setOpenModal,
                         buttonText
                       }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex justify-end px-2 py-1">
              <i
                onClick={() => setOpenModal(false)}
                className="ri-close-line text-2xl text-passive cursor-pointer"
              ></i>
            </div>

            <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6673 50.6667H53.334V32H58.6673V53.3333C58.6673 54.0406 58.3864 54.7189 57.8863 55.219C57.3862 55.7191 56.7079 56 56.0007 56H8.00065C7.29341 56 6.61513 55.7191 6.11503 55.219C5.61494 54.7189 5.33398 54.0406 5.33398 53.3333V32H10.6673V50.6667ZM37.334 24H50.6673L32.0007 42.6667L13.334 24H26.6673V8H37.334V24Z"
                  fill="#1ACD67"
                />
              </svg>

              <h2 className="font-semibold text-alert-green my-1">{title}</h2>
              <p>{question}</p>
              <p className="mt-5 text-center lg:mx-10">{description}</p>
            </div>

            <div className="flex flex-col-reverse lg:flex-row  justify-center bg-white py-3 px-5 lg:px-0">
              <Button
                onClick={() => setOpenModal(false)}
                className="px-12 mr-0   w-full lg:w-auto"
                success
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadModal;
