import { Button } from "components";
import AddNoteModal from "components/Modals/AddNoteModal";
import DNSChangeModal from "components/Services/Domains/Modals/DNSChangeModal";
import ExtendServiceModal from "components/Services/Domains/Modals/ExtendServiceModal";
import WHOISModal from "components/Services/Domains/Modals/WHOISModal";
import BuyDNSServicesModal from "components/Services/Domains/Modals/BuyDNSServicesModal";

import React, { useEffect, useState } from "react";
import ChangeProductProjectModal from "components/Services/ServicesGlobalModals/ChangeProductProjectModal";
import WarningModal from "components/Modals/WarningModal";
import InternalTransferModal from "components/Services/ServicesGlobalModals/InternalTransferModal";
import RemoveLockModal from "components/Modals/RemoveLockModal";
import SuccessModal from "components/Modals/SuccessModal";
import ProfessionalDNSModal from "components/Services/Domains/Modals/ProfessionalDNSModal";
import ErrorModal from "components/Modals/ErrorModal";
import BuyEkstraIpModal from "../ServicesGlobalModals/BuyEkstraIpModal";
import BuyMenagementModal from "../ServicesGlobalModals/BuyMenagementModal";
import BillsModal from "../ServicesGlobalModals/BillsModal";
import moment from "moment";
import { useGlobalStore } from "store/GlobalStore";
import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";
import { cancelProduct, changeProductAutoRenewal, updateProductNote, updateProjectOwnProduct } from "services/allServices";
import AlertInfo from "components/ContentAlerts/AlertInfo";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { onChanceStatus } from "helpers/helpersFunc";
import { useNavigate } from "react-router";
import ProformaInformationModal from "components/Accounting/AccountingModals/ProformaInformationModal";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function FastTransactions({ data, onGetPageData }) {
  const { t } = useTranslation([ "global","services", "nav"]);

  const { addToast } = useToasts();
  const [openDNSChangeModal, setOpenDNSChangeModal] = useState(false);
  const [openWHOISModal, setOpenWHOISModal] = useState(false);
  const [openBillsModal, setOpenBillsModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openExtendServiceModal, setOpenExtendServiceModal] = useState(false);
  const [openBuyDNSServiceModal, setOpenBuyDNSServiceModal] = useState(false);
  const [
    openChangeProductProjectModal,
    setOpenChangeProductProjectModal
  ] = useState(false);
  const [selectedPageTab, setSelectedPageTab] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab
  ]);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openTransferWarningModal, setOpenTransferWarningModal] = useState(
    false
  );
  const [openRemoveLockModal, setOpenRemoveLockModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openProfessionalDNSModal, setOpenProfessionalDNSModal] = useState(
    false
  );
  const [openInternalTransferModal, setOpenInternalTransferModal] = useState(
    false
  );
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openEkstraIPModal, setOpenEkstraIPModal] = useState(false);
  const [openManegementModal, setOpenManegementModal] = useState(false);

  const [remaininDay, setRemaininDay] = useState();
  const [ipShow, setIpShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [openProformaInformationModal, setOpenProformaInformationModal] = useState(false);

  const navigate = useNavigate();


  const onCulculateRemainingDay = async () => {
    if (data) {
      let expire_date = moment(data.date_of_expire);
      let today = moment();
      let diff = expire_date.diff(today, "day");
      setRemaininDay(diff);
    }
  };

  

  const onUpdateProject = (project_id) => {
    updateProjectOwnProduct(project_id, data.product_type_id, data.id).then((res) => {
      setOpenChangeProductProjectModal(false);
      setOpenWarningModal(false);
      onGetPageData();
    });
   };


  const onUpdateNote = (note) => {
    updateProductNote(data.product_type_id, data.id, note).then((res) => {
      setOpenNoteModal(false);
      onGetPageData();
    });
  };

  const onCancelProduct = (reason) => {
    let params = {
      product_type_id: data.product_type_id,
      owned_product_id: data.id,
      reason: reason

    };
    if (reason != "") {
      cancelProduct(params).then((res) => {
        setOpenErrorModal(false);
        onGetPageData();
      });
    }
  };

  const onChangeProductAutoRenewal = () => {
    changeProductAutoRenewal(!data.auto_renewal, data.product_type_id, data.id).then((res) => {
      onGetPageData();
      addToast(t("global:itSaved"), {
        appearance: "success",
        autoDismiss: true
      });
    })
  }


  useEffect(() => {
    onCulculateRemainingDay();
    if (data?.status == "abuse") {
      navigate("/services");
    }
  }, [data]);

  return (
    <div className="container mt-10">

      {(data.is_cancel_requested && data.status != "canceled") &&
        <div className="mb-10">

          <AlertInfo
            title={t("services:createdCancelled")}
            message={t("services:createdCancelledDesc")}
          />
        </div>
      }

      {(data?.status == "suspended") ?
        <div className="mt-5 mb-5">
          <AlertDanger
            title={t("services:suspendedServices")}
            message={t("services:suspendedServicesDesc")}
          />
        </div>
        :
        <></>
      }
      <div className="grid grid-cols-3 gap-10 ">
        <div className="col-span-3 lg:col-span-1">
          <div className="flex flex-col items-center p-4 bg-white rounded-md shadow-md">
            <div className="flex flex-col items-center justify-center h-48 bg-no-repeat bg-servicesChart w-72">
              <p
                className={`mt-4 text-5xl font-semibold ${remaininDay < 0 ? "text-alert-red" : (remaininDay <= data?.renewal_day ? "text-alert-yellow" : "text-alert-green")}`}>
                {remaininDay}
              </p>
              <p className="mt-1 text-main">{t("services:remainingDays")}</p>
            </div>
            <div className="flex">
              <div className="flex">
                <div className="flex items-center">
                  <svg
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5.4383"
                      cy="5.01691"
                      r="4.62921"
                      fill="#1ACD67"
                    />
                  </svg>
                  <p className="ml-2 text-xs"> {t("services:activePeriod")}</p>
                </div>
              </div>
              <div className="flex ml-3">
                <div className="flex items-center">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.87628"
                      cy="4.99445"
                      r="4.62921"
                      fill="#FFC62F"
                    />
                  </svg>
                  <p className="ml-2 text-xs"> {t("services:last")} {data?.renewal_day} {t("services:day")}</p>
                </div>
              </div>
              <div className="flex ml-3">
                <div className="flex items-center">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5.02228"
                      cy="5.01691"
                      r="4.62921"
                      fill="#FF4949"
                    />
                  </svg>
                  <p className="ml-2 text-xs"> {t("services:delayPeriod")}</p>
                </div>
              </div>

            </div>

            <div className="flex flex-col items-start justify-start w-full px-4 mt-4">
              <div className="w-full">
                <p className="font-bold">{t("global:project")}</p>
                <div className="flex items-center justify-between">
                  <p>{data?.project_name}</p>
                  {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
                    <div className="w-auto">
                      <button
                        className="font-medium text-primary-hover hover:text-primary focus:outline-none"
                        onClick={() => setOpenChangeProductProjectModal(true)}
                      >
                         {t("services:manage")}
                      </button>
                    </div>
                  }
                </div>
              </div>
              {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
                <div className="w-full mt-2">
                  <p className="font-bold">{t("services:code")}</p>
                  <p className="">{data?.server_inventory_no ? data?.server_inventory_no : "-" }</p>
                </div>
              }

              <div className="w-full mt-2">
                <p className="font-bold">{t("global:status")}</p>
                <p className="text-alert-green">{onChanceStatus(data?.status)}</p>
              </div>

              {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
                <div className="w-full mt-2">
                  <p className="font-bold">{t("services:ipAddress")}</p>
                  <div className="flex items-center justify-between">
                    <p>{data?.assigned_ip ? (data?.assigned_ip + (data.assigned_ip_port ? (":" + data.assigned_ip_port) : "")) : "-"}</p>
                    {/* <div className="w-auto">
                      <button onClick={() => setIpShow(!ipShow)} className="font-medium text-primary-hover hover:text-primary focus:outline-none ">
                        {ipShow ? "Gizle" : "Göster"}
                      </button>
                    </div> */}
                  </div>
                </div>
              }


              {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
                <div className="w-full mt-2">
                  <p className="font-bold">{t("global:userName")}</p>
                  <p className="">{data?.username ? data?.username : "-"}</p>
                </div>

              }

              {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
                <div className="w-full mt-2">
                  <p className="font-bold">{t("settings:password")}</p>
                  <div className="flex items-center justify-between">
                    <p>{!passwordShow ? "•••••••••" : data?.password ? data?.password : "-"}</p>
                    <div className="w-auto">
                      <button onClick={() => setPasswordShow(!passwordShow)}
                        className="font-medium text-primary-hover hover:text-primary focus:outline-none">
                        {passwordShow ? t("services:hide"): t("services:show")}

                      </button>
                    </div>
                  </div>
                </div>
              }

              <div className="w-full mt-2">
                <p className="font-bold">{t("services:dataCenter")}</p>
                <p className="">{data?.datacenter?.display_name}</p>
              </div>

              {data.hostname &&
                <div className="w-full mt-2">
                  <p className="font-bold">Hostname</p>
                  <p className="">{data.hostname}</p>
                </div>
              }

              {data.nameserver1 &&
                <div className="w-full mt-2">
                  <p className="font-bold">Nameserver-1</p>
                  <p className="">{data.nameserver1}</p>
                </div>
              }
              {data.nameserver2 &&
                <div className="w-full mt-2">
                  <p className="font-bold">Nameserver-2</p>
                  <p className="">{data.nameserver2}</p>
                </div>
              }

              {/* <div className="w-full mt-2">
                  <p className="font-bold">Sunucu</p>
                  <p className="">{data?.server_product_id}</p>
                </div> */}

              <div className="w-full mt-2">
                <p className="font-bold">{t("services:dateOfRegistration")}</p>
                <p className="">{moment(data?.date_of_buy).format("DD-MM-YYYY HH:mm")}</p>
              </div>
              {/* <div className="w-full mt-2">
                  <p className="font-bold">Yenileme Tarihi</p>
                  <p className="">{moment(data?.date_of_renewal).format("DD-MM-YYYY HH:mm")}</p>
                </div> */}
              <div className="w-full mt-2">
                <p className="font-bold">{t("services:serviceEndDate")}</p>
                <p className="">{moment(data?.date_of_expire).format("DD-MM-YYYY HH:mm")}</p>
              </div>
              <div className="w-full mt-2">
                <p className="font-bold">{t("services:paymentCycle")}</p>
                <p
                  className="">{data?.payment_cycle <= 12 ? (data?.payment_cycle + ' ' + t("global:monthly")) : data?.payment_cycle + ' ' + t("global:yearly")}</p>
              </div>
            </div>

          </div>
          <div className="p-8 mt-6 bg-white rounded-md shadow-md">
            <h3 className="mb-3 font-semibold text-title">{t("services:automaticRenewal")}</h3>
            <p className="text-sm">{t("services:automaticRenewalDesc")}</p>
            <p className="mt-1">{t("global:status")} : <span className="font-semibold text-primary-hover">{data?.auto_renewal ? t("services:automaticRenewalOn") : t("services:automaticRenewalOff")}</span> </p>
            <div className="flex items-end justify-end w-full mt-3">
              <Button onClick={onChangeProductAutoRenewal} >
              {t("global:change")}
              </Button>
            </div>
          </div>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <div className="relative p-8 bg-white bg-opacity-25 rounded-md shadow-md">
            <h3 className="font-semibold opacity-25 text-title">Management Port</h3>
            <p className="mt-4 opacity-25">
              {t("services:noServiceforServer")}
            </p>
            <div className="flex items-end justify-end w-full mt-3 opacity-25">
              <Button
              //onClick={() => setOpenManegementModal(true)}
              >
                {t("services:buy")}
              </Button>
            </div>
            <AlertLaterInfo style={"top-1/3 lg:left-12"} />


          </div>

          <div className="p-8 mt-6 bg-white rounded-md shadow-md">
            <h3 className="font-semibold text-title">{t("nav:invoices")}</h3>
            <p className="mt-4">
            {t("services:invoicesDesc")}
            </p>
            <div className="flex items-end justify-end w-full mt-3">
              <Button onClick={() => setOpenBillsModal(true)}>
                {t("services:reviewInvoices")}
              </Button>
            </div>
          </div>

          <div className="p-8 mt-6 bg-white rounded-md shadow-md">
            <h3 className="font-semibold text-title">{t("services:ipAddresses")}</h3>
            <div className="grid grid-cols-12 mt-5 gap-y-2">
              <div className="col-span-4">IP</div>
              <div className="col-span-1">:</div>
              <div
                className="col-span-7">{data?.old_assigned_ip ? data.old_assigned_ip : data?.assigned_ip ?
                  (data?.assigned_ip + (data.assigned_ip_port ? (":" + data.assigned_ip_port) : "")) : "-"}</div>

              <div className="col-span-4">{t("global:extra")} IP</div>
              <div className="col-span-1">:</div>
              {data?.extra_ip1_id ?
                <div className="col-span-7 text-alert-green">{t("services:available")}</div>
                :
                <div className="col-span-7 text-alert-red">{t("services:notAvailable")}</div>

              }

            </div>
            <div className="flex items-end justify-end w-full mt-3">
              <Link to={`/services/dedicated-server/${data.id}/ip-records`}>
                <Button >{t("services:viewInformation")}</Button>
              </Link>

              {/* // <Button onClick={() => setOpenEkstraIPModal(true)}>
                //   Satın Al
                // </Button> */}


            </div>
          </div>

          {!data?.is_cancel_requested && data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
            <div className="p-8 mt-6 bg-white rounded-md shadow-md">
              <h3 className="font-semibold text-title">{t("services:cancelService")}</h3>
              <p className="mt-4">{t("services:cancelProduct")}</p>
              <div className="flex items-end justify-end w-full mt-3">
                <Button onClick={() => setOpenErrorModal(true)} textRed>
                {t("global:cancel")}
                </Button>
              </div>
            </div>

          }


        </div>

        <div className="col-span-3 lg:col-span-1">
          <div className="p-8 bg-white rounded-md shadow-md">
            <h3 className="font-semibold text-title">{t("services:serverFeatures")}</h3>
            <div className="grid grid-cols-12 mt-5 gap-y-1">
              <div className="col-span-4">{t("services:operatingSystem")}</div>
              <div className="col-span-1">:</div>
              <div
                className="col-span-7">{data.operating_system ? (data?.operating_system?.type + " " + data?.operating_system?.version) : "-"}</div>

              <div className="col-span-4">CPU</div>
              <div className="col-span-1">:</div>
              <div
                className="col-span-7">{(data?.cpu || data.product_details?.content?.cpu_core) ? ((data.product_details?.content?.cpu_core ? data.product_details?.content?.cpu_core : 0 + data?.cpu?.core ? data?.cpu?.core : 0) + " GHz") : "-"}</div>

              <div className="col-span-4 mt-2">RAM</div>
              <div className="col-span-1 mt-2">:</div>
              <div
                className="col-span-7 mt-2">{data.product_details?.content?.ram ? data.product_details?.content.ram : "-"}</div>

              {data?.ram &&
                <>
                  <div className="col-span-4 mt-2">{t("services:additional")}  RAM</div>
                  <div className="col-span-1 mt-2">:</div>
                  <div
                    className="col-span-7 mt-2">{data?.ram ? (data?.ram?.memory_limit + " " + data?.ram?.memory_type + " " + data?.ram?.model_name) : "-"}</div>
                </>

              }

              <div className="col-span-4">Disk</div>
              <div className="col-span-1">:</div>
              <div
                className="col-span-7">{data?.product_details?.content?.disk ? (data?.product_details?.content?.disk.disk_size + " " + data?.product_details?.content?.disk.disk_unit) : "-"}</div>
              {data?.disk1 &&
                <>
                  <div className="col-span-4">{t("services:additional")} Disk</div>
                  <div className="col-span-1">:</div>
                  <div
                    className="col-span-7">{data?.disk1 ? (data?.disk1?.memory_limit + " " + data?.disk1?.memory_type + " " + data?.disk1?.disk_type) : "-"}</div>
                </>
              }
              {data?.disk2 &&
                <>
                  <div className="col-span-4">{t("services:additional")}  Disk 2</div>
                  <div className="col-span-1">:</div>
                  <div
                    className="col-span-7">{data?.disk2?.memory_limit + " " + data?.disk2?.memory_type + " " + data?.disk2?.disk_type}</div>
                </>
              }
              {/* {data?.disk3 &&
                <>
                  <div className="col-span-4">Disk3</div>
                  <div className="col-span-1">:</div>
                  <div className="col-span-7">{data?.disk3?.memory_limit + " " + data?.disk3?.memory_type + " " + data?.disk3.disk_type}</div>
                </>
              } */}
            </div>
          </div>
          <div className="relative p-8 mt-5 bg-white bg-opacity-25 rounded-md shadow-md">
            <h3 className="font-semibold opacity-25 text-title">{t("nav:server-usage")}</h3>
            <div className="mt-3 opacity-25">
              <p>
              {t("services:diskUsage")} : <span className="text-primary-hover">25%</span>
              </p>
              <div className="relative h-2 max-w-xl mt-1 overflow-hidden rounded-full">
                <div className="absolute w-full h-full bg-borderGray"></div>
                <div
                  className="absolute h-full rounded-full bg-primary-hover"
                  style={{ width: "25%" }}
                ></div>
              </div>
            </div>
            <div className="mt-3 opacity-25">
              <p>
              {t("services:trafficUsage")}:{" "}
                <span className="text-primary-hover">10%</span>
              </p>
              <div className="relative h-2 max-w-xl mt-1 overflow-hidden rounded-full">
                <div className="absolute w-full h-full bg-borderGray"></div>
                <div
                  className="absolute h-full rounded-full bg-primary-hover"
                  style={{ width: "10%" }}
                ></div>
              </div>
            </div>
            <div className="mt-3 opacity-25">
              <p>
              {t("services:cpuUsage")} : <span className="text-primary-hover">5%</span>
              </p>
              <div className="relative h-2 max-w-xl mt-1 overflow-hidden rounded-full">
                <div className="absolute w-full h-full bg-borderGray"></div>
                <div
                  className="absolute h-full rounded-full bg-primary-hover"
                  style={{ width: "5%" }}
                ></div>
              </div>
            </div>
            <div className="mt-3 opacity-25">
              <p>
              {t("services:ramUsage")} : <span className="text-primary-hover">8%</span>
              </p>
              <div className="relative h-2 max-w-xl mt-1 overflow-hidden rounded-full">
                <div className="absolute w-full h-full bg-borderGray"></div>
                <div
                  className="absolute h-full rounded-full bg-primary-hover"
                  style={{ width: "8%" }}
                ></div>
              </div>
            </div>

            <AlertLaterInfo style={"top-1/3 lg:left-12"} />

          </div>

          {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
            <div className="p-8 mt-5 bg-white rounded-md shadow-md">
              <h3 className="font-semibold text-title">Transfer</h3>
              <div className="grid grid-cols-12 mt-3 gap-y-2">
                <div className="flex items-center col-span-4">{t("services:internalTransfer")}</div>
                <div className="flex items-center col-span-1">:</div>
                <div
                  onClick={() => setOpenInternalTransferModal(true)}
                  className="flex items-center col-span-7 cursor-pointer text-primary-hover"
                >
                  {t("services:startTransfer")}
                </div>
              </div>
            </div>
          }

          {data?.status != "refunded" && data?.status != "canceled" && data?.status != "closed" &&
            <div className="p-8 mt-6 bg-white rounded-md shadow-md">
              <h3 className="font-semibold text-title">{t("services:notes")}</h3>
              <p className="mt-4">{data?.customer_note ? data?.customer_note :  t("services:noNote")}</p>
              <div className="flex items-end justify-end w-full mt-3 text-alert-red">
                <Button onClick={() => setOpenNoteModal(true)}>{t("services:addNote")}</Button>
              </div>
            </div>
          }
        </div>
      </div>
      {openDNSChangeModal && (
        <DNSChangeModal setOpenModal={() => setOpenDNSChangeModal(false)} />
      )}
      {openWHOISModal && (
        <WHOISModal setOpenModal={() => setOpenWHOISModal(false)} />
      )}
      {openBillsModal && (
        <BillsModal typeID={data?.product_type_id} productID={data.id} setOpenModal={() => setOpenBillsModal(false)} setOpenProformaInformationModal={setOpenProformaInformationModal} />
      )}
      {openNoteModal && (
        <AddNoteModal onUpdateNote={onUpdateNote} data={data?.customer_note}
          setOpenModal={() => setOpenNoteModal(false)} />
      )}
      {openExtendServiceModal && (
        <ExtendServiceModal
          setOpenModal={() => setOpenExtendServiceModal(false)}
        />
      )}
      {openBuyDNSServiceModal && (
        <BuyDNSServicesModal
          setOpenModal={() => setOpenBuyDNSServiceModal(false)}
        />
      )}
      {openChangeProductProjectModal && (
        <ChangeProductProjectModal
          data={data}
          setOpenModal={() => setOpenChangeProductProjectModal(false)}
          setOpenWarningModal={setOpenWarningModal}
        />
      )}
      {openWarningModal && (
        <WarningModal
          title={t("services:changeProductProject")}
          question={t("services:changeProductProjectQues")}
          errorText={t("services:changeProductProjectDesc")}
          buttonText={t("services:yesToCarry")}
          setOpenModal={() => setOpenWarningModal(false)}
          onClick={() => onUpdateProject(openWarningModal)}
        />
      )}
      {openInternalTransferModal && (
        <InternalTransferModal
          data={data}
          setOpenModal={() => setOpenInternalTransferModal(false)}
        />
      )}
      {openTransferWarningModal && (
        <WarningModal
          title={t("services:closedForeignTransfer")}
          question={t("services:notAvailableTransfer")}
          description={t("services:openDomainForTransfer")}
          buttonText={t("services:openTransfer")}
          setOpenModal={() => setOpenTransferWarningModal(false)}
          onClick={() => {
            setOpenRemoveLockModal(true);
            setOpenTransferWarningModal(false);
          }}
        />
      )}
      {openRemoveLockModal && (
        <RemoveLockModal
        title={t("services:removeLock")}
        question={t("services:removeLockDesc")}
        buttonText={t("global:send")}
          setOpenModal={() => setOpenRemoveLockModal(false)}
          onClick={() => {
            setOpenSuccessModal(true);
            setOpenRemoveLockModal(false);
          }}
        />
      )}
      {openSuccessModal && (
        <SuccessModal
        title={t("services:transferCodeSent")}
        description={t("services:transferCodeSentDesc")}
        buttonText={t("global:okey")}
          setOpenModal={() => setOpenSuccessModal(false)}
        />
      )}
      {openProfessionalDNSModal && (
        <ProfessionalDNSModal
        title={t("services:transferCodeSent")}
        description={t("services:transferCodeSentDesc")}
        buttonText={t("global:okey")}
          setOpenModal={() => setOpenProfessionalDNSModal(false)}
        />
      )}
      {openErrorModal && (
        <ErrorModal
        title={t("services:cancelService")}
        question={t("services:sureCancelService")}
        errDescription={t("services:sureCancelServiceDesc")}
        buttonText={t("global:yesDelete")}
          setOpenModal={() => setOpenErrorModal(false)}
          onClick={onCancelProduct}
          reasonLabel={t("services:reasonForCancellation")}
          reasonPlaceholder={t("services:enterReasonForCancellation")}
        />
      )}
      {openEkstraIPModal && (
        <BuyEkstraIpModal setOpenModal={() => setOpenEkstraIPModal(false)} />
      )}
      {openManegementModal && (
        <BuyMenagementModal
          setOpenModal={() => setOpenManegementModal(false)}
        />
      )}

      {openProformaInformationModal && (
        <ProformaInformationModal
          setOpenModal={() => setOpenProformaInformationModal(false)}
        />
      )}
    </div>
  );
}

export default FastTransactions;
