import styled, { css } from "styled-components";

const Label = styled.label`
  margin: 0.5rem auto;
  color: #555555;
  font-size: 14px;
`;
const Error = styled(Label)`
  --tw-ring-color: #ff4949;
  color: #ff4949;
  font-size: 14px;
`;
const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #555555;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  margin: 0.125rem 0 0 0;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-color: #d8d8d8;
  border-radius: 0.375rem;

  &:focus {
    --tw-ring-color: #ff9e30;
  }

  &:disabled {
    background-color: #d8d8d8;
    color: #999999;
    cursor: not-allowed;
  }

  //{tw w-full border-none ring-0 outline-none text-2xs text-main py-2 pr-2 pl-3 m-0 mt-0.5 ring-1 ring-primary-disabled outline-none rounded-md focus:ring-primary disabled:bg-primary-disabled  disabled:cursor-not-allowed disabled:text-passive}
  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
  ${(props) =>
  props.type === "password" &&
  css`
      padding-right: 2.5rem;
    `};
  ${(props) =>
  props.realType === "file" &&
  css`
      padding-left: 2rem;
      cursor: default;
      caret-color: transparent;
    `};
  ${(props) =>
  props.realType === "phone" &&
  typeof props.countryCode !== "undefined" &&
  css`
      padding-left: ${props.countryCode.id?.length === 1
    ? "2.25rem"
    : props.countryCode.id?.length === 2
      ? "2.75rem"
      : props.countryCode.id?.length === 3
        ? "3.5rem"
        : props.countryCode.id?.length === 4
          ? "4rem"
          : "4.5rem"};
    `};
`;
export { Label, Error, Input };
