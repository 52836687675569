import Tabs from "components/Tabs";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import { Button } from "components";
import BuyBackupModal from "components/Services/ServicesGlobalModals/BuyBackupModal";
import VirtualNetwork from "components/Services/VirtualServers/VirtualNetwork";
import BackupServicesModal from "components/Services/ServicesGlobalModals/BackupServicesModal";
import FormatModal from "components/Services/ServicesGlobalModals/FormatModal";
import PhysicalVPNUsers from "components/Services/PhysicalServers/PhysicalVPNUsers";
import MailUpgradeServicesModal from "components/Services/MailHosting/Modals/MailUpgradeServicesModal";
import VirtualLogRecords from "components/Services/VirtualServers/VirtualLogRecords";
import VirtualIPRecords from "components/Services/VirtualServers/VirtualIPRecords";
import { getColocation } from "services/allServices";
import { useNavigate, useParams } from "react-router";
import FastTransactions from "components/Services/CoLocation/FastTransactions";
import { isNullOrEmptyOrUndefined } from "utils/common";
import ServicesTickets from "components/Services/VirtualServers/ServicesTickets";
import { useTranslation } from "react-i18next";

function CoLocationDetails() {
  const { t } = useTranslation([ "global", "services", "nav", "support"]);

  const [selectedPageTab, setSelectedPageTab] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab
  ]);
  const [serverStart, setServerStart] = useState(false);
  const [openBuyBackupModal, setOpenBuyBackupModal] = useState(false);
  const [openBackupServicesModal, setOpenBackupServicesModal] = useState(false);
  const [openUpgradeServicesModal, setOpenUpgradeServicesModal] = useState(
    false
  );
  const [openFormatModal, setOpenFormatModal] = useState(false);
  const [openExtendServiceModal, setOpenExtendServiceModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let slug = useParams();
  const [pageData, setPageData] = useState();


  const onGetPageData = () => {
    setIsLoading(true);
    getColocation(slug.id).then(res => {
      setIsLoading(false);
      setPageData(res);
      if (!res) {
        navigate("/");
      }
    });
  };

  const tabs = [
    {
      name: t("services:quickTransactions"),
      id: "1",
      component: <FastTransactions data={pageData} onGetPageData={onGetPageData} />,
      slug: "",
      visible: true
    },
    {
      name: "Network",
      id: "2",
      component: <VirtualNetwork data={pageData} />,
      slug: "network",
      visible: true
    },

    {
      name:t("projects:logRecords"),
      id: "3",
      component: <VirtualLogRecords data={pageData} />,
      slug: "log-records",
      visible: true
    },
    {
      name: t("nav:vpn-users"),
      id: "4",
      component: <PhysicalVPNUsers data={pageData} />,
      slug: "vpn-users",
      visible: true
    },
    {
      name: t("nav:ip-records"),
      id: "5",
      component: <VirtualIPRecords data={pageData} />,
      slug: "ip-records",
      visible: true
    },
    {
      name: t("support:tickets"),
      id: "6",
      component: <ServicesTickets data={pageData} />,
      slug: "tickets",
      visible: true
    }
  ];


  useEffect(() => {
    onGetPageData();
  }, [slug.id]);


  return (
    (pageData && pageData?.status !== "waiting") ?
      <div className="container relative w-full ">
        <div className="items-center justify-between p-4 bg-white rounded-md shadow-sm lg:flex">
          <div>
            <h2 className="font-semibold">{pageData?.product_name}</h2>
            <p className="text-sm text-passive">ID #{pageData?.id}</p>
          </div>
          <div className="items-center lg:flex">
            <div className="items-center lg:flex">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  <i className="mr-1 text-3xl ri-flashlight-fill text-passive" />
                  <p className="text-sm text-passive">{t("global:active")}</p>
                </div>

                <div
                  // onClick={() => setOpenBuyBackupModal(true)}
                  //onClick={() => setOpenBackupServicesModal(true)}
                  className="flex items-center mr-4 cursor-pointer group"
                >
                  <i
                    className="mr-1 text-3xl transition-all duration-300 ri-database-2-fill text-passive group-hover:text-passive" />
                  <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                  {t("services:backup")}
                  </p>
                </div>
              </div>
              <div className="items-center lg:flex">
                <div className="flex items-center">
                  <div
                    //onClick={() => setOpenUpgradeServicesModal(true)}
                    className="flex items-center mr-4 cursor-pointer group"
                  >
                    <i
                      className="mr-1 text-3xl transition-all duration-300 ri-stack-fill text-passive group-hover:text-passive" />
                    <p className="text-sm transition-all duration-300 text-passive group-hover:text-passive">
                    {t("services:upgrade")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Button
             // onClick={() => setOpenExtendServiceModal(true)}
              className="px-8 mt-3 lg:mt-0"
              secondary
              icon
              disabled
            >
              <i className="ri-history-line" />
              <span>{t("services:extendService")}</span>
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <Tabs
            tabs={tabs.filter((x) => x.visible)}
            selectedTab={
              !isNullOrEmptyOrUndefined(slug.url)
                ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
                : tabs.filter((x) => x.visible)[0]?.id
            }
            setSelectedTab={(v) => {
              navigate(
                "/services/co-location/" + slug.id + "/" +
                tabs.find((x) => x.id === v).slug
              );
            }}
          />


          <div>
            {!isNullOrEmptyOrUndefined(slug.url)
              ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
              : tabs.filter((x) => x.visible)[0]?.component
            }
          </div>
        </div>

        {openBuyBackupModal && (
          <BuyBackupModal setOpenModal={() => setOpenBuyBackupModal(false)} />
        )}
        {openUpgradeServicesModal && (
          <MailUpgradeServicesModal
            setOpenModal={() => setOpenUpgradeServicesModal(false)}
          />
        )}
        {openBackupServicesModal && (
          <BackupServicesModal
            setOpenModal={() => setOpenBackupServicesModal(false)}
          />
        )}
        {openFormatModal && (
          <FormatModal setOpenModal={() => setOpenFormatModal(false)} />
        )}
      </div>
      :
      <div className="flex flex-col items-center justify-center mt-20">
        <img src="/servicesWaiting.svg" width="352" height="330" />
        <p className="mt-3 text-5xl font-semibold text-primary-hover">{t("services:preparedService")}</p>
        <p className="my-2">{t("services:preparedServiceDesc1")}</p>
        <p>{t("services:preparedServiceDesc2")}</p>
      </div>
  );
}

export default CoLocationDetails;
