import React from "react";

function ImageModal({ imgUrl, setOpenModal }) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex justify-between items-center px-3 py-3">
              <h3 className="font-semibold">Resim Önizleme</h3>
              <i
                onClick={() => setOpenModal(false)}
                className="ri-close-line text-2xl text-passive cursor-pointer"
              ></i>
            </div>
            <div className=" flex flex-col  px-5 pt-4 pb-10 bg-modalBg">
              <img src={imgUrl} />
            </div>
          </div>
        </div>

        <div
          className="flex flex-col-reverse lg:flex-row items-end justify-end rounded-md bg-white py-3 px-5 lg:px-0 lg:pr-5"></div>
      </div>
    </div>
  );
}

export default ImageModal;
