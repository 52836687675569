export const PermissionList = {
  "permissionList": [

    {
      "id": "support-request-tech",
      "text": "Destek Talepleri (Teknik)",
      "group": "tech",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "support-request-accounting",
      "text": "Destek Talepleri (Muhasebe)",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "support-request-sales",
      "text": "Destek Talepleri (Satış)",
      "group": "",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "complaint-notifications",
      "text": "Şikayet Bildirimleri",
      "group": "all",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "information-center",
      "text": "Bilgi Merkezi",
      "group": "all",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "all-services",
      "text": "Tüm Hizmetler",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    },
    {
      "id": "domains",
      "text": "Domainler",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    },
    {
      "id": "virtual-servers",
      "text": "Sanal Sunucular",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    },
    {
      "id": "cloud-servers",
      "text": "Bulut Sunucular",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    },
    {
      "id": "dedicated-servers",
      "text": "Fiziksel Sunucular",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    },
    {
      "id": "web-hosting",
      "text": "Web Hosting",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    }
    ,
    {
      "id": "mail-hosting",
      "text": "Mail Hosting",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    }
    ,
    {
      "id": "colocation",
      "text": "Colocation",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    }
    ,
    {
      "id": "ssl-certificates",
      "text": "SSL Sertifikaları",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    }
    ,
    {
      "id": "control-panel",
      "text": "Kontrol Panelleri",
      "group": "tech",
      "message": "",
      "tab_name": "product_services",
      "checked": false
    }
    ,
    {
      "id": "proforma-invoices",
      "text": "Proformalar",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    }
    ,
    {
      "id": "invoices",
      "text": "Faturalar",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    }
    ,
    {
      "id": "payment-history",
      "text": "Ödeme Geçmişi",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    }
    ,
    {
      "id": "notifications",
      "text": "Bildirimler",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    }
    ,
    {
      "id": "order",
      "text": "Sipariş Verme",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "settings-profile",
      "text": "Profil",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    },
    {
      "id": "settings-invoices-address",
      "text": "Fatura Adresi",
      "group": "accounting",
      "message": "",
      "tab_name": "",
      "checked": false
    },

    {
      "id": "settings-responsible-sub-accounts",
      "text": "Sorumlular ve Alt Hesaplar",
      "group": "",
      "message": "",
      "tab_name": "",
      "checked": false
    }
  ]
};