import React, { useEffect, useState } from "react";
import { listProductAll } from "services/allServices";
import VirtualServersTable from "./Tables/VirtualServersTable";
import PhysicalServerTable from "./Tables/PhysicalServerTable";
import DomainsTable from "./Tables/DomainsTable";
import WebHostingTable from "./Tables/WebHostingTable";
import MailHostingTable from "./Tables/MailHostingTable";
import SSLTable from "./Tables/SSLTable";
import { TailSpin } from "react-loader-spinner";
import CoLocationTable from "./Tables/CoLocationTable";
import ControlPanelTable from "./Tables/ControlPanelTable";
import CloudServersTable from "./Tables/CloudServersTable";
import { useTranslation } from "react-i18next";
import IPTable from "./Tables/IPTable";
import SupportTable from "./Tables/SupportTable";
import IYSTable from "./Tables/IYSTable";
import FirewallTable from "./Tables/FirewallTable";
import ProxyTable from "./Tables/ProxyTable";

function AllProductServices() {
  const { t, i18n } = useTranslation(["services", "global"]);
  const [searchValue, setSearchValue] = useState("");
  const [listProduct, setListProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onListAllProductServices = () => {
    setIsLoading(true);
    listProductAll().then(res => {
      setListProduct(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    onListAllProductServices();
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-end lg:flex-row lg:items-center">
        {/* <h2 className="my-5 font-semibold">Tüm Hizmetler</h2> */}
        <div className="items-center mt-2 lg:flex">
          {/* <TextField
            id="search"
            name="search"
            type="search"
            ref={null}
            className="relative "
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Ürün Adı Giriniz"
          /> */}
        </div>
      </div>
      {!isLoading ?
        <div>
          {listProduct && listProduct?.cloud_server_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:virtualServers")}</h2>
              <VirtualServersTable datas={listProduct?.cloud_server_list} />
            </div>

          }

          {listProduct && listProduct?.dedicated_server_list?.length !== 0 &&

            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:physicalServers")}</h2>

              <PhysicalServerTable datas={listProduct?.dedicated_server_list} />
            </div>


          }


          {listProduct && listProduct?.cloud_list?.length !== 0 &&

            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:cloudServers")}</h2>

              <CloudServersTable datas={listProduct?.cloud_list} />
            </div>


          }

          {listProduct && listProduct?.colocation_list?.length !== 0 &&

            <div className="mb-5">
              <h2 className="mb-5 font-semibold">Co-Location</h2>

              <CoLocationTable datas={listProduct?.colocation_list} />
            </div>


          }


          {listProduct && listProduct?.domain_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:domainNames")}</h2>

              <DomainsTable datas={listProduct?.domain_list} />
            </div>

          }

          {listProduct && listProduct?.web_hosting_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:webHosting")}</h2>

              <WebHostingTable datas={listProduct?.web_hosting_list} />
            </div>

          }

          {listProduct && listProduct?.mail_hosting_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:mailHosting")}</h2>

              <MailHostingTable datas={listProduct?.mail_hosting_list} />
            </div>

          }

          {listProduct && listProduct?.ssl_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:sslCertificate")}</h2>

              <SSLTable datas={listProduct?.ssl_list} />
            </div>

          }

          {listProduct && listProduct?.control_panel_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:controlPanel")}</h2>

              <ControlPanelTable datas={listProduct?.control_panel_list} />
            </div>
          }

          {listProduct && listProduct?.ip_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">IP</h2>

              <IPTable datas={listProduct?.ip_list} />
            </div>
          }

          {listProduct && listProduct?.support_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:support")}</h2>
              <SupportTable datas={listProduct?.support_list} />
            </div>
          }

          {listProduct && listProduct?.iys_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">{t("global:iys")}</h2>
              <IYSTable datas={listProduct?.iys_list} />
            </div>
          }

          {listProduct && listProduct?.firewall_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">Firewall</h2>
              <FirewallTable datas={listProduct?.firewall_list} />
            </div>
          }

          {listProduct && listProduct?.proxy_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="mb-5 font-semibold">Proxy</h2>
              <ProxyTable datas={listProduct?.proxy_list} />
            </div>
          }



          {listProduct?.cloud_server_list?.length === 0 &&
            listProduct?.dedicated_server_list?.length === 0 &&
            listProduct?.domain_list?.length === 0 &&
            listProduct?.web_hosting_list?.length === 0 &&
            listProduct?.mail_hosting_list?.length === 0 &&
            listProduct?.colocation_list?.length === 0 &&
            listProduct?.ssl_list?.length === 0 &&
            <div className="flex flex-col items-center justify-center my-10 lg:my-20">
              <img src="/servicesOther.svg" width="403" height="339" />
              <h1>{t("global:noProduct")}</h1>
              <h5>{t("global:productDesc")}</h5>
            </div>
          }

        </div>
        :
        <div className="flex items-center justify-center w-full mt-20">
          <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
            type="Oval"
            color="#ff7c30"
            height={75}
            width={75}
            timeout={3000} //3 secs
          />
        </div>
      }


      {/* <div className="flex justify-end">
        <Pagination
          pageItems={dummyDizi}
          paginationValue={paginationValue}
          setPaginationValue={(value) => onSetPaginationValue(value)}
          pageValue={paginationDisplayValue}
          setPageValue={(value) => onSetPaginationDisplayValue(value)}
        />
      </div> */}
    </div>
  );
}

export default AllProductServices;
