import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { useCheckList } from "hooks";
import { useSupportTicketStore } from "store/SupportTicketStore";
import { useNavigate } from "react-router";
import MakdosTable from "components/MakdosTable";
import { useGlobalStore } from "store/GlobalStore";

function SupportRequestTable({ ticketList, onGetTicketList, numberOfPages, setOpenStaffRatingModal }) {
  const { t } = useTranslation(["support", "global", "services"]);
  const [data, setData] = useState(ticketList);
  const [check, setCheck] = useState(false);
  const [checkArr, setCheckArr] = useState([]);
  const [checkList, onSetCheckList, setCheckList] = useCheckList();
  const navigate = useNavigate();
  const [setSelectedTicketID] = useSupportTicketStore((state) => [
    state.setSelectedTicketID
  ]);

  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ticket_code"
      },
      // {
      //   Header: "Öncelik",
      //   accessor: "priority",
      //   Cell: priority,
      // },
      {
        Header: t("global:department"),
        accessor: "department"
      },
      {
        Header: t("global:subject"),
        accessor: "title",
        Cell: ({ value }) => (value.length > 30 ? value.substring(0, 30) + "..." : value)

      },
      {
        Header: t("global:associatedService"),
        accessor: "product_info.product_name"
      },
      {
        Header: t("global:status"),
        accessor: "status",
        Cell: status
      },
      {
        Header: t("support:lastAnswer"),
        accessor: "last_reply_time",
        Cell: (props) =>
          props.value !== null
            ? moment(props.value).format("DD-MM-YYYY HH:mm")
            : "-"
      },
      {
        Header: "",
        accessor: "idbutton",
        Cell: tableButton
      },

      {
        Header: "",
        accessor: "customer_read_receipt"
      }
    ],
    [t, checkList, ticketList]
  );

  function status(props) {
    if (props?.value?.content?.ticket_status_customer === "Açık") {
      return <p
        className="text-alert-green bg-greenAlertBg text-center rounded-full py-0.5 w-40 border-2 border-greenAlertBorder font-semibold">{t("services:open")} </p>;
    } else if (props?.value?.content?.ticket_status_customer === "Cevaplandı") {
      return <p
        className="text-primary-hover bg-primary-tertiary text-center rounded-full py-0.5 w-40 border-2 border-orangeAlertBorder font-semibold">{t("services:answered")} </p>;
    } else if (props?.value?.content?.ticket_status_customer === "Cevapladınız") {
      return <p
        className="text-garantiGreen bg-darkGreenAlertBg text-center rounded-full py-0.5 w-40 border-2 border-darkGreenAlertBorder font-semibold">{t("services:youAnswered")} </p>;
    } else if (props?.value?.content?.ticket_status_customer === "Cevabınız Bekleniyor") {
      return <p
        className="text-garantiGreen bg-darkGreenAlertBg text-center rounded-full py-0.5 w-40 border-2 border-darkGreenAlertBorder font-semibold">{t("services:yourReplyIsAwaited")}</p>;
    } else if (props?.value?.content?.ticket_status_customer === "Beklemede") {
      return <p
        className="text-alert-yellow bg-yellowAlertBg text-center rounded-full py-0.5 w-40 border-2 border-yellowAlertBorder font-semibold">{t("services:pending")} </p>;
    } else if (props?.value?.content?.ticket_status_customer === "İşleme Alındı") {
      return <p
        className="text-alert-blue bg-blueAlertBg text-center rounded-full py-0.5 w-40 border-2 border-blueAlertBorder font-semibold">{t("services:processed")}</p>;
    } else if (props?.value?.content?.ticket_status_customer === "Kapatıldı") {
      return <p
        className="text-alert-red bg-redAlertBg text-center rounded-full py-0.5 w-40 border-2 border-redAlertBorder font-semibold">{t("services:closed")}</p>;
    } else if (props?.value?.content?.ticket_status_customer === "Transfer Edildi") {
      return <p
        className="text-passive bg-makdos-table-th-bg text-center rounded-full py-0.5 w-40 border-2 border-borderGray2 font-semibold">Transfer
        Edildi</p>;
    } else {
      return <p>-</p>;
    }
  }

  function tableButton(props) {
    if (props) {
      return (props.row.original.status.content.ticket_status === "closed" || props.row.original.status.content.ticket_status === "transferred") ? (
        <div

          className="flex items-center justify-center space-x-3 "
        >
          <i onClick={() => onGoTicketMessages(props.row.original.id)} className="cursor-pointer ri-eye-line"></i>
          {props.row.original?.rating_status &&
            <p onClick={() => setOpenStaffRatingModal(props.row.original.id)}
              className="cursor-pointer text-primary-hover hover:text-primary">{t("support:rate")}
            </p>
          }
        </div>
      ) : (
        <div
          onClick={() => onGoTicketMessages(props.row.original.id)}
          className="flex items-center justify-center cursor-pointer"
        >
          <button className="text-primary-hover hover:text-primary focus:outline-none">
            {t("support:answer")}
          </button>
        </div>
      );
    }
  }

  const onGoTicketMessages = async (id) => {
    await setSelectedTicketID(id);
    navigate("/ticket-and-support/ticket-messages");
  };
  useEffect(() => {
    setData(ticketList);
  }, [ticketList]);
  return (
    <div>
      {ticketList && ticketList !== "ticket_not_found" &&
        <div className="w-full mb-12 ">
          <MakdosTable
            isCheckboxVisible={false}
            setCheckList={() => {
            }}
            checkList={[]}
            data={data}
            setData={setData}
            showPagination={true}
            columns={columns}
            pageLimits={pageLimits}
            isBackendPagination={true}
            backendPaginationMethod={(page, limit) => {
              onGetTicketList(page, limit);
            }}
            backendNumberOfPages={numberOfPages}
            activePageSize={activePageSize}
            setActivePageSize={setActivePageSize}
            isDndAvailable={false}
          />
        </div>
      }

    </div>
  );
}

export default SupportRequestTable;
