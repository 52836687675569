import create from "zustand";
import { devtools } from "zustand/middleware";

export const useSupportTicketStore = create(
  devtools((set, get) => ({
    selectedTicketID:
      JSON.parse(localStorage.getItem("__selectedTicketID")) || null,
    setSelectedTicketID: (value) => {
      set((state) => ({
        selectedTicketID: value
      }));

      get().addToLocalStorageSupportTicket();
    },

    addToLocalStorageSupportTicket: () => {
      // localStorage.removeItem("__products")
      localStorage.setItem(
        "__selectedTicketID",
        JSON.stringify(get().selectedTicketID)
      );
    },
    checkLocalStorageSupportTicket: () => {
      if (
        typeof localStorage.getItem("__selectedTicketID") !== "undefined" &&
        localStorage.getItem("__selectedTicketID") !== null &&
        localStorage.getItem("__selectedTicketID") !== ""
      )
        set((state) => ({
          selectedTicketID: JSON.parse(
            localStorage.getItem("__selectedTicketID")
          )
        }));
    }
  }))
);
