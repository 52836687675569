import { isUndefined, toggleCheckBoxItem } from "helpers/helpersFunc";
import React from "react";

export default function CheckBoxList(props) {
  return (
    <div className={props.checkBoxStyle}>
      {props.label !== "hide" && (
        <label
          className={
            props.labelStyle + " float-left w-full text-sm text-main mb-0"
          }
          htmlFor={props.name}
        >
          {props.label}
        </label>
      )}
      <div className={props.containerStyle ? props.containerStyle : ""}>
        {props.items.map((item, index) => (
          <div key={index} className={props.inputStyle}>
            <input
              type="checkbox"
              id={item.id}
              name={props.name}
              onChange={() => {
                !item.disabled &&
                toggleCheckBoxItem(item.id, (props?.allItems ? props?.allItems : props.items), props.setCheckbox);
                props?.onClickedItem(item);

              }}
              checked={item.checked}
              className={
                item.disabled
                  ? "w-4 h-4 text-gray-500 border-gray-400 bg-primary-disabled ring-0 rounded-md"
                  : "w-4 h-4 text-primary-hover outline-none rounded-md ring-0 border-gray-400 active:ring-0 focus:ring-0"
              }
              disabled={item.disabled}
            />
            <span
              className={
                (props.spanStyle !== "" ? props.spanStyle : "pr-5") +
                " ml-2 text-main cursor-pointer"
              }
              onClick={() => {
                !item.disabled &&
                toggleCheckBoxItem(item.id, (props?.allItems ? props?.allItems : props.items), props.setCheckbox);
                props?.onClickedItem(item);
              }}
            >
              {item.text}
              {!isUndefined(item.html) && item.html}
            </span>
            {props.selectIcon &&
              <i onClick={() => props.selectIconOnClick(item.id)}
                 className="ml-2 text-lg font-semibold ri-arrow-right-line text-primary"></i>
            }
            <br />
            {item.message !== "" ? (
              <label className="pl-6 text-sm text-gray-500">
                {item.message}
              </label>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
