import AllProductServices from "components/Services/AllProductServices/AllProductServices";
import CloudServers from "components/Services/Cloud/CloudServers";
import CoLocation from "components/Services/CoLocation/CoLocation";
import ControlPanelServices from "components/Services/ControlPanelServices/ControlPanelServices";
import Domains from "components/Services/Domains/Domains";
import MailHosting from "components/Services/MailHosting/MailHosting";
import PhysicalServers from "components/Services/PhysicalServers/PhysicalServers";
import SSLCertificate from "components/Services/SSLCertificate/SSLCertificate";
import VirtualServers from "components/Services/VirtualServers/VirtualServers";
import WebHosting from "components/Services/WebHosting/WebHosting";
import Tabs from "components/Tabs";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import { isNullOrEmptyOrUndefined } from "utils/common";
import { useTranslation } from "react-i18next";
import IPServices from "components/Services/IPServices/IPServices";
import SupportService from "components/Services/SupportServices/SupportService";
import IYSServices from "components/Services/IYSServices/IYSServices";
import FirewallServices from "components/Services/FirewallServices/FirewallServices";
import ProxyServices from "components/Services/ProxyServices/ProxyServices";

function Services() {
  const { t, i18n } = useTranslation(["services", "global"]);
  const [isResponsible, permissions] = useGlobalStore((state) => [state.isResponsible, state.permissions]);
  let slug = useParams();
  let navigate = useNavigate();

  const tabs = [
    {
      name: t("services:allServices"),
      id: "1",
      component: <AllProductServices />,
      slug: "",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "all-services" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:domainNames"),
      id: "2",
      component: <Domains />,
      slug: "domains",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "domains" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:virtualServers"),
      id: "3",
      component: <VirtualServers />,
      slug: "virtual-servers",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "virtual-servers" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:cloudServers"),
      id: "4",
      component: <CloudServers />,
      slug: "cloud-servers",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "cloud-servers" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:physicalServers"),
      id: "5",
      component: <PhysicalServers />,
      slug: "dedicated-servers",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "dedicated-servers" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:webHosting"),
      id: "6",
      component: <WebHosting />,
      slug: "web-hosting",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "web-hosting" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:mailHosting"),
      id: "7",
      component: <MailHosting />,
      slug: "mail-hosting",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "mail-hosting" && item.checked === true)?.checked) : true
    },
    {
      name: "Co-Location",
      id: "8",
      component: <CoLocation />,
      slug: "colocation",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "colocation" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:sslCertificate"),
      id: "9",
      component: <SSLCertificate />,
      slug: "ssl-certificates",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "ssl-certificates" && item.checked === true)?.checked) : true
    },
    {
      name: t("global:controlPanel"),
      id: "10",
      component: <ControlPanelServices />,
      slug: "control-panel",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "control-panel" && item.checked === true)?.checked) : true
    },
    {
      name: "IP",
      id: "11",
      component: <IPServices />,
      slug: "ip",
      visible: true
    },
    {
      name: t("global:support"),
      id: "12",
      component: <SupportService />,
      slug: "support",
      visible: true
    },
    {
      name: t("nav:iys"),
      id: "13",
      component: <IYSServices />,
      slug: "iys",
      visible: true
    },
    {
      name: "Firewall",
      id: "14",
      component: <FirewallServices />,
      slug: "firewall",
      visible: true
    },
    {
      name: "Proxy",
      id: "15",
      component: <ProxyServices />,
      slug: "proxy",
      visible: true
    }
  ];


  return (
    <div className="container relative w-full ">

      <Tabs
        tabs={tabs.filter((x) => x.visible)}
        selectedTab={
          !isNullOrEmptyOrUndefined(slug.url)
            ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
            : tabs.filter((x) => x.visible)[0]?.id
        }
        setSelectedTab={(v) => {
          navigate(
            "/services/" +
            tabs.find((x) => x.id === v).slug
          );
        }}
        maxLength={9}
      />


      <div className="mt-6">
        {!isNullOrEmptyOrUndefined(slug.url)
          ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
          : tabs.filter((x) => x.visible)[0]?.component
        }
      </div>
    </div>
  );
}

export default Services;
