import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import AlertWarning from "components/ContentAlerts/AlertWarning";
import AlertInfo from "components/ContentAlerts/AlertInfo";
import ComboBox from "components/FormElements/ComboBox";
import { useComboBox, useCountry } from "hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCRT, createCSR, getCountry } from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import TextArea from "components/FormElements/Input/TextArea";
import RadioButton from "components/FormElements/RadioButton";
import { useToasts } from "react-toast-notifications";

const schema = yup.object().shape({

  domain: yup.string().required(),
  //email: yup.string().required(),
  authorized_name: yup.string().required(),
  authorized_surname: yup.string().required(),
  phone: yup.string().required(),
  csr_code: yup.string().required(),

});

function CRTCreateModal({ setOpenModal, setOpenCRTDNSSuccessModal, hostname, onGetPageData, pageData }) {
  let language = localStorage.getItem("ln");

  const { t, i18n } = useTranslation(["global", "services", "nav"]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [userInformations] = useAuthStore((state) => [state.userInformations,]);
  const dummyDizi = [
    { id: 1, text: "Sitesi" },
    { id: 2, text: "Grafik Tasarım" },
    { id: 3, text: "Finans" }
  ];
  const [selectedTab, setSelectedTab] = useState("1");
  const [isCheck, setIsCheck] = useState("email");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { addToast } = useToasts();

  const [
    country,
    countryStates,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    onGetCountry,
    onGetStates,
    onGetCities,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState
  ] = useCountry();

  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();
  const [gsm, setGsm] = useState("5");

  const onSubmit = (data) => {
    let configure = {
      product_name: pageData.product_name,
      csr: data.csr_code,
      webserver_type: selectedTab === "1" ? "other" : "iis",
      common_name: data.domain,
      country: selectedCountry.id,
      state: selectedState.text,
      city: selectedCity.text,
      phone: selectedCountryCode.id + gsm,
      firstname: data.authorized_name,
      lastname: data.authorized_surname,
      dcv_method: isCheck,
    }
    setButtonDisabled(true);

    createCRT(configure).then((res) => {
      setOpenModal(false);
      setSelectedTab("1");
      onGetPageData();
      if (isCheck === "dns") {
        setOpenCRTDNSSuccessModal(res.approver_method.dns.record)
      } else if(res?.status === "goged_order_exist"){
        addToast("Bu sipariş zaten mevcut", {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        addToast(t("global:successfully"), {
          appearance: "success",
          autoDismiss: true
        });
      }

    })

  }

  useEffect(() => {
    const asyncFunction = async () => {
      getCountry().then((ct) => {
        setCountryCodeList(ct, "phone_code", "country_name");
      });

      changeSelectedCountryCode("90", "Turkey");

      reset({

        authorized_name: userInformations?.customer_info?.customer_name,
        authorized_surname: userInformations?.customer_info?.customer_surname,
      
      })
    };
    asyncFunction();
  }, []);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fixed inset-0 z-10 overflow-y-auto ">
        <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <div
            className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{ overflow: "initial" }}
          >
            <div className="bg-white ">
              <div className="sm:flex sm:items-start">
                <div className="w-full">
                  <div className="flex items-center justify-between px-5 py-3 ">
                    <h3 className="text-xl font-semibold leading-6 text-title" > {t("services:createCrt")}</h3>
                    <i onClick={() => setOpenModal(false)} className="text-2xl cursor-pointer ri-close-line text-main" ></i>
                  </div>
                  <div className="w-full px-5 pt-5 pb-4 bg-modalBg">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                      <div
                        onClick={() => setSelectedTab("1")}
                        className={`col-span-1 bg-white flex items-center justify-center rounded-md p-2 border cursor-pointer ${selectedTab === "1" ? "border-primary-hover" : "border-passive"}`}>
                        <i className={`ri-window-2-line  text-2xl ${selectedTab === "1" ? "text-primary-hover" : "text-main"}`}></i>
                        <p className={`ml-2 font-semibold text-lg ${selectedTab === "1" && "text-primary-hover"}`}>{t("global:other")}</p>
                      </div>
                      <div
                        onClick={() => setSelectedTab("2")}
                        className={`col-span-1 bg-white flex items-center justify-center rounded-md p-2 border cursor-pointer ${selectedTab === "2" ? "border-primary-hover" : "border-passive"}`}>
                        <i className={`ri-windows-line  text-2xl ${selectedTab === "2" ? "text-primary-hover" : "text-main"}`}></i>
                        <p className={`ml-2 font-semibold text-lg ${selectedTab === "2" && "text-primary-hover"}`}> IIS</p>
                      </div>
                    </div>
                    <>

                      <div className="grid grid-cols-2 gap-5">
                        <div className="col-span-2 ">
                          <TextArea
                            label={t("services:csrCode")}
                            placeholder={t("services:csrCode")}
                            type="text"
                            name="csr_code"
                            innerRef={register}
                            rows={4}

                          />
                          {errors.csr_code && errors.csr_code.type === "required" && (
                            <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
                          )}
                        </div>


                        <div className="col-span-2 lg:col-span-1">
                          <TextField
                            label={t("services:authorityName")}
                            placeholder={t("services:authorityName")}
                            type="text"
                            name="authorized_name"
                            innerRef={register}
                            //defaultText={userInformations?.customer_info?.customer_name}

                          />
                          {errors.authorized_name && errors.authorized_name.type === "required" && (
                            <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
                          )}
                        </div>

                        <div className="col-span-2 lg:col-span-1">
                          <TextField
                            label={t("services:authoritySurname")}
                            placeholder={t("services:authoritySurname")}
                            type="text"
                            name="authorized_surname"
                            innerRef={register}
                            //defaultText={userInformations?.customer_info?.customer_surname}

                          />
                          {errors.authorized_name && errors.authorized_name.type === "required" && (
                            <span className="text-sm text-alert-red">{t("global:fieldRequired")}</span>
                          )}
                        </div>

                        <div className="col-span-2 lg:col-span-1">
                          <TextField
                            id="phone"
                            name="phone"
                            type="phone"
                            innerRef={register}
                            countries={countryCodeList}
                            selectedCountry={selectedCountryCode}
                            changeSelectedCountry={changeSelectedCountryCode}
                            label={t("services:authorityPhoneNumber")}
                            placeholder={t("services:authorityPhoneNumber")}
                            value={gsm}
                            onChange={setGsm}

                          />
                          {errors.phone && errors.phone.type === "required" && (
                            <span className="text-sm text-alert-red">{t("global:fieldRequired")}.</span>
                          )}
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                          <TextField
                            label={t("nav:domain")}
                            placeholder="www.alanadi.com"
                            type="text"
                            name="domain"
                            innerRef={register}
                            defaultText={hostname}


                          />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                          <ComboBox
                            id="country"
                            name="country"
                            label={t("global:country")}
                            isSearchable
                            list={country}
                            selected={selectedCountry}
                            setSelected={setSelectedCountry}
                          />
                        </div>
                        {selectedCountry &&
                          selectedCountry.text !== "Ülke Seçiniz" && (
                            <div className="col-span-2 lg:col-span-1">
                              <ComboBox
                                id="state"
                                name="state"
                                label={t("global:province")}
                                isSearchable
                                list={countryStates}
                                selected={selectedState}
                                setSelected={setSelectedState}
                              />
                            </div>
                          )}
                        {selectedState && selectedState.text !== "İl Seçiniz" && (
                          <div className="col-span-2 lg:col-span-1">
                            <ComboBox
                              id="cities"
                              name="cities"
                              label={t("global:district")}
                              isSearchable
                              list={cities}
                              selected={selectedCity}
                              setSelected={setSelectedCity}
                            />
                          </div>
                        )}

                        {/* <div className="col-span-2 lg:col-span-2">
                          <TextField
                            id="email"
                            name="email"
                            label="E-Posta Adresi"
                            placeholder="info@alanadiniz.com"
                            type="email"
                            innerRef={register}

                          />
                          {errors.email && errors.email.type === "required" && (
                            <span className="text-sm text-alert-red">Bu alan zorunludur.</span>
                          )}
                          <p className="text-xs">Doğrulama maili değildir</p>
                        </div> */}


                      </div>
                      <p className="mt-4 ">{t("services:verificationMethod")}</p>
                      <div className="flex mt-1 space-x-7">
                        <div >
                          <RadioButton
                            id="email"
                            name="verification"
                            label={t("global:email")}
                            isCheck={isCheck === "email"}
                            setIsCheck={(e) => setIsCheck(e.target.value)}
                          />
                        </div>
                        <div className="">
                          <RadioButton
                            id="dns"
                            name="verification"
                            label="DNS"
                            isCheck={isCheck === "dns"}
                            setIsCheck={(e) => setIsCheck(e.target.value)}

                          />
                        </div>
                      </div>

                      {isCheck === "email" ?
                        <div>
                          <p className="mt-4">
                             {` ${language === 'tr' ? 
                             `Doğrulama yapılabilmesi için  ${"admin@" + hostname} oluşturulmuş ve mail alabiliyor olması gerekmektedir.`
                              :
                              `In order to be verified,  ${"admin@" + hostname} must be created and be able to receive e-mail.`
                              } 
                             `}
                             
                             </p>
                          <p>{`
                          ${language === 'tr' ? 
                          ` Gelecek mail ${pageData?.brand_name} firması tarafından gönderilecektir.` 
                          :
                        `The next e-mail will be sent by ${pageData?.brand_name}.`   
                        } 
                          `}</p>
                        </div>

                        :
                        <p className="mt-4">
                          {` ${language === 'tr' ?
                           `Oluşturulması gereken DNS kaydı ${userInformations?.customer_info?.customer_email} adresine gönderilecektir.` : 
                            
                           `he DNS record that needs to be created will be sent to ${userInformations?.customer_info?.customer_email}.`
                          }
                          
                          `}
                        </p>

                      }
                    </>
                  </div>

                  <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                    <Button
                      onClick={() => {
                        setSelectedTab("8");
                        setOpenModal(false);
                      }}
                      className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                      tertiary
                    >
                      {t("global:giveUp")}
                    </Button>
                    <Button
                      disabled={buttonDisabled}
                      className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                      primary
                      type="submit"
                    >
                      {t("global:create")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form >
  );
}

export default CRTCreateModal;
