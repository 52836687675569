import AuthorizedCodesSettings from "components/Settings/AuthorizedCodes/AuthorizedCodesSettings";
import BillingAddressSetting from "components/Settings/BillingAdress/BillingAddressSetting";
import LogRecordsSettings from "components/Settings/LogRecords/LogRecordsSettings";
import NotificationAndNewsSettings from "components/Settings/NotificationAndNews/NotificationAndNewsSettings";
import ProfileSettings from "components/Settings/Profile/ProfileSettings";
import ResponsibleAndSubAccountsSettings
  from "components/Settings/ResponsibleAndSubAccounts/ResponsibleAndSubAccountsSettings";
import SecuritySettings from "components/Settings/Security/SecuritySettings";
import Tabs from "components/Tabs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getOwnInfo } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";
import { isNullOrEmptyOrUndefined } from "utils/common";
import { useTranslation } from "react-i18next";

function Settings() {
  const { t, i18n } = useTranslation(["settings", "global", "nav"]);
  const [isResponsible, permissions] = useGlobalStore((state) => [state.isResponsible, state.permissions]);
  const [isCorparete, setIsCorporate] = useState(false);
  const [tabs, setTabs] = useState([]);
  let slug = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    getOwnInfo("*").then(res => {
      let tabs = [];
      if (res) {
        tabs.push({
          name: t("settings:profile"),
          id: "1",
          component: <ProfileSettings />,
          slug: "",
          visible: permissions?.length > 0 ? ((permissions.length === 0  || permissions.find(x => x.id === "settings-profile")?.checked) ? true : false) : true
        });
        if (res.customer_info.content.is_personal) {
          tabs.push({
            name: t("nav:security"),
            id: "2",
            component: <SecuritySettings />,
            slug: "security",
            visible: true
          });
        }

        if (res.authorized_show) {
          tabs.push({
            name: t("nav:authorized-codes"),
            id: "3",
            component: <AuthorizedCodesSettings />,
            slug: "authorized-codes",
            visible: !isResponsible
          });
        }
        tabs.push({
          name: t("nav:responsible-and-sub-accounts"),
          id: "4",
          component: <ResponsibleAndSubAccountsSettings />,
          slug: "responsible-and-sub-accounts",
          visible: permissions?.length > 0 ? ((permissions.length === 0  || permissions.find(x => x.id === "settings-responsible-sub-accounts")?.checked) ? true : false) : true
        });
        tabs.push({
          name: t("nav:billing-addresses"),
          id: "5",
          component: <BillingAddressSetting />,
          slug: "billing-addresses",
          visible: permissions?.length > 0 ? ((permissions.length === 0  || permissions.find(x => x.id === "settings-invoices-address")?.checked )? true : false) : true
        });

        tabs.push({
          name: t("nav:notification-and-news"),
          id: "6",
          component: <NotificationAndNewsSettings />,
          slug: "notification-and-news",
          visible: true
        });

        tabs.push({
          name: t("nav:log-records"),
          id: "7",
          component: <LogRecordsSettings />,
          slug: "log-records",
          visible: true
        });


        setIsCorporate(res.authorized_show);
        setTabs(tabs);
      }

    });
  }, [isResponsible]);
  return (
    <div className="container relative w-full ">
      <Tabs
        tabs={tabs.filter((x) => x.visible)}
        selectedTab={
          !isNullOrEmptyOrUndefined(slug.url)
            ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
            : tabs.filter((x) => x.visible)[0]?.id
        }
        setSelectedTab={(v) => {
          navigate(
            "/settings/" +
            tabs.find((x) => x.id === v).slug
          );
        }}
      />


      <div className="mt-6">
        {!isNullOrEmptyOrUndefined(slug.url)
          ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
          : tabs.filter((x) => x.visible)[0]?.component
        }
      </div>
    </div>
  );
}

export default Settings;
