import Button from "components/FormElements/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import PaymentInformationsTable from "../AccountingTables/PaymentInformationsTable";

function PaymentInformationModal({ setOpenModal, transactions }) {
  const { t, i18n } = useTranslation(["projects", "global"]);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    Ödeme Geçmişi Detayı
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 pt-5 pb-10 overflow-y-scroll bg-modalBg max-h-72 ">
                  <PaymentInformationsTable transactions={transactions} />
                </div>
                <div className="flex flex-col-reverse items-end justify-end px-5 py-5 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    secondary
                    icon
                    dark
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                  >
                    <span>Tamam</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentInformationModal;
