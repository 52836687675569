import React, { useEffect, useState } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "hooks";
import { createTicketStaffRating, listTicketStaffRating } from "services/allServices";
import ReactStars from "react-stars";
import Confetti from "react-confetti";
import { useNavigate } from "react-router";

function TicketStaffRatingModal({ ticketID, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "services"]);
  const { width, height } = useWindowSize();

  const [staffList, setStaffList] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [confetti, setConfetti] = useState(false);

  const navigate = useNavigate();


  const ratingChanged = (newRating, id) => {
    let list = [...ratingList];

    list.map((item, index) => {
      if (item.staff_id == id) {
        item.rating = newRating;
      }
    });

    if (list.find(item => item.staff_id == id) == undefined) {
      list.push({ staff_id: id, rating: newRating });
    }
    setRatingList(list);


  };


  const onCreateTicketStaffRating = () => {
    if (ratingList.length > 0) {
      createTicketStaffRating(ticketID, ratingList).then(res => {
        setConfetti(true);
        setTimeout(() => {
          setOpenModal(false);
          navigate("/ticket-and-support");

        }, 3000);
      });
    }
  };

  useEffect(() => {
    listTicketStaffRating(ticketID).then((res) => {
      setStaffList(res);
      if (res.length == 0) {
        navigate("/ticket-and-support");
      }
    });
  }, []);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-end px-5 py-3 ">

                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-white">
                  <div className="flex flex-col items-center justify-center py-4 mb-6 bg-primary-tertiary">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-hover">
                      <i className="text-3xl text-white ri-emotion-fill"></i>
                    </div>
                    <p className="my-2 text-xl font-semibold text-title">{t("services:evaluateTheInterview")}</p>
                    <p className="text-main">{t("services:improveOurselves")}</p>
                  </div>

                  {staffList?.map((item, index) => {

                    return (
                      <div className="flex items-center justify-between mb-2" key={index}>
                        <div>
                          <p className="text-lg font-semibold text-main">{item?.staff_name_surname}</p>
                          <p className="text-sm text-passive">{t("services:authorized")}</p>
                        </div>
                        <ReactStars
                          count={5}
                          value={ratingList?.length > 0 ? ratingList?.find(rat => rat.staff_id == item.staff_id)?.rating : 0}
                          onChange={(v) => ratingChanged(v, item.staff_id)}
                          size={24}
                          color={"#999999"}
                          color2={"#ff7c30"} />
                      </div>
                    );
                  })
                  }
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-10 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    className="w-full mx-4 "
                    primary
                    disabled={confetti}
                    onClick={onCreateTicketStaffRating}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>

              {confetti &&
                <Confetti
                  width={width}
                  height={height}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketStaffRatingModal;
