import React from "react";
import { useTranslation } from "react-i18next";
import SupportRequestForm from "./SupportRequestForm";
import ComplaintNotificationForm from "./ComplaintNotificationForm";
import { useGlobalStore } from "store/GlobalStore";

function NewSupportRequestModal({
  title,
  question,
  description,
  setOpenModal,
  defaultProduct
}) {
  const { t, i18n } = useTranslation(["projects", "global", "support"]);
  const [supportRequestType, setSupportRequestType] = useGlobalStore((state) => [state.supportRequestType, state.setSupportRequestType]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{t("support:newTicket")}</h3>
              <i
                onClick={() => {
                  setOpenModal(false);
                  setSupportRequestType("1");
                }}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>
            <div className="flex flex-col bg-modalBg rounded-b-md">
              <div className="flex px-5 pt-4 mb-3 ">
                <div
                  onClick={() => setSupportRequestType("1")}
                  className={
                    supportRequestType === "1"
                      ? "flex items-center bg-white px-6 py-2 text-primary-hover font-semibold rounded-md cursor-pointer shadow-sm ring-2 ring-primary-hover mr-5"
                      : "flex items-center bg-white px-6 py-2 text-main font-semibold rounded-md cursor-pointer shadow-sm mr-5"
                  }
                >
                  <i className="mr-2 text-lg ri-question-answer-line"></i>
                  <p
                    className={
                      supportRequestType === "1" && "text-primary-hover"
                    }
                  >
                    {t("support:ticket")}
                  </p>
                </div>
                <div
                  onClick={() => setSupportRequestType("2")}
                  className={
                    supportRequestType === "2"
                      ? "flex items-center bg-white px-6 py-2 text-primary-hover font-semibold rounded-md cursor-pointer shadow-sm ring-2 ring-primary-hover"
                      : "flex items-center bg-white px-6 py-2 text-main font-semibold rounded-md cursor-pointer shadow-sm"
                  }
                >
                  <i className="mr-2 text-lg ri-bank-line"></i>
                  <p
                    className={
                      supportRequestType === "2" && "text-primary-hover"
                    }
                  >
                    {t("support:paymentNotification")}
                  </p>
                </div>
              </div>

              {supportRequestType === "1" && (
                <>
                  <SupportRequestForm
                    setOpenModal={setOpenModal}
                    defaultProduct={defaultProduct}
                    setSupportRequestType={(v) => setSupportRequestType(v)}
                  />
                </>
              )}

              {supportRequestType === "2" && (
                <>
                  <ComplaintNotificationForm
                    setOpenModal={setOpenModal}
                    setSupportRequestType={(v) => setSupportRequestType(v)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSupportRequestModal;
