import React, { useEffect, useState } from "react";
import { changeCurrency, findCurrency } from "helpers/helpersFunc";
import { useGlobalStore } from "store/GlobalStore";
import { useTranslation } from "react-i18next";

function BillCard({ name, productPrice, quantity }) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [splitedPrice, setSplitedPrice] = useState(null);
  const [currencyType, currencyRates] = useGlobalStore((state) => [
    state.currencyType,
    state.currencyRates
  ]);

  const onSplitedPrice = () => {
    if (productPrice) {
      let newPrice = changeCurrency(
        currencyRates,
        currencyType,
        parseFloat((quantity * productPrice).toFixed(2))
      );
      let price = newPrice.toString();
      let splitedPriceArr = price.split(".");
      setSplitedPrice(splitedPriceArr);
    }
  };
  useEffect(() => {
    if (productPrice) {
      onSplitedPrice();
    }
  }, [currencyType]);
  return (
    <div className="flex items-center justify-between p-4 mb-3 bg-white rounded-md shadow-md">
      <h4 className="font-semibold text-title">{name}</h4>
      <div className="flex items-center">
        <h5 className="mr-10 font-semibold text-main">{t("accounting:piece")}: {quantity}</h5>
        {splitedPrice && (
          <div className="flex justify-center w-36 text-primary-hover">
            <p className="text-2xl font-medium lg:text-3xl text-primary-hover">
              {splitedPrice[0]}
            </p>
            <div className="flex flex-col ml-1">
              <span className="p-0 m-0 text-sm leading-4 ">
                .{splitedPrice[1] ? splitedPrice[1] : "00"}
              </span>
              <span className="p-0 m-0 text-xs leading-4 ">+ {t("accounting:tax")}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BillCard;
