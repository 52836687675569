import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useComboBox } from "hooks";

const Library = () => {
  const [value, setValue] = useState("");
  const [
    selectedCountryCode,
    countryCodeList,
    changeSelectedCountryCode,
    setCountryCodeList
  ] = useComboBox();

  const [
    selectedCountry,
    countries,
    setSelectedCountry,
    setCountries
  ] = useComboBox("Ülke Seçiniz");

  useEffect(() => {
    setCountryCodeList(
      [
        { id: "90", text: "Türkiye" },
        { id: "1", text: "USA" },
        { id: "357", text: "Kıbrıs" }
      ],
      "id",
      "text"
    );
    changeSelectedCountryCode("90", "Türkiye");
    setCountries(
      [
        {
          id: 1,
          text: "Türkiye"
        },
        { id: 2, text: "USA" },
        {
          id: 3,
          text: "Bangladeş"
        }
      ],
      "id",
      "text"
    );
  }, []);

  const [error, setError] = useState("Hata");
  return (
    <>
      <div className="w-full ml-2 flex flex-row gap-2 mb-2">
        <Button disabled>Disabled</Button>
        <Button>Submit</Button>
        <Button primary>Submit</Button>
        <Button secondary>Submit</Button>
        <Button tertiary>Submit</Button>
        <Button border red>
          Submit
        </Button>
        <Button border yellow>
          Submit
        </Button>
        <Button border green>
          Submit
        </Button>
        <Button border blue>
          Submit
        </Button>
        <Button secondary icon>
          <i className="ri-shopping-cart-line" />
          <span>Sepet</span>
          <div className="amount">4</div>
        </Button>
        <Button tertiary icon>
          <i className="ri-edit-line" />
          <span>Filtrele</span>
        </Button>
        <Button secondary icon>
          <i className="ri-edit-line" />
          <span>Kaydet</span>
        </Button>
        <Button secondary icon dark>
          <i className="ri-add-circle-line" />
          <span>Yeni Talep Oluştur</span>
        </Button>
      </div>
      <div className="w-1/2 ml-2 grid grid-cols-3 gap-3">
        <TextField
          id="full-name"
          name="full-name"
          type="text"
          ref={null}
          value={value}
          onChange={setValue}
          label="Ad Soyad"
          placeholder="Adınız Soyadınız"
        />
        <TextField
          id="full-name"
          name="full-name"
          type="text"
          ref={null}
          value={value}
          onChange={setValue}
          label="Ad Soyad"
          placeholder="Adınız Soyadınız"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="full-name"
          name="full-name"
          type="text"
          ref={null}
          value={value}
          onChange={setValue}
          label="Ad Soyad"
          placeholder="Adınız Soyadınız"
          disabled
        />

        <TextField
          id="pass"
          name="pass"
          ref={null}
          type="password"
          value={value}
          onChange={setValue}
          label="Şifre"
          placeholder="Şifre"
        />
        <TextField
          id="pass"
          name="pass"
          ref={null}
          type="password"
          value={value}
          onChange={setValue}
          label="Şifre"
          placeholder="Şifre"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="pass"
          name="pass"
          ref={null}
          type="password"
          value={value}
          onChange={setValue}
          label="Şifre"
          placeholder="Şifre"
          disabled
        />

        <TextField
          id="email"
          name="email"
          type="email"
          ref={null}
          value={value}
          onChange={setValue}
          label="E-Posta Adresi"
          placeholder="E-Posta Adresi"
        />
        <TextField
          id="email"
          name="email"
          type="email"
          ref={null}
          value={value}
          onChange={setValue}
          label="E-Posta Adresi"
          placeholder="E-Posta Adresi"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="email"
          name="email"
          type="email"
          ref={null}
          value={value}
          onChange={setValue}
          label="E-Posta Adresi"
          placeholder="E-Posta Adresi"
          disabled
        />

        <TextField
          id="number"
          name="number"
          type="number"
          ref={null}
          value={value}
          onChange={setValue}
          label="Adet"
          placeholder="Adet"
        />
        <TextField
          id="number"
          name="number"
          type="number"
          ref={null}
          value={value}
          onChange={setValue}
          label="Adet"
          placeholder="Adet"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="number"
          name="number"
          type="number"
          ref={null}
          value={value}
          onChange={setValue}
          label="Adet"
          placeholder="Adet"
          disabled
        />

        <TextField
          id="money"
          name="money"
          type="currency"
          ref={null}
          symbol="₺"
          value={value}
          onChange={setValue}
          label="Fiyat"
          placeholder="100.00"
        />
        <TextField
          id="money"
          name="money"
          type="currency"
          ref={null}
          symbol="₺"
          value={value}
          onChange={setValue}
          label="Fiyat"
          placeholder="100.00"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="money"
          name="money"
          type="currency"
          ref={null}
          symbol="₺"
          value={value}
          onChange={setValue}
          label="Fiyat"
          placeholder="100.00"
          disabled
        />

        <TextField
          id="hour"
          name="hour"
          type="time"
          ref={null}
          value={value}
          onChange={setValue}
          label="Saat"
        />
        <TextField
          id="hour"
          name="hour"
          type="time"
          ref={null}
          value={value}
          onChange={setValue}
          label="Saat"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="hour"
          name="hour"
          type="time"
          ref={null}
          value={value}
          onChange={setValue}
          label="Saat"
          disabled
        />

        <TextField
          id="date"
          name="date"
          type="date"
          ref={null}
          value={value}
          onChange={setValue}
          label="Gün"
        />
        <TextField
          id="date"
          name="date"
          type="date"
          ref={null}
          value={value}
          onChange={setValue}
          label="Gün"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="date"
          name="date"
          type="date"
          ref={null}
          value={value}
          onChange={setValue}
          label="Gün"
          disabled
        />

        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
        />
        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
          disabled
        />

        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
        />
        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="search"
          name="search"
          type="search"
          ref={null}
          value={value}
          onChange={setValue}
          onClick={() => alert("clicked!")}
          label="Ara"
          disabled
        />

        <TextField
          id="personal_phone"
          name="personal_phone"
          type="phone"
          countries={countryCodeList}
          selectedCountry={selectedCountryCode}
          changeSelectedCountry={changeSelectedCountryCode}
          innerRef={null}
          value={value}
          onChange={setValue}
          label="Telefon Numaranızı Giriniz"
          placeholder="Telefon numaranız"
        />
        <TextField
          id="personal_phone"
          name="personal_phone"
          type="phone"
          countries={countryCodeList}
          selectedCountry={selectedCountryCode}
          changeSelectedCountry={changeSelectedCountryCode}
          innerRef={null}
          value={value}
          onChange={setValue}
          label="Telefon Numaranızı Giriniz"
          placeholder="Telefon numaranız"
          hasError={error}
          setError={setError}
        />
        <TextField
          id="personal_phone"
          name="personal_phone"
          type="phone"
          countries={countryCodeList}
          selectedCountry={selectedCountryCode}
          changeSelectedCountry={changeSelectedCountryCode}
          innerRef={null}
          value={value}
          onChange={setValue}
          label="Telefon Numaranızı Giriniz"
          placeholder="Telefon numaranız"
          disabled
        />
      </div>
    </>
  );
};

export default Library;
