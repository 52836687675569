import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "hooks";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function Tabs({ tabs, selectedTab, setSelectedTab, maxLength = 0 }) {
  const size = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const { t } = useTranslation("global");
  useOutsideAlerter(wrapperRef);

  //when user clicks the outside of the item, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      {size.width > 900 ? (
        <>
          <div className="flex w-full pb-1 border-b-2 border-primary-disabled ">
            {tabs.length > maxLength &&
              tabs.filter((item, index) => (item.visible === true && (maxLength === 0 || index < maxLength))).map((tab, index) => {
                return (
                  <div className="" key={index}>
                    <div
                      onClick={() => {
                        navigate(location.pathname);
                        setSelectedTab(tab.id);
                      }}
                      className={
                        selectedTab === tab.id
                          ? "mr-5 pb-2 relative cursor-pointer font-semibold text-title whitespace-nowrap"
                          : "mr-5 pb-2 relative cursor-pointer font-semibold text-passive whitespace-nowrap"
                      }
                    >
                      {tab.name}
                      {selectedTab === tab.id && (
                        <div className="w-full py-0.5 absolute -bottom-1.5 rounded-xl bg-primary-hover"></div>
                      )}
                    </div>
                  </div>
                );
              })
            }

            {maxLength > 0 && tabs.length !== maxLength && (
              <div className="relative flex-none" ref={wrapperRef}>
                <div
                  className="relative pb-2 mr-5 font-semibold cursor-pointer text-passive whitespace-nowrap"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <div className="flex items-end space-x-1">
                    {t("global:other")}
                    <i className="ri-arrow-down-s-line"/>
                  </div>
                </div>
                <div className={(isOpen ? "visible" : "invisible") + " absolute z-[60] bg-white p-2 right-0 top-11 rounded-md shadow-md "}>
                  {tabs
                    .filter((x, i) =>
                      i >= maxLength
                    )
                    .map((tab, index) => {
                      return (
                        <div
                          className="flex-none px-3 "
                          key={index}
                        >
                          <div
                            onClick={() => !tab.disabled && setSelectedTab(tab.id)}
                            className={
                              selectedTab === tab.id
                                ? " p-2 relative cursor-pointer font-semibold hover:bg-primary-hover hover:text-title  text-primary-hover whitespace-nowrap rounded-md"
                                : " p-2 relative cursor-pointer font-semibold text-passive  hover:bg-primary-hover hover:text-title  whitespace-nowrap rounded-md"
                            }
                          >
                            <span className={tab.disabled ? "text-gray-300" : ""}>
                              {tab.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

          </div>

        </>
      ) : (
        <div
          className="flex w-full pb-1 overflow-x-scroll overflow-y-hidden border-b-2 mobileTabsScroll border-primary-disabled">
          {tabs.filter(item => item.visible === true).map((tab, index) => {
            return (
              <div className="flex-none" key={index}>
                <div
                  onClick={() => {
                    navigate(location.pathname);
                    setSelectedTab(tab.id);
                  }}
                  className={
                    selectedTab === tab.id
                      ? "mr-5 pb-2 relative cursor-pointer font-semibold text-title"
                      : "mr-5 pb-2 relative cursor-pointer font-semibold text-passive"
                  }
                >
                  {tab.name}
                  {selectedTab === tab.id && (
                    <div className="w-full py-0.5 absolute -bottom-1.5 rounded-xl bg-primary-hover"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Tabs;
