import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Error, Input, Label, Li, Ul } from "./styles";
import Arrow from "../Input/TextField/components/Arrow";
import { useTranslation } from "react-i18next";

const ComboBox = styled(
  ({
    id,
    name,
    list,
    selected,
    setSelected,
    label,
    hasError,
    innerRef = () => {
    },
    multiselect,
    isGrouped,
    setError,
    isSearchable,
    disabled,
    onSelected,
    className,
    isDefaultAvailable,
    isListOnTop = false,
    onClickItemHTML,
    onClickItemEvent,
    defaultText,
    isBackendSearch,
    backendSearchMethod,
    defaultItem,
    searchType = "includes",
    hideLabel = false,
    addButton
  }) => {

    const { t, i18n } = useTranslation(["global"]);

    const [selectedItem, setSelectedItem] = useState({
      id: 0,
      text: typeof defaultText !== "undefined" ? defaultText : ""
    });
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [newList, setNewList] = useState(list);
    const error = hasError;
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    //when user clicks the outside of the item, it will be closed
    function useOutsideAlerter(ref) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
    const getListItems = (list) => {
      let items = "";
      if (list?.length > 0)
        for (let i = 0; i < list?.length; i++)
          if (i + 1 !== list?.length) items += list[i].text + ", ";
          else items += list[i].text;
      return items;
    };

    const checkIfExist = (id) => {
      for (let i = 0; i < selected?.length; i++) {
        if (selected[i].id === id) return true;
      }
      return false;
    };

    const filterList = (val) => {
      if (val?.length <= 1) setNewList(list);
      else if (searchType === "startsWith") {
        setNewList(
          list.filter((x) =>
            x.text.toLowerCase().startsWith(searchText.toLowerCase())
          )
        );
      } else if (searchType === "includes") {
        setNewList(
          list.filter((x) =>
            x.text.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      }
    };

    useEffect(() => {
      setNewList(list);
      typeof defaultItem !== "undefined" && setSelectedItem(defaultItem);
    }, [list, defaultItem]);

    useEffect(() => {
      setTimeout(function () {
        inputRef?.current?.focus();
        inputRef2?.current?.focus();
      }, 1);
    }, [isOpen]);

    if (typeof addButton !== "undefined") {
      return (
        <div className="flex flex-row">
          <div className="w-full">
            <div className={"relative " + className} ref={wrapperRef}>
              {!hideLabel ? <Label htmlFor={name}>{label}</Label> : <></>}
              {/*<Ul/> is the visible part of the combobox which displays the selected item*/}
              <Ul
                isOpen={isOpen}
                disabled={disabled}
                onClick={() => {
                  //if combobox is not disabled, you can reset error messages and open combobox
                  if (!disabled) {
                    typeof setError !== "undefined" && setError("");
                    setIsOpen(!isOpen); //toggle combobox
                  }
                }}
              >
                <input
                  type="hidden"
                  name={name}
                  {...innerRef(name)}
                  id={id}
                  autoFocus={false}
                  value={
                    !multiselect && typeof selectedItem.id !== "undefined"
                      ? selectedItem.id
                      : null
                  }
                />
                <input
                  type="text"
                  disabled={true}
                  className={
                    "border-none m-0 p-0 text-sm text-main outline-none ring-0 focus:outline-none focus:border-none focus:ring-0 cursor-pointer w-full " +
                    (disabled &&
                      "bg-primary-disabled cursor-not-allowed text-passive")
                  }
                  autoFocus={false}
                  value={
                    !multiselect
                      ? typeof selected !== "undefined"
                        ? selected !== null && selected?.text !== ""
                          ? selected?.text
                          : (label !== "" ? label + " " : "") + "Seçiniz"
                        : selectedItem.id === 0
                          ? typeof defaultText !== "undefined"
                            ? defaultText
                            : label !== ""
                              ? label + " Seçiniz"
                              : "Seçiniz"
                          : selectedItem.text
                      : selected?.length > 0 && selected[0].id !== 0
                        ? getListItems(selected)
                        : selected[0].text !== ""
                          ? selected[0].text
                          : label + " Seçiniz"
                  }
                />
              </Ul>
              {/*the div below shows the combobox items when <Ul/> is clicked*/}
              <div
                className={
                  "rounded-lg bg-white max-h-64 overflow-y-auto border-none z-50 mt-0.5 ring-0 outline-none absolute shadow-md ring-0 w-full " +
                  (isOpen ? "visible" : "invisible")
                }
              >
                {/*list combobox items*/}
                {isSearchable && list?.length > 0 && (
                  <Input
                    type="text"
                    ref={inputRef}
                    className="my-1 border-none outline-none ring-0 focus:outline-none focus:ring-0 focus:border-none"
                    value={searchText}
                    onChange={(e) => {
                      if (isBackendSearch) {
                        setSearchText(e.target.value);
                        backendSearchMethod(e.target.value);
                      } else {
                        setSearchText(e.target.value);
                        setNewList(list);
                        filterList(e.target.value);
                      }
                      //store the search text in a state and filter the list
                      // if (typeof selected !== 'undefined') {
                      // } else if (typeof selectedItem.id !== 0) {
                      // }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (newList?.length > 0) {
                          if (typeof selected !== "undefined") {
                            setSelected(newList[0].id, newList[0].text, newList[0]?.otherValues);
                          } else {
                            setSelectedItem({
                              id: newList[0].id,
                              text: newList[0].text
                            });
                          }
                          setIsOpen(false);
                          setSearchText("");
                          setNewList(list);
                        }
                      }
                    }}
                    placeholder={t("global:search")}
                    autoFocus={true}
                  />
                )}
                {newList?.length > 0 &&
                  typeof isDefaultAvailable !== "undefined" &&
                  searchText === "" &&
                  isDefaultAvailable && (
                    <Li
                      value={0}
                      selected={
                        typeof selected !== "undefined"
                          ? selected !== {} && selected.id === 0
                          : selectedItem !== {} && selectedItem.id === 0
                      }
                      onClick={() => {
                        if (typeof selected !== "undefined") {
                          setSelected(
                            0,
                            typeof defaultText !== "undefined"
                              ? defaultText
                              : label !== ""
                                ? label + " Seçiniz"
                                : "Seçiniz", null
                          );
                        } else {
                          setSelectedItem({
                            id: 0,
                            text:
                              typeof defaultText !== "undefined"
                                ? defaultText
                                : label !== ""
                                  ? label + " Seçiniz"
                                  : "Seçiniz"
                          });
                        }
                        setIsOpen(false);
                        setSearchText("");
                        setNewList(list);
                      }}
                      isGroup={false}
                    >
                      {typeof defaultText !== "undefined"
                        ? defaultText
                        : label !== ""
                          ? label + " Seçiniz"
                          : "Seçiniz"}
                    </Li>
                  )}
                {typeof newList !== "undefined" &&
                  newList !== null &&
                  newList?.length > 0 &&
                  isListOnTop
                  ? newList
                    .filter((x) =>
                      searchText !== ""
                        ? x.text
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                        : x
                    )
                    .map((value, key) => {
                      return !multiselect ? (
                        <Li
                          value={value.id}
                          key={key}
                          selected={
                            typeof selected !== "undefined"
                              ? selected !== {} && value.id === selected.id
                              : selectedItem !== {} &&
                              value.id === selectedItem.id
                          }
                          onClick={() => {
                            if (
                              (!value.id.toString().startsWith("-1") &&
                                typeof setSelected !== "undefined") || innerRef
                            ) {
                              if (typeof selected !== "undefined") {
                                setSelected(value.id, value.text, value?.otherValues);
                              } else {
                                setSelectedItem({
                                  id: value.id,
                                  text: value.text
                                });
                              }
                              setIsOpen(false);
                              setSearchText("");
                              setNewList(list);
                            }
                            if (onSelected) {
                              onSelected({
                                id: value.id,
                                text: value.text
                              });

                              setIsOpen(false);
                              setSearchText("");
                            }
                          }}
                          isGroup={
                            isGrouped && value.id.toString().startsWith("-1")
                          }
                        >
                          <div className="flex flex-row w-full">
                            <div className="w-full">{value.text}</div>
                            <div
                              className="flex justify-end"
                              onClick={() => onClickItemEvent(value)}
                            >
                              {onClickItemHTML}
                            </div>
                          </div>
                        </Li>
                      ) : (
                        <Li
                          value={value.id}
                          selected={
                            typeof selected !== "undefined" &&
                            selected !== {} &&
                            value.id === selected.id
                          }
                          onClick={() => {
                            if (typeof selected !== "undefined") {
                              setSelected(value.id, value.text, value?.otherValues);
                              setSearchText("");
                            } else {
                              setSelectedItem({
                                id: value.id,
                                text: value.text
                              });
                              setSearchText("");
                            }
                          }}
                        >
                          <input
                            autoFocus={false}
                            type="checkbox"
                            checked={checkIfExist(value.id)}
                            onChange={() => {
                              if (typeof selected !== "undefined") {
                                setSelected(value.id, value.text, value?.otherValues);
                              } else {
                                setSelectedItem({
                                  id: value.id,
                                  text: value.text
                                });
                              }
                            }}
                          />
                          {value.text}
                        </Li>
                      );
                    })
                  : newList
                    .filter((x) =>
                      searchText !== ""
                        ? x.text
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                        : x
                    )
                    .map((value, key) => {
                      return !multiselect ? (
                        <Li
                          value={value.id}
                          key={key}
                          selected={
                            typeof selected !== "undefined"
                              ? selected !== {} && value.id === selected.id
                              : selectedItem !== {} &&
                              value.id === selectedItem.id
                          }
                          onClick={() => {
                            if (
                              (!value.id.toString().startsWith("-1") &&
                                typeof setSelected !== "undefined") || innerRef
                            ) {
                              if (typeof selected !== "undefined") {
                                setSelected(value.id, value.text, value?.otherValues);
                              } else {
                                setSelectedItem({
                                  id: value.id,
                                  text: value.text
                                });
                              }
                              setIsOpen(false);
                              setSearchText("");
                              setNewList(list);
                            }
                            if (onSelected) {
                              onSelected({
                                id: value.id,
                                text: value.text
                              });

                              setIsOpen(false);
                              setSearchText("");
                            }
                          }}
                          isGroup={
                            isGrouped && value.id.toString().startsWith("-1")
                          }
                        >
                          <div className="flex flex-row w-full">
                            <div className="w-full">{value.text}</div>
                            <div className="flex items-center justify-end ">
                              {onClickItemHTML?.map((item, index) => (
                                <div onClick={() => onClickItemEvent[index](value)} key={index}>{item}</div>
                              ))}
                            </div>
                          </div>
                        </Li>
                      ) : (
                        <Li
                          input
                          value={value.id}
                          selected={
                            typeof selected !== "undefined" &&
                            selected !== {} &&
                            value.id === selected.id
                          }
                          onClick={() => {
                            if (typeof selected !== "undefined") {
                              setSelected(value.id, value.text, value?.otherValues);
                              setSearchText("");
                            } else {
                              setSelectedItem({
                                id: value.id,
                                text: value.text
                              });
                              setSearchText("");
                            }
                          }}
                        >
                          <input
                            type="checkbox"
                            autoFocus={false}
                            checked={checkIfExist(value.id)}
                            onChange={() => {
                              if (typeof selected !== "undefined") {
                                setSelected(value.id, value.text, value?.otherValues);
                              } else {
                                setSelectedItem({
                                  id: value.id,
                                  text: value.text
                                });
                              }
                            }}
                          />
                          {value.text}
                        </Li>
                      );
                    })}
              </div>
              <Arrow
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                top={
                  label !== "" && typeof label !== "undefined"
                    ? "top-9"
                    : "top-3"
                }
                disabled={disabled}
              />
              {error && <Error>{error}</Error>}
            </div>
          </div>
          <div className="ml-2 pt-9">
            <svg
              onClick={addButton}
              className="cursor-pointer"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.16666 8.16699V4.83366H9.83332V8.16699H13.1667V9.83366H9.83332V13.167H8.16666V9.83366H4.83332V8.16699H8.16666ZM8.99999 17.3337C4.39749 17.3337 0.666656 13.6028 0.666656 9.00033C0.666656 4.39783 4.39749 0.666992 8.99999 0.666992C13.6025 0.666992 17.3333 4.39783 17.3333 9.00033C17.3333 13.6028 13.6025 17.3337 8.99999 17.3337ZM8.99999 15.667C10.7681 15.667 12.4638 14.9646 13.714 13.7144C14.9643 12.4641 15.6667 10.7684 15.6667 9.00033C15.6667 7.23222 14.9643 5.53652 13.714 4.28628C12.4638 3.03604 10.7681 2.33366 8.99999 2.33366C7.23188 2.33366 5.53619 3.03604 4.28594 4.28628C3.0357 5.53652 2.33332 7.23222 2.33332 9.00033C2.33332 10.7684 3.0357 12.4641 4.28594 13.7144C5.53619 14.9646 7.23188 15.667 8.99999 15.667V15.667Z"
                fill="#FF7C30"
              />
            </svg>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"relative " + className} ref={wrapperRef}>
          {!hideLabel ? <Label htmlFor={name}>{label}</Label> : <></>}
          {/*<Ul/> is the visible part of the combobox which displays the selected item*/}
          <Ul
            isOpen={isOpen}
            disabled={disabled}
            onClick={() => {
              //if combobox is not disabled, you can reset error messages and open combobox
              if (!disabled) {
                typeof setError !== "undefined" && setError("");
                setIsOpen(!isOpen); //toggle combobox
              }
            }}
          >
            <input
              autoFocus={false}
              type="hidden"
              name={name}
              {...innerRef(name)}
              id={id}
              value={
                !multiselect && selectedItem.id !== 0 ? selectedItem.id : null
              }
            />
            <input
              autoFocus={false}
              disabled={true}
              type="text"
              className={
                "border-none m-0 p-0 text-sm text-main outline-none ring-0 focus:outline-none focus:border-none focus:ring-0 cursor-pointer w-full " +
                (disabled &&
                  "bg-primary-disabled cursor-not-allowed text-passive")
              }
              value={
                !multiselect
                  ? typeof selected !== "undefined"
                    ? selected !== null && selected?.text !== ""
                      ? selected?.text
                      : (label !== "" ? label + " " : "") + "Seçiniz"
                    : selectedItem.id === 0
                      ? typeof defaultText !== "undefined"
                        ? defaultText
                        : label !== ""
                          ? label + " Seçiniz"
                          : "Seçiniz"
                      : selectedItem?.text
                  : selected?.length > 0 && selected[0].id !== 0
                    ? getListItems(selected)
                    : selected[0]?.text !== ""
                      ? selected[0]?.text
                      : label + " Seçiniz"
              }
            />
          </Ul>
          {/*the div below shows the combobox items when <Ul/> is clicked*/}
          <div
            className={
              "rounded-lg bg-white max-h-64 overflow-y-auto border-none z-50 mt-0.5 ring-0 outline-none absolute shadow-md ring-0 w-full " +
              (isOpen ? "visible" : "invisible") +
              (isListOnTop ? " bottom-11" : "")
            }
          >
            {/*list combobox items*/}
            {isSearchable && list?.length > 0 && (
              <Input
                ref={inputRef2}
                autoFocus={true}
                type="text"
                className="my-1 border-none outline-none ring-0 focus:outline-none focus:ring-0 focus:border-none"
                value={searchText}
                onChange={(e) => {
                  //store the search text in a state and filter the list
                  // if (typeof selected !== 'undefined') {
                  if (isBackendSearch) {
                    setSearchText(e.target.value);
                    backendSearchMethod(e.target.value);
                  } else {
                    setSearchText(e.target.value);
                    setNewList(list);
                    filterList(e.target.value);
                  }
                  // } else if (typeof selectedItem.id !== 0) {
                  // }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (newList?.length > 0) {
                      if (typeof selected !== "undefined") {
                        setSelected(newList[0].id, newList[0].text, newList[0]?.otherValues);
                      } else {
                        setSelectedItem({
                          id: newList[0].id,
                          text: newList[0].text
                        });
                      }
                      setIsOpen(false);
                      setSearchText("");
                      setNewList(list);
                    }
                  }
                }}
                placeholder={t("global:search")}
              />
            )}
            {newList?.length > 0 &&
              typeof isDefaultAvailable !== "undefined" &&
              searchText === "" &&
              isDefaultAvailable && (
                <Li
                  value={0}
                  selected={
                    typeof selected !== "undefined"
                      ? selected !== {} && selected.id === 0
                      : selectedItem !== {} && selectedItem.id === 0
                  }
                  onClick={() => {
                    if (typeof selected !== "undefined") {
                      setSelected(
                        0,
                        typeof defaultText !== "undefined"
                          ? defaultText
                          : label !== ""
                            ? label + " Seçiniz"
                            : "Seçiniz", null
                      );
                    } else {
                      setSelectedItem({
                        id: 0,
                        text:
                          typeof defaultText !== "undefined"
                            ? defaultText
                            : label !== ""
                              ? label + " Seçiniz"
                              : "Seçiniz"
                      });
                    }
                    setIsOpen(false);
                    setSearchText("");
                    setNewList(list);
                  }}
                  isGroup={false}
                >
                  {typeof defaultText !== "undefined"
                    ? defaultText
                    : label !== ""
                      ? label + " Seçiniz"
                      : "Seçiniz"}
                </Li>
              )}
            {typeof newList !== "undefined" &&
              newList !== null &&
              newList?.length > 0 &&
              newList
                .filter((x) =>
                  searchText !== ""
                    ? x.text.toLowerCase().includes(searchText.toLowerCase())
                    : x
                )
                .map((value, key) => {
                  return !multiselect ? (
                    <Li
                      key={key}
                      value={value.id}
                      selected={
                        typeof selected !== "undefined"
                          ? selected !== {} && value.id === selected.id
                          : selectedItem !== {} && value.id === selectedItem.id
                      }
                      onClick={() => {
                        if (
                          (!value.id.toString().startsWith("-1") &&
                            typeof setSelected !== "undefined") || innerRef
                        ) {
                          if (typeof selected !== "undefined") {
                            setSelected(value.id, value.text, value?.otherValues);
                          } else {
                            setSelectedItem({ id: value.id, text: value.text });
                          }
                          setIsOpen(false);
                          setSearchText("");
                          setNewList(list);
                        }
                        if (onSelected) {
                          onSelected({ id: value.id, text: value.text });

                          setIsOpen(false);
                          setSearchText("");
                        }
                      }}
                      isGroup={
                        isGrouped && value.id.toString().startsWith("-1")
                      }
                    >
                      <div className="flex flex-row w-full">
                        <div className="w-full">{value.text}</div>
                        <div
                          className="flex justify-end"
                          onClick={() => onClickItemEvent(value)}
                        >
                          {onClickItemHTML}
                        </div>
                      </div>
                    </Li>
                  ) : (
                    <Li
                      input
                      value={value.id}
                      selected={
                        typeof selected !== "undefined" &&
                        selected !== {} &&
                        value.id === selected.id
                      }
                      onClick={() => {
                        if (typeof selected !== "undefined") {
                          setSelected(value.id, value.text, value?.otherValues);
                          setSearchText("");
                        } else {
                          setSelectedItem({ id: value.id, text: value.text });
                          setSearchText("");
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        autoFocus={false}
                        checked={checkIfExist(value.id)}
                        onChange={() => {
                          if (typeof selected !== "undefined") {
                            setSelected(value.id, value.text, value?.otherValues);
                          } else {
                            setSelectedItem({ id: value.id, text: value.text });
                          }
                        }}
                      />
                      {value.text}
                    </Li>
                  );
                })}
          </div>
          <Arrow
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            top={
              label !== "" && typeof label !== "undefined" ? "top-9" : "top-3"
            }
            disabled={disabled}
          />
          {error && <Error>{error}</Error>}
        </div>
      );
    }
  }
)``;

export default ComboBox;
