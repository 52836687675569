import React, { useEffect, useState } from "react";
import HomeCard from "components/HomePage/HomeCard";
import HomePriceCard from "components/HomePage/HomePriceCard";
import { useTranslation } from "react-i18next";
import { checkCustomerActivationCode, getCustomerCredit, getHomepageCounts, sendCustomerActivationCode } from "services/allServices";
import { Link } from "react-router-dom";
import AddBalanceModal from "components/Accounting/AccountingModals/AddBalanceModal";
import { TailSpin } from "react-loader-spinner";
import { useAuthStore } from "store/AuthStore";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Validation from "components/Validation";
import { Button } from "components";

function Homepage() {
  const { t } = useTranslation(["homepage"]);

  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const { addToast } = useToasts();

  const [customerCredit, setCustomerCredit] = useState();

  const [counts, setCounts] = useState();
  const [userInformations, setUserInformations] = useAuthStore((state) => [state.userInformations, state.setUserInformations]);
  const [OTP, setOTP] = useState("");
  const [viewOTP, setViewOTP] = useState(false);
  const [errorCount, setErrorCount] = useState(5);
  const [banDate, setBanDate] = useState();



  const onSendCustomerActivationCode = () => {
    sendCustomerActivationCode().then(res => {
      if (res?.status === false) {
        setErrorCount(0);
        setBanDate(moment(res?.message).format("DD-MM-YYYY , h:mm:ss"));

      } else {
        setViewOTP(true);
      }
    });
  };

  const onCheckCustomerActivationCode = () => {
    checkCustomerActivationCode(OTP).then(res => {
      if (res?.status === "not_match") {
        let newRemainingEntry = 5 - res?.error_count;
        setErrorCount(newRemainingEntry);
        addToast("Girdiğiniz kod hatalı", {
          appearance: "error",
          autoDismiss: true
        });
      }

      if (res?.status === "you_are_banned") {
        setErrorCount(0);
        setBanDate(moment(res?.message).format("DD-MM-YYYY , h:mm:ss"));
      }
      if (res?.status === "mail_validated") {
        let newInfo = { ...userInformations };
        newInfo.customer_info.is_activated = true;
        setUserInformations(newInfo);

        addToast("Hesabınız Aktive Edildi", {
          appearance: "success",
          autoDismiss: true
        });
      }
    });
  };


  const onSetOTP = (v) => {
    setOTP(v.toUpperCase());
  };


  useEffect(() => {
    getCustomerCredit().then(res => {
      setCustomerCredit(res);
    });
    getHomepageCounts().then(res => {
      setCounts(res);
    });
  }, []);
  return (
    <div className="container w-full">
      {userInformations?.customer_info?.is_activated == true ?

        <>

          {counts ?
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3 lg:col-span-1">
                <Link to="/ticket-and-support">
                  <HomeCard animate count={counts?.tickets || 0}
                    description={t("homepage:box1")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/accounting">
                  <HomeCard count={counts?.overdue_invoices || 0} description={t("homepage:box2")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/domains">
                  <HomeCard count={counts?.owned_domains || 0} description={t("homepage:box3")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/virtual-servers">
                  <HomeCard count={counts?.owned_cloud_servers || 0} description={t("homepage:box4")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/cloud-servers">
                  <HomeCard count={counts?.owned_clouds || 0} description={t("homepage:box5")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/dedicated-servers">
                  <HomeCard count={counts?.owned_dedicated_servers || 0} description={t("homepage:box6")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/web-hosting">
                  <HomeCard count={counts?.owned_web_hosting || 0} description={t("homepage:box7")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/mail-hosting">
                  <HomeCard count={counts?.owned_mail_servers || 0} description={t("homepage:box8")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/colocation">
                  <HomeCard count={counts?.owned_co_locations || 0} description={t("homepage:box9")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/ssl-certificates">
                  <HomeCard count={counts?.owned_ssl_certificates || 0} description={t("homepage:box10")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/control-panel">
                  <HomeCard count={counts?.owned_control_panels || 0} description={t("homepage:box11")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/ip">
                  <HomeCard count={counts?.owned_ips || 0} description={t("homepage:box12")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/support">
                  <HomeCard count={counts?.owned_supports || 0} description={t("homepage:box13")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/iys">
                  <HomeCard count={counts?.owned_iyss || 0} description={t("homepage:box14")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/firewall">
                  <HomeCard count={counts?.owned_firewalls || 0} description={t("homepage:box15")} />
                </Link>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <Link to="/services/proxy">
                  <HomeCard count={counts?.owned_proxys || 0} description={t("homepage:box16")} />
                </Link>
              </div>
            </div>
            :
            <div className="flex flex-col items-center justify-center lg:my-20">
              <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                type="Oval"
                color="#ff7c30"
                height={75}
                width={75}
                timeout={3000} //3 secs
              />
            </div>
          }

          <div>
            <h2 className="mt-10 mb-6 font-semibold">{t("homepage:balance")}</h2>
            <div className="grid grid-cols-12 gap-7">
              <div className="col-span-6 lg:col-span-3 2xl:col-span-2">
                <HomePriceCard credit={customerCredit?.credit} />
              </div>
              <div className="col-span-6 mb-10 lg:mb-0 lg:col-span-4">
                <h5 className="mb-3 font-semibold">{t("homepage:addBalance")}</h5>
                {customerCredit?.credit === 0 &&
                  <>
                    <p>{t("homepage:balanceDesc1")}</p>
                    <p>{t("homepage:balanceDesc2")}</p>
                  </>
                }
                <p onClick={() => setOpenBalanceModal(true)}
                  className="mt-3 font-semibold cursor-pointer text-primary hover:text-primary-hover">
                  {t("homepage:balanceLoad")}
                </p>
              </div>
            </div>
          </div>
          {/* <div>
        <h2 className="mt-10 mb-6 font-semibold">{t("homepage:shortcuts")}</h2>
        <div className="grid grid-cols-12 gap-7">
        <div className="col-span-6 lg:col-span-3 2xl:col-span-2">
        <div className="flex items-center justify-around w-full h-full px-4 py-8 transition-all duration-300 bg-white rounded-md shadow-md cursor-pointer hover:shadow-lg">
        <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
        d="M16.5 17V11H19.5V17H25.5V20H19.5V26H16.5V20H10.5V17H16.5ZM18 33.5C9.7155 33.5 3 26.7845 3 18.5C3 10.2155 9.7155 3.5 18 3.5C26.2845 3.5 33 10.2155 33 18.5C33 26.7845 26.2845 33.5 18 33.5ZM18 30.5C21.1826 30.5 24.2348 29.2357 26.4853 26.9853C28.7357 24.7348 30 21.6826 30 18.5C30 15.3174 28.7357 12.2652 26.4853 10.0147C24.2348 7.76428 21.1826 6.5 18 6.5C14.8174 6.5 11.7652 7.76428 9.51472 10.0147C7.26428 12.2652 6 15.3174 6 18.5C6 21.6826 7.26428 24.7348 9.51472 26.9853C11.7652 29.2357 14.8174 30.5 18 30.5V30.5Z"
        fill="#D8D8D8"
        />
        </svg>
        </div>
        </div>
        <div className="col-span-6 lg:col-span-4 ">
        <h5 className="mb-3 font-semibold">{t("homepage:addShortcut")}</h5>
        <p>{t("homepage:shortcutDesc1")}</p>
        <p>{t("homepage:shortcutDesc2")}</p>
        <p className="mt-3 font-semibold cursor-pointer text-primary hover:text-primary-hover">
        {t("homepage:addShortcut")}
        </p>
        </div>
        </div>
      </div> */}

          {openBalanceModal && (
            <AddBalanceModal setOpenModal={() => setOpenBalanceModal(false)} />
          )}
        </>
        :
        <div>
          <h2 className="mt-5 mb-5 text-3xl font-bold">Aktivasyon</h2>
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 lg:col-span-3">
              <p>
                Hesabınız şu an onaylanmamış görünüyor. Onay için telefonunuza
                gelen kodu giriniz.
              </p>
            </div>
            <div className="col-span-12 lg:col-span-3">
              {!viewOTP &&
                <Button onClick={() => onSendCustomerActivationCode()} primary className="flex items-center px-3">
                  <i className="mr-2 ri-add-circle-line"></i>
                  Onay Kodu Gönder
                </Button>
              }

            </div>
          </div>

          {viewOTP &&
            <div className="mt-5">
              <Validation
                value={OTP}
                onChange={onSetOTP}
                autoFocus
                inputClassName={
                  "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                }
                OTPLength={6}
                otpType="string"
                disabled={false}
                secure
              />

              <Button onClick={() => onCheckCustomerActivationCode()} disabled={errorCount === 0 && true} primary
                className="flex items-center px-3 mt-5">
                <i className="mr-2 ri-add-circle-line"></i>
                Onay Kodu Gönder
              </Button>
            </div>

          }
        </div>
      }
    </div>
  );
}

export default Homepage;
