import Button from "components/FormElements/Button";
import TextField from "components/FormElements/Input/TextField"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useGlobalStore } from "store/GlobalStore";
import IPServiceCard from "./IPServiceCard";
import IPServiceFilterModal from "./IPServiceFilterModal";
import { listProductIP } from "services/allServices";

const IPServices = () => {
    const { t, i18n } = useTranslation(["services", "global"]);
    const [searchValue, setSearchValue] = useState("");
    const [IPService, setIPService] = useState();
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [ipFilterData, setIpFilterData] = useGlobalStore((state) => [state.ipFilterData, state.setIpFilterData]);

    const onListIP = (configureFilter) => {
        setIsLoading(true);
    
        listProductIP(configureFilter ? configureFilter : ipFilterData).then(res => {
          setIPService(res);
          setIsLoading(false);
        });
      };

      useEffect(() => {
        const asyncFunction = async () => {
          let configureFilter = {
            content: {
              project_id_list: null,
              status_list: ["active", "waiting", "suspended"]
            },
            count: 1
          };
          if (!ipFilterData || ipFilterData == "") {
            await setIpFilterData(configureFilter);
            await onListIP(configureFilter);
    
          } else {
            setIpFilterData({
              ...ipFilterData,
            })
            await onListIP(ipFilterData);
          }
        };
        asyncFunction();
      }, []);

  return (
    <div>
        <div className="flex flex-col justify-between lg:flex-row lg:items-center">
            <h2 className="my-5 font-semibold">IP</h2>
            <div className="flex flex-col space-x-6 lg:flex-row">
                <div className="items-center lg:flex">
                    <TextField
                        id="search"
                        name="search"
                        type="search"
                        ref={null}
                        className="relative w-full lg:w-96"
                        value={searchValue}
                        onChange={setSearchValue}
                        placeholder={t("global:enterProductNameAndId")}
                    />
                </div>

                <div className="relative">
                    <Button tertiary className="flex items-center mt-2 -ml-6 lg:mt-0 lg:-ml-0 px-7" onClick={() => setOpenFilterModal(true)}>
                        <i className="ri-equalizer-line" />
                        <span>{t("global:filter")}</span>
                    </Button>
                    {(ipFilterData?.count && ipFilterData?.count > 0) ?
                        <div className="absolute right-0 flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 bg-primary-hover">
                        {ipFilterData?.count}
                    </div> :
                    < ></>
                    }
                </div>
            </div>
        </div>

        <div>

        {!isLoading ?
          <div>
            {IPService && IPService?.status !== "product_not_found" ?
              IPService.filter(item => (
                item.product_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.owned_product_code.toLowerCase().includes(searchValue.toLowerCase())
              ))?.map((item, index) => {
                return (
                  <IPServiceCard datas={item} key={index} />
                )
              })
              :
              <div className="flex flex-col items-center justify-center my-10 lg:my-20">
                <img src="/servicesOther.svg" width="403" height="339" />
                <h1>{t("global:noProduct")}</h1>
                <h5>{t("global:productDesc")}</h5>
              </div>
            }

          </div>
          :
          <div className="flex items-center justify-center w-full mt-20">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
              type="Oval"
              color="#ff7c30"
              height={75}
              width={75}
              timeout={3000} //3 secs
            />
          </div>
        }
      </div>

      {openFilterModal && (
        <IPServiceFilterModal openModal={openFilterModal} setOpenModal={setOpenFilterModal}
          ipFilterData={ipFilterData}
          setIpFilterData={setIpFilterData}
          onListIP={onListIP}
        />
      )}
    </div>
  )
}

export default IPServices