import React from "react";

function Arrow({ isOpen, disabled, setIsOpen, top }) {
  return (
    <i
      onClick={setIsOpen}
      className={
        (isOpen ? "ri-arrow-up-s-fill" : "ri-arrow-down-s-fill") +
        " float-right outline-none border-l mr-2 pl-1.5 h-auto w-8 z-10 border-primary-disabled border-solid absolute bg-white text-xl leading-5 -right-1 text-primary-disabled cursor-pointer " +
        top +
        (!disabled ? " visible" : " invisible")
      }
    />
  );
}

export default Arrow;
