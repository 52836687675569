import React from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";

function AuthorizedCreateModal({
                                 title,
                                 question,
                                 description,
                                 setOpenModal,
                                 buttonText
                               }) {
  const { t, i18n } = useTranslation(["settings", "global", "projects"]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white">
            <div className="flex items-center justify-between px-3 py-3">
              <h3 className="font-semibold">{t("settings:createNewAuthority")}</h3>
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>

            <div className="flex flex-col px-5 pt-4 pb-10 bg-modalBg">
              <TextField
                id="email"
                name="email"
                type="text"
                ref={null}
                label={t("global:email")}
                placeholder="info@alanadi.com"
              />
              <div className="grid grid-cols-2 gap-4 mt-5">
                <div className="col-span-2 lg:col-span-1">
                  <TextField
                    id="pass"
                    name="pass"
                    ref={null}
                    type="password"
                    label={t("settings:password")}
                    placeholder="••••••••"
                  />
                </div>
                <div className="col-span-2 lg:col-span-1">
                  <TextField
                    id="passAgain"
                    name="passAgain"
                    ref={null}
                    type="password"
                    label={t("settings:repassword")}
                    placeholder="••••••••"
                  />
                </div>
              </div>
              <p className="mt-5">
                {t("settings:authorityCodeDesc")}
              </p>
              <p className="mt-4 font-semibold text-alert-red">
               {t("settings:passwordNote")}
              </p>
            </div>

            <div className="flex flex-col-reverse items-end justify-center px-5 py-3 bg-white lg:flex-row lg:px-0">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button className="w-full px-12 mr-0 lg:w-auto" primary>
                {t("global:apply")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorizedCreateModal;
