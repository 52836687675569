import React from "react";

function CheckBox({
                    id,
                    name,
                    label,
                    description,
                    disabled,
                    onChange,
                    checked
                  }) {
  return (
    <div className="flex ">
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={() => {
          !disabled && onChange(!checked);
        }}
        checked={checked}
        className={
          disabled
            ? "w-4 h-4 text-gray-500 rounded-md mt-0.5 bg-primary-disabled ring-0"
            : "w-4 h-4 text-primary-hover border-2 border-borderGray mt-0.5  text-sm cursor-pointer rounded-md outline-none ring-0  focus:ring-0 focus:outline-none focus:border-none focus:ring-transparent"
        }
        disabled={disabled}
      />

      <div className="flex flex-col ml-2">
        <label
          className={" w-full text-main mb-0 cursor-pointer"}
          htmlFor={name}
        >
          {label}
        </label>
        <span
          className={"text-sm text-passive cursor-pointer"}
          onClick={() => {
            !disabled && onChange(!checked);
          }}
        >
          {description}
        </span>
      </div>
    </div>
  );
}

export default CheckBox;
