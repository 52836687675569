import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAbuseList } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import NewSupportRequestModal from "./TicketAndSupportModals/NewSupportRequestModal";
import ComplaintNotificationTable from "./TicketAndSupportTables/ComplaintNotificationTable";
import ComplaintNotificationFilterModal from "./TicketAndSupportModals/ComplaintNotificationFilterModal";

function ComplaintNotification() {
  const { t } = useTranslation(["support", "global"]);
  const dummyData = ["Tüm Hizmetler", "Grafik Tasarım", "Finans"];
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openNewSupportRequestModal, setOpenNewSupportRequestModal] = useState(
    false
  );
  const [abuseList, setAbuseList] = useState();
  const [openBalanceModal, setOpenBalanceModal] = useState(false);

  useEffect(() => {
    getAbuseList().then((data) => {
      setAbuseList(data);
    });
  }, []);
  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("support:complaintNotification")}</h2>
        <div className="items-center lg:flex ">
          <div className="flex my-5 lg:my-0">
            {/* <Button
              onClick={() => setOpenFilterModal(true)}
              className="flex items-center mr-5 px-7"
              tertiary
            >
              <i className="ri-equalizer-line" />
              <span>{t("projects:filter")}</span>
            </Button> */}

          </div>
        </div>
      </div>
      <div className="overflow-x-auto">

        {abuseList && abuseList?.status !== "ticket_not_found" ? (
          <ComplaintNotificationTable abuseList={abuseList} />
        ) : abuseList && abuseList?.status === "ticket_not_found" ? (
            <div className="flex flex-col items-center justify-center my-10 lg:my-20">
              <img src="/ticketNotFound.svg" width="572" height="288" />
              <h1 className="mt-6">{t("support:noComplaintNotification")}</h1>
              <h5>{t("support:noComplaintNotificationDesc")}</h5>
            </div>
          )
          : (
            <div className="flex items-center justify-center w-full mt-20">
              <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                        type="Oval"
                        color="#ff7c30"
                        height={75}
                        width={75}
                        timeout={3000} //3 secs
              />
            </div>
          )}
      </div>

      {openNewSupportRequestModal && (
        <NewSupportRequestModal
          setOpenModal={() => setOpenNewSupportRequestModal(false)}
        />
      )}
      {openFilterModal && (
        <ComplaintNotificationFilterModal
          setOpenModal={() => setOpenFilterModal(false)}
        />
      )}
    </div>
  );
}

export default ComplaintNotification;
