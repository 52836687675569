import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorModal from "components/Modals/ErrorModal";
import RemoveLockModal from "components/Modals/RemoveLockModal";
import VirtualVPNFilterModal from "../VirtualServers/Modals/VirtualVPNFilterModal";
import VirtualVPNUsersTable from "../VirtualServers/Tables/VirtualVPNUsersTable";
import VirtualEditVPNUserModal from "../VirtualServers/Modals/VirtualEditVPNUserModal";
import ManagementPortUsersTable from "./Tables/ManagementPortUsersTable";
import { listVPNUser } from "services/allServices";


function PhysicalVPNUsers({ data }) {
  let language = localStorage.getItem("ln");
  const { t, i18n } = useTranslation(["projects", "global", "services", "nav"]);
  const dummyData = ["Tüm Hizmetler", "Grafik Tasarım", "Finans"];
  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRemoveLockModal, setOpenRemoveLockModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [VPNUserList, setVPNUserList] = useState();

  const onDeleteUser = () => {
    alert(deleteID + " id li kullanıcı silinecek");
    setOpenDeleteModal(false);
  };

  const onListVpnUser = () => {
    listVPNUser(data?.product_type_id, data?.id).then((res) => {
      if (res.status !== "vpn_assigned_record_not_found") {
        setVPNUserList(res);
      }
    });
  };


  useEffect(() => {
    onListVpnUser();
  }, [data]);


  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="my-5 font-semibold">{t("nav:vpn-users")}</h2>
        {/* <Button
          onClick={() => setOpenModal(true)}
          className="flex items-center px-7"
          tertiary
        >
          <i className="ri-equalizer-line" />
          <span>{t("projects:filter")}</span>
        </Button> */}
      </div>
      <div className="overflow-x-auto">
        {VPNUserList ?
          <VirtualVPNUsersTable
            setOpenDeleteModal={() => setOpenDeleteModal(true)}
            setOpenEditModal={() => setOpenEditModal(true)}
            setDeleteID={(id) => setDeleteID(id)}
            list={VPNUserList}

          />
          :
          <div className="flex flex-col items-center justify-center my-10 lg:my-10">
            <img src="/servicesOther.svg" width="203" height="139" />
            <h2>{t("services:noVpnUsers")}</h2>
          </div>
        }
        <div className="mt-10">
          <p>
            {t("services:noVpnUsersDesc1")}
          </p>
          {language === 'tr' ? <p>
            FortiClient VPN'i indirmek için{" "}
            <a
              className="text-primary-hover"
              href="https://www.fortinet.com/support/product-downloads"
              target="_blank"
            >
              https://www.fortinet.com/support/product-downloads
            </a>{" "}
            adresini kullanabilirsiniz.
          </p> : 
          
          <p>
          You can use{" "}
          <a
              className="text-primary-hover"
              href="https://www.fortinet.com/support/product-downloads"
              target="_blank"
            >
              https://www.fortinet.com/support/product-downloads
            </a>{" "}
            download FortiClient VPN.

          </p>
          }
          {/* <p className="mt-10">
            Kullanıcıları görebilmeniz için aşağıdaki butona tıklayarak size
            gönderilecek mail iletisindeki kodu girmeniz gerekmektedir.
          </p>
          <div className="mt-5">
            <Button
              onClick={() => setOpenRemoveLockModal(true)}
              className="px-5"
              secondary
              icon
              dark
            >
              <i className="ri-eye-line" />
              <span>Kullanıcıları Görüntüle</span>
            </Button>
          </div> */}
        </div>
      </div>
      {(data?.product_type_id == 5 || data?.product_type_id == 9) &&
        <div className="mt-10">
          <div className="flex items-center justify-between">
            <h2 className="my-5 font-semibold">{t("services:managementPortUser")}</h2>
            {/* <Button
              onClick={() => setOpenModal(true)}
              className="flex items-center px-7"
              tertiary
            >
              <i className="ri-equalizer-line" />
              <span>{t("projects:filter")}</span>
            </Button> */}
          </div>
          {( data.management_ip && data.management_username && data.management_password) ?
            <ManagementPortUsersTable
              setOpenDeleteModal={() => setOpenDeleteModal(true)}
              setOpenEditModal={() => setOpenEditModal(true)}
              setDeleteID={(id) => setDeleteID(id)}
              list={[
                {
                  management_ip: data.management_ip,
                  management_username: data.management_username,
                  management_password: data.management_password
                }
              ]}
            />
            :

            <div className="flex flex-col items-center justify-center my-10 lg:my-10">
              <img src="/servicesOther.svg" width="203" height="139" />
              <h2>{t("services:noManagementPortUser")}</h2>
            </div>
          }

        </div>
      }


      {openModal && (
        <VirtualVPNFilterModal setOpenModal={(value) => setOpenModal(value)} />
      )}
      {openDeleteModal && (
        <ErrorModal
          title={t("services:deleteUser")}
          onClick={() => onDeleteUser()}
          question={t("services:deleteVpnUserQues")}
          buttonText={t("global:yesDelete")}
          setOpenModal={(value) => setOpenDeleteModal(value)}
        />
      )}
      {openRemoveLockModal && (
        <RemoveLockModal
          title={t("services:emailActivation")}
          titleColor="primary-hover"
          question={t("services:emailActivationDesc")}
          buttonText={t("global:send")}
          setOpenModal={() => setOpenRemoveLockModal(false)}
          onClick={() => {
            setOpenRemoveLockModal(false);
          }}
          svg={
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.6668 35.576C55.8181 34.5669 52.7418 34.3853 49.7941 35.0522C46.8465 35.719 44.1479 37.2071 42.0109 39.3441C39.8739 41.4811 38.3859 44.1796 37.719 47.1273C37.0522 50.075 37.2338 53.1513 38.2428 56H8.00016C7.29292 56 6.61464 55.7191 6.11454 55.219C5.61445 54.7189 5.3335 54.0406 5.3335 53.3333V10.6667C5.3335 9.95942 5.61445 9.28115 6.11454 8.78105C6.61464 8.28095 7.29292 8 8.00016 8H56.0002C56.7074 8 57.3857 8.28095 57.8858 8.78105C58.3859 9.28115 58.6668 9.95942 58.6668 10.6667V35.576ZM32.1602 31.1547L15.0615 16.6347L11.6082 20.6987L32.1948 38.1787L52.4108 20.6853L48.9228 16.6507L32.1628 31.1547H32.1602ZM50.6668 58.6667L41.2375 49.2373L45.0108 45.4667L50.6668 51.1253L60.0962 41.696L63.8668 45.4667L50.6668 58.6667Z"
                fill="#FF7C30"
              />
            </svg>
          }
        />
      )}

      {openEditModal && (
        <VirtualEditVPNUserModal
          setOpenModal={(value) => setOpenEditModal(value)}
        />
      )}
    </div>
  );
}

export default PhysicalVPNUsers;
