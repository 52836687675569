import React, { useState } from "react";
import VirtualMonthlyTrafficBarChart from "./Charts/VirtualMonthlyTrafficBarChart";
import VirtualTrafficChart from "./Charts/VirtualTrafficChart";
import TextField from "components/FormElements/Input/TextField";
import moment from "moment";
import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";
import { useTranslation } from "react-i18next";

function VirtualNetwork() {
  const { t } = useTranslation([ "global","services"]);

  const [date1, setDate1] = useState(
    moment().add(-1, "month").format("YYYY-MM-DD")
  );
  const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));

  return (
    <div>
      <div className="relative w-full p-8 mt-10 bg-white bg-opacity-25 rounded-md shadow-md">
        <div className="items-center justify-between px-6 my-2 lg:flex">
          <h3 className="font-semibold opacity-25 text-title">{t("services:traffic")}</h3>
          <div className="items-start opacity-25 lg:flex">
            <div className="lg:flex">
              <TextField
                id="date"
                name="date"
                type="date"
                ref={null}
                label={t("services:fromThisDate")}
                value={date1}
                onChange={setDate1}
                max={date2}
              />
              <TextField
                className="lg:ml-5"
                id="date"
                name="date"
                type="date"
                ref={null}
                label={t("services:toThisDate")}
                value={date2}
                onChange={setDate2}
                min={date1}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>
        </div>
        <div className="opacity-25">
          <VirtualTrafficChart />

        </div>

        <AlertLaterInfo style={"top-1/3 lg:left-[500px]"} />
      </div>
      <div className="relative w-full p-8 mt-10 bg-white bg-opacity-25 rounded-md shadow-md">
        <h3 className="font-semibold opacity-25 text-title">{t("services:monthlyTraffic")}</h3>
        <div className="opacity-25">
          <VirtualMonthlyTrafficBarChart />
        </div>

        <AlertLaterInfo style={"top-1/3 lg:left-[500px]"} />

      </div>
    </div>
  );
}

export default VirtualNetwork;
