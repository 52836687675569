import { Button, TextField } from "components";
import TextArea from "components/FormElements/Input/TextArea";
import React, { useEffect, useState } from "react";
import CheckBox from "components/FormElements/CheckBox";
import { useTranslation } from "react-i18next";
import ComboBox from "components/FormElements/ComboBox/index.js";
import { useComboBox } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  checkDeleteProjectPurpose,
  createProject,
  deleteProjectPurpose,
  getBillingInfoList,
  getPurposeList
} from "services/allServices";
import SuccessModal from "components/Modals/SuccessModal";
import { useGlobalStore } from "store/GlobalStore";
import AddProjectPurposeModal from "components/Project/AddProjectPurposeModal";
import { useAuthStore } from "store/AuthStore";
import { useToasts } from "react-toast-notifications";
import { convertToSlug } from "utils/common";
import { useNavigate } from "react-router";
import NewBillingAddressModal from "../../../components/Settings/BillingAdress/NewBillingAddressModal";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string()
});

function NewProject() {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const navigate = useNavigate();

  const [refreshSidebar, setRefreshSidebar] = useGlobalStore((state) => [state.refreshSidebar, state.setRefreshSidebar]);
  const [userInformations] = useAuthStore((state) => [state.userInformations]);
  const [check, setCheck] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openPurposeModal, setOpenPurposeModal] = useState(false);
  const [openEditPurposeModal, setOpenEditPurposeModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [projectSlug, setProjectSlug] = useState(false);

  const [showError, setShowError] = useState(false);
  const { addToast } = useToasts();

  const onSubmit = async (data) => {
    if (selectedInvoices.id !== 0 && selectedPurpose.id !== 0) {
      let configure = {
        customer_id: userInformations.customer_info.id,
        billing_info_id: selectedInvoices.id,
        purpose_id: selectedPurpose.id,
        name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        description: data.description,
        default_project: check,
        slug: convertToSlug(data.name)
      };
      createProject(configure).then(res => {
        if (res?.status === "project_name_already_exist") {
          return (
            addToast("Proje ismi kullanılıyor.", {
              appearance: "error",
              autoDismiss: true
            })
          );
        }
        setOpenSuccessModal(true);
        setRefreshSidebar(!refreshSidebar);
        setProjectSlug(convertToSlug(data.name));
      });
    } else {
      setShowError(true);
    }

  };
  const dummyDizi = [
    { id: 1, text: "Sitesi" },
    { id: 2, text: "Grafik Tasarım" },
    { id: 3, text: "Finans" }
  ];

  const onGetInvoicesList = () => {
    getBillingInfoList().then(res => {
      setListInvoices(res, "id", "billing_info_name");
      let findDefaultAddress = res.find(item => item.billing_info_name === "Default")
      if(findDefaultAddress){
        changeSelectedInvoices(findDefaultAddress.id, findDefaultAddress.billing_info_name);
      }else{
        changeSelectedInvoices(res[0].id, res[0].billing_info_name);
      }
    });
  };

  const onGetPurposeList = () => {
    getPurposeList().then(res => {
      if (res) {
        setListPurpose(res, "id", "purpose");
        changeSelectedPurpose(res[0].id, res[0].purpose);

      }
    });
  };

  const onDeleteProjectPurpose = (id) => {

    checkDeleteProjectPurpose(id).then(res => {
      if (res.status) {
        addToast("Proje amacı kullanılıyor. Lütfen diğer projelerden kaldırdığınıza emin olun.", {
          appearance: "error",
          autoDismiss: true
        });
      } else {
        deleteProjectPurpose(id).then(res => {
          onGetPurposeList();
          changeSelectedPurpose(0, "Seçiniz");
          addToast(" Proje Amacı Silindi.", {
            appearance: "success",
            autoDismiss: true
          });
        });
      }
    });


  };

  const [selectedInvoices, listInvoices, changeSelectedInvoices, setListInvoices] = useComboBox("Seçiniz");
  const [selectedPurpose, listPurpose, changeSelectedPurpose, setListPurpose] = useComboBox("Seçiniz");
  useEffect(() => {
    onGetInvoicesList();
    onGetPurposeList();
  }, [openPurposeModal, openEditPurposeModal]);


  return (
    <div className="container">
      <h2 className="mb-5 font-semibold">{t("projects:projectInformation")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6">
            <div className="mb-5">
              <TextField
                id="name"
                name="name"
                innerRef={register}
                label={t("projects:projectName")}
                inputClass="capitalize"
                placeholder={t("projects:projectInformation")}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-sm text-alert-red">
                  Bu alan zorunludur.
                </span>
              )}
            </div>
            <div>
              <TextArea
                id="description"
                name="description"
                innerRef={register}
                label={t("projects:projectDescription")}
                placeholder={t("projects:projectDescription")}
                rows="4"
              />
              {errors.description && errors.description.type === "required" && (
                <span className="text-sm text-alert-red">
                  Bu alan zorunludur.
                </span>
              )}
            </div>
            <div className="mt-5">
              <CheckBox
                id="1"
                name="1"
                label={t("projects:defaultproject")}
                description={t("projects:defaultprojectDesc")}
                onChange={() => setCheck(!check)}
              />
            </div>
            {showError && (
              <p className="mt-3 text-sm text-alert-red">
                Proje amacı ve Fatura adresi alanları zorunludur lütfen seçim yapınız.
              </p>
            )}
            <div className="mt-5 lg:flex">
              {/* <SelectBox items={dummyData} placeholder="Seçiniz.." /> */}
              <div className="lg:mr-5">
                <ComboBox
                  id="select1"
                  name="select1"
                  selected={selectedPurpose}
                  setSelected={changeSelectedPurpose}
                  list={listPurpose}
                  defaultText="Seçiniz"
                  label={t("projects:goalProject")}
                  addButton={() => setOpenPurposeModal(true)}
                  onClickItemHTML={[<i className="ri-edit-line mr-0.5 "></i>,
                  <i className="text-lg ri-close-line text-alert-red"></i>]}
                  onClickItemEvent={[(e) => setOpenEditPurposeModal(e), (e) => onDeleteProjectPurpose(e.id)]}
                />
              </div>
              <div className="mt-3 lg:mt-0 lg:mr-5">
                <ComboBox
                  id="select2"
                  name="select2"
                  selected={selectedInvoices}
                  setSelected={changeSelectedInvoices}
                  list={listInvoices}
                  defaultText="Seçiniz"
                  label={t("projects:invoiceAddress")}
                  addButton={() => setOpenCreateModal(true)}
                />
              </div>

              <div>
                <Button primary full className="px-12 mt-5 lg:mt-7">
                  {t("global:save")}
                </Button>
              </div>

            </div>

          </div>
        </div>
      </form>
      {openSuccessModal &&
        <SuccessModal onClick={() => navigate("/project/" + convertToSlug(projectSlug))} title="İşlem Başarılı"
          question="Projeniz başarılı bir şekilde oluşmuştur."
          description="Yan menüden projenizi düzenleyebilirsiniz." buttonText="Tamam"
          setOpenModal={(v) => setOpenSuccessModal(v)} />
      }
      {openPurposeModal &&
        <AddProjectPurposeModal setOpenModal={() => setOpenPurposeModal(false)} />
      }

      {openEditPurposeModal &&
        <AddProjectPurposeModal userInformations={userInformations} editData={openEditPurposeModal}
          setOpenModal={() => setOpenEditPurposeModal(false)}
          onGetPurposeList={() => onGetPurposeList()} />
      }

      {openCreateModal && (
        <NewBillingAddressModal setOpenModal={(v) => setOpenCreateModal(v)}
          onListBilingInfo={() => onGetInvoicesList()} />
      )}
    </div>
  );
}

export default NewProject;
