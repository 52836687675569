import React, { useEffect, useState } from "react";
import AlertSuccess from "components/ContentAlerts/AlertSuccess";
import { paymentSuccessPaypal, postPaypalHtml } from "services/allServices";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router";
import { TailSpin } from "react-loader-spinner";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function PaypalModal({ postValues, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [orderID, setOrderID] = useState(false);
  const [clientToken, setClientToken] = useState("");
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const paypalOptions = {
    clientId: "AYaCmZEi20Ois6GkN9KHuWMUzijFkgc0jyWiFvdaq_S6IYT6CilWL3shJrvLpkQO5eWipll8emX0RScA",
    intent: "capture",
    "data-client-token": clientToken?.custom_html,
    "disable-funding": 'card,paylater'
  };
  function createOrder(data, actions) {
    console.log(data, "create Order Data")
    console.log(actions, "create Order Actions")
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: clientToken.paypal_price,
              currency_code: "USD"
            }
          }
        ]
      })
      .then((orderID) => {
        console.log(orderID, "create OrderID")
        setOrderID(orderID);
        return orderID;
      }).catch(err => {
        console.log(err)
      })
  }

  function onApprove(data, actions) {
    return actions.order.capture().then(function (details) {
      let configure = {
        paypal_order_no: data?.orderID,
        required_info: clientToken?.custom_html
      }
      paymentSuccessPaypal(configure).then(data => {
        addToast(t("accounting:paymentSuccesful"), {
          appearance: "success",
          autoDismiss: true
        });
        navigate("/");
      })

    }).catch(err => {
      console.log(err, "onApprove Error")
    })
  }

  function onCancel() {
    addToast(t("accounting:cancelledPayment"), {
      appearance: "error",
      autoDismiss: true
    });
    setOpenModal(false);
  }
  function onError() {
    addToast(t("accounting:paymentError"), {
      appearance: "error",
      autoDismiss: true
    });
    setOpenModal(false);
  }

  console.log(postValues)
  useEffect(() => {
    setClientToken(postValues);
  }, []);
  return (
    <PayPalScriptProvider options={paypalOptions}>
      <div className="fixed inset-0 z-10 overflow-y-auto ">
        <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <div
            className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{ overflow: "initial" }}
          >
            <div className="bg-white rounded-md">
              <div className="flex items-center justify-between px-3 py-3">
                <h3 className="font-semibold">{t("accounting:paymentWithPayPal")}</h3>
                <i
                  onClick={() => setOpenModal(false)}
                  className="text-2xl cursor-pointer ri-close-line text-passive"
                ></i>
              </div>
              <div className="flex flex-col px-2 pt-3 pb-3 bg-modalBg">
                <div className="flex flex-col items-center justify-center w-full px-5 pt-4 pb-10 ">
                  <AlertSuccess
                    title={t("accounting:successfulOrder")}
                    message={t("accounting:orderDesc")}
                  />
                  <div className="w-full p-10 mt-5 bg-paypalLaci">
                    <div className="flex flex-col items-center justify-center">
                      {clientToken ?
                        <PayPalButtons
                          //style={{ layout: "horizontal" }}
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onCancel={onCancel}
                          onError={onError}
                        />
                        :
                        <div className="flex justify-center w-full px-3">
                          <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                            type="Oval"
                            color="#ff7c30"
                            height={75}
                            width={75}
                            timeout={3000} //3 secs
                          />
                        </div>
                      }

                    </div>
                  </div>
                  <div className="flex mt-5">
                    <div>
                      <svg
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="64" height="64" rx="32" fill="#ff9e30" />
                        <path
                          d="M30.6667 18.667L40.3973 21.707C40.6687 21.7917 40.9059 21.961 41.0743 22.19C41.2427 22.4191 41.3335 22.696 41.3333 22.9803V25.3337H44C44.3536 25.3337 44.6928 25.4741 44.9428 25.7242C45.1929 25.9742 45.3333 26.3134 45.3333 26.667V37.3337C45.3333 37.6873 45.1929 38.0264 44.9428 38.2765C44.6928 38.5265 44.3536 38.667 44 38.667L39.7067 38.6683C39.1907 39.3483 38.564 39.9483 37.84 40.4417L30.6667 45.3337L23.4933 40.443C22.4167 39.7089 21.5357 38.723 20.9269 37.5709C20.318 36.4188 19.9999 35.1354 20 33.8323V22.9803C20.0002 22.6962 20.091 22.4196 20.2594 22.1908C20.4278 21.962 20.6648 21.793 20.936 21.7083L30.6667 18.667ZM30.6667 21.459L22.6667 23.9603V33.8323C22.6665 34.6486 22.8537 35.4541 23.2138 36.1866C23.574 36.9192 24.0975 37.5593 24.744 38.0577L24.996 38.239L30.6667 42.107L35.7093 38.667H29.3333C28.9797 38.667 28.6406 38.5265 28.3905 38.2765C28.1405 38.0264 28 37.6873 28 37.3337V26.667C28 26.3134 28.1405 25.9742 28.3905 25.7242C28.6406 25.4741 28.9797 25.3337 29.3333 25.3337H38.6667V23.9603L30.6667 21.459ZM30.6667 32.0003V36.0003H42.6667V32.0003H30.6667ZM30.6667 29.3337H42.6667V28.0003H30.6667V29.3337Z"
                          fill="#101E26"
                        />
                      </svg>
                    </div>
                    <div className="ml-5">
                      <p className="mb-2 font-semibold">{t("accounting:securePayment")}</p>
                      <p className="text-cardText">
                        {t("accounting:secureBank")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default PaypalModal;
