import React from 'react'

const KVKKPolicy = () => {
  return (
    <div className='text-cardText'>
    makdos.com sitesinde işlem yapmadan önce lütfen bu Gizlilik Politikasını okuyunuz, Gizlilik Politikası'nı kabul etmiyorsanız, lütfen makdos.com sitesi üzerinde işlem yapmayınız. Bu Gizlilik Politikası makdos.com için geçerlidir ve makdos.com üzerinde bahsi geçen diğer siteler için bağlayıcı niteliği bulunmamaktadır. Bu Gizlilik Politikası ile makdos.com un şartlarını kabul etmiş olmaktasınız.
    <br />
    <br />
    <b> Kişisel Bilgi</b>
    <br />
    Ürünlerimizi sizlere tanıtmak, yeni ürünlerimiz, hizmetlerimiz yada promosyonlarımızdan sizleri haberdar etmek, yeni ürün satışı yapmak veya ürün satışı sonrası destek vermek için makdos.com sitesinde; isminiz, adresiniz, e-posta adresiniz ve telefon numaranız gibi size özel, kişisel bilgilerinizi istememiz gerekebilir. Mevcut bilgileriniz yeterli olmadığı durumda ek bilgiler sizden e-posta, telefon veya faks gibi iletişim araçları ile istenebilir. Tüm bu kapsam içerisinde toplanan bilgi makdos.com sitesinde kişisel bilgi olarak nitelendirilmektedir.
    <br />
    <br />
    <b>  Bilginin Paylaşımı</b>
    <br />
    makdos.com için web sitesini ziyaret edenlerin, kişisel bilgilerini korumak başlıca ilkeleri arasında yer almaktadır. makdos.com kişisel bilgi içermeyen datayı (Kullanılan işletim sistemi, ziyaretçilerin cinsiyeti vb.) 3. şahıslar ile bilgi vermek amacıyla paylaşabilir. Yasal olarak tarafımızdan istenmedikçe kişisel olarak nitelendirilen özel bilgiler 3. şahıslar ile paylaşılmamaktadır. makdos.com Makdos Bilişim Teknolojileri San. Tic. Ltd. Şti. markasıdır, ve kişisel bilgiler; bilgi veya promosyon amaçlı şirket bünyesindeki diğer siteler ile paylaşılabilir.
    <br />
    Otomatik Olarak Toplanan Kişisel Olmayan Bilgi
    <br />
    makdos.com siteyi ziyaretiniz sırasında, İnternet protokol adresiniz, bilgisayarınızın işletim sistemi, tarayıcı tipiniz, trafik biçimleri yada herhangi ilgili Web sitelerinin adresi gibi belirli teknik bilgiyi toplamamıza imkan veren teknolojileri kullanabilir. Ziyaretçilerimiz hakkında daha detaylı bilgi elde etmek için sitemiz tarafından bilgisayarınıza cookie -çerez- olarak nitelendirilen bilgi dosyaları gönderilebilir. Bu dosyalar tamamen kullandığınız sistem ile ilgili teknik bilgi içeren ve dünyada yaygın olarak kullanılan dosyalardır.
    <br />
    <br />
    <b>   Güvenlik</b>
    <br />
    makdos.com verinin mutlak güvenliği için gerekli teknolojik tedbirleri almaktadır. Ancak hiçbir teknolojik sistem %100 güvenli değildir. makdos.com un hatalı kullanım yada kişisel bilgilerinize yetkisiz erişim gibi riskleri önlemek yada minimum seviyeye indirmek için gerekli tedbirleri almaya ve bu yönde bilgilendirici yayın yapmaya çalıştığını bilmenizi isteriz. Özellikle online işlem sırasında kullanılan kredi kartı bilgilerinin 128 bit şifreleme teknolojisi ile tarafınızdan alınıp bankaya iletilmektedir.
    <br />
    <br />
    <b>   Toplanan Verilerin Doğruluğu</b>
    <br />
    makdos.com ziyaretçisi tarafından girilmiş olan eksik yada yanlış bilgiyi, ziyaretçisinden gelecek yeni bilgi veya çapraz sorgulama yöntemi ile elde edeceği doğru bilgi ile değiştirebilir yada silebilir.
    <br />
    <br />
    <b>  Değişiklikler</b>
    <br />
    makdos.com olarak dilediğimiz zaman ve bildirimde bulunmadan bu Gizlilik Politikası'nı, Siteyi yada bu sayfalara erişimi değiştirebilir yada kaldırabiliriz.
    <br />
    <br />
    <b>  Makdos Bilişim Teknolojileri</b>
</div>
  )
}

export default KVKKPolicy