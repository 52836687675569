import Button from "components/FormElements/Button";
import React, { useEffect, useState } from "react";
import LogRecordsFilterModal from "./LogRecordsFilterModal";
import LogRecordsTable from "./LogRecordsTable";
import { listCustomerLogs } from "services/allServices";
import { useLocation } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import { combineIDContent } from "helpers/helpersFunc";
import AlertLaterInfo from "components/ContentAlerts/AlertLaterInfo";
import { useTranslation } from "react-i18next";

function LogRecordsSettings() {

  const { t, i18n } = useTranslation(["settings", "global", "nav"]);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const location = useLocation();
  const [pageLimit, logsFilterData, setLogsFilterData] = useGlobalStore((state) => [state.pageLimit, state.logsFilterData, state.setLogsFilterData]);
  const [data, setData] = useState();

  const onGetLogList = (page, limit, configureFilter) => {
    listCustomerLogs(page, limit, configureFilter ? configureFilter : logsFilterData).then(async (res) => {
      let combineData = await combineIDContent(res.logs_all)
      let newList = []
      combineData.map((item, index) => {
        newList.push({
          ...item,
          id: index + 1
        })
      })
      setData(newList)

    })
  }

  useEffect(() => {

    const asyncFunction = async () => {

      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }
      let configureFilter = {
        log_type: null,
        table_name: null,
        operation_type: null,
        operation_time_start: null,
        operation_time_end: null
      };

      if (!logsFilterData || logsFilterData == "") {
        await setLogsFilterData(configureFilter);
        await onGetLogList(page, pageLimit, configureFilter);

      } else {
        await onGetLogList(page, pageLimit, logsFilterData);
      }


    };
    asyncFunction();

  }, []);

  return (
    <div className="container">
      <div className="items-center justify-between lg:flex">
        <div>
          <h2 className="mt-5 mb-1 font-semibold">{t("nav:log-records")}</h2>
        </div>
        <Button
          onClick={() => setOpenFilterModal(true)}
          className="flex items-center px-7"
          tertiary
        >
          <i className="ri-equalizer-line" />
          <span>{t("global:filter")}</span>
        </Button>
      </div>
      <div className="opacity-10 mt-7 select">
        {data &&
          <LogRecordsTable data={data} setData={setData} onGetLogList={onGetLogList} />
        }
      </div>

      <AlertLaterInfo style={"top-1/2 lg:left-[500px]"} />

      {openFilterModal && (
        <LogRecordsFilterModal setOpenModal={(v) => setOpenFilterModal(v)} />
      )}
    </div>
  );
}

export default LogRecordsSettings;
