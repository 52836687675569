import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "components/Tabs";
import FinancialInformations from "components/Accounting/FinancialInformations";
import { useGlobalStore } from "store/GlobalStore";
import AllNotifications from "components/Notifications/AllNotifications";
import InvoicesNotification from "components/Notifications/InvoicesNotification";
import ProductNotification from "components/Notifications/ProductNotification";

function Notification() {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [selectedPageTab, setSelectedPageTab] = useGlobalStore((state) => [
    state.selectedPageTab,
    state.setSelectedPageTab
  ]);

  const tabs = [
    { name: "Tüm Bildirimler", id: "1" },
    { name: "Fatura Bildirimleri", id: "2" },
    { name: "Ürün Bildirimleri", id: "3" },
    { name: "Diğer Bildirimler", id: "4" }
  ];
  useEffect(() => {
    if (parseInt(selectedPageTab) > 4) {
      setSelectedPageTab("1");
    }
  }, [selectedPageTab]);
  return (
    <div className="container relative w-full ">
      <Tabs tabs={tabs} />

      {selectedPageTab === "1" && (
        <>
          <AllNotifications />
        </>
      )}
      {selectedPageTab === "2" && (
        <>
          <InvoicesNotification />
        </>
      )}
      {selectedPageTab === "3" && (
        <>
          <ProductNotification />
        </>
      )}

      {selectedPageTab === "4" && (
        <>
          <FinancialInformations />
        </>
      )}
    </div>
  );
}

export default Notification;
