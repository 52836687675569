import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import { listAuthorizedAccount } from "services/allServices";
import AuthorizedCodesTable from "./AuthorizeCodeTable";
import AuthorizedCreateModal from "./AuthorizedCreateModal";
import { useTranslation } from "react-i18next";

function AuthorizedCodesSettings() {
  const { t, i18n } = useTranslation(["settings", "global", "nav"]);
  
  const [authorizedAccount, setAuthorizedAccount] = useState();
  const [openModal, setOpenModal] = useState(false);


  const onListAuthorizedAccount = () => {
    listAuthorizedAccount().then(res => {
      setAuthorizedAccount(res?.authorized_accounts);
    });
  };

  useEffect(() => {
    onListAuthorizedAccount();
  }, []);

  return (
    <div className="container">
      <div className="items-center justify-between lg:flex">
        <div>
          <h2 className="mt-5 mb-1 font-semibold">{t("nav:authorized-codes")}</h2>
        </div>
        {/* <Button
          onClick={() => setOpenModal(true)}
          primary
          className="flex items-center px-3 mt-3 lg:mt-0"
        >
          <i className="mr-2 ri-add-circle-line"></i>
          Yeni Yetkili Oluştur
        </Button> */}
      </div>
      <div className="mt-7">
        {authorizedAccount && authorizedAccount?.length !== 0 &&
          <AuthorizedCodesTable datas={authorizedAccount} />


        }
        {authorizedAccount && authorizedAccount?.length === 0 &&

          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/servicesOther.svg" width="403" height="339" />
            <h1>{t("settings:noAuthorizedAccount")}</h1>
            {/* <h5>Dilerseniz yeni bir hizmet satın alabilirsiniz</h5> */}
          </div>
        }
      </div>

      
      {openModal && (
        <AuthorizedCreateModal setOpenModal={(v) => setOpenModal(v)} />
      )}
    </div>
  );
}

export default AuthorizedCodesSettings;
