import ProformaInformationModal from "components/Accounting/AccountingModals/ProformaInformationModal";
import AddNoteModal from "components/Modals/AddNoteModal";
import DownloadModal from "components/Modals/DownloadModal";
import ErrorModal from "components/Modals/ErrorModal";
import RemoveLockModal from "components/Modals/RemoveLockModal";
import SuccessModal from "components/Modals/SuccessModal";
import WarningModal from "components/Modals/WarningModal";
import BuyDNSServicesModal from "components/Services/Domains/Modals/BuyDNSServicesModal";
import DNSChangeModal from "components/Services/Domains/Modals/DNSChangeModal";
import ExtendServiceModal from "components/Services/Domains/Modals/ExtendServiceModal";
import ProfessionalDNSModal from "components/Services/Domains/Modals/ProfessionalDNSModal";
import WHOISModal from "components/Services/Domains/Modals/WHOISModal";
import BillsModal from "components/Services/ServicesGlobalModals/BillsModal";
import ChangeProductProjectModal from "components/Services/ServicesGlobalModals/ChangeProductProjectModal";
import InternalTransferModal from "components/Services/ServicesGlobalModals/InternalTransferModal";
import CSRCreatedModal from "components/Services/SSLCertificate/Modals/CSRCreatedModal";
import CSRCreateModal from "components/Services/SSLCertificate/Modals/CSRCreateModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { Button } from "components";
import { cancelProduct, changeProductAutoRenewal, getProxy, updateProductNote, updateProjectOwnProduct } from "services/allServices";

const ProxyServiceDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(["global", "services"]);
    const navigate = useNavigate();
    const { addToast } = useToasts();

    let { id } = useParams();
    const [pageData, setPageData] = useState();
    const [openExtendServiceModal, setOpenExtendServiceModal] = useState(false);
    const [remaininDay, setRemaininDay] = useState();
    const [openChangeProductProjectModal, setOpenChangeProductProjectModal] = useState(false);
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [openBillsModal, setOpenBillsModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openDNSChangeModal, setOpenDNSChangeModal] = useState(false);
    const [openWHOISModal, setOpenWHOISModal] = useState(false);
    const [openBuyDNSServiceModal, setOpenBuyDNSServiceModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [openInternalTransferModal, setOpenInternalTransferModal] = useState(false);
    const [openTransferWarningModal, setOpenTransferWarningModal] = useState(false);
    const [openRemoveLockModal, setOpenRemoveLockModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openProfessionalDNSModal, setOpenProfessionalDNSModal] = useState(false);
    const [openCSRCreateModal, setOpenCSRCreateModal] = useState(false);
    const [openCSRCreatedModal, setOpenCSRCreatedModal] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [openProformaInformationModal, setOpenProformaInformationModal] = useState(false);

    const onGetPageData = () => {
        setIsLoading(true);
        getProxy(id).then(res => {
            setIsLoading(false);
            setPageData(res);
            if (!res) {
                navigate("/");
            }
        });
    };

    const onChanceStatus = (status) => {
        if (status === "waiting") {
            return (
                <p className="text-alert-yellow">{t("services:pending")}</p>
            );
        } else if (status === "completed") {
            return (
                <p className="text-alert-blue">{t("services:completed")}</p>
            );
        } else if (status === "active") {
            return (
                <p className="text-alert-green">{t("global:active")}</p>
            );
        } else if (status === "suspended") {
            return (
                <p className="text-garantiGreen">{t("global:suspended")}</p>
            );
        } else if (status === "closed") {
            return (
                <p className="text-passive">{t("services:closed")}</p>
            );
        } else if (status === "canceled") {
            return (
                <p className="text-alert-red">{t("services:cancelled")}</p>
            );
        } else if (status === "abuse") {
            return (
                <p className="text-alert-red">{t("services:abuse")}</p>
            );
        } else if (status === "deleted") {
            return (
                <p className="text-alert-red">{t("services:deleted")}</p>
            );
        } else {
            return (
                <p className="">-</p>
            );
        }
    };

    const onCulculateRemainingDay = async () => {
        if (pageData) {
            let expire_date = moment(pageData.date_of_expire);
            let today = moment();
            let diff = expire_date.diff(today, "day");
            setRemaininDay(diff);
        }
    };

    const onUpdateProject = (project_id) => {
        updateProjectOwnProduct(project_id, pageData.product_type_id, pageData.id).then((res) => {
            setOpenChangeProductProjectModal(false);
            setOpenWarningModal(false);
            onGetPageData();
        });
    };

    const onUpdateNote = (note) => {
        updateProductNote(pageData.product_type_id, pageData.id, note).then((res) => {
            setOpenNoteModal(false);
            onGetPageData();
        });
    };

    const onCancelProduct = (reason) => {
        let params = {
            product_type_id: pageData.product_type_id,
            owned_product_id: pageData.id,
            reason: reason

        };
        if (reason != "") {
            cancelProduct(params).then((res) => {
                setOpenErrorModal(false);
                onGetPageData();
            });
        }
    };

    const onChangeProductAutoRenewal = () => {
        changeProductAutoRenewal(!pageData.auto_renewal, pageData.product_type_id, pageData.id).then((res) => {
            onGetPageData();
            addToast(t("global:itSaved"), {
                appearance: "success",
                autoDismiss: true
            });
        })
    }

    useEffect(() => {
        onCulculateRemainingDay();
    }, [pageData]);

    useEffect(() => {
        onGetPageData();
    }, [id]);


    return (
        isLoading ?
            <div className="flex items-center justify-center w-full mt-20">
                <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                    type="Oval"
                    color="#ff7c30"
                    height={75}
                    width={75}
                    timeout={3000} //3 secs
                />
            </div>
            :
            (pageData && pageData?.status !== "waiting") ?
                <div className="container">
                    <div className="items-center justify-between p-4 bg-white rounded-md shadow-sm lg:flex">
                        <div>
                            <h2 className="font-semibold">{pageData?.product_name}</h2>
                            <p className="text-sm text-passive">ID #{pageData?.id}</p>
                        </div>
                        <div className="items-center lg:flex">

                            <Button
                                onClick={() => setOpenExtendServiceModal(true)}
                                className="px-8 mt-3 lg:mt-0"
                                secondary
                                icon
                            >
                                <i className="ri-history-line" />
                                <span>{t("services:extendService")}</span>
                            </Button>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-10 mt-10">
                        <div className="col-span-3 lg:col-span-1">
                            <div className="flex flex-col items-center p-4 bg-white rounded-md shadow-md">
                                <div className="flex flex-col items-center justify-center h-48 bg-no-repeat bg-servicesChart w-72">
                                    <p
                                        className={`mt-4 text-5xl font-semibold ${remaininDay < 0 ? "text-alert-red" : (remaininDay <= pageData?.renewal_day ? "text-alert-yellow" : "text-alert-green")}`}>
                                        {remaininDay}
                                    </p>
                                    <p className="mt-1 text-main">{t("services:remainingDays")}</p>
                                </div>
                                <div className="flex">
                                    <div className="flex">
                                        <div className="flex items-center">
                                            <svg
                                                width="11"
                                                height="10"
                                                viewBox="0 0 11 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="5.4383"
                                                    cy="5.01691"
                                                    r="4.62921"
                                                    fill="#1ACD67"
                                                />
                                            </svg>
                                            <p className="ml-2 text-xs"> {t("services:activePeriod")}</p>
                                        </div>
                                    </div>
                                    <div className="flex ml-3">
                                        <div className="flex items-center">
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="4.87628"
                                                    cy="4.99445"
                                                    r="4.62921"
                                                    fill="#FFC62F"
                                                />
                                            </svg>
                                            <p className="ml-2 text-xs"> {t("services:last")}  {pageData?.renewal_day}  {t("services:day")}</p>
                                        </div>
                                    </div>
                                    <div className="flex ml-3">
                                        <div className="flex items-center">
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="5.02228"
                                                    cy="5.01691"
                                                    r="4.62921"
                                                    fill="#FF4949"
                                                />
                                            </svg>
                                            <p className="ml-2 text-xs"> {t("services:delayPeriod")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col items-start justify-start w-full px-4 mt-4">
                                    <div className="w-full">
                                        <p className="font-bold">{t("global:project")}</p>
                                        <div className="flex items-center justify-between">
                                            <p>{pageData?.project_name}</p>
                                            <div className="w-auto">
                                                <button
                                                    className="font-medium text-primary-hover hover:text-primary"
                                                    onClick={() => setOpenChangeProductProjectModal(true)}
                                                >
                                                    {t("services:manage")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full mt-2">
                                        <p className="font-bold">{t("global:status")}</p>
                                        <p className="text-alert-green">{onChanceStatus(pageData?.status)}</p>
                                    </div>
                                    <div className="w-full mt-2">
                                        <p className="font-bold">{t("services:connectedDomain")}</p>
                                        <p className="text-primary-hover">{pageData?.hostname}</p>
                                    </div>

                                    <div className="w-full mt-2">
                                        <p className="font-bold">{t("services:serviceStartDate")}</p>
                                        <p className="">{moment(pageData?.date_of_buy).format("DD-MM-YYYY HH:mm")}</p>
                                    </div>
                                    {/* <div className="w-full mt-2">
                                  <p className="font-bold">Yenileme Tarihi</p>
                                  <p className="">{moment(pageData?.date_of_renewal).format("DD-MM-YYYY HH:mm")}</p>
                              </div> */}
                                    <div className="w-full mt-2">
                                        <p className="font-bold">{t("services:serviceEndDate")}</p>
                                        <p className="">{moment(pageData?.date_of_expire).format("DD-MM-YYYY HH:mm")}</p>
                                    </div>
                                    <div className="w-full mt-2">
                                        <p className="font-bold">{t("services:paymentCycle")}</p>
                                        <p
                                            className="">{pageData?.payment_cycle <= 12 ? (pageData?.payment_cycle + ' ' + t("global:monthly")) : pageData?.payment_cycle + ' ' + t("global:yearly")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3 lg:col-span-1">
                            <div className="p-8 bg-white rounded-md shadow-md">
                                <h3 className="font-semibold text-title">{t("services:notes")}</h3>
                                <p className="mt-4">{pageData?.customer_note ? pageData?.customer_note : t("services:noNote")}</p>
                                <div className="flex items-end justify-end w-full mt-3 text-alert-red">
                                    <Button onClick={() => setOpenNoteModal(true)}>{t("services:addNote")}</Button>
                                </div>
                            </div>
                            <div className="p-8 mt-6 bg-white rounded-md shadow-md">
                                <h3 className="mb-3 font-semibold text-title">{t("services:automaticRenewal")}</h3>
                                <p className="text-sm">{t("services:automaticRenewalDesc")}</p>
                                <p className="mt-1">{t("global:status")}  : <span className="font-semibold text-primary-hover">{pageData?.auto_renewal ? t("services:automaticRenewalOn") : t("services:automaticRenewalOff")}</span> </p>
                                <div className="flex items-end justify-end w-full mt-3">
                                    <Button onClick={onChangeProductAutoRenewal} >
                                        {t("global:change")}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-3 lg:col-span-1">
                            <div className="p-8 bg-white rounded-md shadow-md">
                                <h3 className="font-semibold text-title">{t("nav:invoices")}</h3>
                                <p className="mt-4">
                                    {t("services:invoicesDesc")}
                                </p>
                                <div className="flex items-end justify-end w-full mt-3">
                                    <Button onClick={() => setOpenBillsModal(true)}>
                                        {t("services:reviewInvoices")}
                                    </Button>
                                </div>
                            </div>
                            <div className="p-8 mt-6 bg-white rounded-md shadow-md">
                                <h3 className="font-semibold text-title">{t("services:cancelService")}</h3>
                                <p className="mt-4">{t("services:cancelProduct")}</p>
                                <div className="flex items-end justify-end w-full mt-3">
                                    <Button onClick={() => setOpenErrorModal(true)} textRed>
                                        {t("global:cancel")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {openDNSChangeModal && (
                        <DNSChangeModal setOpenModal={() => setOpenDNSChangeModal(false)} />
                    )}
                    {openWHOISModal && (
                        <WHOISModal setOpenModal={() => setOpenWHOISModal(false)} />
                    )}
                    {openBillsModal && (
                        <BillsModal typeID={pageData?.product_type_id} productID={pageData.id} setOpenModal={() => setOpenBillsModal(false)} setOpenProformaInformationModal={setOpenProformaInformationModal} />
                    )}
                    {openNoteModal && (
                        <AddNoteModal onUpdateNote={onUpdateNote} data={pageData?.customer_note}
                            setOpenModal={() => setOpenNoteModal(false)} />
                    )}
                    {openExtendServiceModal && (
                        <ExtendServiceModal
                            setOpenModal={() => setOpenExtendServiceModal(false)}
                        />
                    )}
                    {openBuyDNSServiceModal && (
                        <BuyDNSServicesModal
                            setOpenModal={() => setOpenBuyDNSServiceModal(false)}
                        />
                    )}
                    {openChangeProductProjectModal && (
                        <ChangeProductProjectModal
                            data={pageData}
                            setOpenModal={() => setOpenChangeProductProjectModal(false)}
                            setOpenWarningModal={setOpenWarningModal}
                        />
                    )}
                    {openWarningModal && (
                        <WarningModal
                            title={t("services:changeProductProject")}
                            question={t("services:changeProductProjectQues")}
                            errorText={t("services:changeProductProjectDesc")}
                            buttonText={t("services:yesToCarry")}
                            setOpenModal={() => setOpenWarningModal(false)}
                            onClick={() => onUpdateProject(openWarningModal)}

                        />
                    )}
                    {openInternalTransferModal && (
                        <InternalTransferModal
                            setOpenModal={() => setOpenInternalTransferModal(false)}
                        />
                    )}
                    {openTransferWarningModal && (
                        <WarningModal
                            title={t("services:closedForeignTransfer")}
                            question={t("services:notAvailableTransfer")}
                            description={t("services:openDomainForTransfer")}
                            buttonText={t("services:openTransfer")}
                            setOpenModal={() => setOpenTransferWarningModal(false)}
                            onClick={() => {
                                setOpenRemoveLockModal(true);
                                setOpenTransferWarningModal(false);
                            }}
                        />
                    )}
                    {openRemoveLockModal && (
                        <RemoveLockModal
                            titleColor="alert-red"
                            title={t("services:removeLock")}
                            question={t("services:removeLockDesc")}
                            buttonText={t("global:send")}
                            setOpenModal={() => setOpenRemoveLockModal(false)}
                            onClick={() => {
                                setOpenSuccessModal(true);
                                setOpenRemoveLockModal(false);
                            }}
                            svg={
                                <svg
                                    width="54"
                                    height="54"
                                    viewBox="0 0 54 54"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M27.0002 53.6668C12.2722 53.6668 0.333496 41.7282 0.333496 27.0002C0.333496 12.2722 12.2722 0.333496 27.0002 0.333496C41.7282 0.333496 53.6668 12.2722 53.6668 27.0002C53.6668 41.7282 41.7282 53.6668 27.0002 53.6668ZM24.3335 35.0002V40.3335H29.6668V35.0002H24.3335ZM24.3335 13.6668V29.6668H29.6668V13.6668H24.3335Z"
                                        fill="#FF4949"
                                    />
                                </svg>
                            }
                        />
                    )}
                    {openSuccessModal && (
                        <SuccessModal
                            title={t("services:transferCodeSent")}
                            description={t("services:transferCodeSentDesc")}
                            buttonText={t("global:okey")}
                            setOpenModal={() => setOpenSuccessModal(false)}
                        />
                    )}
                    {openProfessionalDNSModal && (
                        <ProfessionalDNSModal
                            title={t("services:transferCodeSent")}
                            description={t("services:transferCodeSentDesc")}
                            buttonText={t("global:okey")}
                            setOpenModal={() => setOpenProfessionalDNSModal(false)}
                        />
                    )}
                    {openErrorModal && (
                        <ErrorModal
                            title={t("services:cancelService")}
                            question={t("services:sureCancelService")}
                            errDescription={t("services:sureCancelServiceDesc")}
                            buttonText={t("global:yesDelete")}
                            setOpenModal={() => setOpenErrorModal(false)}
                            onClick={onCancelProduct}
                            reasonLabel={t("services:reasonForCancellation")}
                            reasonPlaceholder={t("services:enterReasonForCancellation")}
                        />
                    )}
                    {openCSRCreateModal && (
                        <CSRCreateModal
                            setOpenModal={() => setOpenCSRCreateModal(false)}
                            setOpenCSRCreatedModal={(v) => setOpenCSRCreatedModal(v)}
                        />
                    )}
                    {openCSRCreatedModal && (
                        <CSRCreatedModal
                            setOpenModal={() => setOpenCSRCreatedModal(false)}
                            setOpenCSRCreatedModal={(v) => setOpenCSRCreatedModal(v)}
                        />
                    )}
                    {openDownloadModal && (
                        <DownloadModal
                            title={t("services:downloadStarted")}
                            description={t("services:downloadStartedDesc")}
                            buttonText={t("services:okUnderstand")}
                            setOpenModal={() => setOpenDownloadModal(false)}
                        />
                    )}
                    {openProformaInformationModal && (
                        <ProformaInformationModal
                            setOpenModal={() => setOpenProformaInformationModal(false)}
                        />
                    )}
                </div>
                :
                <div className="flex flex-col items-center justify-center mt-20">
                    <img src="/servicesWaiting.svg" width="352" height="330" />
                    <p className="mt-3 text-5xl font-semibold text-primary-hover">{t("services:preparedService")}</p>
                    <p className="my-2">{t("services:preparedServiceDesc1")}</p>
                    <p>{t("services:preparedServiceDesc2")}</p>
                </div>
    )
}

export default ProxyServiceDetail