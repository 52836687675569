import React from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";

function ResponsibleCard({
                           setOpenModal,
                           setOpenCreateModal,
                           title,
                           description,
                           data,
                           isResponsible
                         }) {

                          const { t, i18n } = useTranslation(["settings", "global", "nav"]);

  return (
    <div className="px-4 pt-2 pb-4 bg-white rounded-md ">
      <div className="flex items-center justify-end">
        <i
          onClick={() => setOpenModal(data)}
          className="text-lg cursor-pointer ri-close-line text-passive "
        ></i>
      </div>
      <div>
        <h3 className="font-semibold text-main">{title}</h3>
        <p
          className="text-sm text-passive">{isResponsible ? description === "accounting" ? "Muhasebe" : (description === "tech" ? "Teknik" : "İdari") : description}</p>
        <Button
          onClick={() => setOpenCreateModal(data)}
          secondary
          className="flex items-center justify-center w-full px-3 mt-5 "
        >
          <i className="mr-2 ri-edit-line"></i>
          {t("global:edit")}
        </Button>
      </div>
    </div>
  );
}

export default ResponsibleCard;
