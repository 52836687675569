import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

function FinancialInformations() {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [isCopy, setIsCopy] = useState(false);

  const onCopyIban = (value) => {
    setIsCopy(value);
    setTimeout(() => {
      setIsCopy(false);
    }, 5000);
  };
  return (
    <div className="container">
      <h2 className="my-5 font-semibold">{t("accounting:financialCommercialInfo")}</h2>
      <div className="grid grid-cols-2 gap-10">
        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center justify-between mb-6">
            <img src="/garantiLogo.png" width="220" height="40" />
            <p className="text-xl font-medium">{t("accounting:turkishLira")}(TL)</p>
          </div>
          <div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountHolder")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Makdos Bilişim Teknolojileri San.
                Tic. Ltd. Şti.
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">IBAN No</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>TR41 0006 2000 3280 0006 2975 23
                <CopyToClipboard text={"TR41 0006 2000 3280 0006 2975 23"} onCopy={() => onCopyIban("1")}>
                  {isCopy === "1" ?
                    <i className="ml-4 font-bold cursor-pointer ri-check-fill text-alert-green"
                      title="Kopyalandı"></i>
                    :
                    <i className="ml-4 cursor-pointer ri-file-copy-line" title="Kopyala"></i>

                  }
                </CopyToClipboard>
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branch")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Yeşilköy
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branchCode")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>328
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountNo")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>6297523
              </p>
            </div>
          </div>
        </div>
        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center justify-between mb-6">
            <img src="/garantiLogo.png" width="220" height="40" />
            <p className="text-xl font-medium">USD ($)</p>
          </div>
          <div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountHolder")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Makdos Bilişim Teknolojileri San.
                Tic. Ltd. Şti.
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">IBAN No</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>TR96 0006 2000 3280 0009 0849 92
                <CopyToClipboard text={"TR96 0006 2000 3280 0009 0849 92 "} onCopy={() => onCopyIban("2")}>
                  {isCopy === "2" ?
                    <i className="ml-4 font-bold cursor-pointer ri-check-fill text-alert-green"
                      title="Kopyalandı"></i>
                    :
                    <i className="ml-4 cursor-pointer ri-file-copy-line" title="Kopyala"></i>

                  }
                </CopyToClipboard>
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branch")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Yeşilköy
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branchCode")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>328
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountNo")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>9084992
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center justify-between mb-6">
            <img src="/ziraatLogo.png" width="188" height="40" />
            <p className="text-xl font-medium">{t("accounting:turkishLira")} (TL)</p>
          </div>
          <div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountHolder")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Makdos Bilişim Teknolojileri San.
                Tic. Ltd. Şti.
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">IBAN No</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>TR68 0001 0026 4673 9649 6550 01
                <CopyToClipboard text={"TR68 0001 0026 4673 9649 6550 01"} onCopy={() => onCopyIban("3")}>
                  {isCopy === "3" ?
                    <i className="ml-4 font-bold cursor-pointer ri-check-fill text-alert-green"
                      title="Kopyalandı"></i>
                    :
                    <i className="ml-4 cursor-pointer ri-file-copy-line" title="Kopyala"></i>

                  }
                </CopyToClipboard>
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branch")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Radar-Yenibosna
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branchCode")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>2646
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountNo")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>73964965-5001
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center justify-between mb-6">
            <img src="/isbankLogo.png" width="150" height="40" />
            <p className="text-xl font-medium">{t("accounting:turkishLira")} (TL)</p>
          </div>
          <div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountHolder")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Makdos Bilişim Teknolojileri San.
                Tic. Ltd. Şti.
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">IBAN No</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>TR42 0006 4000 0011 1280 4255 40
                <CopyToClipboard text={"TR42 0006 4000 0011 1280 4255 40"} onCopy={() => onCopyIban("4")}>
                  {isCopy === "4" ?
                    <i className="ml-4 font-bold cursor-pointer ri-check-fill text-alert-green"
                      title="Kopyalandı"></i>
                    :
                    <i className="ml-4 cursor-pointer ri-file-copy-line" title="Kopyala"></i>

                  }
                </CopyToClipboard>
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branch")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>Yeşilköy
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:branchCode")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>1128
              </p>
            </div>
            <div className="grid grid-cols-5 mb-2">
              <p className="col-span-1 font-medium text-main">{t("accounting:accountNo")}</p>
              <p className="col-span-4 text-main">
                <span className="mr-2">:</span>425540
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center mb-6">
            <p className="text-xl font-medium">{t("accounting:ourCommercialInformation")}</p>
          </div>
          <div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">
                {t("accounting:registeredTaxOffice")}
              </p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>Yenibosna / İSTANBUL
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">{t("accounting:taxNumber")}</p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>6110628528
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">
                {t("accounting:registeredChamberOfCommerce")}
              </p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>İstanbul Ticaret Odası
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">{t("accounting:registrationDate")}</p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>05.10.2015
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">{t("accounting:corporateName")}</p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>Makdos Bilişim Teknolojileri San.
                Tic. Ltd. Şti.
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">
                {t("accounting:tradeRegisterNumber")}
              </p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>26625
              </p>
            </div>
            <div className="grid grid-cols-7 mb-2">
              <p className="col-span-2 font-medium text-main">{t("accounting:roomRegistrationNo")}</p>
              <p className="col-span-5 text-main">
                <span className="mr-2">:</span>15215
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-2 p-8 bg-white rounded-md shadow-md lg:col-span-1">
          <div className="flex items-center mb-6">
            <p className="text-xl font-medium">{t("accounting:documents")}</p>
          </div>
          <div className="grid grid-cols-5 gap-2">
            <a
              href="/vergilevhasi.png"
              target="blank"
              className="col-span-1 cursor-pointer"
            >
              <img className=" w-[111px] h-[163px]" src="/vergilevhasi_thumb.jpg" width="111" height="143" />
              <p className="mt-2 text-xs text-cardText">{t("accounting:taxBoard")}</p>
            </a>

            <a
              href="/2024OCAK2662_5_Faaliyet_Belgesi_5476191_page-0001.jpg"
              target="blank"
              className="col-span-1 "
            >
              <img className=" w-[111px] h-[163px]" src="/2024OCAK2662_5_Faaliyet_Belgesi_5476191_page-0001_thumb.jpg" width="111" height="143" />
              <p className="mt-2 text-xs text-cardText">{t("accounting:operatingCertificate")}</p>
            </a>

            <a
              href="/2024OCAK2662_5_Faaliyet_Belgesi_5476191_page-0002.jpg"
              target="blank"
              className="col-span-1 "
            >
              <img className=" w-[111px] h-[163px]" src="/2024OCAK2662_5_Faaliyet_Belgesi_5476191_page-0002_thumb.jpg" width="111" height="143" />
              <p className="mt-2 text-xs text-cardText">{t("accounting:operatingCertificate2")}</p>
            </a>

            <a
              href="/iso1.pdf"
              target="blank"
              className="col-span-1 "
            >
              <img className=" w-[111px] h-[163px]" src="/iso1.png" width="111" height="143" />
              <p className="mt-2 text-xs text-cardText">ISO 27001</p>
            </a>

            <a
              href="/iso2.pdf"
              target="blank"
              className="col-span-1 "
            >
              <img className=" w-[111px] h-[163px]" src="/iso2.png" width="111" height="143" />
              <p className="mt-2 text-xs text-cardText">ISO 22301</p>
            </a>


          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialInformations;
