import React from "react";

function Numeric({ value, setNumericValue }) {
  return (
    <button
      className="w-28 py-1 pl-1 pr-1 border flex justify-between items-center bg-white rounded-lg border-borderGray2 text-passive outline-none focus:outline-none  focus:border-0 focus:ring-1 focus:ring-primary-hover">
      <div className="w-full outline-none">
        <input
          className="w-full py-0 border-none focus:outline-none focus:ring-transparent group"
          type="number"
          onChange={(e) => setNumericValue(e.target.value)}
          value={value}
        />
      </div>
      <div className="flex items-center">
        <div className="mr-1 bg-borderGray2 w-0.5 h-5"></div>
        <div className="flex flex-col relative ">
          <i
            onClick={() => setNumericValue(value + 1)}
            className="ri-arrow-up-s-fill text-2xl py-0.5 leading-3"
          ></i>
          <i
            onClick={() => setNumericValue(value - 1)}
            className="ri-arrow-down-s-fill text-2xl py-0.5 leading-3"
          ></i>
        </div>
      </div>
    </button>
  );
}

export default Numeric;
