import React, { useState } from "react";
import moment from "moment/min/moment-with-locales";
import { ticketEncrypt, ticketSendDecrypt } from "services/allServices";
import Button from "components/FormElements/Button";
import ImageModal from "./TicketAndSupportModals/ImageModal";
import CheckBox from "components/FormElements/CheckBox";
import Validation from "components/Validation";

function TicketMessageBox({ message, onOpenMessages, checkID, allMessages, refreshMessages }) {
  const [fileList, setFileList] = useState();
  const [openImageModal, setOpenImageModal] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [OTP, setOTP] = useState("");

  const splitFileName = (file) => {
    if (file) {
      let splitedName = file.split("/");
      let newName = splitedName[splitedName?.length - 1];
      return newName;
    }

  };

  const onOpenImageModal = async (file) => {
    let newUrl =  file.file_path_https;
    await setFileUrl(newUrl);
    let splitedName = file.file_name.split(".")[file.file_name.split(".").length - 1];
    if (splitedName === "png" || splitedName === "jpg" || splitedName === "jpeg" ) {
      setOpenImageModal(true);
    } else {
      window.open(newUrl);
    }
  };
  const onDecryptMessage = async (e) => {
    let configure = {
      ticket_id: message.ticket_id,
      reply_id: message.id,
      is_all: isCheckAll
    };
    ticketSendDecrypt(configure).then((res) => {
      setOpenOTP(true);
    });
  };

  const onEncryptMessage = async () => {
    ticketEncrypt(message.ticket_id, message.id).then(res => {
      refreshMessages();
      setOpenOTP(false);
    });
  };
  const onSetOTP = (v) => {
    setOTP(v.toUpperCase());
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     getTicketAttachmentList(message.ticket_id, message.id).then((res) => {
  //       if (res.status !== "attahcment_not_found" && res.status !== "ticket_not_found") {
  //         setFileList(res);
  //       }
  //     });
  //   }, 2000);
  // }, [allMessages]);
  return (
    <div className="p-4 bg-white rounded-md shadow-sm lg:w-7/12">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src="/messageAvatar.png" width="42" height="42" />
          {message.reply_by_staff ? (
            <div>
              <p className="ml-3">
                {message.staff_name + " " + message.staff_surname.charAt(0) + "."}
              </p>
              <p className="mt-1 ml-3 text-passive">Yetkili</p>
            </div>
          ) : (
            <p className="ml-3">
              {message.customer_name + " " + message.customer_surname}
            </p>
          )}
        </div>
        <p>{moment(message.reply_time).format("DD-MM-YYYY h:mm")}</p>
      </div>
      {!message.is_concealed && (
        <div className="p-4 mt-2 whitespace-pre-wrap bg-modalBg">
          <div className="text-sm break-words text-main" dangerouslySetInnerHTML={{__html:message.reply_content}}></div>
          {/* <p className="text-sm text-main">{message.reply_content}</p> */}
        </div>
      )}
      {message.is_concealed && message.is_cansee && (
        <div className="p-4 mt-2 whitespace-pre-wrap bg-modalBg">
          <p className="break-words text-main">{message.reply_content}</p>
        </div>
      )}

      {message.is_concealed && !message.is_cansee && (
        <div
          className="flex flex-col items-center justify-center p-4 mt-2 text-center bg-opacity-25 bg-concealedMessage">
          {!openOTP ? (
            <>
              <p>
                Mesajı görmek için kilit kaldır butonuna tıklayarak size
                göndereceğimiz kodu giriniz.
              </p>
              <div className="flex items-center mt-5">
                <Button
                  onClick={(e) => onDecryptMessage(e)}
                  className="px-5"
                  secondary
                  icon
                  dark
                >
                  <i className="ri-lock-line" />
                  <span>Kilidi Kaldır</span>
                </Button>
                <div className="p-1 ml-5 rounded-md bg-borderGray ">
                  <CheckBox
                    id={checkID}
                    name={checkID}
                    label="Tüm Cevapları Göster"
                    onChange={() => setIsCheckAll(!isCheckAll)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              <p>Size gönderilen kodu giriniz</p>
              <div>
                <Validation
                  value={OTP}
                  onChange={onSetOTP}
                  autoFocus
                  inputClassName={
                    "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                  }
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
              <div className="flex justify-center mt-5">
                <Button
                  onClick={() =>
                    onOpenMessages(
                      message.ticket_id,
                      message.id,
                      isCheckAll,
                      OTP
                    )
                  }
                  className="px-5"
                  secondary
                  icon
                  dark
                >
                  <i className="ri-lock-line" />
                  <span>Kilidi Kaldır</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex items-center justify-between">
        {allMessages && (message.is_cansee || !message.is_concealed) ? (
          <div className="flex pb-1 mt-3 overflow-x-auto customScroll">
            {allMessages?.attachments?.filter(file => file?.content?.reply_id === message.id).map((file, index) => {
              return (
                <div className="flex items-center px-2 py-1 mr-5 border rounded-md border-borderGray2" key={index}>
                  <p className="mr-3">{splitFileName(file?.content?.file_path?.file_path_https)}</p>
                  <a
                    title="indir"
                    onClick={() => {
                      fetch(file?.content?.file_path?.file_path_https, { method: "GET" }).then((res) => {
                        res.arrayBuffer().then((buffer) => {
                          const url = window.URL.createObjectURL(new Blob([buffer]));
                          const img = document.createElement("a");
                          img.href = url;
                          img.setAttribute("download", splitFileName(file?.content?.file_path?.file_path_https));
                          document.body.appendChild(img);
                          img.click();
                        });
                      });
                    }}
                    download
                  >
                    <i className="text-xl cursor-pointer ri-download-2-line text-primary-hover"></i>
                  </a>
                  <a title="Önizleme">
                    <i
                      onClick={() =>
                        onOpenImageModal(file?.content?.file_path)
                      }
                      className="ml-2 text-lg cursor-pointer ri-eye-line"
                    ></i>
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
        <div className="mt-3 ml-5">
          {!message.is_concealed && !message.reply_by_staff && (
            <i onClick={() => onEncryptMessage()} className="cursor-pointer ri-lock-unlock-line"></i>
          )}
          {message.is_concealed &&
            message.is_cansee &&
            !message.reply_by_staff &&
            <i onClick={() => onEncryptMessage()} className="cursor-pointer ri-lock-unlock-line"></i>}
        </div>
      </div>

      {openImageModal && (
        <ImageModal
          imgUrl={fileUrl}
          setOpenModal={() => setOpenImageModal(false)}
        />
      )}
    </div>
  );
}

export default TicketMessageBox;
