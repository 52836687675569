import styled, { css } from "styled-components";

const Label = styled.label`
  margin: 0.5rem auto;
  color: #555555;
  font-size: 14px;
`;
const Error = styled(Label)`
  --tw-ring-color: #ff4949;
  color: #ff4949;
  font-size: 14px;
`;
const Textarea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  color: #555555;
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.375rem 0 0 0;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-color: #d8d8d8;
  border-radius: 0.375rem;

  &:focus {
    --tw-ring-color: #ff7c30;
  }

  &:disabled {
    background-color: #d8d8d8;
    color: #999999;
    cursor: not-allowed;
  }

  ${(props) =>
  props.error &&
  css`
      --tw-ring-color: #ff4949;
    `};
`;
export { Label, Error, Textarea };
