import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "components";
import { getInformationCenterData } from "services/allServices";
import { TailSpin } from "react-loader-spinner";

function InformationCenter() {
  const { t } = useTranslation(["support", "global"]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState();
  const [completedTyping, setCompletedTyping] = useState();
  const [displayResponse, setDisplayResponse] = useState();

  const onSearch = async () => {
    setIsLoading(true);
    await getInformationCenterData({ search_text: search }).then(res => {
      if (res?.status === "out_of_limit") {
        setIsLoading(false);
        return setSearchData("Günlük arama sınırını aştınız. Günlük limitiniz 5 aramadır.  Lütfen daha sonra tekrar deneyin.");

      }
      setSearchData(res);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    if (searchData) {
      setCompletedTyping(false);

      let i = 0;
      const stringResponse = searchData

      const intervalId = setInterval(() => {
        setDisplayResponse(stringResponse.slice(0, i));

        i++;

        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }, 20);

      return () => clearInterval(intervalId);
    }

  }, [searchData]);

  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("support:informationCenter")}</h2>
        <TextField
          id="search"
          name="search"
          type="search"
          value={search}
          onChange={setSearch}
          onClick={() => onSearch()}
          onKeyPress={onSearch}
          placeholder={t("support:search")}
          className="w-80"
        />
      </div>

      {isLoading ?
        <div className="flex items-center justify-center w-full mt-20">
          <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
            type="Oval"
            color="#ff7c30"
            height={75}
            width={75}
            timeout={3000} //3 secs
          />
        </div>
        :
        (searchData ?
          <div className="p-4 bg-white rounded-md shadow-md min-h-72">
            <p className="text-lg whitespace-pre-wrap text-main">
              {displayResponse}
              {!completedTyping && <svg
                viewBox="8 4 8 16"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor"
              >
                <rect x="10" y="6" width="4" height="12" fill="#555555" />
              </svg>}
            </p>

          </div>
          :
          <div className="flex items-center justify-center w-full py-32 bg-white rounded-md">
            <div className="flex flex-col items-center text-center">
              <img src="/InformationCenter.svg" width="405" height="302" />
              <h1 className="mt-5">{t("support:informationCenter")}</h1>
              <h5 className="font-semibold">
                {t("support:informationCenterDesc")}
              </h5>
            </div>
          </div>
        )


      }

    </div>
  );
}

export default InformationCenter;
