import React from "react";
import Proforma from "components/Accounting/Proforma";
import { useTranslation } from "react-i18next";
import Tabs from "components/Tabs";
import PaymentHistory from "components/Accounting/PaymentHistory";
import FinancialInformations from "components/Accounting/FinancialInformations";
import Invoices from "components/Accounting/Invoices";
import { useGlobalStore } from "store/GlobalStore";
import { useNavigate, useParams } from "react-router";
import { isNullOrEmptyOrUndefined } from "utils/common";

function Accounting() {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const [isResponsible, permissions] = useGlobalStore((state) => [state.isResponsible, state.permissions]);
  let slug = useParams();
  let navigate = useNavigate();

  const tabs2 = [
    { name: "Proformalarım", id: "1", url: "/accounting" },
    { name: "Faturalarım", id: "2", url: "/accounting/invoices" },
    { name: "Ödeme Geçmişi", id: "3", url: "/accounting/payment-history" },
    { name: "Finansal ve Ticari Bilgiler", id: "4", url: "/accounting/financial-informations" }
  ];

  const tabs = [
    {
      name: t("accounting:myProformas"),
      id: "1",
      component: <Proforma />,
      slug: "",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "proforma-invoices" && item.checked === true)?.checked) : true
    },
    {
      name: t("accounting:myBills"),
      id: "2",
      component: <Invoices />,
      slug: "invoices",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "invoices" && item.checked === true)?.checked) : true
    },
    {
      name: t("accounting:paymentHistory"),
      id: "3",
      component: <PaymentHistory />,
      slug: "payment-history",
      visible: isResponsible ? (permissions.length === 0 || permissions?.find((item) => item.id === "payment-history" && item.checked === true)?.checked) : true
    },
    {
      name:  t("accounting:financialCommercialInfo"),
      id: "4",
      component: <FinancialInformations />,
      slug: "financial-informations",
      visible: true
    }
  ];


  return (
    <div className="container relative w-full ">
      <Tabs
        tabs={tabs.filter((x) => x.visible)}
        selectedTab={
          !isNullOrEmptyOrUndefined(slug.url)
            ? tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.id ?? tabs.filter((x) => x.visible)[0]?.id
            : tabs.filter((x) => x.visible)[0]?.id
        }
        setSelectedTab={(v) => {
          navigate(
            "/accounting/" +
            tabs.find((x) => x.id === v).slug
          );
        }}
      />


      <div className="mt-6">
        {!isNullOrEmptyOrUndefined(slug.url)
          ? (tabs.filter((x) => x.visible).find((x) => x.slug === slug.url)?.component) ?? tabs.filter((x) => x.visible)[0]?.component
          : tabs.filter((x) => x.visible)[0]?.component
        }
      </div>
    </div>
  );
}

export default Accounting;
