import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import Validation from "components/Validation";
import { checkCustomerProductTransferRequest, customerProductTransferRequest } from "services/allServices";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";

function InternalTransferModal({ data, setOpenModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "signup", "services"]);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [customerCode, setCustomerCode] = useState("");

  const [OTP, setOTP] = useState("");
  const [openOTP, setOpenOTP] = useState(false);
  const [codeAgainShow, setCodeAgainShow] = useState(true);


  const onSetOTP = (v) => {
    setOTP(v.toUpperCase());
  };

  const onApply = () => {
    if (!openOTP) {
      customerProductTransferRequest(data?.product_type_id, data?.id, customerCode).then(res => {
        if (res.status === "transfer_requested") {
          setOpenOTP(true);
        } else if (res.status === "invalid_customer_code") {
          addToast(t("services:authCodeIncorrect"), {
            appearance: "error",
            autoDismiss: true
          });
        } else {
          addToast(t("services:somethingWentWrong"), {
            appearance: "error",
            autoDismiss: true
          });
        }
      });
    } else {
      checkCustomerProductTransferRequest(data?.product_type_id, data?.id, customerCode, OTP).then(res => {
        if (res.status === "invalid_request") {
          addToast(t("services:invalidRequest"), {
            appearance: "error",
            autoDismiss: true
          });
        } else if (res.status === "time_expired") {
          addToast(t("services:timesUp"), {
            appearance: "error",
            autoDismiss: true
          });
        } else {
          addToast(t("services:operationSuccessful"), {
            appearance: "success",
            autoDismiss: true
          });
          navigate("/services");
        }
      });
    }
  };

  useEffect(() => {
    if (!codeAgainShow) {
      setTimeout(() => {
        setCodeAgainShow(true);
      }, 30000);
    }
  }, [codeAgainShow]);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:productInternalTransfer")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 bg-modalBg ">
                  {!openOTP &&
                    <div>
                      <div className="">
                        <TextField
                          id="code"
                          name="code"
                          label={t("services:customerAuthorizationCode")}
                          placeholder="örn: CP74112679"
                          onChange={setCustomerCode}
                          value={customerCode}
                        />
                      </div>
                      <p className="my-5">
                        {t("services:productInternalTransferDesc")}
                      </p>
                    </div>
                  }


                  {openOTP &&


                    <div className="mt-5">
                      <p>{t("services:confirmationCode")}</p>
                      <div className="flex items-center">
                        <Validation
                          value={OTP}
                          onChange={onSetOTP}
                          autoFocus
                          inputClassName={
                            "text-gray-500 border border-passive  focus:border-1 focus:border-primary-hover focus:ring-1 focus:ring-primary-hover focus:outline-none"
                          }
                          OTPLength={6}
                          disabled={false}
                          secure
                        />
                        {/* <div className="flex ml-5">
                        <i className="mr-1 ri-timer-line text-passive"></i>
                        <p className="mt-0.5 text-passive">60 sn</p>
                      </div> */}
                      </div>

                      <div className="flex items-center">
                        <p className="mt-1">{t("services:confirmationCodeQues")}</p>
                        {codeAgainShow &&
                          <Button className="ml-4">{t("signup:codeagain")}</Button>
                        }
                      </div>
                    </div>
                  }
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={onApply}
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternalTransferModal;
