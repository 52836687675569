import React, { useEffect, useState } from "react";
import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";
import ProformaTable from "./AccountingTables/ProformaTable";
import { getProformaList, multiplePayProforma } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import AddBalanceModal from "./AccountingModals/AddBalanceModal";
import { useCheckListObj } from "hooks";
import MultiplePaymentModal from "./AccountingModals/MultiplePaymentModal";
import MultiplePaymentTransferModal from "./AccountingModals/MultiplePaymentTransferModal";
import ProformaInformationModal from "./AccountingModals/ProformaInformationModal";
import { useLocation } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import ProformaFilterModal from "./AccountingModals/ProformaFilterModal";
import CreditCardModal from "./AccountingModals/CreditCardModal";
import PaypalModal from "./AccountingModals/PaypalModal";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { useToasts } from "react-toast-notifications";

function Proforma() {
  const { t, i18n } = useTranslation(["projects", "global", "accounting"]);
  const { addToast } = useToasts();
  const location = useLocation();
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [proformas, setProformas] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLimit, proformaFilterData, setProformaFilterData] = useGlobalStore((state) => [state.pageLimit, state.proformaFilterData, state.setProformaFilterData]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openMultiplePaymentModal, setOpenMultiplePaymentModal] = useState(false);
  const [openProformaInformationModal, setOpenProformaInformationModal] = useState(false);
  const [checkList, onSetCheckList, setCheckList] = useCheckListObj();
  const [check, setCheck] = useState(false);
  const [selectedTotalPrice, setSelectedTotalPrice] = useState(false);
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false);
  const [openPaypalModal, setOpenPaypalModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedProformaData, setSelectedProformaData] = useState();

  const onGetProformaList = (page, limit, configureFilter) => {
    setIsLoading(true);
    getProformaList(page, limit, configureFilter ? configureFilter : proformaFilterData).then((data) => {
      if (data?.status !== "proforma_invoices_not_found") {
        setProformas(data);
        setNumberOfPages(data[0]?.number_of_pages);
        setIsLoading(false);
      }
      if (data?.status === "proforma_invoices_not_found") {
        setProformas("proforma_invoices_not_found");
      }
    });
  };


  const onSetPay = async (payment_amount, currencycode, payment_method, isCheckPay, selectedProformaMethod, selectedProformasData) => {
    if (payment_method === "transfer") {
      setOpenTransferModal(selectedProformaMethod);
      setSelectedProformaData(selectedProformasData);
    } else if (isCheckPay === "pay2") {
      //await setPostValues(configureValue);
      // setOpenPaypalModal(paymentAmount);
    } else {
      multiplePayProforma(payment_amount, payment_method, currencycode).then((data) => {
        if (data?.status === "proforma_error") {

          addToast(data?.message + " " + "numaralı proforma için ödeme yapamazsınız.", {
            appearance: "error",
            autoDismiss: true
          });
        } else {
          if (payment_method === "balance") return setOpenBalanceModal(true);
          if (isCheckPay === "pay1") return setOpenCreditCardModal(data);
        }
      })
    }

  }

  const onSetCheck = () => {
    const tempArr = [];
    if (check === true) {
      setCheck(false);
      setCheckList([]);
    } else {
      setCheck(true);
      proformas.map((d) => {
        if (d.status !== "completed") {
          let configure = {
            id: d.id,
            project_name: d.billing_infos[0]?.projectname,
            price: d.with_tax_total_price_project,
            proforma_no: d.proforma_invoice_no
          };
          tempArr.push(configure);
        }

      });
      setCheckList(tempArr);
    }
  };

  const onSetOpenMultiplePaymentModal = () => {
    if (checkList?.length > 0) {
      setOpenMultiplePaymentModal(true);
    }
  };
  useEffect(() => {

    const asyncFunction = async () => {

      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }
      let configureFilter = {
        project_id: null,
        status: null,
        price: null,
        billing_date: null,
        payment_date: null
      };

      if (!proformaFilterData || proformaFilterData == "") {
        await setProformaFilterData(configureFilter);
        await onGetProformaList(page, pageLimit, configureFilter);

      } else {
        await onGetProformaList(page, pageLimit, proformaFilterData);
      }


    };
    asyncFunction();

  }, []);

  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("accounting:myProformas")}</h2>
        <div className="items-center lg:flex ">
          <div className="flex">
            <Button
              onClick={() => setOpenBalanceModal(true)}
              secondary
              icon
              className="flex items-center mr-5 px-7"
            >
              <i className="ri-add-circle-line" />
              <span>{t("accounting:addBalance")}</span>
            </Button>
          </div>
          <div className="flex my-5 lg:my-0">
            <div className="relative">
              <Button
                onClick={() => setOpenFilterModal(true)}
                className="flex items-center mr-5 px-7"
                tertiary
              >
                <i className="ri-equalizer-line" />
                <span>{t("projects:filter")}</span>
              </Button>
              {(proformaFilterData?.count && proformaFilterData?.count > 0) ?
                <div
                  className="absolute flex items-center justify-center w-5 h-5 text-sm text-center text-white rounded-full -top-2 right-3 bg-primary-hover">
                  {proformaFilterData?.count}
                </div>
                :
                <></>
              }
            </div>

            {
              proformas && proformas !== "proforma_invoices_not_found" &&
              <Button
                onClick={() => onSetOpenMultiplePaymentModal(true)}
                className="flex items-center px-7"
                secondary
                icon
                dark
                disabled={checkList.length === 0}
              // disabled={proformas?.find((i) => i.status === "completed" || i.status === "canceled" || i.status === "refunded")}
              >
                <i className="ri-checkbox-circle-line" />
                <span>{t("accounting:paySelectedProforma")}</span>
              </Button>
            }

          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        {proformas !== "proforma_invoices_not_found" && proformas && !isLoading ? (
          <ProformaTable
            proformas={proformas}
            onSetCheck={() => onSetCheck()}
            onSetCheckList={(v) => onSetCheckList(v)}
            checkList={checkList}
            openProformaInformationModal={() =>
              setOpenProformaInformationModal(true)
            }
            onGetProformaList={onGetProformaList}
            numberOfPages={numberOfPages}
          />
        ) : proformas === "proforma_invoices_not_found" && proformas ? (
          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/servicesOther.svg" width="403" height="339" />
            <h1>{t("accounting:noProforma")}</h1>
            <h5>{t("global:productDesc")}</h5>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full mt-20">
            <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
              type="Oval"
              color="#ff7c30"
              height={75}
              width={75}
              timeout={3000} //3 secs
            />
          </div>
        )}
      </div>

      {openBalanceModal && (
        <AddBalanceModal setOpenModal={() => setOpenBalanceModal(false)} />
      )}
      {openMultiplePaymentModal && (
        <MultiplePaymentModal
          tableData={checkList}
          setOpenModal={() => setOpenMultiplePaymentModal(false)}
          onSetPay={onSetPay}
        />
      )}
      {openTransferModal && (
        <MultiplePaymentTransferModal
          setOpenModal={() => setOpenTransferModal(false)}
          price={openTransferModal?.final_price}
          proformaNoList={selectedProformaData?.proforma_list}
          selectedPaymentMethod={openTransferModal}

        />
      )}


      {openFilterModal && (
        <ProformaFilterModal pageLimit={pageLimit} onClick={onGetProformaList} proformaFilterData={proformaFilterData}
          setProformaFilterData={setProformaFilterData}
          setOpenModal={() => setOpenFilterModal(false)} />
      )}
      {openProformaInformationModal && (
        <ProformaInformationModal
          setOpenModal={() => setOpenProformaInformationModal(false)}
        />

      )}

      {openCreditCardModal && (
        <CreditCardModal
          setOpenModal={() => setOpenCreditCardModal(false)}
          postValues={openCreditCardModal}
        />
      )}
      {openPaypalModal && (
        <PaypalModal
          setOpenModal={() => setOpenPaypalModal(false)}
          postValues={openPaypalModal}
        />
      )}
    </div>
  );
}

export default Proforma;
