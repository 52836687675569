import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { checkRedirectToken } from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import api from "services/api";
import CryptoJS from "crypto-js";
import { useGlobalStore } from "store/GlobalStore";

function WebLogin() {

  const location = useLocation();
  const [isLoggin, setIsLoggin, setUserInformations] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.setUserInformations
  ]);

  const [setPermissions, setIsResponsible] = useGlobalStore(state => [state.setPermissions, state.setIsResponsible]);

  const navigate = useNavigate();

  const encryptMessage = (message) => {
    let secretKey = process.env.REACT_APP_CRYPTO_KEY;
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(message), secretKey);
    return cipherText.toString();
  };

  useEffect(() => {
    setIsLoggin(false);
    if (location?.search) {
      let token = location.search.split("=")[1];
      checkRedirectToken(token).then(res => {
        let language = localStorage.getItem("ln");
        localStorage.clear();
        localStorage.setItem("ln", language);
        localStorage.setItem("__mp_tk", res.access_token);
        localStorage.setItem("__is_logged_in", true);
        if (res.permissions && res?.is_responsible) {
          localStorage.setItem("__u_per", encryptMessage(res?.permissions));
          setPermissions(res?.permissions);
        }
        localStorage.setItem("__u_is_resp", res?.is_responsible);
        setIsResponsible(res?.is_responsible);
        setIsLoggin(true);
        setUserInformations(res);
        api.defaults.headers.Authorization = `Bearer ${res.access_token}`;
        setTimeout(function() {
          navigate("/");
        }, 500);
      }).catch(err => {
        navigate("/login");

      });
    }

  }, []);
  return (
    <div></div>
  );
}

export default WebLogin;