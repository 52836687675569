import React from "react";

function NotificationCard({ text, date }) {
  return (
    <div className="flex items-center justify-between p-3 duration-300 bg-white rounded-md shadow-sm hover:shadow-md">
      <p className="text-main">{text}</p>
      <p className="text-textGray">{date}</p>
    </div>
  );
}

export default NotificationCard;