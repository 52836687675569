import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";

function VirtualIPFilterModal({ setOpenModal, onClick, ipRecordsFilterData, setIPRecordsFilterData }) {
  const { t, i18n } = useTranslation(["projects", "global"]);

  const [ipAddress, setIPAddress] = useState("");
  const [subnet, setSubnet] = useState("");

  const onApply = () => {
    let configureFilter = {
      ip_address: ipAddress && ipAddress != "" ? ipAddress : null,
      subnet: subnet && subnet != "" ? subnet : null
    };

    setIPRecordsFilterData({
      ...configureFilter,
      count: Object.entries(configureFilter).filter(item => item[1] !== null).length
    });
    onClick(configureFilter);
    setOpenModal(false);
  };

  const onClear = () => {
    let configureFilter = {
      ip_address: null,
      subnet: null
    };
    setIPRecordsFilterData({ ...configureFilter, count: 0 });
    onClick(configureFilter);
    setOpenModal(false);

  };

  useEffect(() => {
    if (ipRecordsFilterData.ip_address) {
      setIPAddress(ipRecordsFilterData.ip_address);
    }
    if (ipRecordsFilterData.subnet) {
      setSubnet(ipRecordsFilterData.subnet);
    }

  }, []);


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("services:filterRecords")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="w-full px-5 py-10 bg-modalBg">
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-2 lg:col-span-1">
                      <TextField
                        label={t("services:ipAddress")}
                        placeholder="999.999.999.999"
                        value={ipAddress}
                        onChange={setIPAddress}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <TextField
                        label="Subnet"
                        placeholder="/32"
                        value={subnet}
                        onChange={setSubnet}
                      />
                    </div>
                    {/* <div className="col-span-2 lg:col-span-1">
                      <TextField label="Ürün ID" placeholder="9999999" />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <TextField label="Ürün Adı" placeholder="Ürün Adı" />
                    </div> */}
                  </div>
                </div>

                <div className="flex flex-col-reverse items-end justify-end px-5 py-3 bg-white lg:flex-row lg:px-0">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    tertiary
                  >
                    {t("global:giveUp")}
                  </Button>
                  <Button
                    onClick={() => onClear()}
                    className="w-full px-8 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                    secondary
                  >
                    {t("global:reset")}
                  </Button>
                  <Button
                    className="w-full px-8 mr-0 lg:mr-5 lg:w-auto"
                    primary
                    onClick={onApply}
                  >
                    {t("global:apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualIPFilterModal;
