import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useComboBox } from "hooks";
import ComboBox from "components/FormElements/ComboBox";
import { useNavigate } from "react-router";
import { getProjectList, listProductAllWithProjects } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import VirtualServersTable from "components/Services/AllProductServices/Tables/VirtualServersTable";
import PhysicalServerTable from "components/Services/AllProductServices/Tables/PhysicalServerTable";
import DomainsTable from "components/Services/AllProductServices/Tables/DomainsTable";
import WebHostingTable from "components/Services/AllProductServices/Tables/WebHostingTable";
import MailHostingTable from "components/Services/AllProductServices/Tables/MailHostingTable";
import SSLTable from "components/Services/AllProductServices/Tables/SSLTable";
import { useGlobalStore } from "store/GlobalStore";
import CloudServersTable from "components/Services/AllProductServices/Tables/CloudServersTable";
import CoLocationTable from "components/Services/AllProductServices/Tables/CoLocationTable";
import ControlPanelTable from "components/Services/AllProductServices/Tables/ControlPanelTable";


function ProjectServices({ projectName }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [selected, list, changeSelected, setList] = useComboBox("Seçiniz");
  const navigate = useNavigate();
  const [refreshSidebar, setRefreshSidebar] = useGlobalStore((state) => [state.refreshSidebar, state.setRefreshSidebar]);
  const [allListInfo, setAllListInfo] = useState([]);
  const [listProduct, setListProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onListProductAllWithProjects = (id) => {
    setIsLoading(true);
    listProductAllWithProjects(id).then(res => {
      setListProduct(res);
      setIsLoading(false);

    }).catch(err => {
      navigate("/");
      setRefreshSidebar(!refreshSidebar);
    });
  };

  useEffect(() => {
    getProjectList().then(async res => {
      setList(res, "id", "name");
      setAllListInfo(res);
    });
  }, []);

  useEffect(() => {
    if (selected.id > 0) {
      onListProductAllWithProjects(selected.id);
    } else {
      setListProduct();
    }

  }, [selected]);


  useEffect(() => {
    const selectedProject = allListInfo.find(item => item.slug === projectName);
    if (selectedProject) {
      changeSelected(selectedProject.id, selectedProject.name)
    }
  }, [projectName, allListInfo]);

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row lg:items-center">
        <h2 className="my-5 font-semibold">{t("projects:servicesUsed")}</h2>
        <div className="items-center lg:flex">
          {/* <TextField
            id="search"
            name="search"
            type="search"
            ref={null}
            className="relative mr-5"
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Ürün Adı Giriniz"
          /> */}
          <div>
            <ComboBox
              id="select"
              name="select"
              selected={selected}
              setSelected={changeSelected}
              list={list}
              defaultText="Seçiniz"
            />
          </div>
        </div>
      </div>

      {!isLoading ?
        <div>
          {listProduct && listProduct?.cloud_server_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:virtualServers")}</h2>

              <VirtualServersTable datas={listProduct?.cloud_server_list} />
            </div>

          }

          {listProduct && listProduct?.cloud_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:cloudServers")}</h2>

              <CloudServersTable datas={listProduct?.cloud_list} />
            </div>

          }

          {listProduct && listProduct?.dedicated_server_list?.length !== 0 &&

            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:physicalServers")}</h2>

              <PhysicalServerTable datas={listProduct?.dedicated_server_list} />
            </div>

          }

          {listProduct && listProduct?.co_location_list?.length !== 0 &&

            <div className="mb-5">
              <h2 className="my-5 font-semibold">Co-Location</h2>

              <CoLocationTable datas={listProduct?.co_location_list} />
            </div>


          }


          {listProduct && listProduct?.domain_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:domainNames")}</h2>

              <DomainsTable datas={listProduct?.domain_list} />
            </div>
          }

          {listProduct && listProduct?.web_hosting_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:webHosting")}</h2>

              <WebHostingTable datas={listProduct?.web_hosting_list} />
            </div>
          }

          {listProduct && listProduct?.mail_hosting_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:mailHosting")}</h2>

              <MailHostingTable datas={listProduct?.mail_hosting_list} />
            </div>
          }

          {listProduct && listProduct?.ssl_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("projects:sslCertificate")}</h2>

              <SSLTable datas={listProduct?.ssl_list} />
            </div>
          }

          
          {listProduct && listProduct?.control_panel_list?.length !== 0 &&
            <div className="mb-5">
              <h2 className="my-5 font-semibold">{t("global:controlPanel")}</h2>

              <ControlPanelTable datas={listProduct?.control_panel_list} />
            </div>
          }


          {listProduct?.cloud_server_list?.length === 0 &&
            listProduct?.dedicated_server_list?.length === 0 &&
            listProduct?.domain_list?.length === 0 &&
            listProduct?.web_hosting_list?.length === 0 &&
            listProduct?.mail_hosting_list?.length === 0 &&
            listProduct?.ssl_list?.length === 0 &&
            <div className="flex flex-col items-center justify-center my-10 lg:my-20">
              <img src="/servicesOther.svg" width="403" height="339" />
              <h1>{t("global:noProduct")}</h1>
              <h5>{t("global:productDesc")}</h5>
            </div>
          }

        </div>
        :
        <div className="flex items-center justify-center w-full mt-20">
          <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
            type="Oval"
            color="#ff7c30"
            height={75}
            width={75}
            timeout={3000} //3 secs
          />
        </div>
      }
      {/* <div className="flex justify-end">
        <Pagination
          pageItems={dummyDizi}
          paginationValue={paginationValue}
          setPaginationValue={(value) => onSetPaginationValue(value)}
          pageValue={paginationDisplayValue}
          setPageValue={(value) => onSetPaginationDisplayValue(value)}
        />
      </div> */}
    </div>
  );
}

export default ProjectServices;
