import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";

function WarningModal({
                        title,
                        question,
                        description,
                        errorText,
                        setOpenModal,
                        buttonText,
                        onClick
                      }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-modalBg">
            <div className="flex justify-end px-2 py-1">
              <i
                onClick={() => setOpenModal(false)}
                className="text-2xl cursor-pointer ri-close-line text-passive"
              ></i>
            </div>

            <div className="flex flex-col items-center px-5 pt-4 pb-10 bg-modalBg">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.3093 7.99972L59.7119 51.9997C59.946 52.4051 60.0692 52.8649 60.0692 53.333C60.0692 53.8011 59.946 54.261 59.7119 54.6664C59.4779 55.0717 59.1413 55.4084 58.7359 55.6424C58.3305 55.8765 57.8707 55.9997 57.4026 55.9997H6.59726C6.12917 55.9997 5.66933 55.8765 5.26395 55.6424C4.85858 55.4084 4.52195 55.0717 4.28791 54.6664C4.05387 54.261 3.93066 53.8011 3.93066 53.333C3.93067 52.8649 4.05388 52.4051 4.28793 51.9997L29.6906 7.99972C29.9247 7.59437 30.2613 7.25776 30.6667 7.02374C31.072 6.78971 31.5319 6.6665 31.9999 6.6665C32.468 6.6665 32.9278 6.78971 33.3332 7.02374C33.7386 7.25776 34.0752 7.59437 34.3093 7.99972ZM29.3333 42.6664V47.9997H34.6666V42.6664H29.3333ZM29.3333 23.9997V37.3331H34.6666V23.9997H29.3333Z"
                  fill="#FFC62F"
                />
              </svg>

              <h2 className="my-1 font-semibold text-alert-yellow">{title}</h2>
              {question && <p>{question}</p>}
              {description && (
                <p className="mt-5 text-center lg:mx-10">{description}</p>
              )}
              {errorText && (
                <p className="mt-5 text-center text-alert-red lg:mx-10">
                  {errorText}
                </p>
              )}
            </div>

            <div className="flex flex-col-reverse items-end justify-center px-5 py-3 bg-white lg:flex-row lg:px-0">
              <Button
                onClick={() => setOpenModal(false)}
                className="w-full px-12 mt-3 mr-0 lg:mt-0 lg:mr-5 lg:w-auto"
                tertiary
              >
                {t("global:giveUp")}
              </Button>
              <Button
                onClick={onClick}
                warning
                className="w-full px-12 mr-0 lg:w-auto bg-alert-yellow"
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarningModal;
