import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { listServiceInvoices } from "services/allServices";
import DomainsBillsTable from "../Domains/Tables/DomainsBillsTable";
import { useToasts } from "react-toast-notifications";

function BillsModal({ setOpenModal, typeID, productID, setOpenProformaInformationModal }) {
  const { t, i18n } = useTranslation(["projects", "global", "nav", "services"]);

  const [invoices, setInvoices] = useState();
  const { addToast } = useToasts();

  const onListServiceInvoices = () => {
    listServiceInvoices(productID, typeID).then((res) => {
      if (res.status === "proforma_not_found") {
        addToast(t("services:noInvoiceForProduct"), {
          appearance: "error",
          autoDismiss: true
        });
        setOpenModal(false);
      }
      else {
        setInvoices(res);
      }

    });
  };


  useEffect(() => {
    onListServiceInvoices();
  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <h3
                    className="text-xl font-semibold leading-6 text-title"
                    id="modal-headline"
                  >
                    {t("nav:invoices")}
                  </h3>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 py-5 overflow-y-scroll bg-modalBg h-96 ">
                  {invoices ?
                    <DomainsBillsTable invoices={invoices} setOpenProformaInformationModal={setOpenProformaInformationModal} />
                    :
                    <div className="flex items-center justify-center w-full mt-20">
                      <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                        type="Oval"
                        color="#ff7c30"
                        height={75}
                        width={75}
                        timeout={3000} //3 secs
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillsModal;
